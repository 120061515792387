import React, { Fragment } from "react";
import SearchSort from "./SearchSort";
import { withRouter } from "react-router";
import PatientInfo from "./PatientInfo";
import { connect } from "react-redux";
import Filters from "./Filter";
import PatientBlock from "./PatientBlock";
import FullScreenLoader from "./FullScreenLoader";
import NoResultsPopup from "./Popup/NoResultsPopup";
import editSearch from "../../images/edit_search.svg";
import SearchSites from "./SearchSites";
import { Spinner } from "./../../ui/spinner";
import InmunityApi from "../../api/InmunityApi";
import {
  setPatientsResult,
  setPatientsFilterResult,
  getSearchForPopulation,
  setCurrentNavigationLink,
  setPatientOverview
} from "../../actions/trialActions";
import InfiniteScroll from "react-infinite-scroller";
import arrow from "../../images/left.png";
import "./index.css";
import moment from "moment";
import _ from "lodash";

class Patients extends React.Component {
  state = {
    searchResult: [],
    initialSearch: [],
    filters: [],
    searchId: "",
    isMore: false,
    link: "",
    receivedData: [],
    count: 0,
    loading: false,
    isFetching: false,
    noResults: false,
    anchor: false,
    activeTab: 1,
    sites: [],
    hospitals: [],
    site: [],
    patientSpinner: false,
    hospitalSpinner: false,
    siteSpinner: false,
    mainSpinner: false,
    analiticData: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let {
      patientsResult,
      location,
      setPatientsResult,
      setCurrentNavigationLink
    } = this.props;
    setCurrentNavigationLink(2);
    let currentID = location.pathname.split("/")[2];
    this.setState({ mainSpinner: true });
    if (!patientsResult || Object.keys(patientsResult).length === 0) {
      InmunityApi.getPatientSearchInfo(currentID)
        .then(res => res.json())
        .then(info => {
          const formattedInfo = this.formatInfo(info);
          setPatientsResult(formattedInfo);
          this.renderSearchResult(formattedInfo, currentID);
        })
        .catch(error =>
          console.log("Error occured while creating a search: ", error)
        );
    } else {
      this.renderSearchResult(patientsResult, currentID);
    }
    this.fetchSitesData();

    // InmunityApi.getAnalytics(currentID)
    //     .then(r1 => this.setState({analiticData: r1}))
    //     .catch(error =>
    //       console.log("Error occured while creating a search: ", error)
    //     );

    //     InmunityApi.getTopChartAnalytics(currentID)
    //     .then(r2 => this.setState({site: r2}))
    //     .catch(error =>
    //       console.log("Error occured while creating a search: ", error)
    //     );
  }

  componentWillUnmount() {
    this.props.setPatientsFilterResult({});
  }

  renderSearchResult = (renderResult, currentID) => {
    let searchResult = [];
    renderResult.result &&
      renderResult.result.map((el, i) => {
        searchResult.push(<PatientBlock itemIndex={i} patient={el} />);
        return null;
      });
    searchResult.sort((a, b) => {
      return b.props.rate - a.props.rate;
    });
    this.setState(
      {
        searchResult,
        initialSearch: searchResult,
        id: currentID,
        count: renderResult.count,
        searchId: renderResult.search_id,
        isMore: renderResult.next ? true : false,
        link: renderResult.next,
        receivedData: renderResult
      },
      () => this.getSearchInfo()
    );
  };

  fetchSitesData = () => {
    const { location } = this.props;
    const searchId = parseInt(location.pathname.split("/")[2], 10);
    this.setState({
      patientSpinner: true,
      hospitalSpinner: true,
      siteSpinner: true
    });

    InmunityApi.getAnalytics(searchId)
      .then(analiticData => {
        this.setState({ analiticData, patientSpinner: false });
      })
      .catch(e => console.log("Error occured while getting sites: " + e));
    InmunityApi.getPatientsHospitals(searchId)
      .then(hospitals => {
        this.setState({ hospitals, hospitalSpinner: false });
      })
      .catch(e => console.log("Error occured while getting all sites: " + e));
    InmunityApi.getTopChartAnalytics(searchId)
      .then(r2 => this.setState({ site: r2, siteSpinner: false }))
      .catch(error =>
        console.log("Error occured while creating a search: ", error)
      );
  };

  handleScroll = e => {
    document.getElementById("scroolTop__list").scrollTop > 500
      ? this.setState({ anchor: true })
      : this.setState({ anchor: false });
  };

  moveToTop = e => {
    e.preventDefault();
    document.getElementById("scroolTop__list").scrollTop = 0;
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (
      !_.isEqual(state.receivedData, nextProps.patientsResult) ||
      !_.isEqual(state.filters, nextProps.patientsFilterResult)
    ) {
      return {
        receivedData: nextProps.patientsResult,
        filters: nextProps.patientsFilterResult,
        count: nextProps.patientsResult.count,
        isMore: nextProps.patientsResult.next ? true : false,
        link: nextProps.patientsResult.next
      };
    } else if (state.loading !== nextProps.patientOverview.loading) {
      return {
        loading: nextProps.patientOverview.loading
      };
    } else {
      return null;
    }
  }

  reRenderPR = (res = this.state.receivedData) => {
    let searchResult = [];
    res.result.map((el, i) => {
      searchResult.push(<PatientBlock itemIndex={i} patient={el} />);
      return null;
    });
    this.setState({
      searchResult,
      initialSearch: searchResult
    });
  };

  getPatientOverview = async patient => {
    //Start fetching
    // return; //ghost#temp
    this.props.setPatientOverview({
      loading: true
    });

    // Move to top
    if (document.getElementById("scroolTop__list"))
      document.getElementById("scroolTop__list").scrollTop = 0;

    // Get new patient overview
    patient &&
      (await InmunityApi.getPatientOverview(patient.hadm_id).then(obj => {
        obj.chart = patient.chart;
        obj.loading = false;
        this.props.setPatientOverview(obj);
      }));
    await this.setState({ mainSpinner: false }, () => this.setActive());
  };

  setActive = () => {
    // Reset active
    let patientsParrent = document.getElementsByClassName(
      "patients-infscroll"
    )[0];
    if (
      patientsParrent &&
      patientsParrent.querySelector(".patientBlock-active")
    ) {
      patientsParrent
        .querySelector(".patientBlock-active")
        .classList.remove("patientBlock-active");
    }
    patientsParrent &&
      patientsParrent
        .getElementsByClassName("patientBlock")[0]
        .classList.add("patientBlock-active");
  };

  sortPatients = url => {
    this.setState({ isFetching: true });
    InmunityApi.getPatientSearchInfoFiltered(this.state.id, url)
      .then(res => {
        this.setState({ isFetching: false });
        this.setState(
          {
            receivedData: res,
            link: res.next,
            isMore: res.next ? true : false
          },
          () => {
            //ghost#todo
            res = this.formatInfo(res);
            this.props.setPatientsResult(res);
            this.reRenderPR(res);
            this.getPatientOverview(res.result[0]);
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  onClosePopup = () => {
    this.setState({ noResults: false });
    this.props.history.push({
      pathname: "/patients"
    });
  };

  formatMatchingCriteria = (arr, missing) => {
    const diseases = [];
    const procedures = [];
    const labs = [];
    const medication = [];
    const allergies = [];

    const m_diseases = [];
    const m_procedures = [];
    const m_labs = [];
    const m_medication = [];
    const m_allergies = [];

    arr.forEach(r => {
      if (r.criteria === 'disease') {
        diseases.push(r);
      }
      else if (r.criteria === 'procedure') {
        procedures.push(r);
      }
      else if (r.criteria === 'lab') {
        labs.push(r);
      }
      else if (r.criteria === 'medication') {
        medication.push(r);
      }
      else if (r.criteria === 'allergie') {
        allergies.push(r);
      }
      // else if (r.criteria === 'demography') {
      //   demography.push(r);
      // }
    }
    )

    missing.forEach(r => {
      if (r.criteria === 'disease') {
        m_diseases.push(r);
      }
      else if (r.criteria === 'procedure') {
        m_procedures.push(r);
      }
      else if (r.criteria === 'lab') {
        m_labs.push(r);
      }
      else if (r.criteria === 'medication') {
        m_medication.push(r);
      }
      else if (r.criteria === 'allergie') {
        m_allergies.push(r);
      }
    }
    )

    const res =
    {
      diseases: {
        find: diseases,
        missing: m_diseases //ghost#temp
      },
      procedures: {
        find: procedures,
        missing: m_procedures //ghost#temp
      },
      labs: {
        find: labs,
        missing: m_labs //ghost#temp
      },
      medication: {
        find: medication,
        missing: m_medication //ghost#temp
      },
      allergies: {
        find: allergies,
        missing: m_allergies //ghost#temp
      },
      // demography
    }


    return (res)
  }

  formatInfo = (info) => {
    let res = info.result;

    if (!Array.isArray(res)) res = [];
    const newRes = res.map(r => {
      return ({
        age: r.age,
        gender: r.gender,
        hadm_id: r.hadmId,
        immunity_id: r.immunityId,
        last_seen: r.lastSeen,
        chart: this.formatMatchingCriteria(r.matchingCriteria, r.missing),
        next_visit: r.nextVisit,
        rate: r.rate,
        label: r.label
      })
    }
    )

    info.result = newRes;


    return (info);
  }

  formatFilters = (data) => {
    const filters = data.criteria;

    if (!Array.isArray(filters)) return ([]);
    const pushFilterElement = (obj, r) => {
      if (r.criteria === 'demography') {
        obj.push(r.name);
      }
      else if (r.criteria === 'geography') {
        obj.push(r.name);
      }
      else if (r.criteria === 'practitioner') {
        obj.push(r.name);
      }
      else if(r.criteria === 'immunity'){
        obj.push(r.name);
      }
      else if (r.include === true) {
        obj.include.push(r.name)
      }
      else if (r.include === false) {
        obj.exclude.push(r.name)
      }
      else {
        obj.push(r.name)
      }
    }
    const ret = {
      demography: [],
      practitioner: [],
      disease: {
        include: [],
        exclude: []
      },
      lab: {
        include: [],
        exclude: []
      },
      medication: {
        include: [],
        exclude: []
      },
      procedure: {
        include: [],
        exclude: []
      },
      immunity: [],
      substance: {
        include: [],
        exclude: []
      },
      geography: [],
      physicians: [],
      timestamp: data.searchTimestamp
    }
    filters.forEach(r => {
      pushFilterElement(ret[r.criteria], r);
      }
    )

    return ret
  }

  deleteFilter = async (name, value) => {
    let obj = {};
    obj.criteria = value;
    obj.type = name;

    let { id } = this.state;

    this.setState({ isFetching: true });
    const filtersResponse = await InmunityApi.patientDeleteFilters(obj, id);
    if (!filtersResponse) {
      this.setState({ noResults: true, isFetching: false });
      return;
    }

    this.props.setPatientsFilterResult({});
    this.props.setPatientsFilterResult(this.formatFilters(filtersResponse));
    const response = await InmunityApi.deletePatientSearchFilter(obj, id);
    this.setState({ isFetching: false });
    if (response.status !== 200) {
      //Show popup here
      this.setState({ noResults: true });
      return;
    }
    let json = await response.json();
    const formattedInfo = this.formatInfo(json);
    this.getPatientOverview(formattedInfo.result[0]);
    this.props.setPatientsResult(formattedInfo);
    this.reRenderPR();
    // .catch(error =>
    //   console.log("Error occured while creating a search: ", error)
    // );
  };

  getSearchInfo = () => {
    InmunityApi.getPatientSearchFilters(this.state.id)
      .then(resp => {
        this.props.setPatientsFilterResult(resp);
        this.props.setPatientsFilterResult(this.formatFilters(resp));
      })
      .catch(error =>
        console.log("Error occured while creating a search: ", error)
      );

    InmunityApi.getPatientSearchInfo(this.state.id)
      .then(resp => {
        if (resp.status === 400) {
          this.props.history.push({
            pathname: "/patients"
          });
        }
        return resp.json();
      })
      .then(info => {
        if (info.count === 0) {
          this.props.history.push({
            pathname: "/patients"
          });
          return false;
        }

        this.formatInfo(info)
        const formattedInfo = info;
        this.getPatientOverview(formattedInfo.result[0]);
        this.props.setPatientsResult(formattedInfo);
        this.reRenderPR();
      })
      .catch(error => {
        console.log("Error occured while creating a search: ", error);
      });
  };

  loadMoreData = () => {
    let { isMore, link, receivedData, isLoadingMore } = this.state;
    let { setPatientsResult } = this.props;
    if (isMore && !isLoadingMore) {
      this.setState({ isLoadingMore: true })
      InmunityApi.getPatientSearchPaginationFullUrl(link).then(response => {
        let oldSearchResult = [...receivedData.result];
        response = this.formatInfo(response);

        let newSearchResult = oldSearchResult.concat(response.result);
        response.result = newSearchResult;
        this.setState(
          {
            receivedData: response,
            link: response.next,
            isMore: link ? true : false
          },
          () => {
            setTimeout(this.setState({ isLoadingMore: false, }), 1500);
            setPatientsResult(response);
            this.reRenderPR();
          }
        );
      });
    }
  };

  goBack = () => {
    let { id } = this.state;
    this.props.getSearchForPopulation(id);
    this.props.history.push({ pathname: `/patients` });
  };

  changeTabs = activeTab => {
    activeTab === 1 &&
      this.getPatientOverview(this.props.patientsResult.result[0]);
    this.setState({ activeTab });
  };

  render() {
    let { searchTimestamp } = this.props.patientsFilterResult;
    const {
      activeTab,
      sites,
      site,
      hospitals,
      patientSpinner,
      hospitalSpinner,
      siteSpinner,
      filters,
      searchResult,
      id,
      count,
      initialSearch,
      isMore,
      anchor,
      noResults,
      analiticData,
      mainSpinner
    } = this.state;
    let formattedCount = String(count);
    if (typeof count !== 'undefined') {
      formattedCount = formattedCount.split("").reverse().reduceRight(
        (c, e, index) => (c + ((index && index < formattedCount.length - 1 && index % 3 === 0) ? e + ',' : e)), ''
      );
    } else {
      formattedCount = "0"
    }
    return (
      <div className="container-n cont-searchP">
        {mainSpinner ? (
          <Spinner />
        ) : (
            <Fragment>
              <div className="patientsTest-header">
                <div className="patientsTest-headerHelper">
                  <h1 className="title-container">
                    {filters.timestamp || searchTimestamp
                      ? moment(filters.timestamp || searchTimestamp).format("DD MMM YYYY - hh:mm A")
                      : ""}
                    ({formattedCount} result)
                </h1>
                  <div className="wrappForBtn-patients">
                    <span className="patInfo_title">Edit Search</span>
                    <div className="editSearch" onClick={this.goBack}>
                      <img src={editSearch} alt="Arrow" />
                    </div>
                  </div>
                </div>
                <div className="patients_tabs_wrapp">
                  <div
                    className={`tab_item${activeTab === 1 ? " active_tab" : ""}`}
                    onClick={() => this.changeTabs(1)}
                  >
                    Patients
                </div>
                  <div
                    className={`tab_item${activeTab === 2 ? " active_tab" : ""}`}
                    onClick={() => this.changeTabs(2)}
                  >
                    Sites
                </div>
                </div>
                <div className="wrappForTopSearchItems">
                  {filters
                    ? Object.values(filters).map((type, i) => {
                      return (
                        <Filters
                          deleteFilter={this.deleteFilter}
                          filters={type}
                          type={Object.keys(filters)[i]}
                          key={i}
                        />
                      );
                    })
                    : null}
                </div>
              </div>
              {activeTab === 1 && (
                <SearchSort
                  id={id}
                  patients={searchResult}
                  initialPatients={initialSearch}
                  sortPatients={this.sortPatients}
                />
              )}
              {activeTab === 1 &&
                (searchResult.length > 0 ? (
                  <div className="matchDetailsWrapper patientsView">
                    <div
                      className="patientsView-ls"
                      id="scroolTop__list"
                      onScroll={this.handleScroll}
                      ref={ref => (this.scrollParentRef = ref)}
                    >
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMoreData}
                        hasMore={isMore}
                        getScrollParent={() => this.scrollParentRef}
                        loader={
                          <div className="loader" key={0}>
                            <img
                              className="small-spinner"
                              src={require("../../images/spinner.gif")}
                              alt="Spinner"
                            />
                          </div>
                        }
                        useWindow={false}
                      // className="patients-infscroll"
                      >
                        {searchResult.length > 0 &&
                          searchResult.map((component, i) => {
                            return <Fragment key={i}>{component}</Fragment>;
                          })}
                        <div
                          id="anchor"
                          className={`btn-up btn-up-patSerRes ${
                            anchor ? "" : "hide"
                            }`}
                          onClick={this.moveToTop}
                        >
                          <img src={arrow} alt="Arrow" />
                        </div>
                      </InfiniteScroll>
                    </div>
                    <div className="patientsView-rs">
                      <PatientInfo />
                    </div>
                  </div>
                ) : (
                    <div className="noResFound">No result.</div>
                  ))}
              {activeTab === 2 && (
                <SearchSites
                  sites={analiticData}
                  site={site}
                  hospitals={hospitals}
                  hospitalSpinner={hospitalSpinner}
                  patientSpinner={patientSpinner}
                  siteSpinner={siteSpinner}
                />
              )}
              <NoResultsPopup status={noResults} onClose={this.onClosePopup} />
              {this.state.isFetching ? <FullScreenLoader /> : null}
            </Fragment>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientsResult: state.patientsResult,
    patientOverview: state.patientOverview,
    patientsFilterResult: state.patientsFilterResult,
    search: state.search
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPatientsResult: result => dispatch(setPatientsResult(result)),
    setPatientsFilterResult: res => dispatch(setPatientsFilterResult(res)),
    getSearchForPopulation: id => dispatch(getSearchForPopulation(id)),
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setPatientOverview: obj => dispatch(setPatientOverview(obj))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Patients)
);
