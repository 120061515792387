import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history, persistor } from "./store";
import { PersistGate } from 'redux-persist/lib/integration/react';

import App from "./components/App";

import {
  // loadDiseases,
  loadLabNames,
  loadLabUoms,
  loadLabWithUom
} from "./actions/trialActions";
import "./i18n";
// store.dispatch(loadDiseases())
store.dispatch(loadLabNames());
store.dispatch(loadLabUoms());
store.dispatch(loadLabWithUom());
// store.dispatch(setCurrentTrial(Object.keys(va.trials)[0]));

const target = document.querySelector("#root");
render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
    </PersistGate>
  </Provider>,
  target
);
