import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getLabWithUom(state = initialState.labWithUom, action) {
  switch (action.type) {
    case types.LABWITHUOM_SUCCESS:
      return action.labWithUom;
    default:
      return state;
  }
}
