import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import { Circle } from 'rc-progress'
import { withRouter } from 'react-router'
import moment from 'moment'

import InmunityApi from '../../api/InmunityApi'
import PatientOverview from './../PatientOverview'

class AllEnrolled extends Component {
  state = {
    letterPopup: false,
    dayCount: 5,
    emailFields: '',
    showOverview: false,
    details: {}
  }

  goToDetails = ({ target }) => {
    if (
      !target.classList.contains('greenIconWithoutImage') &&
      !target.closest('.pat-over-wrapp') &&
      (!target.classList.contains('blueIcon') &&
        !target.classList.contains('popup-overlay'))
    ) {
      this.redirect()
    }
  }

  showPatientOverview = () => {
    this.setState({ showOverview: true })
    InmunityApi.getPatientWorkflow(
      this.props.candidate.hadmId,
      this.props.candidate.studyId
    ).then(details => {
      this.setState({
        details
      })
    })
  }

  closePatientOverview = () => {
    this.setState({ showOverview: false })
  }

  redirect = () => {
    let { history } = this.props
    let { hadmId, studyId } = this.props.candidate
    let url = studyId
      ? `/trial/${studyId}/matches/details/${hadmId}`
      : `/matches/details/${hadmId}`
    history.push({
      pathname: url,
      id: hadmId,
      detailTitle: 'Patient Details'
    })
  }

  render () {
    let {
      immunityId,
      enrollTime,
      studyTags,
      status,
      name,
      rate
    } = this.props.candidate
    let trials = []
    return (
      <div
        className='candidatesWr forToolTip singleTrialEnroll'
        onClick={this.goToDetails}
      >
        <div className='candidates hadmId enrolled'>
          <span className='imm-id'>Immunity ID</span>
          <span className='td-content'>{immunityId}</span>
          {studyTags.length !== 0 && (
            <span
              className='td-content candidatesAmTrial'
              data-tip={trials}
              data-class='toolTip'
              data-multiline
              data-for={this.props.id}
            >
              {studyTags.length > 1 && `${studyTags.length} trials`}
              {studyTags.length === 1 && `${studyTags.length} trial`}
            </span>
          )}
          <ReactTooltip
            id={this.props.id}
            place='bottom'
            type='light'
            effect='solid'
          />
        </div>
        <div className='candidates c-physician' style={{ width: '20%' }}>
          <span className='forIcon'>Name</span>
          <span className='td-content'>{name || '–'}</span>
        </div>
        <div className='candidates forlsuv enrolled'>
          <div className='c-lastSeen'>
            <span>Date of enrollment</span>
            <span className='td-content'>
              {moment(enrollTime).format('MM/DD/YYYY')}
            </span>
          </div>
        </div>
        <div
          className={
            'candidates enrolled enStatus ' +
            (status === 'Enrolled'
              ? 'contact-consent-can'
              : 'non-contact-consent-can')
          }
        >
          <span>{status}</span>
        </div>
        <div className='candidates enrolled enIcon proceed-to-trial'>
          <div className='lastencForCircle all-matches-tags'>
            <Circle
              percent={rate}
              strokeWidth='5'
              trailWidth='0'
              strokeColor='#5BBEBF'
              className='lastenCircle'
            />
            <span className='circleNumber' style={{ color: '#000' }}>
              {Math.round(rate)}%
            </span>
          </div>
          <div className='forTwoIcons'>
            <div className='blueIcon' onClick={this.showPatientOverview} />
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            <div className='greenIconWithoutImage' />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(AllEnrolled)
