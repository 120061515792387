import React from "react";
import img from "../../../../../images/plus-icon.svg";
import Item from "./Item";
import _ from "lodash";

// let id = 0;

function DataStructured({
  data,
  showPicker,
  flag,
  setInfo,
  freeTextVersion,
  freeTextBlock,
  allData,
  newFdata,
}) {
  // console.log('showPicker = ', showPicker);
  // console.log('flag = ', flag);
  // console.log('freeTextVersion = ', freeTextVersion);
  // console.log('newFdata = ', newFdata);

  const ourCriteria = flag.split(" ")[0];
  const remove = (index, criteria, elements) => {
    let newData = [...data];
    if (freeTextBlock) {
      removeBlockFromFreeText(newData, criteria, elements);
    } else {
      newData.forEach(el => {
        elements.forEach(elem => {
          el.criteria = el.criteria.filter(e => elem.fakeId !== e.fakeId);
        });
      });
      newData = newData.filter(el => el.criteria.length !== 0);
      freeTextVersion
        ? setInfo("eligibilityNewFreeText", newData)
        : setInfo("eligibility_criteria", newData);
    }
  };
  const removeBlockFromFreeText = (newData, criteria, elements) => {
    let newAllData = [...allData];
    let index = newAllData.findIndex(el => _.isEqual(el, newData[0]));
    switch (criteria) {
      case "Medication Filters":
        newData[0].criteria = newData[0].criteria.filter(
          el => el.criteria !== "Medication"
        );
        break;
      case "Disease Filters":
        newData[0].criteria = newData[0].criteria.filter(
          el => el.criteria !== "Disease"
        );
        break;
      case "Procedure History":
        newData[0].criteria = newData[0].criteria.filter(
          el => el.criteria !== "Procedure"
        );
        break;
      case "Lab Filters":
      case "Substance":
      case "Smoking":
        newData[0].criteria = newData[0].criteria.filter(
          el => !_.isEqual(el, elements[0])
        );
        break;
      default:
        break;
    }
    newData[0].criteria.length > 0
      ? newAllData.splice(index, 1, newData[0])
      : newAllData.splice(index, 1);
    setInfo("eligibility_criteria", newAllData);
  };
  return (
    <div
      className="ntw__EC-main"
      style={{ margin: data.length === 0 && freeTextVersion && 0 }}
    >
      {data.length > 0 && (
        <div className="search_container ntw__ti-inputs newTrialWrapp newWrappElBlockSearch">
          <div className="width100">
            {data.map((item, i) => {
              return (
                <Item
                  data={item}
                  allData={data}
                  key={i} // freeTextVersion ? `fr${item.fakeId}` : item.fakeId
                  ourCriteria={ourCriteria}
                  flag={freeTextVersion ? item.flag : flag}
                  remove={remove}
                  setInfo={setInfo}
                  freeTextVersion={freeTextVersion}
                  freeTextBlock={freeTextBlock}
                  newFdata={newFdata}
                  fullData={allData}
                />
              );
            })}
          </div>
        </div>
      )}
      {!freeTextVersion && !freeTextBlock && (
        <div className="contactWrapp" onClick={() => showPicker(flag)}>
          <img src={img} alt="Plus" />
          <span>
            New {ourCriteria === "Substance" ? "Substance Use" : ourCriteria}
          </span>
        </div>
      )}
    </div>
  );
}

export default React.memo(DataStructured);