import React, { Fragment, useState } from 'react'
import Popup from 'reactjs-popup'
import store from './../../../../../store'
import crossicon from './../../../../../images/icon-close-w.svg'
import InmunityApi from './../../../../../api/InmunityApi'
import {
  loadTrials,
  getTrialDescription
} from './../../../../../actions/trialActions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const RetireUnretire = ({ trial: { is_recruiting, id }, history }) => {
  const openRetireDeleteWrapp = () => {
    setShowDeleteRetire(!showDeleteRetire)
  }
  const closeRetirePopup = () => {
    setRetirePopup(false)
  }
  const [showDeleteRetire, setShowDeleteRetire] = useState(false)
  const [retirePopup, setRetirePopup] = useState(false)
  const [retiringMessage, setRetiringMessage] = useState('')
  const moveWithTrial = text => {
    setRetirePopup(true)
    setRetiringMessage('spinner')
    if (text === 'delete') {
      InmunityApi.deleteTrialTest(id)
        .then(resolve => {
          if (resolve.status !== 204) {
            setRetiringMessage('An error occurred while deleting a trial')
            throw resolve
          }
          setRetiringMessage('Trial was deleted successfully')
          store.dispatch(loadTrials())
          setTimeout(() => {
            history.push({ pathname: '/trials' })
          }, 1000)
        })
        .catch(err => {
          console.log('ERROR WHILE DELETING A TRIAL: ', err)
        })
    } else if (text === 'retire') {
      InmunityApi.retireTrialTest(id)
        .then(resolve => {
          if (resolve.status !== 200) {
            setRetiringMessage('An error occurred while retiring a trial')
            throw resolve
          }
          setRetiringMessage('Trial was retired successfully')
          store.dispatch(loadTrials())
          store.dispatch(getTrialDescription(id))
        })
        .catch(err => {
          console.log('ERROR WHILE RETIRING A TRIAL: ', err)
        })
    } else {
      InmunityApi.unretireTrialTest(id)
        .then(resolve => {
          if (resolve.status !== 200) {
            setRetiringMessage('An error occurred while unretiring a trial')
            throw resolve
          }
          setRetiringMessage('Trial was unretired successfully')
          store.dispatch(loadTrials())
          store.dispatch(getTrialDescription(id))
        })
        .catch(err => {
          console.log('ERROR WHILE UNRETIRING A TRIAL: ', err)
        })
    }
  }
  return (
    <Fragment>
      <div className='threeDotBtn' onClick={openRetireDeleteWrapp}>
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
      </div>
      <div
        className={
          showDeleteRetire ? 'retireDeleteWrapp' : 'retireDeleteWrapp hidden'
        }
      >
        <div className='retireDeleteWrappTriangle' />
        {!is_recruiting && (
          <div className='text' onClick={() => moveWithTrial('unretire')}>
            Unretire
          </div>
        )}
        {is_recruiting && (
          <div className='text' onClick={() => moveWithTrial('retire')}>
            Retire
          </div>
        )}
        <div className='text' onClick={() => moveWithTrial('delete')}>
          Delete Trial
        </div>
      </div>
      <Popup
        open={retirePopup}
        closeOnDocumentClick
        onClose={closeRetirePopup}
        className='saveUserPopup'
      >
        <div>
          <div className='closePendingPopupCross' onClick={closeRetirePopup}>
            <img src={crossicon} alt='Cross' />
          </div>
          {retiringMessage === 'spinner' ? (
            <h1 className='header-spinner'>
              <img
                className='small-spinner'
                src={require('./../../../../../images/spinner.gif')}
                alt=''
              />
            </h1>
          ) : (
            <div className='saveUserPopupText'>{retiringMessage}</div>
          )}
        </div>
      </Popup>
    </Fragment>
  )
}

function mapStateToProps (state) {
  return {
    trial: state.trial
  }
}

export default withRouter(connect(mapStateToProps)(RetireUnretire))
