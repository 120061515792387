export const analyticsTrialWorkflowStatistic = recruitment_goal => {
  return [
    [
      'Element',
      'Density',
      { role: 'style' },
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify'
      }
    ],
    ['Matches', recruitment_goal.diagram.matches, '#00B3A9', null],
    ['Candidates', recruitment_goal.diagram.candidates, '#00B3A9', null],
    ['Authorized', recruitment_goal.diagram.authorized, '#00B3A9', null],
    ['Screened', recruitment_goal.diagram.proceed, '#00B3A9', null],
    ['Enrolled', recruitment_goal.diagram.enrolled, '#00B3A9', null]
  ]
}
export const analyticsLineChartOptions = () => {
  return [
    { value: '1', label: 'Match for trial' },
    { value: '4', label: 'Candidate for trial' },
    { value: '18', label: 'Enrolled' }
    // { value: '19', label: 'Enrolled' },
  ]
}
