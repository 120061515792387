import React, { Fragment } from 'react'
import Popup from 'reactjs-popup'
import { SpinnerSmall, CloseIcon } from '../index'

export const InfoPopup = ({ message, loading, close }) => {
  return (
    <Fragment>
      <Popup
        open={!!(loading || message !== '')}
        closeOnDocumentClick
        onClose={close}
        className='saveUserPopup'
      >
        <div>
          <CloseIcon close={close} />
          {loading ? (
            <SpinnerSmall />
          ) : (
            <div className='saveUserPopupText'>{message}</div>
          )}
        </div>
      </Popup>
    </Fragment>
  )
}
