import React from 'react'
import iconCollapse from "./../../../../images/arrow-collapse.svg";

const BlockHeader = ({searchPage, isOpen, toggleFilterView, testOption}) => {
    if (!searchPage) return null
    return (
        <div className="searchWrapper__close">
        <div
          className={
            isOpen
              ? "searchWrapper__close-icon-rotate"
              : "searchWrapper__close-icon"
          }
          onClick={toggleFilterView}
        >
          <img src={iconCollapse} alt="Icon" />
        </div>
        <div className="searchWrapper__close-title">{testOption.label}</div>
        <div className="searchWrapper__close-line" />
        <div className="red-cross" />
      </div>
    )
}

export default BlockHeader;