import React, { Component, useState, useEffect } from "react";
import { TitleSelectorGroup, Title, ContentGroup, NotesDataBlock, Allergies } from './ChartBoxComponents'
import { StyledBlockWrapper } from './styled'
import Select from "react-select";
import "./index.css";
import store from '../../../store'
import {setFullChartName} from '../../../actions/trialActions'
import InmunityApi from '../../../api/InmunityApi'
import arrowleft from "../../../images/left.png";
import arrowright from "../../../images/right.png";


function ChartBox({changeTab, title, data, tabs, contentType, searchText, currentTab }) {

    const [tab, setTab] = useState(0);
    return (
        <div id="patientChartSubContent">
            <div className="ppWrapper">
                <Title
                    title={title}
                    searchText={searchText} />
                <TitleSelectorGroup
                    hideCount={contentType === 'identified'}
                    tabs={tabs}
                    data={data}
                    currentTab={currentTab ? currentTab : tab}
                    searchText={searchText}
                    onClick={changeTab ? (n) => changeTab(n) : (n) => setTab(n)}
                />

                <StyledBlockWrapper>
                    {
                        contentType !== 'identified'
                            ? <ContentGroup
                                searchText={searchText}
                                content={data[tab]}
                                extended={contentType !== 'medications' && tab === 1}
                                contentType={contentType}
                            />
                            :
                            <NotesDataBlock
                                searchText={searchText}
                                content={data[tab]}
                                contentType={contentType}
                            />
                    }

                </StyledBlockWrapper>
            </div>
        </div>
    )
}


function ChartContainer({ searchId, hadmId, medications, procedures, diseases, identifiedData, searchText, allergiesString, trialName, history }) {
    const [options , changeOptions] = useState([])
    const [allText, changeAllText ] = useState([])
    const [currentNotesTab, changeNotesTab] = useState("")
    const [currentTab, changeCurrentTab] = useState(0)
    const [linkNext, changeLinkNext] = useState("")
    const [allTabs, changeAllTabs] = useState([])
    const [totalCount, changeCount] = useState('')
    const [oldData, changeOldData] = useState({})
    const [disabled, toggleDisabled] = useState(false)
    const [currentPart, changePart] = useState(0)
    useEffect(() => {
      let isRequest = true
      InmunityApi.getPatientNotesList(hadmId)
      .then(res => {
        if(isRequest){
          changeOptions(res.map(note => ({value: note.id, label: `${note.date} ${note.summary}`})))
        }
      });
      InmunityApi.getPatientNotes(hadmId)
      .then(res => {
        if(isRequest){
          changeAllTabs([...res.results.map(tab => tab.date)])
          changeAllText(res.results.map(text => text.text))
          changeCount(res.count)
          changeLinkNext(res.next)
          changeOldData({link: res.next, allTabs: res.results.map(tab => tab.date), allText: res.results.map(text => text.text), count: res.count})
        }
      });
      return () => isRequest = false
    },[])

    const cutTrilName = (str) => {
        if (str.length <= 14) return str;

        store.dispatch(setFullChartName(str));
        return (`${str.substring(0, 14)}...`);
    }
    const onClickNext = () => {
      if(!disabled){
        allTabs.slice(currentPart,currentPart+5).length > currentTab+1 && changeCurrentTab(currentTab+1)
        if(currentTab===4){
            if(linkNext!==null){
              toggleDisabled(true)
              let token = localStorage.getItem("token");
              fetch(linkNext, {
                method: "GET",
                headers: {
                  "Authorization": "Bearer " + token,
                  "Content-Type": "application/json",
                }
              }).then(response => {
                return response.json()
              }).then(response => {
                response.results.map(tab => allTabs.push(tab.date))
                response.results.map(text => allText.push(text.text))
                changeAllTabs([...allTabs])
                changeAllText([...allText])
                changeLinkNext(response.next)
                changeCurrentTab(0)
                changePart(currentPart+5)
                changeOldData({link: response.next, allTabs: allTabs, allText: allText, count: totalCount})
              }).then(() => toggleDisabled(false))
            }else if(currentPart < totalCount){
              changeCurrentTab(0)
              changePart(currentPart+5)
            }
          }
        }
    }
    const onClickBack = () => {
      if(currentTab===0 && currentPart>0){
        changePart(currentPart-5)
        changeCurrentTab(4)
      }else if(currentTab!==0){
        changeCurrentTab(currentTab-1)
      }
    }
    const onChangeNotesTab = stageOption => {
      changeOldData({link: linkNext, allTabs: allTabs, allText: allText, count: totalCount})
      changeNotesTab(stageOption)
      changeAllText(['Loading...'])
      InmunityApi.getPatientNotesText(hadmId, stageOption.value)
      .then(res => {
        changeAllText([res.text])
        changeAllTabs([res.date])
        changeCurrentTab(0)
        changePart(0)
        changeCount(1)
        changeLinkNext(null)
      })
    }
    const onClear = () => {
      changeAllTabs(oldData.allTabs)
      changeAllText(oldData.allText)
      changeCount(oldData.count)
      changeLinkNext(oldData.link)
      changeCurrentTab(0)
      changePart(0)
      changeNotesTab('')
    }
    const onTabClick = (n) => {
      changeCurrentTab(n)
    }
    const getDirrectionLock = move => {
      if(move==="next"){
        if(allTabs.slice(currentPart,currentPart+5).length === currentTab+1 && totalCount === allTabs.length && currentPart > allTabs.length-5){
          return true
        }
      }else{
        if(currentTab===0 && currentPart===0){
          return true
        }
      }
    }
    const matchedTab = trialName ? `Matched To ${cutTrilName(trialName)}` : null
    const searchTab = searchId ? `Matched To Search` : null
    return (
        <>
            <ChartBox
                title="Past Medical History"
                contentType="diseases"
                data={
                    [
                        diseases.all,
                        {
                            inclusion: diseases.matchedInc,
                            exclusion: diseases.matchedExc,
                        },
                        {
                            inclusion: diseases.searchInc,
                            exclusion: diseases.searchExc,
                        }
                    ]
                }
                tabs={['All', matchedTab, searchTab]}
                searchText={searchText}
            />
            <ChartBox
                title="Past Surgical History"
                data={
                    [
                        procedures.all,
                        {
                            inclusion: procedures.matchedInc,
                            exclusion: procedures.matchedExc,
                        },
                        {
                            inclusion: procedures.searchInc,
                            exclusion: procedures.searchExc,
                        }
                    ]
                }
                tabs={['All', matchedTab, searchTab]}
                contentType="procedures"
                searchText={searchText}
            />
            <ChartBox
                title="Medication History"
                data={[medications.all, medications.matchedInc, medications.searchInc]}
                tabs={['All', matchedTab, searchTab]}
                contentType="medications"
                searchText={searchText}
            />

            <Allergies allergiesString={allergiesString} searchText={searchText} />
            <div className="notes-list">
              <ChartBox
                title="Discharge Notes"
                contentType="identified"
                data={allText.slice(currentPart,currentPart+5)}
                tabs={allTabs.slice(currentPart,currentPart+5)}
                currentTab={currentTab}
                searchText={searchText}
                changeTab={onTabClick}
                />
              <div className="notes-select-container">
                <img
                  src={arrowleft}
                  className={getDirrectionLock("forward") && `disabled`}
                  onClick={() => onClickBack()}
                />
                <img
                  src={arrowright}
                  className={getDirrectionLock("next") && `disabled`}
                  onClick={() => onClickNext()}
                />
                <Select
                  className="notes-select"
                  name="identifiedData"
                  placeholder="Select one"
                  options={options}
                  value={currentNotesTab}
                  onChange={onChangeNotesTab}
                  />
                {currentNotesTab!=="" && (
                  <div className="red-cross" onClick={()=>onClear()}/>
                )}
              </div>
            </div>
        </>
    )
}

export default ChartContainer;
