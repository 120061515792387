import React, { Component } from 'react'
import PermissionEl from './PermissionEl'
import formatRole from './formatRole'
import './index.css'

class PermissionList extends Component {
  constructor (props) {
    super(props)
    let editPerms = JSON.parse(localStorage.getItem('editPerm'))
    let status = editPerms ? 'edit' : 'create'
    if (status === 'edit') {
      this.state = {
        permissionList: [
          <PermissionEl
            roleName='Create new trials'
            first
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['create_trials']}
            status={status}
          />,
          <PermissionEl
            roleName='View existing trials'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['view_trials']}
            status={status}
          />,
          <PermissionEl
            roleName='Edit existing trials'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['edit_trials']}
            status={status}
          />,
          <PermissionEl
            roleName='Delete existing trials'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['delete_trials']}
            status={status}
          />,
          <PermissionEl
            roleName='Add/delete users'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['add_user']}
            status={status}
          />,
          <PermissionEl
            roleName='Add institutions'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['add_includion']}
            status={status}
          />,
          <PermissionEl
            roleName='Analytics on coordinator behavior'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['analytics_coordinator']}
            status={status}
          />,
          <PermissionEl
            roleName='Analytics on physician/patient behavior'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['analytics_patient']}
            status={status}
          />,
          <PermissionEl
            roleName='Analytics on trials and sites'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['analytics_trials']}
            status={status}
          />,
          <PermissionEl
            roleName='Give a coordinator or physican access to a trial'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['give_access_trial']}
            status={status}
          />,
          <PermissionEl
            roleName='Import a trial if your have been given access'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['import_trial']}
            status={status}
          />,
          <PermissionEl
            roleName='View identified data - preconsent (own patient)'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['view_data_own']}
            status={status}
          />,
          <PermissionEl
            roleName='View identified data - postconsent'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['view_data']}
            status={status}
          />,
          <PermissionEl
            roleName='View de-identified data'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['view_deind_data']}
            status={status}
          />,
          <PermissionEl
            roleName='Schedule oriented view of patients'
            permissionEmitter={this.permissionAdd}
            editStatus={editPerms['schedule_view']}
            status={status}
          />
        ],
        permissionObj: {
          name: editPerms.name,
          id: editPerms.id,
          create_trials: editPerms.create_trials,
          view_trials: editPerms.view_trials,
          edit_trials: editPerms.edit_trials,
          delete_trials: editPerms.delete_trials,
          add_user: editPerms.add_user,
          add_includion: editPerms.add_includion,
          analytics_coordinator: editPerms.analytics_coordinator,
          analytics_patient: editPerms.analytics_patient,
          analytics_trials: editPerms.analytics_trials,
          give_access_trial: editPerms.give_access_trial,
          import_trial: editPerms.import_trial,
          view_data_own: editPerms.view_data_own,
          view_data: editPerms.view_data,
          view_deind_data: editPerms.view_deind_data,
          schedule_view: editPerms.schedule_view
        }
      }
    } else {
      this.state = {
        permissionList: [
          <PermissionEl
            roleName='Create new trials'
            first
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='View existing trials'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Edit existing trials'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Delete existing trials'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Add/delete users'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Add institutions'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Analytics on coordinator behavior'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Analytics on physician/patient behavior'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Analytics on trials and sites'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Give a coordinator or physican access to a trial'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Import a trial if your have been given access'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='View identified data - preconsent (own patient)'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='View identified data - postconsent'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='View de-identified data'
            permissionEmitter={this.permissionAdd}
            status={status}
          />,
          <PermissionEl
            roleName='Schedule oriented view of patients'
            permissionEmitter={this.permissionAdd}
            status={status}
          />
        ],
        permissionObj: {
          create_trials: false,
          view_trials: false,
          edit_trials: false,
          delete_trials: false,
          add_user: false,
          add_includion: false,
          analytics_coordinator: false,
          analytics_patient: false,
          analytics_trials: false,
          give_access_trial: false,
          import_trial: false,
          view_data_own: false,
          view_data: false,
          view_deind_data: false,
          schedule_view: false
        }
      }
    }
  }
  componentWillMount () {
    localStorage.removeItem('editPerm')
  }

  permissionAdd = (role, status) => {
    let permissionObj = { ...this.state.permissionObj }
    let formattedRole = formatRole(role)
    permissionObj[formattedRole] = status
    this.setState({ permissionObj }, () => {
      localStorage.setItem('permissionObj', JSON.stringify(permissionObj))
    })
  }

  render () {
    return (
      <div className='permission-list__items'>
        {this.state.permissionList.map((component, key) => {
          return <div key={key}>{component}</div>
        })}
      </div>
    )
  }
}
export default PermissionList
