import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import { connect } from "react-redux";

class TrialFilter extends Component {
  state = {
    trials: [],
    trialOption: "",
    options: []
  };

  componentDidMount() {
    let options = [];
    let array = [...this.props.trials];
    array.forEach(el => {
      let obj = {};
      obj.value = obj.label = el.studyName;
      options.push(obj);
    });
    this.setState({ options });
  }

  handleSelectChange = trialOption => {
    let res = "";
    this.setState({ trialOption }, () => {
      let options = [...this.state.trialOption];
      options.forEach((el, i) => {
        i !== options.length - 1 ? (res += el.value + ", ") : (res += el.value);
      });
      this.props.getValue(res);
      if (options.length > 0) {
        document
          .querySelector(".ex-filter-select")
          .classList.remove("redBorder");
      }
    });
  };

  render() {
    return (
      <div className="filterContent">
        <div className="dateWrapper newTrialWrapp">
          <div className="datePicker">Trial</div>
          <Select
            value={this.state.trialOption}
            placeholder="Select one or more"
            closeMenuOnSelect={false}
            onChange={this.handleSelectChange}
            options={this.state.options}
            className="sort_select withInput ex-filter-select"
            isMulti={true}
            components={makeAnimated()}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trials: state.trials
  };
}

export default connect(mapStateToProps)(TrialFilter);
