import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function setPatientsFilterResult(state = initialState.patientsFilterResult, action) {
  switch(action.type) {
    case types.PATIENTS_FILTER_RESULT:
      return action.patientsFilterResult
    default:
      return state;
  }
}
