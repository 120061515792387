import React, { Component } from 'react'
import { Circle } from 'rc-progress'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import moment from 'moment'
import { InfoIcon } from '../../ui'
import InmunityApi from '../../api/InmunityApi'
import PatientOverview from './../PatientOverview'

class AllEnrolled extends Component {
  state = {
    showOverview: false,
    details: {},
    currentTrialNumber: 0
  }

  componentDidMount() {
    this.setState({
      trials: this.props.candidate.trials
    })
  }

  goToDetails = ({ target }) => {
    if (
      !target.classList.contains('button-center') &&
      !target.classList.contains('arrow-next-page') &&
      !target.closest('.pat-over-wrapp') &&
      (!target.classList.contains('button__icon') &&
        !target.classList.contains('popup-overlay'))
    ) {
      let { history } = this.props
      let hadmId = this.props.candidate.hadmId
      let studyId = this.props.candidate.studyId
      studyId
        ? history.push({
          pathname: `/trial/${studyId}/matches/details/${hadmId}`,
          id: hadmId,
          detailTitle: 'Patient Details'
        })
        : history.push({
          pathname: `/matches/details/${hadmId}`,
          id: hadmId,
          detailTitle: 'Patient Details'
        })
    }
  }

  makeTrials = obj => {
    let result = ''
    obj.sort((a, b) => b.protocolMatch - a.protocolMatch)
    obj.forEach(trial => {
      let precntage = trial.protocolMatch * 100
      result += ` ${trial.protocol} ${Math.ceil(precntage)}%<br>`
    })
    return result
  }

  showPatientOverview = () => {
    this.setState({ showOverview: true })
    this.props.userToken.token &&
      this.props.candidate.hadmId &&
      InmunityApi.getPatientWorkflow(this.props.candidate.hadmId).then(
        details => {
          this.setState({
            details
          })
        }
      )
  }

  closePatientOverview = () => {
    this.setState({ showOverview: false })
  }

  redirect = () => {
    let { history } = this.props
    let { hadmId, studyId } = this.props.candidate
    let url = studyId
      ? `/trial/${studyId}/matches/details/${hadmId}`
      : `/matches/details/${hadmId}`
    history.push({
      pathname: url,
      id: hadmId,
      detailTitle: 'Candidate Details'
    })
  }

  changeCurrentTrialInfo = way => {
    if (way === 'left') {
      if (this.state.currentTrialNumber === 0) {
        this.setState({
          currentTrialNumber: this.state.trials.length - 1
        })
      } else {
        this.setState({
          currentTrialNumber: this.state.currentTrialNumber - 1
        })
      }
    } else if (way === 'right') {
      if (this.state.currentTrialNumber === this.state.trials.length - 1) {
        this.setState({
          currentTrialNumber: 0
        })
      } else {
        this.setState({
          currentTrialNumber: this.state.currentTrialNumber + 1
        })
      }
    }
  }

  render() {
    let { immunityId, studyTags } = this.props.candidate
    let trials = this.makeTrials(studyTags)
    return (
      <div
        className='candidatesWr forToolTip allEnrolled'
        onClick={this.goToDetails}
      >
        <div className='candidates hadmId enrolled'>
          <span className='imm-id'>Immunity ID</span>
          <span className='td-content'>{immunityId}</span>
          {studyTags.length !== 0 && (
            <span
              className='td-content candidatesAmTrial'
              data-tip={trials}
              data-class='toolTip'
              data-multiline
              data-for={this.props.id}
            >
              {studyTags.length > 1 && `${studyTags.length} trials`}
              {studyTags.length === 1 && `${studyTags.length} trial`}
            </span>
          )}
          <ReactTooltip
            id={this.props.id}
            place='bottom'
            type='light'
            effect='solid'
          />
        </div>
        <div className='candidates c-physician' style={{ width: '27%' }}>
          <span className='forIcon'>
            Enrolled in {this.state.trials ? this.state.trials.length : null}{' '}
            {this.state.trials && this.state.trials.length > 1
              ? 'trials'
              : 'trial'}
          </span>
          <div className='illich_arrows'>
            {this.state.trials && this.state.trials.length > 1 && (
              <img
                src={require('./../../images/left-b.png')}
                alt='Left'
                height='12px'
                className="arrow-next-page"
                style={{
                  paddingRight: "5px",

                }}
                onClick={() => this.changeCurrentTrialInfo('left')}

              />
            )}
            <span className='td-content'>
              {this.state.trials
                ? this.state.trials[this.state.currentTrialNumber].name
                : ''}
            </span>
            {this.state.trials && this.state.trials.length > 1 && (
              <img
                src={require('./../../images/left-b.png')}
                alt='Left'
                height='12px'
                style={{
                  transform: 'rotate(180deg)',
                  paddingLeft: "5px",
                }}
                onClick={() => this.changeCurrentTrialInfo('right')}
                className="arrow-next-page"
              />
            )}
          </div>
        </div>
        <div className='candidates forlsuv enrolled'>
          <div className='c-lastSeen'>
            <span>Date of enrollment</span>
            <span className='td-content'>
              {this.state.trials
                ? moment(
                  this.state.trials[this.state.currentTrialNumber].date
                ).format('MM/DD/YYYY')
                : ''}
            </span>
          </div>
        </div>
        <div className='candidates c-physician' style={{ width: '23%' }}>
          <span className='forIcon'>Enrolled by</span>
          <span className='td-content'>
            {this.state.trials
              ? this.state.trials[this.state.currentTrialNumber].physician
              : ''}
          </span>
        </div>
        <div className='candidates enrolled enIcon'>
          <div className='lastencForCircle all-matches-tags'>
            <Circle
              percent={
                this.state.trials
                  ? this.state.trials[this.state.currentTrialNumber].rate
                  : ''
              }
              strokeWidth='5'
              trailWidth='0'
              strokeColor='#5BBEBF'
              className='lastenCircle'
            />
            <span className='circleNumber'>
              {this.state.trials
                ? Math.round(
                  this.state.trials[this.state.currentTrialNumber].rate
                ) + '%'
                : ''}
            </span>
          </div>
          <div className='forTwoIcons'>
            <InfoIcon click={this.showPatientOverview} />
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            {/* <div className="greenIconWithoutImage" /> */}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    userToken: state.userToken
  }
}

export default withRouter(connect(mapStateToProps)(AllEnrolled))
