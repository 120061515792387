import React, { Component } from "react";
import { connect } from "react-redux";
import "./index.css";
import Highlighter from "react-highlight-words";
import InmunityApi from "../../../../../api/InmunityApi";
import {
  setMedicationsATC,
  setMedicationsATCList,
  setMedicationsEPC,
  setMedicationsEPCList,
  setMedicationsMOA,
  setMedicationsMOAList,
  setDiseasesDO,
  setDiseasesDOList,
  setDiseasesICD9CM,
  setDiseasesICD9CMList,
  setProcedures,
  setMedicationsLabel,
  setProceduresList
} from "../../../../../actions/trialActions";

class TreeSearchResult extends Component {
  state = {
    items: [],
    searchText: []
  };

  componentDidMount() {
    this.setState({
      items: this.props.searchResult,
      searchText: [this.props.searchText]
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.searchText !== state.searchText.toString()) {
      return {
        searchText: [props.searchText],
        items: props.searchResult
      };
    } else {
      return null;
    }
  }

  closePopup = () => {
    this.props.closeSearchResult();
  };

  searchResultRequest = (el, index) => {
    const {
      dispatch,
      resetResultsCount,
      setSpinner,
      setCategorySpinner,
      closeSearchResult,
      searchResult,
      makeFindActive,
      pickerLabel,
      filter,
      source,
    } = this.props;
    setSpinner(true);
    setCategorySpinner(true);

    if (filter === "Medication Filters") {
      InmunityApi.searchInMedicationPicker(el.id, searchResult[index].label).then(res => {
        switch (res.id) {
          case "0":
            dispatch(setMedicationsATC([]));
            dispatch(setMedicationsATCList([]));
            makeFindActive(0, el.id);
            dispatch(setMedicationsATC(res.children));
            break;
          case "N0000189939":
            dispatch(setMedicationsEPC([]));
            dispatch(setMedicationsEPCList([]));
            makeFindActive(1, el.id);
            dispatch(setMedicationsEPC(res.children));
            break;
          case "N0000000223":
            dispatch(setMedicationsMOA([]));
            dispatch(setMedicationsMOAList([]));
            makeFindActive(2, el.id);
            dispatch(setMedicationsMOA(res.children));
            break;
          default:
            console.log("no such root medication");
        }
      });
    } else if (filter === "Disease Filters") {
      InmunityApi.searchInDiseasePicker(el.id, pickerLabel).then(res => {
        switch (res.id) {
          case "4":
            dispatch(setDiseasesDO([]));
            dispatch(setDiseasesDOList([]));
            makeFindActive(0, el.id);
            dispatch(setDiseasesDO(res.children));
            break;
          case "icd9":
            dispatch(setDiseasesICD9CM([]));
            dispatch(setDiseasesICD9CMList([]));
            makeFindActive(1, el.id);
            dispatch(setDiseasesICD9CM(res.children));
            break;
          default:
            console.log("no such root disease");
        }
      });
    } else if (filter === "Procedure History") {
      InmunityApi.searchInProcedurePicker(el.id).then(res => {
        dispatch(setProcedures([]));
        dispatch(setProceduresList([]));
        makeFindActive(0, el.id);
        dispatch(setProcedures(res));
      });
    }
    resetResultsCount();
    closeSearchResult();
  };

  render() {
    return (
      <div
        className="treeSearchResult"
        ref={ref => (this.scrollParentRef = ref)}
      >
        <div className="closeSearchResultCross" onClick={this.closePopup}>
          <img
            src={require("../../../../../images/icon-close-blue.svg")}
            alt="Cross"
          />
        </div>

        {this.state.items.length === 0 && (
          <div style={{ fontFamily: "Gotham" }}>No results</div>
        )}

        {this.state.items.map((el, i) => (
          <div
            key={el.name + i}
            className="searchResult__item"
            onClick={() => this.searchResultRequest(el, i)}
          >
            <Highlighter
              highlightClassName="found"
              searchWords={this.state.searchText}
              autoEscape
              textToHighlight={el.name}
            />
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    token: state.userToken.token,
    pickerLabel: state.pickerLabel
  };
}

export default connect(mapStateToProps)(TreeSearchResult);
