import React from 'react'
import Select from 'react-select'
import { Search } from './search'
import './sorting.css'
export function Sort ({
  options,
  selectedOption,
  isRevert,
  result,
  revert,
  select,
  change,
  press,
  searching = true
}) {
  return (
    <div className='inputSearch'>
      <div className='isimginputWrapp'>
        <Search change={change} press={press} />
        <div className='searchLengthResult'>
          {searching === true && result}{' '}
          {typeof result === 'number' && searching
            ? result === 0
              ? 'results'
              : result === 1
                ? 'result'
                : 'results'
            : ''}
        </div>
      </div>
      <div className='isssWrapp trialsPage'>
        <div className='ntw__headerWrapp-btn' onClick={revert}>
          {isRevert
            ? selectedOption.value === 'encounter' ||
              selectedOption.value === 'rate' ||
              selectedOption.value === 'immunity' ||
              selectedOption.value === 'age' ||
              selectedOption.value === 'visit' ||
              selectedOption.value === 'enrolled'
              ? '9 - 0'
              : 'Z - A'
            : selectedOption.value === 'encounter' ||
              selectedOption.value === 'rate' ||
              selectedOption.value === 'immunity' ||
              selectedOption.value === 'age' ||
              selectedOption.value === 'visit' ||
              selectedOption.value === 'enrolled'
              ? '0 - 9'
              : 'A - Z'}{' '}
        </div>
        <span>Sort by:</span>
        <Select
          value={selectedOption}
          onChange={select}
          options={options}
          className={'sort_select'}
        />
      </div>
    </div>
  )
}
