import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getLastStagesReducer(
  state = initialState.lastStages,
  action
) {
  switch (action.type) {
    case types.GET_LAST_STAGES_SUCCESS:
      return action.lastStages;
    default:
      return state;
  }
}
