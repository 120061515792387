import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import ImmunityApi from "./../../../../api/InmunityApi";
import TreeSearchResult from "./TreeSearchResult";

const PickerSearch = props => {
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [inputText, setInputText] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [resultsCount, setResultsCount] = useState(0);
  const inputRef = useRef();

  const {
    showPicker: { filter },
    makeFindActive,
    setSpinner,
    setCategorySpinner,
    source,
    disease,
    pickerLabel,
    dispatch,
    medicationsLabel
  } = props;

  const searchInPicker = () => {
    const {
      showPicker: { filter }
    } = props;

    setIsLoadingSearch(true);

    if (filter === "Medication Filters") {
      searchBy("Medication");
    } else if (filter === "Disease Filters") {
      searchBy("Disease");
    } else if (filter === "Procedure History") {
      searchBy("Procedures");
    }
  };

  const searchBy = param => {
    let method;

    switch (param) {
      case "Medication":
        method = () => ImmunityApi.medicationPickerSearch(inputText);
        break;
      case "Disease":
        method = () => ImmunityApi.diseasePickerSearch(inputText, pickerLabel);
        break;
      case "Procedures":
        method = () => ImmunityApi.procedurePickerSearch(inputText);
        break;
      default:
        return "incorrect param!";
    }
    fetchSearch(method);
  };

  const fetchSearch = method => {
    method()
      .then(res => {
        if (res.status === 200) {
          res.text().then(resp => {
            let obj = JSON.parse(resp);
            setSearchResult(obj);
            setResultsCount(obj.count);
            setShowSearchResult(true);
          });
        }
        setIsLoadingSearch(false);
      })
      .catch(err => {
        setIsLoadingSearch(false);
        console.log("Error occured while getting results: ", err);
      });
  };

  const handleSearch = ({ target }) => {
    const query = target.value;
    setInputText(query);
  };

  const closeSearchResult = () => {
    setShowSearchResult(false);
    setSearchResult([]);
    setInputText("");
  };

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      searchInPicker();
      inputRef.current.blur();
    }
  };

  const resetResultsCount = () => {
    setResultsCount(0);
  };

  return (
    <div
      className={`trial-search-container space-btw${
        disease ? " dis_pick_search_con" : ""
        }`}
    >
      <div className="dis-center forSearchResult">
        {!isLoadingSearch && (
          <img
            className="icon-left-search"
            src={require("./../../../../images/search.svg")}
            alt="Search"
            onClick={searchInPicker}
          />
        )}

        {isLoadingSearch && (
          <img
            className="icon-left-search"
            src={require("./../../../../images/spinner.gif")}
            alt="Spinner"
          />
        )}

        <input
          ref={inputRef}
          type="text"
          className="trial-search-field"
          name="trialName"
          id="trialName"
          placeholder="Search"
          onChange={handleSearch}
          value={inputText}
          onFocus={closeSearchResult}
          onKeyDown={handleKeyPress}
        />
        {showSearchResult && (
          <TreeSearchResult
            searchResult={searchResult}
            searchText={inputText}
            closeSearchResult={closeSearchResult}
            filter={filter}
            isLoading={isLoadingSearch}
            makeFindActive={makeFindActive}
            resetResultsCount={resetResultsCount}
            setSpinner={setSpinner}
            setCategorySpinner={setCategorySpinner}
            source={source}
          />
        )}
      </div>

      <div
        className={`search-result${resultsCount === 1 ? " plus-margin" : ""}`}
      >
        {resultsCount > 0
          ? resultsCount === 1
            ? `${resultsCount} Result`
            : `${resultsCount} Results`
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    showPicker: state.showPicker,
    pickerLabel: state.pickerLabel,
    medicationsLabel: state.medicationsLabel
  };
};

export default connect(mapStateToProps)(PickerSearch);
