import React, { Fragment, useState, useEffect } from "react";
import ReactSlider from "react-slider";
import MessagePopup from "./MessagePopup";
import ConfirmSavePopup from "./ConfirmSavePopup";
import RetireUnretire from "./RetireUnretire";
import { withRouter } from "react-router";

const NavBarScroll = ({
  setInfo,
  sendForm,
  successMsg,
  showSave,
  defaultMinMatch,
  checkForEmptiness,
  validError,
  currentTrial,
  list,
  showWarningPopup,
  closeWarningPopup,
  name,
  initialName,
  history,
  active: activeLi
}) => {
  const [open, setOpen] = useState(false);
  const [showSaveTrial, setShowSaveTrial] = useState(false);
  const [message, setMessage] = useState("");
  const [saveSuccess, setSaveSuccess] = useState("");
  const [newName, setNewName] = useState(false);
  const [saveAs, setSaveAs] = useState(false);
  const [active, setActive] = useState(0);

  const changeMinMatchPercentageValue = minMatchPercentage => {
    setInfo("min_match", minMatchPercentage);
  };

  const openModalSaveConfirm = saveAs => {
    let error = checkForEmptiness();
    if (!error) {
      setSaveAs(saveAs);
      setShowSaveTrial(true);
    } else {
      return null;
    }
  };

  const saveAsClick = saveAs => {
    if (!newName) return;
    openModalSaveConfirm(saveAs);
  };

  const closeModal = () => {
    setOpen(false);
    setMessage("");
  };

  const handleCloseStatus = () => {
    setShowSaveTrial(false);
    setSaveSuccess("");
  };

  useEffect(() => {
    setSaveSuccess(successMsg);
  }, [successMsg]);

  useEffect(() => {
    setShowSaveTrial(showSave);
  }, [showSave]);

  useEffect(() => {
    if (name === initialName) {
      setNewName(false);
    } else {
      setNewName(true);
    }
  }, [name, initialName]);

  useEffect(() => {
    setActive(activeLi);
  }, [activeLi]);

  return (
    <Fragment>
      <RetireUnretire />
      <div>
        <li className={active === 0 ? "ntw__rs-p-active" : ""}>
          <a href="#info">
            <p className="ntw__rs-p ">Trial Information</p>
          </a>
        </li>
        <li className={active === 1 ? "ntw__rs-p-active" : ""}>
          <a href="#facts">
            <p className="ntw__rs-p">Trial Facts</p>
          </a>
        </li>
        <li className={active === 2 ? "ntw__rs-p-active" : ""}>
          <a href="#criteria">
            <p className="ntw__rs-p">Eligibility Criteria</p>
          </a>
        </li>
      </div>
      <div className="pograyLine" />
      <div className="ntw__rs-info">
        <span className="ntw__rs-minmatch">Minimum match percentage</span>
      </div>
      <div className="ntw__tf-sliderWrapp">
        <ReactSlider
          min={0}
          max={100}
          value={defaultMinMatch}
          onChange={changeMinMatchPercentageValue}
          withBars
        >
          <div className="numberBlock">{defaultMinMatch}%</div>
        </ReactSlider>
      </div>
      <div className="pograyLine" />
      <div className="wrapForRsBtn">
        {parseFloat(currentTrial) || parseFloat(currentTrial) === 0 ? (
          <div className="edit_btns_wrapp">
            <div
              className="ntw__headerWrapp-btn"
              onClick={() => openModalSaveConfirm(false)}
            >
              Save
            </div>
            <div
              className={`ntw__headerWrapp-btn${
                newName ? "" : " btn_disabled"
              }`}
              onClick={() => saveAsClick(true)}
            >
              Save as
            </div>
            <div
              className="ntw__headerWrapp-btn ntw__headerWrapp-btn-cancel"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </div>
          </div>
        ) : (
          <div
            className="ntw__headerWrapp-btn"
            onClick={() => openModalSaveConfirm(true)}
          >
            Save Trial
          </div>
        )}
        {validError && (
          <span className="warningMessage">
            Please fill in all required fields
          </span>
        )}
      </div>
      <MessagePopup open={open} closeModal={closeModal} message={message} />
      <ConfirmSavePopup
        showSaveTrial={showSaveTrial}
        handleCloseStatus={handleCloseStatus}
        saveSuccess={saveSuccess}
        sendForm={sendForm}
        currentTrial={currentTrial}
        showWarningPopup={showWarningPopup}
        closeWarningPopup={closeWarningPopup}
        list={list}
        name={name}
        newName={newName}
        saveAs={saveAs}
      />
    </Fragment>
  );
};

export default withRouter(NavBarScroll);
