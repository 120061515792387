import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import RecentSearchBlock from "./RecentSearchBlock";
import SavedSearchBlock from "./SavedSearchBlock";
import InmunityApi from "../../../api/InmunityApi";
import {
  getSavedSearches,
  getRecentSearches,
  setRecentSearchesReload,
  getSavedSearchesSuccess
} from "./../../../actions/trialActions";
import { SpinnerSmall } from "../../../ui";
import "./index.css";

class RecentSearch extends Component {
  state = {
    recentSearches: [],
    savedSearches: [],
    responseSavedSearches: {},
    loadingRecentSearches: false,
    loadingSavedSearches: false,
    isMoreRecentSearch: null,
    linkRecentSearch: null,
    isMoreSavedSearch: null,
    linkSavedSearch: null,
    activeTab: 0,
    isLoadingMore: false
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      this.setState({
        loadingRecentSearches: true,
        loadingSavedSearches: true
      });
      this.props
        .getRecentSearches()
        .then(() => {
          if (!this.props.searchesRecent.hasOwnProperty("results")) {
            this.setState({
              loadingRecentSearches: false
            });
            throw new Error();
          }
          this.setState({
            isMoreRecentSearch: !!this.props.searchesRecent.next,
            linkRecentSearch: this.props.searchesRecent.next,
            recentSearches: this.props.searchesRecent.results,
            loadingRecentSearches: false
          });
        })
        .catch(error =>
          console.log("Error occured while receiving recent changes: ", error)
        );
      this.props
        .getSavedSearches()
        .then(() => {
          let responseSavedSearches = { ...this.props.savedSearches };
          this.setState({
            isMoreSavedSearch: !!responseSavedSearches.next,
            linkSavedSearch: responseSavedSearches.next,
            savedSearches: responseSavedSearches.results,
            loadingSavedSearches: false,
            responseSavedSearches
          });
        })
        .catch(error =>
          console.log("Error occured while receiving saved searches: ", error)
        );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchesRecent.next !== this.props.searchesRecent.next) {
      this.setState({
        isMoreRecentSearch: !!this.props.searchesRecent.next,
        linkRecentSearch: this.props.searchesRecent.next
      });
    }
    if (
      prevProps.searchesRecent.reload !== this.props.searchesRecent.reload &&
      this.props.searchesRecent.reload === true
    ) {
      this.setState({
        loadingRecentSearches: true
      });
    }
    if (
      prevProps.searchesRecent.reload !== this.props.searchesRecent.reload &&
      this.props.searchesRecent.reload === false
    ) {
      this.setState({
        loadingRecentSearches: false
      });
    }
  }

  toggleWindows = id => {
    this.setState({ activeTab: id });
  };

  static getDerivedStateFromProps(props, state) {
    let oldSavedSearches = [].concat(state.savedSearches);
    let newSavedSearches = [];
    let savedSearches = [];
    let responseSavedSearches = { ...props.savedSearches };
    if (props.savedSearches !== state.responseSavedSearches) {
      if (props.savedSearches.count - state.responseSavedSearches.count === 1) {
        responseSavedSearches.results &&
          responseSavedSearches.results.forEach(item => {
            savedSearches.push(
              item
              // <SavedSearchBlock
              //   name={item.name}
              //   id={item.id}
              //   created={item.created}
              // />
            );
          });
      } else {
        responseSavedSearches.results &&
          responseSavedSearches.results.forEach(item => {
            newSavedSearches.push(
              item
              // <SavedSearchBlock
              //   name={item.name}
              //   id={item.id}
              //   created={item.created}
              // />
            );
          });
        if (oldSavedSearches.length > 0 && newSavedSearches.length > 0) {
          if (oldSavedSearches[0].id === newSavedSearches[0].id) {
            savedSearches = oldSavedSearches;
          } else {
            savedSearches = oldSavedSearches.concat(newSavedSearches);
          }
        } else {
          savedSearches = oldSavedSearches.concat(newSavedSearches);
        }
      }
      return {
        isMoreSavedSearch: !!responseSavedSearches.next,
        linkSavedSearch: responseSavedSearches.next,
        savedSearches,
        responseSavedSearches
      };
    }
    // if (
    //   props.searchesRecent.results.length !== state.recentSearches.length
    // ) {
    //   return {
    //     isMoreRecentSearch: !!props.searchesRecent.next,
    //     linkRecentSearch: props.searchesRecent.next,
    //     loadingRecentSearches: false
    //   };
    // }
    return null;
  }

  loadMoreRecentSearchData = async () => {
    if (this.state.isMoreRecentSearch && !this.state.isLoadingMore) {
      this.setState({ isLoadingMore: true })
      await this.props
        .getRecentSearches(this.state.linkRecentSearch)
        .then(() => {
          if (!this.props.searchesRecent.hasOwnProperty("results")) {
            this.setState({
              loadingRecentSearches: false
            });
            throw new Error();
          }
          this.setState({
            isMoreRecentSearch: !!this.props.searchesRecent.next,
            // isMoreRecentSearch: false,
            linkRecentSearch: this.props.searchesRecent.next,
            // linkRecentSearch: false,
            recentSearches: this.props.searchesRecent.results,
            loadingRecentSearches: false,
          });

          setTimeout(() => this.setState({ isLoadingMore: false }), 1500);
        })
        .catch(error =>
          console.log("Error occured while receiving recent changes: ", error)
        );
    }
  };

  loadMoreSavedSearchData = () => {
    if (this.state.isMoreSavedSearch) {
      InmunityApi.getPatientSearchPagination(this.state.linkSavedSearch)
        .then(response => {
          this.props.getSavedSearchesSuccess(response);
        })
        .catch(error =>
          console.log("Error occured while receiving recent changes: ", error)
        );
    }
  };

  render() {
    let { activeTab, loadingRecentSearches, loadingSavedSearches } = this.state;
    return (
      <div>
        <div className="searchBlockWrapper__selector">
          <div
            className={`searchBlockWrapper__item ${
              activeTab === 0 ? "searchBlockWrapper__active" : ""
              }`}
            id="recentSearches"
            onClick={() => this.toggleWindows(0)}
          >
            Recent Searches
          </div>
          <div
            className={`searchBlockWrapper__item ${
              activeTab === 1 ? "searchBlockWrapper__active" : ""
              }`}
            id="savedSearches"
            onClick={() => this.toggleWindows(1)}
          >
            Saved Searches
          </div>
        </div>

        <div className="searchBlockWrapper" ref={ref => (this.scrollParentRef = ref)}>
          {activeTab === 0 && (
            <React.Fragment>
              {false ? (
                <SpinnerSmall />
              ) : (
                  <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    // className="patients-infscroll"
                    loadMore={this.loadMoreRecentSearchData}
                    hasMore={this.state.isMoreRecentSearch}
                    getScrollParent={() => this.scrollParentRef}
                    loader={<SpinnerSmall key={0} />}
                  >
                    {this.props.searchesRecent.results.map((item, key) => {
                      return (
                        <>
                        <RecentSearchBlock
                          name={item.name}
                          id={item.id}
                          created={item.created}
                          key={item.id}
                          hasResults={item.has_results}
                        />
                        <div style={{margin: 18}}></div>
                        </>
                      );
                    })}
                  </InfiniteScroll>
                )}
            </React.Fragment>
          )}
          <div className="savedSearches">
            {activeTab === 1 && (
              <React.Fragment>
                {loadingSavedSearches && <SpinnerSmall />}
                <InfiniteScroll
                  // className="patients-infscroll"
                  pageStart={0}
                  useWindow={false}
                  loadMore={this.loadMoreSavedSearchData}
                  hasMore={this.state.isMoreSavedSearch}
                  getScrollParent={() => this.scrollParentRef}
                  loader={<SpinnerSmall key={0} />}
                >
                  {this.state.savedSearches.map((item, key) => {
                    return (
                      <SavedSearchBlock
                        name={item.name}
                        id={item.id}
                        created={item.created}
                        key={key}
                        hasResults={item.has_results}
                      />
                    );
                  })}
                </InfiniteScroll>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.currentUser,
    savedSearches: state.searches,
    searchesRecent: state.searchesRecent
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSavedSearches: token => dispatch(getSavedSearches(token)),
    getSavedSearchesSuccess: number => getSavedSearchesSuccess(number),
    getRecentSearches: url => dispatch(getRecentSearches(url))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentSearch);
