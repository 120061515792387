import { simpleRequest, NoJSONRequest } from "./request";
export const getData = url => {
  const headers = Object.assign({
    "Content-Type": "application/json"
  });
  const request = new Request(`${process.env.REACT_APP_API_PATH}/${url}`, {
    method: "GET",
    headers: headers
  });
  return simpleRequest(request);
};

export const getAuthData = (url, picker = false, additionalHeaders = null) => {
  let token = localStorage.getItem("token");
  let headers;
  if (additionalHeaders && Array.isArray(additionalHeaders)) {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      [additionalHeaders[0]]: additionalHeaders[1]
    });
  } else {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    });
  }

  const request = new Request(`${picker ? url : process.env.REACT_APP_API_PATH + "/" + url}`, {
    method: "GET",
    headers: headers
  });
  return simpleRequest(request);
};

export const getAuthDataNoJSON = (url, picker = false) => {
  let token = localStorage.getItem("token");
  const headers = Object.assign({
    Authorization: "Bearer " + token,
    "Content-Type": "application/json"
  });
  const request = new Request(`${picker ? url : process.env.REACT_APP_API_PATH + "/" + url}`, {
    method: "GET",
    headers: headers
  });
  return NoJSONRequest(request);
};

export const getAuthDataFullLink = url => {
  let token = localStorage.getItem("token");
  const headers = Object.assign({
    Authorization: "Bearer " + token,
    "Content-Type": "application/json"
  });
  const request = new Request(`${url}`, {
    method: "GET",
    headers: headers
  });
  const res = simpleRequest(request);
  return res;
};

export const getDataNoJSON = url => {
  const headers = Object.assign({
    "Content-Type": "application/json"
  });
  const request = new Request(`${process.env.REACT_APP_API_PATH}/${url}`, {
    method: "GET",
    headers: headers
  });
  return NoJSONRequest(request);
};
