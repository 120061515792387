import React from "react";
import img from "../../../../../images/plus-icon.svg";
import FreetextBlock from "./../FreetextBlock";

export default React.memo(function FreetextData({
  globalText,
  handleChange,
  setInfo,
  openTextAreaPopup,
  defaultEligibilityCriteria,
  selectedCriteria,
  newFdata
}) {
  return (
    <div className="freetextWrapp ">
      <div className="ntw__ti-inputs ntw__ti-input ntw__ti-iDescr forSymbolCounter">
        <span className="ntw__ti-inputlabel">
          Full Eligibility Criteria
          <span className="symbolCounter">
            {globalText ? globalText.length : 0}/30000
          </span>
        </span>
        <textarea
          className="get_global_text"
          id="fullEC"
          maxLength="30000"
          name="global_text"
          value={globalText}
          onChange={handleChange}
        />
      </div>
      <div className="search_container ntw__ti-inputs newTrialWrapp newWrappElBlockSearch">
        <div className="searchInputs EC-main__listfields-extrawrapp">
          {defaultEligibilityCriteria.map((el, i) => {
            return (
              el.freeText !== "" && (
                <div className="width100 searchItem" key={i}>
                  <FreetextBlock
                    freeText={el.freeText}
                    defaultEligibilityCriteria={defaultEligibilityCriteria}
                    setInfo={setInfo}
                    selectedCriteria={selectedCriteria}
                    data={el}
                    newFdata={newFdata}
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
      <div className="contactWrapp addELBlock-btn" onClick={openTextAreaPopup}>
        <img src={img} alt="Plus" />
        <span>New Eligibility Criteria</span>
      </div>
    </div>
  );
})
