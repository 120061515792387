import React, { useState, useEffect } from "react";
import HospitalRow from "./HospitalsRow";
import HospitalPatientsCountRow from "./HospitalPatientsCountRow";
import HospitalGraphs from "./HospitalGraphs";
import SearchInput from "./../../../trials/TrialInformation/SearchInput";
import _ from "lodash";

export default function SiteDetails({
  hospitals,
  spinner: initSpinner,
  sites
}) {
  const [hospital, setHospital] = useState({});
  const [newHospitals, setNewHospitals] = useState([]);
  const [graphsData, setGraphsData] = useState({});
  const [age, setAge] = useState([]);
  const [ethnicity, setEthnicity] = useState([]);
  const [gender, setGender] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [graphSpinner, setGraphSpinner] = useState(false);
  const [searching, setSearching] = useState(false);
  const inputChange = _.debounce(text => {
    setSearching(true);
    const newHospitals = hospitals.filter(item => {
      return item.name.toLowerCase().search(text.toLowerCase()) !== -1;
    });
    if (text === "") {
      setSearching(false);
    }
    setNewHospitals(newHospitals);
  }, 500);

  useEffect(() => {
    setNewHospitals(hospitals);
    return () => {
      inputChange.cancel();
    };
  }, [hospitals]);

  useEffect(() => {
    setSpinner(initSpinner);
  }, [initSpinner]);

  return (
    <div className="site_det_wrapp">
      <div className="sites_details_search">
        <div className="title_pat_bys">Site Details</div>
        <SearchInput handleInputSearchWord={e => inputChange(e.target.value)} />
      </div>
      <HospitalRow
        setAge={setAge}
        setEthnicity={setEthnicity}
        setGender={setGender}
        hospitals={newHospitals}
        makeHospital={setHospital}
        setGraphsData={setGraphsData}
        setSpinner={setSpinner}
        setGraphSpinner={setGraphSpinner}
        searching={searching}
      />
      <HospitalPatientsCountRow
        hospital={hospital}
        graphsData={graphsData}
        graphSpinner={graphSpinner}
        spinner={spinner}
        sites={sites}
      />
      <HospitalGraphs
        additionalData={{age, gender, ethnicity}}
        graphsData={graphsData}
        spinner={graphSpinner}
        sites={sites}
      />
    </div>
  );
}
