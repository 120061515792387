import React, { Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Popup from "reactjs-popup";
import WorkFlowItem from "./WorkFlowItem";
import { globalState, setWorkFlowId, sendEmailNotification } from "../../../actions/trialActions";
import InmunityApi from "../../../api/InmunityApi";
import i18n from "../../../i18n";
import EmailPopup from "../../EmailPop";
import PrimitivePopup from "../../PrimitivePopup";
import store from "../../../store";
import crossicon from "../../../images/icon-close-w.svg";
import AddNotesContact from "../AddNotesContact";
import ActionsOverview from "./../ActionsOverview";
import CustomSelect from '../../CustomSelect'
import "./index.css";

class WorkFlow extends React.Component {
  state = {
    dropdown: false,
    selectOptions: [],
    workFlow: { workflow: [] },
    actionId: null,
    emailPopupDisplay: false,
    primitivePopupDisplay: false,
    protocolSelected: 0,
    thisTrial: null,
    patientConsented: false,
    uploadedFiles: [],
    decline: false,
    showSpinner: false
  };

  setWorkFlowActions = (actions, status, workFlowState) => {
    let selectOptions = [];
    if (status && actions[status.toString()]) {
      actions[status.toString()].forEach(a => {
        selectOptions.push({ value: a.id, label: a.action });
      });
    }
    this.setState({ selectOptions });
    this.props.globalState({
      ...this.props.globalState,
      workFlowState: workFlowState
    });
  };


  handleOpenPopup = (id) => {
    this.setState({
      emailPopupDisplay: {id}
    });
  }

  openPrimitivePopup = (param) => {
    this.setState({
      primitivePopupDisplay: {id: param}
    }, () => {console.log( this.state)});
  }

  uploadedFile = e => {
    let input = e.target;
    let uploadedFiles = [...this.state.uploadedFiles];
    let obj = {};
    obj.file = null;
    obj.title = null;
    if (input.files && input.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) { };
      obj.title = input.files[0].name;
      obj.file = input.files[0];
      uploadedFiles.push(obj);
      this.setState({ uploadedFiles });
      reader.readAsDataURL(input.files[0]);
    }
  };

  deleteFile = e => {
    let uploadedFiles = [...this.state.uploadedFiles];
    let files = document
      .querySelector(".popupfilesWrapp")
      .querySelectorAll(".uploadedFile");
    files.forEach((el, i) => {
      if (e.target.parentNode.parentNode === el) {
        uploadedFiles.splice(i, 1);
        this.setState({ uploadedFiles });
      }
    });
  };

  checkExclusions = (workflow, index = null) => {
    let exclusions = [];
    let ind = index ? index : 0;
    let id = workflow[ind].id;
    workflow[ind].workflow.forEach(el => {
      if (
        el.statusId === 3 ||
        el.statusId === 2 ||
        el.statusId === 1 ||
        el.statusId === 4 ||
        el.statusId === 6 ||
        el.statusId === 7 ||
        el.statusId === 11 ||
        el.statusId === 12
      ) {
        exclusions.push(el.statusId);
      }
    });
    exclusions.reverse();
    exclusions = exclusions.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    exclusions.reverse();
    if (exclusions.indexOf(2) !== -1) {
      if (exclusions.indexOf(1) > exclusions.indexOf(2)) {
        exclusions.splice(exclusions.indexOf(2), 1);
      } else if (exclusions.indexOf(4) > exclusions.indexOf(2)) {
        exclusions.splice(exclusions.indexOf(2), 1);
      }
    }
    if (exclusions.indexOf(3) !== -1) {
      if (exclusions.indexOf(1) > exclusions.indexOf(3)) {
        exclusions.splice(exclusions.indexOf(3), 1);
      } else if (exclusions.indexOf(4) > exclusions.indexOf(3)) {
        exclusions.splice(exclusions.indexOf(3), 1);
      }
    }
    if (exclusions.indexOf(6) !== -1) {
      if (exclusions.indexOf(1) > exclusions.indexOf(6)) {
        exclusions.splice(exclusions.indexOf(6), 1);
      } else if (exclusions.indexOf(4) > exclusions.indexOf(6)) {
        exclusions.splice(exclusions.indexOf(6), 1);
      }
    }
    if (exclusions.indexOf(7) !== -1) {
      if (exclusions.indexOf(1) > exclusions.indexOf(7)) {
        exclusions.splice(exclusions.indexOf(7), 1);
      } else if (exclusions.indexOf(4) > exclusions.indexOf(7)) {
        exclusions.splice(exclusions.indexOf(7), 1);
      }
    }
    if (exclusions.indexOf(11) !== -1) {
      if (exclusions.indexOf(1) > exclusions.indexOf(11)) {
        exclusions.splice(exclusions.indexOf(11), 1);
      } else if (exclusions.indexOf(4) > exclusions.indexOf(11)) {
        exclusions.splice(exclusions.indexOf(11), 1);
      }
    }
    if (exclusions.indexOf(12) !== -1) {
      if (exclusions.indexOf(1) > exclusions.indexOf(12)) {
        exclusions.splice(exclusions.indexOf(12), 1);
      } else if (exclusions.indexOf(4) > exclusions.indexOf(12)) {
        exclusions.splice(exclusions.indexOf(12), 1);
      }
    }
    this.props.setWorkFlowId({ id: id, status: exclusions });
  };


  sendCandidateEmail = action => {
    if (action) {
      store.dispatch(
        sendEmailNotification({
          messageSuccess: "The email is sending",
          success: undefined,
        }))
      InmunityApi.postCandidateEmail(
        this.props.currentHadmId,
        this.props.workFlow[this.state.protocolSelected].id
      )
        .then(response => {
          if (response.status === 200) {

            store.dispatch(
              sendEmailNotification({
                messageSuccess: "The email was sent successfully",
                success: true,
              })
            );
            response.json().then(e => {
            this.postWorkFlow({...action, emailId: e.id});
            })
          } else {
            this.setState({ showSpinner: false });

            store.dispatch(
              sendEmailNotification({
                messageSuccess: "An error occured while sending email",
                success: false,
              })
            );

          }
        })
        .catch(error => {
          console.log("Sending Candiate Email Failed ", error);
        }
        );
    }
  };

  postWorkFlow = action => {
    if (!action.value) return;
    if (action) {
      let object = { actionId: action.value };
      if (action.emailId) {
        object.emailId = action.emailId;
      }
      InmunityApi.postWorkFlow(
        this.props.currentHadmId,
        this.props.workFlow[this.state.protocolSelected].id,
        object
      )
        .then(response => {
          if (response.status === 200) {
            this.checkExclusions(
              this.props.workFlow,
              this.state.protocolSelected
            );
            this.props.updateWorkflow();
            this.setState({ actionId: null, showSpinner: false });
          } else {
            console.log("error sending workflow");
          }
        })
        .catch(error => console.log("error sending workflow ", error));
    }

  };

  sendAction = action => {
    if (action) {
      if (action.value === 3 || action.value === 10) {
        this.setState({
          emailPopupDisplay: true,
          actionId: action,
          showSpinner: true
        });

      }
      else if (action.value === 11 || action.value === 12) {
        this.setState({
          showSpinner: true
        });
        this.sendCandidateEmail(action);
      }
      else if (
        action.value === 4 ||
        action.value === 5 ||
        action.value === 7 ||
        action.value === 8
      ) {
        this.setState({ decline: true, showSpinner: true });
      } else {
        this.postWorkFlow(action);
        this.setState({
          showSpinner: true
        });
      }
    }
  };

  handleActionChange = selectedOption => {
    this.setState({ actionId: selectedOption });
  };

  selectWorkflow = protocolSelected => {
    this.setState({ protocolSelected });
    this.props.onClick(protocolSelected);
    this.setWorkFlowActions(
      this.props.workFlowActions,
      this.props.workFlow[protocolSelected].stages,
      this.props.workFlow[protocolSelected].workflow[
      this.props.workFlow[protocolSelected].workflow.length - 1
      ]
    );
    this.checkExclusions(this.props.workFlow, protocolSelected);
  };

  componentDidMount() {
    if (
      this.props.workFlow &&
      this.props.workFlow.length > 0 &&
      this.props.workFlowActions
    ) {
      this.setWorkFlowActions(
        this.props.workFlowActions,
        this.props.workFlow[0].stages,
        this.props.workFlow[0].workflow[
        this.props.workFlow[0].workflow.length - 1
        ]
      );
      this.checkExclusions(this.props.workFlow);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.workFlow[this.state.protocolSelected].status !==
      (this.props.workFlow.length > 0 &&
        this.props.workFlow[this.state.protocolSelected].status) ||
      this.props.workFlow.length !== nextProps.workFlow.length
    ) {
      this.checkExclusions(nextProps.workFlow, this.state.protocolSelected);
      this.setWorkFlowActions(
        nextProps.workFlowActions,
        nextProps.workFlow[this.state.protocolSelected].stages,
        nextProps.workFlow[this.state.protocolSelected].workflow[
        nextProps.workFlow[this.state.protocolSelected].workflow.length - 1
        ]
      );
    }
  }

  openPatientConsentedPopup = () => {
    this.setState({
      patientConsented: true
    });
  };

  closePatientConsentedPopup = () => {
    this.setState({
      patientConsented: false
    });
  };

  proceedConfirmation = () => {
    this.sendAction(this.state.actionId);
    this.closePatientConsentedPopup();
  };

  declineOff = () => {
    this.setState({ decline: false, actionId: null });
  };

  hideSpinner = () => {
    this.setState({ showSpinner: false });
  };

  render() {
    return (
      <div className="workflow-container">
        {this.state.decline && (
          <ActionsOverview
            hadmID={this.props.candidate.hadmId}
            decline={true}
            idAction={this.state.actionId.value}
            workflow={this.props.workFlow}
            updateWorkflow={this.props.updateWorkflow}
            setAction={this.declineOff}
            currentTrial={this.props.studyId ? true : false}
            hideSpinner={this.hideSpinner}
          />
        )}
        <h1 className="poHeader">{i18n.t("general.workFlow")}</h1>
        {this.props.workFlow && this.props.workFlow.length === 0 && (
          <div style={{ marginTop: "12px" }}>
            No current workflow available.
          </div>
        )}
        <div className="trials-workflow workflow-multiple-container trials-workflowMoreStyle">
          {!this.props.studyId &&
            this.props.workFlow &&
            this.props.workFlow.map((el, i) => (
              <div
                className={
                  this.state.protocolSelected === i
                    ? "trial-button selected"
                    : "trial-button"
                }
                key={el.trial + i}
                onClick={() => this.selectWorkflow(i)}
                title={el.trial}
              >
                {el.trial}
              </div>
            ))}
          {this.props.studyId && this.props.workFlow && (
            <div className="trial-button selected">
              {this.props.workFlow[this.state.protocolSelected].trial}
            </div>
          )}
        </div>
        <div className="sub-container">
          <div className="time-line">
            {this.props.workFlow &&
              this.props.workFlow.length > 0 &&
              this.props.workFlow[this.state.protocolSelected].workflow.map(
                (item, index) => {
                  return (
                    <div key={index}>
                      <div className="item">
                        <WorkFlowItem
                          openEmailPopup={this.handleOpenPopup}
                          openPrimitivePopup={this.openPrimitivePopup}
                          item={item}
                          index={index}
                          workflow={
                            this.props.workFlow[this.state.protocolSelected]
                              .workflow
                          }
                        />

                        {index + 1 ===
                          this.props.workFlow[this.state.protocolSelected]
                            .workflow.length && (
                            <div className="actions">
                              <div className="select-container">
                                {/*<Select
                                  classNamePrefix={"select-search"}
                                  className="zIndex"
                                  name="workFlowAction"
                                  options={this.state.selectOptions}
                                  onChange={this.handleActionChange}
                                  value={this.state.actionId}
                                /> */}
                                <CustomSelect
                                  options={this.state.selectOptions}
                                  onChange={this.handleActionChange}
                                  value={this.state.actionId}
                                />
                              </div>
                              {!this.state.showSpinner && (
                                <div
                                  className="button-workflow"
                                  onClick={() => {
                                    if (!this.state.actionId) {
                                      return null;
                                    }
                                    if (
                                      this.state.actionId.label ===
                                      "Patient consented"
                                    ) {
                                      this.setState({
                                        patientConsented: true
                                      });
                                    } else {
                                      this.sendAction(this.state.actionId);
                                    }
                                  }}
                                >
                                  <img
                                    className="icon-button-workflow"
                                    src={require("../../../images/save-icon.svg")}
                                    alt="Save"
                                  />
                                </div>
                              )}
                              {this.state.showSpinner && (
                                <div className="workflow-container__spinner">
                                  <img
                                    src={require("../../../images/spinner.gif")}
                                    alt="Spinner"
                                    height="30px"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                      {item.reminder && (
                        <div className="ml-wf-item ml-wf-item-mgtop-0">
                          <WorkFlowItem openEmailPopup={this.handleOpenPopup} item={item.reminder} />
                        </div>
                      )}
                      {item.resents &&
                        item.resents.length > 0 &&
                        item.resents.map((el, i) => {
                          return (
                            <Fragment key={i}>
                              <div
                                key={i}
                                className={`ml-wf-item${
                                  i === 0 && !item.reminder
                                    ? " ml-wf-item-mgtop-0"
                                    : ""
                                  }`}
                              >
                                <WorkFlowItem openEmailPopup={this.handleOpenPopup} item={el} />
                              </div>
                              {el.reminder && (
                                <div className="ml-wf-item-reminder">
                                  <WorkFlowItem openEmailPopup={this.handleOpenPopup} item={el.reminder} />
                                </div>
                              )}
                            </Fragment>
                          );
                        })}
                    </div>
                  );
                }
              )}
          </div>
        </div>
        <AddNotesContact
          updateWorkflow={this.props.updateWorkflow}
          studyId={
            this.props.workFlow && this.props.workFlow.length !== 0
              ? this.props.workFlow[this.state.protocolSelected].id
              : ""
          }
          currentHadmId={this.props.currentHadmId}
        />
        <Popup
          open={this.state.emailPopupDisplay}
          closeOnDocumentClick
          className="matchDetailsEmailPopup"
          onClose={() =>
            this.setState({ emailPopupDisplay: false, showSpinner: false })
          }
        >
          <EmailPopup
            candidate={this.props.candidate}
            studyId={
              this.props.workFlow && this.props.workFlow.length !== 0
                ? this.props.workFlow[this.state.protocolSelected].id
                : ""
            }
            currentUser={this.props.currentUser}
            closePopup={() =>
              this.setState({ emailPopupDisplay: false, showSpinner: false })
            }
            idData={this.state.emailPopupDisplay}
            postWorkFlow={this.postWorkFlow}
            param={this.state.actionId}
          />
        </Popup>

        <Popup
          open={this.state.primitivePopupDisplay}
          closeOnDocumentClick
          className="matchDetailsEmailPopup"
          onClose={() =>
            this.setState({ primitivePopupDisplay: false, showSpinner: false })
          }
        >
          <PrimitivePopup
            candidate={this.props.candidate}
            studyId={
              this.props.workFlow && this.props.workFlow.length !== 0
                ? this.props.workFlow[this.state.protocolSelected].id
                : ""
            }
            currentUser={this.props.currentUser}
            closePopup={() =>
              this.setState({ primitivePopupDisplay: false, showSpinner: false })
            }
            postWorkFlow={this.postWorkFlow}
            param={this.state.actionId}
            idData={this.state.primitivePopupDisplay.id}
          />
        </Popup>


        <Popup
          open={this.state.patientConsented}
          closeOnDocumentClick
          onClose={this.closePatientConsentedPopup}
          className="popup-newtrial"
        >
          <div>
            <div
              className="closePendingPopupCross"
              onClick={this.closePatientConsentedPopup}
            >
              <img src={crossicon} alt="Cross" />
            </div>
            <div className="newTrialPopupWrapper confirmationPp">
              <div className="import-container__header">
                <h1>Confirmation</h1>
                <p>
                  Please ensure Authorized Consent was received from the
                  patient. Proceeding with this action will display Protected
                  Health Information (PHI) in this record. Please ensure
                  documentation is appropriately stored on Immunity Health or at
                  another source.
                </p>
                <div className="attachmentsWrapp">
                  <span className="attachmentsWrappTitle">Attachments</span>
                  <div className="filesWrapp popupfilesWrapp">
                    {this.state.uploadedFiles &&
                      this.state.uploadedFiles.length > 0 &&
                      this.state.uploadedFiles.map((el, i) => {
                        return (
                          <div className="uploadedFile" key={el.title + i}>
                            <span>{el.title}</span>
                            <div className="cross">
                              <img
                                src={require("../../../images/close-circle-o-anticon.svg")}
                                alt="Close Circle"
                                onClick={this.deleteFile}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="addNew">
                    <img
                      src={require("../../../images/clip.svg")}
                      alt="Close Circle"
                      height="16px"
                      style={{ marginRight: "6px" }}
                    />
                    Add New
                    <input
                      id="attachment"
                      className="forSending"
                      type="file"
                      onChange={this.uploadedFile}
                    />
                  </div>
                </div>
                <div className="forNTWBtns">
                  <div
                    className="fromScratch"
                    onClick={this.proceedConfirmation}
                  >
                    Proceed
                  </div>
                  <div
                    className="fromScratch"
                    onClick={this.closePatientConsentedPopup}
                  >
                    Decline
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state.trial,
    globalState: state.globalState,
    currentUser: state.currentUser
  };
}

function mapDispatchToProps(dispatch) {
  return {
    globalState: obj => dispatch(globalState(obj)),
    setWorkFlowId: obj => dispatch(setWorkFlowId(obj))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkFlow);
