import React, { useState } from 'react'
import moment from 'moment'
import Calendar from 'react-calendar'

export default function StudyCalendar ({
  setInfo,
  defaultSudyLength,
  defaultStartDate,
  start_dateError
}) {
  const [monthCount, setMonthCount] = useState(1)
  const [showCalendar, setShowCalendar] = useState(false)

  const minusMonth = () => {
    if (monthCount - 1 > 0) {
      setMonthCount(monthCount - 1)
      setInfo('study_length', monthCount - 1)
    }
  }

  const plusMonth = () => {
    setMonthCount(monthCount + 1)
    setInfo('study_length', monthCount + 1)
  }

  const showCalendarToggle = () => {
    setShowCalendar(!showCalendar)
  }

  const dateChange = date => {
    let dateResult = moment(date).format('YYYY-MM-DD')
    setInfo('start_date', dateResult)
    showCalendarToggle()
  }

  return (
    <div className='ntw__tf-counterWrapp calendar-wrapp'>
      <div className='los-wrapper'>
        <span className='ntw__ti-inputlabel'>Length of study</span>
        <div className='ntw__los'>
          <div className='ntw__los-mp' onClick={minusMonth}>
            -
          </div>
          <div className='ntw__los-number'>
            <span>{defaultSudyLength || 1}</span>
            <span> months</span>
          </div>
          <div className='ntw__los-mp' onClick={plusMonth}>
            +
          </div>
        </div>
      </div>
      <div className='tn_wrapForIP cal-pick los-wrapper'>
        <span className='ntw__ti-inputlabel'>Study start date</span>
        <div className='ntw__los'>
          <div
            className={
              start_dateError
                ? 'checkedCo cal-newtrial redBorder'
                : 'checkedCo cal-newtrial'
            }
            onClick={showCalendarToggle}
          >
            {defaultStartDate || 'Select date..'}
          </div>
          {showCalendar && <Calendar onChange={dateChange} locale='en-GB' />}
        </div>
      </div>
    </div>
  )
}
