import React from 'react'
import { withRouter } from 'react-router'
import Popup from 'reactjs-popup'
import InmunityApi from '../api/InmunityApi'
import './styles/PatientAuthorization.css'
import logo from '../images/logo-w.png'
import BirthdayPicker from '../components/BirthdayPicker'
class PatientAuthorization extends React.Component {
  state = {
    token: null,
    loading: false,
    error: false,
    errorText: null,
    name: null,
    email: null,
    hadm: null,
    date: []
  }

  componentDidMount() {
    this.setState({
      loading: true
    })
    let urlString = window.location.href
    let url = new URL(urlString)
    let token = url.searchParams.get('token')

    this.setState({ token }, () => {
      token &&
        InmunityApi.getPatientAuthorization(token)
          .then(res => {
            if (res.status === 200) {
              return res.json()
            } else if (res.status === 400) {
              res.text().then(txt => {
                this.setState({
                  error: true,
                  errorText: JSON.parse(txt).error
                })
              })
            }
            throw res
          })
          .then(resp => {
            this.setState({
              name: resp.name,
              email: resp.email,
              hadm: resp.hadm,
              loading: false
            })
          })
          .catch(err => {
            this.setState({
              loading: false
            })
          })
    })
  }

  changeDetails = ({ target }) => {
    this.setState({ [target.id]: target.value })
  }

  handleSetDate = (date) => {
    console.log("data =", date);
    this.setState({
      date
    });
  }

  closeModal = () => {
    let { history } = this.props
    history.push({
      pathname: '/'
    })
  }

  postPatient = () => {
    let obj = {}
    if (!this.state.date) return;
    obj.name = this.state.name
    obj.token = this.state.token
    obj.email = this.state.email
    obj.dob = this.state.date

    InmunityApi.postPatientAuthorization(obj).then(res => {
      return res.json()
    }).then(data => {
      let { history } = this.props
      let user = JSON.parse(JSON.stringify(data))
      history.push({
        pathname: '/patient-consented',
        state: { user }
      })
    })
  }

  decline = () => {
    let obj = {}
    if (!this.state.date) return;
    obj.name = this.state.name
    obj.token = this.state.token
    obj.email = this.state.email
    obj.dob = this.state.date

    InmunityApi.declinePatientAuthorization(obj).then(res => {
      if (res.status === 201) {
        res.text().then(res => {
          let { history } = this.props

          const dest = {
            pathname: '/reason-decline-login',
            obj: JSON.parse(res)
          }
          history.push(dest);
        })
      }
    })
  }

  render() {

    return (

      <div className='patientAuthorization forImportPopup'>
        <div className='patAuthBody'>
          <img src={logo} alt='Immunity' />
          <div className='patAuthPaper'>
            <h1>Patient Authorization Regarding Clinical Trials</h1>
            <p>
              Your physician indicated that you are eligible for and may be
              interested in participating in a clinical trial. If you wish to
              continue please sign the authorization form below.{' '}
            </p>
            <span style={{ marginTop: '40px', marginBottom: '40px' }}>
              I hereby authorize my health care providers to disclose health
              information about me from my medical records, including
              information about my medical condition and treatment
              (collectively, “my personal information”) to clinical trial
              coordinators working at my health care provider, to Immunity
              Health, a software company working for my health care provider,
              and to affiliates, business partners, and agents (collectively,
              “Immunity Health”) so that clinical trial coordinators at my
              health care provider may use my personal information to identify
              research studies that may be of interest to me and contact me
              about those studies. I understand that clinical trial coordinators
              at my health care provider and Immunity Health may share my
              personal information with my health care providers and with
              researchers working on studies, including pharmaceutical companies
              sponsoring clinical trials that may be of interest to me, so that
              those researchers may contact me about clinical trials and other
              research studies. I understand that once my personal information
              is disclosed, it may no longer be protected by federal privacy
              law. I understand that I may refuse to sign this authorization. I
              also may revoke (withdraw) this authorization at any time in the
              future by calling 617 480 5992 or by writing to 310 Fifth Avenue,
              New York City, 10036. My refusal or future revocation will not
              affect my treatment, payment, enrollment, or eligibility for
              benefits. If I revoke this authorization, Immunity Health, the
              clinical trial coordinators, and researchers, will stop using or
              sharing my information, but my revocation will not affect uses and
              disclosures of my personal information previously disclosed in
              reliance upon this authorization. I understand that I may receive
              a copy of this authorization. This authorization will expire five
              years after your last visit at your health care provider. I agree
              to be contacted by researchers and sponsors by telephone calls,
              e-mail, and mail at the number(s) and address(es) that I provide
              to my health care provider for all purposes described in this
              authorization. I also agree to be contacted by clinical trial
              coordinators using software developed by Immunity Health,
              researchers, and sponsors, by telephone calls and text messages
              made by using an automatic telephone dialing system or prerecorded
              voice at the number(s) that I provide for all non-marketing
              purposes. I confirm that I am the subscriber for the telephone
              number(s) provided and the authorized user for the e-mail
              address(es) provided, and I agree to notify my health care
              provider directly or via software interfaces provided by Immunity
              Health promptly if any of my number(s) or address(es) change in
              the future. I understand that my wireless service provider’s
              message and data rate may apply. By signing below, I acknowledge
              that I have completely read and fully understand the above release
              and agree to the terms described herein and to be bound thereby. I
              certify that I am over the age of eighteen (18) years and have
              legal capacity to sign this form.
            </span>
            <p>I have read and agree to the above</p>
            <div className='patAuthInputs'>
              <div className='inptWrapp'>
                <span>Typed Name *</span>
                <input
                  onChange={this.changeDetails}
                  type='text'
                  id='name'
                  disabled
                  placeholder='John Doe'
                  defaultValue={this.state.name}
                />
              </div>
              <div className='inptWrapp'>
                <span>E-mail address *</span>
                <input
                  onChange={this.changeDetails}
                  type='email'
                  id='email'
                  placeholder='name@example.co'
                  defaultValue={this.state.email}
                />
              </div>
              <div className='inptWrapp'>
                <span>Birthday *</span>
                <BirthdayPicker setDate={this.handleSetDate} />
              </div>
            </div>
            <div className='btnsWrapp'>
              <div className='decline' onClick={this.decline}>
                Decline
              </div>
              <div className='submit' onClick={() => this.postPatient()}>
                I Agree
              </div>
            </div>
          </div>
        </div>
        <Popup
          open={this.state.error}
          closeOnDocumentClick={false}
          className='saveUserPopup'
        >
          <div>
            <div className='saveUserPopupText'>{this.state.errorText}</div>
          </div>
        </Popup>
      </div>
    )
  }
}

export default withRouter(PatientAuthorization)
