import React from "react";
import { connect } from "react-redux";
import "./index.css";
import moment from "moment";
import { setPatientOverview } from "../../../actions/trialActions";
import WorkflowMultiple from "../WorkflowMultiple";
import { withRouter } from "react-router";
import InfoBlock from "../InfoBlock";

class PatientInfo extends React.Component {
  state = {
    workflow: null,
    loading: false
  };
  componentWillUnmount() {
    let nullObj = {};
    this.props.dispatch(setPatientOverview(nullObj));
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.patientOverview &&
      props.patientOverview.workflow_trials !== state.workflow
    ) {
      return {
        workflow: props.patientOverview.workflow_trials,
        loading: props.patientOverview.loading
      };
    } else if (state.loading !== props.patientOverview.loading) {
      return {
        loading: props.patientOverview.loading
      };
    } else {
      return null;
    }
  }

  goToDetails = () => {
    const searchId=  this.props.match.params.id;

    let { history } = this.props;
    let hadmId = this.props.patientOverview.hadm_id;
    history.push({
      pathname: `/matches/details/${hadmId}/${searchId}`,
      id: hadmId,
      detailTitle: "Patient Details"
    });
  };

  render() {
    let { patientOverview } = this.props;
    return (
      <div className="patientInfoWrapper">
        <div className="patient_overview">
          <h2 className="patTitle">Patient Overview</h2>
          <div className="patInfo">
            <span className="patInfo_title">ID</span>
            <span className="patInfo_value">{patientOverview.immunity_id}</span>
          </div>
          <div className="patInfo">
            <span className="patInfo_title">Name</span>
            <span className="patInfo_value">{patientOverview.name}</span>
          </div>
          <div className="patInfo">
            <span className="patInfo_title">Age</span>
            <span className="patInfo_value">{patientOverview.age}</span>
          </div>
          <div className="patInfo">
            <span className="patInfo_title">Gender</span>
            <span className="patInfo_value">
              {patientOverview.gender === "M"
                ? "Male"
                : patientOverview.gender === "F"
                  ? "Female"
                  : patientOverview.gender === "B"
                    ? "Both"
                    : ""}
            </span>
          </div>
          <div className="patInfo">
            <span className="patInfo_title">Diagnosis</span>
            <span className="patInfo_value">{patientOverview.diagnosis}</span>
          </div>
          <div className="pograyLine" />
          <div className="patEdInfo">
            <span className="patInfo_title">Physicians</span>
            <span className="patInfo_value">
              {patientOverview.physicians
                ? patientOverview.physicians.join(" - ")
                : ""}
            </span>
          </div>
          <div className="patEdInfo">
            <span className="patInfo_title">Last Encounter</span>
            <span className="patInfo_value">
              {patientOverview.last_encounter
                ? moment(patientOverview.last_encounter.date).format(
                  "DD/MM/YY - hh:mm A"
                )
                : ""}{" "}
              {patientOverview.last_encounter
                ? `(${patientOverview.last_encounter.name})`
                : ""}
            </span>
          </div>
          <div className="patEdInfo">
            <span className="patInfo_title">Next Apointment</span>
            <span className="patInfo_value">
              {patientOverview.next_appointment
                ? moment(patientOverview.next_appointment.date).format(
                  "DD/MM/YY - hh:mm A"
                )
                : ""}{" "}
              {patientOverview.next_appointment
                ? `(${patientOverview.next_appointment.name})`
                : ""}
            </span>
          </div>
          <div className="pograyLine" />
        </div>
        <div className="patient_chart">
          <h2 className="patTitle" style={{ marginBottom: "7px" }}>
            Workflow
          </h2>
          <section className="patient_sec">
            {patientOverview.workflow_trials && (
              <WorkflowMultiple workflowTrials={this.state.workflow} />
            )}
          </section>
          <div className="pograyLine" />
        </div>
        <div className="patient_chart">
          <h2 className="patTitle patientChart_indent">Patient Chart</h2>
          <section className="patient_sec">
            <div className="sec_title">Past medical history</div>
            <div className="patientDP_Wrapper">
              <div className="patientDP">
                <span className="matchedMissingText">Matched search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.diseases.find.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
              <div className="patientDP">
                <span className="matchedMissingText">Missing from search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.diseases.missing.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
            </div>
          </section>
          <section className="patient_sec">
            <div className="sec_title">Past surgical history</div>
            <div className="patientDP_Wrapper">
              <div className="patientDP">
                <span className="matchedMissingText">Matched search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.procedures.find.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
              <div className="patientDP">
                <span className="matchedMissingText">Missing from search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.procedures.missing.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
            </div>
          </section>
          <section className="patient_sec">
            <div className="sec_title">Medications</div>
            <div className="patientDP_Wrapper">
              <div className="patientDP">
                <span className="matchedMissingText">Matched search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.medication.find.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
              <div className="patientDP">
                <span className="matchedMissingText">Missing from search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.medication.missing.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
            </div>
          </section>
          <section className="patient_sec">
            <div className="sec_title">Allergies</div>
            <div className="patientDP_Wrapper">
              <div className="patientDP">
                <span className="matchedMissingText">Matched search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.allergies.find.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
              <div className="patientDP">
                <span className="matchedMissingText">Missing from search</span>
                <div className="forPMitems">
                  {patientOverview.chart &&
                    patientOverview.chart.allergies.missing.map((el, i) => (
                      <InfoBlock
                        activeOverview={this.activeOverview}
                        key={i}
                        el={el}
                        immunityId={patientOverview.immunityId}
                      />
                    ))}
                </div>
              </div>
            </div>
          </section>
          <div className="editPatient_btn" onClick={this.goToDetails}>
            Patient Details
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    patientOverview: state.patientOverview
  };
}

export default withRouter(connect(mapStateToProps)(PatientInfo));
