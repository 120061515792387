import React, { Component } from "react";
import { connect } from "react-redux";
import AllExclusions from "./AllExclusions";
import Exclusions from "./Exclusions";
import inmunityApi from "../../api/InmunityApi";
import TrialsNav from "../TrialsNav";
import {
  getExclusionReasons,
  getLastStages,
  setCurrentNavigationLink,
  getTrialDescription,
  setCurrentNavigationSubLink
} from "../../actions/trialActions";
import Filter from "./Filter";
import { Sort, CustomInfinityScroll } from "../../ui";
import { URLGenerator } from "../../utils/trailsNavigation";
import "./index.css";

class Exclusion extends Component {
  state = {
    trialID: "",
    candidates: [],
    initialCandidates: [],
    initialForSearch: [],
    loading: false,
    searching: false,
    showFilter: true,
    isMore: false,
    link: null,
    isReverseSort: true,
    input: "",
    searchLengthResult: null,
    paths: [],
    loadingNext: false,
    url: "",
    options: [],
    selectedOption: ""
  };

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink,
      getTrialDescription
    } = this.props;
    let currentTrial = location.pathname.split("/")[2];
    currentTrial = currentTrial || "";
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial || "all");
    getTrialDescription(currentTrial);
    const { options, selectedOption } = this.makeOptionsForSelect(currentTrial);
    this.setState(
      {
        loading: true,
        showFilter: false,
        trialID: currentTrial,
        paths: URLGenerator({
          id: currentTrial,
          count: {
            name: "exclusions",
            count: ""
          }
        }),
        options,
        selectedOption
      },
      () => this.defaultExclusion()
    );
    this.props.getLastStages();
    this.props.getExclusionReasons();
  }

  makeOptionsForSelect = trialId => {
    const options = [
      { value: "immunity", label: "Immunity ID" },
      {
        value: trialId ? "date" : "excluded",
        label: "Date of exclusion"
      },
      { value: "reason", label: "Reason for exclusion" },
      { value: "stage", label: "Last stage" },
      { value: "rate", label: "Match rate" },
      { value: "name", label: "Name" }
    ];
    const selectedOption = options[0];
    return { options, selectedOption };
  };

  defaultExclusion = () => {
    let { trialID } = this.state;
    if (trialID.length > 0) {
      inmunityApi.getExclusionPatients(trialID).then(candidates => {
        if (this.mounted) {
          let candidats = Array.isArray(candidates.results)
            ? candidates.results.sort((a, b) => a.immunityId - b.immunityId)
            : [];
          this.setState({
            candidates: candidats,
            initialCandidates: candidats,
            loading: false,
            showFilter: Array.isArray(candidates.results)
              ? candidates.results.length > 0
              : false,
            initialForSearch: candidates.results,
            isMore: !!candidates.next,
            link: candidates.next,
            paths: URLGenerator({
              id: trialID,
              count: {
                name: "exclusions",
                count: candidates.count
              }
            })
          });
        }
      });
    } else {
      inmunityApi.getAllExclusionPatients().then(candidates => {
        if (this.mounted) {
          let candidats = Array.isArray(candidates.results)
            ? candidates.results.sort((a, b) => a.immunityId - b.immunityId)
            : [];
          this.setState({
            candidates: candidats,
            initialCandidates: candidats,
            loading: false,
            showFilter: Array.isArray(candidates.results)
              ? candidates.results.length > 0
              : false,
            initialForSearch: candidates.results,
            isMore: !!candidates.next,
            link: candidates.next,
            paths: URLGenerator({
              id: trialID,
              count: {
                name: "exclusions",
                count: candidates.count
              }
            })
          });
        }
      });
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = selectedOption => {
    this.setState(
      {
        selectedOption,
        candidates: []
      },
      () => {
        this.sortExclusionsApi();
      }
    );
  };

  reverseSort = () => {
    this.setState(
      { isReverseSort: !this.state.isReverseSort, candidates: [] },
      () => {
        this.sortExclusionsApi();
      }
    );
  };

  sortExclusionsApi = () => {
    const url = this.sortPatients();
    let { trialID } = this.state;
    this.setState({ loading: true, loadingNext: true }, () => {
      if (trialID !== "") {
        inmunityApi.getExclusionsSorted(trialID, url).then(response => {
          this.setState({
            candidates: response.results,
            loading: false,
            isMore: !!response.next,
            link: response.next,
            searchLengthResult: response.count,
            loadingNext: false
          });
        });
      } else {
        inmunityApi.getAllExclusionsSorted(url).then(response => {
          this.setState({
            candidates: response.results,
            loading: false,
            isMore: !!response.next,
            link: response.next,
            searchLengthResult: response.count,
            loadingNext: false
          });
        });
      }
    });
  };

  applyFilter = filters => {
    let url = ``;
    filters.forEach(filter => {
      switch (filter.filterName) {
        case "Date of exclusion":
          url += `date_min=${filter.dateFrom}&date_max=${filter.dateTo}&`;
          break;
        case "Reason for exclusion":
          url += `reason=${filter.value.min}&`;
          break;
        case "Gender":
          if (filter.value.min === "Male") {
            url += `gender=M&`;
          } else {
            url += `gender=F&`;
          }
          break;
        case "Last stage":
          url += `staging=${filter.value.min}&`;
          break;
        case "Next visit":
          url += `upcoming_min=${filter.dateFrom}&upcoming_max=${
            filter.dateTo
          }&`;
          break;
        case "Trial":
          url += `trials=${filter.value.min}&`;
          break;
        default:
          return null;
      }
    });

    if (url.length === 0) {
      this.setState(
        { url, candidates: [], searching: false, isMore: false, link: null },
        () => this.sortExclusionsApi()
      );
    } else {
      this.setState(
        { url, candidates: [], searching: true, isMore: false, link: null },
        () => this.sortExclusionsApi()
      );
    }
  };

  sortPatients = () => {
    let {
      url,
      selectedOption: { value: flag },
      isReverseSort: reverse,
      input
    } = this.state;
    if (flag) {
      if (reverse) {
        flag = "-" + flag;
      }
      if (url.length !== 0) {
        url += `&ordering=${flag}`;
      } else {
        url += `ordering=${flag}`;
      }
    }
    if (input.length > 0) {
      if (url.length !== 0) {
        url += `&q=${input}`;
      } else {
        url += `q=${input}`;
      }
    }
    return url.replace('&&', '&');
  };

  loadMoreData = () => {
    this.setState({ loadingNext: true }, () => {
      if (this.state.isMore) {
        inmunityApi
          .getPatientSearchPagination(this.state.link)
          .then(response => {
            let link = response.next;
            let isMore = !!link;
            let oldCandidates = [...this.state.candidates];
            let newCandidates = oldCandidates.concat(response.results);
            this.setState({
              candidates: newCandidates,
              initialCandidates: newCandidates,
              link,
              isMore,
              loadingNext: false
            });
          });
      }
    });
  };

  handleChangeInput = e => {
    if (e.target.value.length === 0) {
      this.setState(
        {
          input: e.target.value,
          candidates: [],
          searchLengthResult: null,
          searching: false
        },
        () => this.sortExclusionsApi()
      );
    } else {
      this.setState({
        input: e.target.value
      });
    }
  };

  searchExclusions = () => {
    this.setState(
      {
        candidates: [],
        isMore: false,
        link: null,
        searching: true
      },
      () => this.sortExclusionsApi()
    );
  };

  render() {
    let {
      candidates,
      loading,
      searching,
      showFilter,
      paths,
      selectedOption,
      isReverseSort,
      searchLengthResult,
      trialID,
      isMore,
      loadingNext,
      options
    } = this.state;
    let { name } = this.props.trial;
    return (
      <div className="content-body-matches" id="list">
        {name && trialID && <div className="study-name">{name}</div>}
        <TrialsNav paths={paths} />
        <Sort
          options={options}
          selectedOption={selectedOption}
          isRevert={isReverseSort}
          result={searchLengthResult}
          revert={this.reverseSort}
          select={this.handleChange}
          change={this.handleChangeInput}
          press={this.searchExclusions}
          searching={searching}
        />
        {showFilter && (
          <Filter
            type="exclusionsFilter"
            applyFilter={this.applyFilter}
            allExclusions={!(trialID.length > 0)}
          />
        )}
        <CustomInfinityScroll
          data={candidates}
          searching={searching}
          loading={loading}
          isMore={isMore}
          textNoResult=" No exclusions yet for this trial."
          textNoResultSearch="No results found"
          loadMoreData={this.loadMoreData}
          loadingNext={loadingNext}
        >
          {candidates.map((candidate, i) => {
            return trialID ? (
              <Exclusions
                key={i}
                candidate={candidate}
                id={"trial-exclusion-slice-" + candidate["hadmId"]}
                idd={"forExclusion-" + candidate["hadmId"]}
              />
            ) : (
              <AllExclusions
                key={i}
                candidate={candidate}
                id={"trial-exclusion-slice-" + candidate["hadmId"]}
                idd={"forExclusion-" + candidate["hadmId"]}
              />
            );
          })}
        </CustomInfinityScroll>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state.trial
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getLastStages: () => dispatch(getLastStages()),
    getExclusionReasons: () => dispatch(getExclusionReasons()),
    getTrialDescription: id => dispatch(getTrialDescription(id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Exclusion);
