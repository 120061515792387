import React, { useState, useEffect } from 'react'


const months = [
    '-',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

function range(start, end) {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
}

const pickerContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 8
}

const item = {
    marginRight: 10,
    // width: '40%',
    height: 40,
    background: 'white',

    color: '#3c3c3c',
    fontFamily: 'Gotham',
    fontFize: 14,
    fontWeight: 300,
    padding: '12px 22px',
    boxSizing: 'border-box',
    borderRadius: 4,
    border: '1px solid #d2d8dd',
    marginTop: 2,
    outline: 'none',
}

const title = {

}

const addZeroPrefix = (n) => {
    let ret = String(n);

    if (ret.length < 2) return `0${ret}`
    return (ret);
}

const BirthdayPicker = ({ setDate }) => {
    const [y, setY] = useState('-');
    const [m, setM] = useState('-');
    const [d, setD] = useState('-');

    useEffect(() => {
        if (m === '-' || y === '-' || d === '-') {
            setDate(null);
            return;
        }

        const month = addZeroPrefix(months.indexOf(m));
        const year = y;
        const day = addZeroPrefix(d);

        setDate(`${year}-${month}-${day}`);
    }, [d, y, m])

    const years = range(1900, new Date().getFullYear()).reverse();
    years.unshift('-');
    const days = range(1, 31);
    days.unshift('-');
    return (
        <div style={pickerContainer}>
            <select
                onChange={(e) => setM(e.target.value)}
                value={m}
                style={item}
            >
                {
                    months.map((e, i) => <option value={e}> {e} </option>)
                }
            </select>
            <select
                onChange={(e) => setD(e.target.value)}
                value={d}
                style={item}
            >
                {
                    days.map(e => <option value={e}> {e} </option>)
                }
            </select>
            <select
                onChange={(e) => setY(e.target.value)}
                value={y}
                style={item}
            >
                {
                    years.map(e => <option value={e}> {e} </option>)
                }
            </select>
        </div>
    )
}

export default BirthdayPicker;