import React from 'react'
import Popup from 'reactjs-popup'
import InfiniteScroll from 'react-infinite-scroller'
import i18n from '../../i18n'
import ContactInfo from './ContactInfo'
import '../styles/TopBar.css'
import ImmunityApi from './../../api/InmunityApi'
import { SpinnerSmall, Spinner } from '../../ui'
export default class Contacts extends React.Component {
  state = {
    contacts: [],
    contact: {},
    contactSearch: '',
    newContactPopup: false,
    create: false,
    loading: false,
    isMore: null,
    link: null,
    contactsLength: 0,
    noContacts: false
  }

  componentDidMount () {
    this.mounted = true
    this.setState({ loading: true })
    this.fetchData(this.mounted)
  }

  fetchData = mounted => {
    ImmunityApi.getContacts()
      .then(contacts => {
        if (mounted) {
          this.setState({
            contacts: contacts.results,
            loading: false,
            isMore: !!contacts.next,
            link: contacts.next,
            contactsLength: contacts.count,
            noContacts: false
          })
        }
      })
      .catch(e => console.log('Error getting contacts: ', e))
  }

  componentWillUnmount () {
    this.mounted = false
  }

  loadMoreData = () => {
    if (this.state.isMore) {
      ImmunityApi.getPatientSearchPagination(this.state.link).then(response => {
        let link = response.next
        let isMore = !!link
        let oldContacts = [...this.state.contacts]
        let newContacts = oldContacts.concat(response.results)
        this.setState({
          contacts: newContacts,
          isMore,
          link,
          noContacts: false
        })
      })
    }
  }

  handleInputSearchChange = event => {
    const value = event.target.value
    this.setState({ contactSearch: value })
    if (!value) {
      this.fetchData(true)
    }
  }

  keyPressHandler = event => {
    if (event.charCode === 13) {
      this.searchContatcs()
    }
  }

  searchContatcs = () => {
    this.setState({
      contacts: [],
      isMore: false,
      loading: true,
      noContacts: false
    })
    const { contactSearch } = this.state
    ImmunityApi.getContactsBySearch(contactSearch)
      .then(contacts => {
        this.setState({
          contacts: contacts.results,
          loading: false,
          isMore: !!contacts.next,
          link: contacts.next,
          contactsLength: contacts.count,
          noContacts: !(contacts.results.length > 0)
        })
      })
      .catch(e => console.log('Error getting contacts: ', e))
  }

  showNewContactPopup = (create, id = false) => {
    if (id) {
      ImmunityApi.getContact(id)
        .then(contact => this.setState({ contact }))
        .catch(e => console.log('Error getting contact info: ', e))
      create = false
    } else {
      create = true
    }
    let newContactPopup = true
    this.setState({ newContactPopup, create })
  }

  closeNewContactPopup = () => {
    let newContactPopup = false
    let create = false
    this.setState({ newContactPopup, create })
  }

  closePopupAndFetchData = () => {
    this.fetchData(true)
    this.closeNewContactPopup()
  }

  render () {
    const { contacts, contact, loading, create, noContacts } = this.state
    return (
      <div className='nav-dropdown-contact forImportPopup'>
        <div className='rombo-contact' />
        <div className='contact-container'>
          <div className='contact-search space-btw'>
            <input
              type='text'
              className='field-search field-search-contacts'
              name='search'
              id='search'
              placeholder={`${i18n.t('general.search')}`}
              value={this.state.contactSearch}
              onChange={this.handleInputSearchChange}
              onKeyPress={this.keyPressHandler}
            />
            <img
              className='input-left-icon contact-search-btn'
              src={require('../../images/search.svg')}
              alt='Search'
              onClick={this.searchContatcs}
            />
          </div>
          <div
            className='contact-list'
            ref={ref => (this.scrollParentRef = ref)}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={this.loadMoreData}
              hasMore={this.state.isMore}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
              loader={<SpinnerSmall key={0} />}
            >
              {!noContacts &&
                contacts.map((contact, i) => {
                  let aName = contact.name.split(' ')
                  let fl = ''
                  let ll = ''
                  fl = aName[0].substr(0, 1).toUpperCase()
                  if (aName.length > 1) {
                    ll = aName[1].substr(0, 1).toUpperCase()
                  }
                  return (
                    <div
                      className='contact-item'
                      key={i}
                      onClick={() => {
                        this.showNewContactPopup(false, contact.id)
                      }}
                    >
                      <div className='contact-image'>{fl + ll}</div>
                      <div className='contact-text'>
                        <div className='contact-name'>{contact.name}</div>
                        <div className='contact-phone'>{contact.phone1}</div>
                      </div>
                    </div>
                  )
                })}
            </InfiniteScroll>
            {loading && <Spinner />}
            {noContacts && (
              <div className='loadingCandidates'>
                <div className='contact-phone'>No contacts found</div>
              </div>
            )}
          </div>
          <div
            className='contact-create'
            onClick={() => this.showNewContactPopup(true)}
          >
            <img
              className='navtop-icon-dropdown'
              src={require('../../images/plus-icon.svg')}
              alt=''
            />
            {i18n.t('menu.createContact')}
          </div>
        </div>

        <Popup
          open={this.state.newContactPopup}
          closeOnDocumentClick
          onClose={this.closePopupAndFetchData}
          className='popup-newtrial widthauto pad0 contact-pop'
        >
          <ContactInfo
            onClose={this.closeNewContactPopup}
            contact={contact}
            create={create}
          />
        </Popup>
      </div>
    )
  }
}
