import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function medicationsReducer(state = initialState.medications, action) {
  switch(action.type) {
    case types.MEDICATIONS_SUCCESS:
      return action.medications
    default:
      return state;
  }
}
