import React from "react";
import { connect } from "react-redux";

import "../styles/Notification.css";

class NotificationElement extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      nonRead: this.props.nonRead
    };
  }

  render() {
    return (
      <div className={this.state.nonRead ? "notElement nonRead" : "notElement"}>
        <div className="contact-image">CV</div>
        <div className="notText">
          <span>
            Patient <span className="notNumber">no. 923329</span> has responded
          </span>
          <span>Yes to the consent e-mail.</span>
        </div>
        <div className="notHrs">
          <span>9h</span>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
  
  };
}

export default connect(mapStateToProps)(NotificationElement);
