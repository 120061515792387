import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import { Circle } from "rc-progress";
import moment from "moment";

class AllExclusions extends Component {
  goToDetails = () => {
    let { history } = this.props;
    let hadmId = this.props.candidate.hadmId;
    let studyId = this.props.candidate.studyId;
    history.push({
      pathname: `/trial/${studyId}/matches/details/${hadmId}`,
      id: hadmId,
      detailTitle: "Patient Details"
    });
  };

  makeTrials = obj => {
    let result = "";
    obj.sort((a, b) => b.protocolMatch - a.protocolMatch);
    obj.forEach(trial => {
      let precntage = trial.protocolMatch;
      result += ` ${trial.protocol} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  makeExclusionsTrials = array => {
    let result = "";
    array.sort((a, b) => b.name - a.name);
    array.forEach(trial => {
      let precntage = trial.rate;
      result += ` ${trial.name} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  makeExclusionsReasons = array => {
    let result = {};
    result.res = "";
    result.crOther = "";
    result.crMatched = "";
    result.crUnMatched = "";
    let ar = array.filter(el => {
      return el.criteria.length === 0;
    });
    let arrayWithCriteria = array.filter(el => {
      return el.criteria.length !== 0;
    });
    let a = [];
    arrayWithCriteria.length > 0 && (a = arrayWithCriteria);
    result.arrayWithCriteria = a;
    if (arrayWithCriteria.length > 0) {
      result.count = ar.length;
    } else {
      // ar.splice(0, 1);
      result.count = ar.length;
    }
    ar.sort((a, b) => b.name - a.name);
    ar.forEach(elem => {
      result.res += ` ${elem.name}<br>`;
    });
    arrayWithCriteria.forEach(e => {
      if (e.name === "Other") {
        e.criteria.forEach(el => {
          result.crOther += `${el}<br>`;
        });
      } else if (e.name === "Matched exclusion criteria") {
        e.criteria.forEach(el => {
          result.crMatched += `${el}<br>`;
        });
      } else if (e.name === "Did not match inclusion criteria") {
        e.criteria.forEach(el => {
          result.crUnMatched += `${el}<br>`;
        });
      }
    });
    return result;
  };

  render() {
    let {
      immunity,
      exclusionTime,
      exclusionReason,
      lastStage,
      exclusionMatchrate,
      studyTags,
      exclusionTags,
      name
    } = this.props.candidate;
    let trials = this.makeTrials(studyTags);
    let exclusionTrials = this.makeExclusionsTrials(exclusionTags.trials);
    let exclusions = this.makeExclusionsReasons(exclusionReason);
    return (
      <div className="candidatesWr exclusionsPage" onClick={this.goToDetails}>
        <div
          className="candidates hadmId forExclusionTooltip"
          style={{ width: "20%" }}
        >
          <span className="imm-id">Candidate Information</span>
          <span className="td-content">{immunity}</span>
          <span className="td-content">{name}</span>
          {studyTags
            ? studyTags.length !== 0 && (
                <span
                  className="td-content candidatesAmTrial"
                  data-tip={trials}
                  data-class="toolTip"
                  data-for={this.props.id}
                  data-multiline
                >
                  {studyTags.length > 1 && `${studyTags.length} trials`}
                  {studyTags.length === 1 && `${studyTags.length} trial`}
                </span>
              )
            : ""}
          {studyTags
            ? studyTags.length !== 0 && (
                <ReactTooltip
                  place="bottom"
                  type="light"
                  id={this.props.id}
                  effect="solid"
                />
              )
            : ""}
        </div>
        <div className="candidates c-physician" style={{ width: "20%" }}>
          <span className="forIcon">
            {`Excluded${
              exclusionTags.count > 0 ? ` (${exclusionTags.count})  ` : ""
            }`}
            {exclusionTags.count > 0 && (
              <span
                className="exclusionsTrialsHover"
                data-tip={exclusionTrials}
                data-class="toolTip"
                data-for={`exTrial${this.props.id}`}
                data-multiline
              />
            )}
          </span>{" "}
          <ReactTooltip
            place="bottom"
            type="light"
            id={`exTrial${this.props.id}`}
            effect="solid"
          />
        </div>
        <div
          className="candidates forlsuv"
          style={{ width: "20%", alignItems: "flex-start" }}
        >
          <div className="c-lastSeen">
            <span>Date of exclusion</span>
            <span className="td-content">
              {moment(exclusionTime).format("MM/DD/YYYY")}
            </span>
          </div>
        </div>
        <div
          className="candidates c-physician"
          style={{ width: "20%", position: "relative" }}
        >
          <span className="pad0">Reason for exclusion</span>
          <div className="centerItems">
            <div
              className={
                exclusions.arrayWithCriteria && exclusions.count > 0
                  ? "width90"
                  : ""
              }
            >
              {exclusions.arrayWithCriteria &&
                exclusions.arrayWithCriteria.length > 0 &&
                exclusions.arrayWithCriteria.map((el, i) => {
                  return (
                    <div key={i} className="centerItems">
                      <span className="td-content pad0">{el.name}</span>
                      {el.name === "Other" && exclusions.crOther !== "" && (
                        <span className="infoToolTip pad0mT10 m5">
                          <span
                            data-tip={exclusions.crOther}
                            data-class="toolTip"
                            className="toolTipInfoEx"
                            data-multiline
                            data-for={`infoEx${immunity + "i" + i}`}
                          >
                            i
                          </span>
                          <ReactTooltip
                            place="bottom"
                            id={`infoEx${immunity + "i" + i}`}
                            type="light"
                            effect="solid"
                          />
                        </span>
                      )}
                      {el.name === "Matched exclusion criteria" &&
                        exclusions.crMatched !== "" && (
                          <span className="infoToolTip pad0mT10 m5">
                            <span
                              data-tip={exclusions.crMatched}
                              data-class="toolTip"
                              className="toolTipInfoEx"
                              data-multiline
                              data-for={`infoEx${immunity + "i" + i}`}
                            >
                              i
                            </span>
                            <ReactTooltip
                              place="bottom"
                              id={`infoEx${immunity + "i" + i}`}
                              type="light"
                              effect="solid"
                            />
                          </span>
                        )}
                      {el.name === "Did not match inclusion criteria" &&
                        exclusions.crUnMatched !== "" && (
                          <span className="infoToolTip pad0mT10 m5">
                            <span
                              data-tip={exclusions.crUnMatched}
                              data-class="toolTip"
                              className="toolTipInfoEx"
                              data-multiline
                              data-for={`infoEx${immunity + "i" + i}`}
                            >
                              i
                            </span>
                            <ReactTooltip
                              place="bottom"
                              id={`infoEx${immunity + "i" + i}`}
                              type="light"
                              effect="solid"
                            />
                          </span>
                        )}
                    </div>
                  );
                })}
            </div>
            {exclusions.res && exclusions.res.length > 0 && (
              <div className="centerItems">
                <span
                  className="td-content pad0"
                  dangerouslySetInnerHTML={{ __html: exclusions.res }}
                />
              </div>
            )}
            {exclusions.arrayWithCriteria && exclusions.count > 0 && (
              <div
                className="multiIndicator exclusionIndicator"
                data-tip={exclusions.res}
                data-class="match"
                data-for={`reasonEx${immunity}`}
                data-multiline
              >
                +{exclusions.count}
              </div>
            )}
            <ReactTooltip
              id={`reasonEx${immunity}`}
              place="bottom"
              type="light"
              effect="solid"
            />
          </div>
          {/* <span className="td-content pad0">
            {exclusionReason[0].name}
            {exclusionReason[0].criteria.length > 0 && (
              <div className="infoToolTip">
                <span
                  data-tip={exclusions}
                  data-class="toolTip"
                  data-multiline={true}
                  data-for={this.props.idd}
                >
                  i
                </span>
                <ReactTooltip
                  place="bottom"
                  id={this.props.idd}
                  type="light"
                  effect="solid"
                />
              </div>
            )}
          </span> */}
        </div>
        <div className="candidates lastenc" style={{ width: "22%" }}>
          <div>
            <span>Last Stage</span>
            <span className="td-content td-contant-wrap">{lastStage}</span>
          </div>
          <div className="lastencForCircle">
            <Circle
              percent={exclusionMatchrate}
              strokeWidth="5"
              trailWidth="0"
              strokeColor="#5BBEBF"
              className="lastenCircle"
            />
            <span className="circleNumber">
              {Math.round(exclusionMatchrate)}%
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AllExclusions);
