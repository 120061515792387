import React from "react";
import Popup from "reactjs-popup";
import crossicon from "./../../../../../images/icon-close-w.svg";

export default function MessagePopup({ open, closeModal, message }) {
  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={closeModal}
      className="saveUserPopup"
    >
      <div>
        <div className="closePendingPopupCross" onClick={closeModal}>
          <img src={crossicon} alt="Cross" />
        </div>
        <div className="saveUserPopupText">{message}</div>
      </div>
    </Popup>
  );
}
