import React from "react";
import { withRouter } from "react-router";
import logo from "../images/logo-w.png";
import InmunityApi from '../api/InmunityApi'
import "./styles/PatientDetails.css";

class ThankYou extends React.Component {
  state = {
    isChecking: true,
    error: false,
    errorMwssage: ""
  }

  goToLogin = () => {
    let { history } = this.props;
    history.push({
      pathname: "/"
    });
  };

  componentDidMount() {
    const token = this.props.match.params.token;
    const company = this.props.match.params.company;
    if (token && company)
      InmunityApi.createCompany(company, token).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.setState({ isChecking: false, error: false });
        }
        else {
          this.setState({ isChecking: false, error: true, errorMwssage: 'The Token is Invalid!' });
        }
      }
      )
    else {
      this.setState({ isChecking: false })
      const { location } = this.props
      console.log(location.state);
      if(typeof location.state!=='undefined' && typeof location.state.user!=='undefined' && location.state.user.error){
        this.setState({ isChecking: false, error: true, errorMwssage: 'Invalid personal information!' })
      }
      // InmunityApi.postPatientAuthorization().then(res => {
      //   if (res.status === 400 || res.status === 500){
      //     this.setState({ isChecking: false, error: true, errorMwssage: 'Invalid personal information!' })
      //   }
      // })
    }

  }

  render() {
    const {
      location: { pathname, state }
    } = this.props;

    if (this.state.isChecking) {
      return (
        <div style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <img
            className="small-spinner"
            style={{ height: "50px" }}
            src={require("../images/spinner.gif")}
            alt="Spinner"
          />
        </div>
      )
    };

    return (
      <div className="base-container forPD forBlueBC thankYouPage">
        <div className="base-container">
          <div className="login-container patientDetails reasonDecline">
            <img src={logo} alt="Immunity" />
            <p className="helloName">
              {
                this.state.error ?
                  this.state.errorMwssage :
                  'Thank You!'
              }
            </p>
            {/* {pathname === '/thank-you' && (
              <span>
                System administrator will review your information shortly, and
                you will get an email when your account is ready for use.
              </span>
            )} */}
            {pathname === "/physician-consented" && (
              <span>The patient will be contacted</span>
            )}
            {(pathname === "/patient-consented") && (!this.state.error) && (
              <span>
                Thank you, we will be reaching out to you within the next 5
                business days with more information. If you have any questions
                please feel free to contact us by phone{" "}
                {state && state.user.phone} or email {state && state.user.email}
              </span>
            )}
            <div className="closeWindow" onClick={this.goToLogin}>
              Close window
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ThankYou);
