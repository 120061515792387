import React from "react";
import { connect } from "react-redux";

import NotificationElement from './NotificationElement';

import "../styles/Notification.css";


class Notification extends React.Component {
  render() {
    return (
      <div className="nav-dropdown-notification">
        <div className="contact-container">
          <div className="allread">Mark all as read</div>
          <div className="notificationsWrapp">
            <NotificationElement nonRead={true} />
            <NotificationElement nonRead={true} />
            <NotificationElement nonRead={false} />
            <NotificationElement nonRead={false} />
            <NotificationElement nonRead={true} />
            <NotificationElement nonRead={false} />
            <NotificationElement nonRead={false} />
            <NotificationElement nonRead={false} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    
  };
}

export default connect(mapStateToProps)(Notification);
