import React, { Component } from "react";
import { connect } from "react-redux";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import WeekHeaders from "./weekHeaders";
import WeekEvents from "./weekEvent";
import InmunityApi from "../../api/InmunityApi";
import ToolBar from "./toolbar";
import PreScreening from "./PreScreening";
import CalendarList from "./CalendarList";
import {GlobalSpinner} from '../../ui/spinner'
import {
  setReminders,
  setOtherReminders,
  loadDiseases,
  loadDiseasesSuccess
} from "../../actions/trialActions";

import "./index.css";

moment.locale("ko", {
  week: {
    dow: 1,
    doy: 1
  }
});

BigCalendar.momentLocalizer(moment);


const DashboardFooter = () => {
  return (
    <a
    style={{float: 'right', margin: 40}}
    href="https://seal.beyondsecurity.com/vulnerability-scanner-verification/clinicaltrialsdev.immunityhealth.io"><img src="https://seal.beyondsecurity.com/verification-images/clinicaltrialsdev.immunityhealth.io/vulnerability-scanner-8.gif" alt="Vulnerability Scanner" border="0" /></a>
  )
}


class Dashboard extends Component {
  state = {
    trialOption: localStorage.getItem("trials")
      ? JSON.parse(localStorage.getItem("trials"))
      : "",
    physicianChoosed: localStorage.getItem("physicians")
      ? JSON.parse(localStorage.getItem("physicians"))
      : "",
    events: [],
    physiciansOptions: [],
    trialsOptions: [],
    calendarSpinner: false,
    allEvents: localStorage.getItem("allEvents")
      ? JSON.parse(localStorage.getItem("allEvents"))
      : [],
    calendarEvents: localStorage.getItem("calendarEvents")
      ? JSON.parse(localStorage.getItem("calendarEvents"))
      : [],
    startOfWeek: localStorage.getItem("dashboardWeeks")
      ? moment(
        JSON.parse(localStorage.getItem("dashboardWeeks")).startOfWeek
      ).startOf("isoWeek")._d
      : moment(moment(new Date()).startOf("isoWeek")._d).format(),
    endOfWeek: localStorage.getItem("dashboardWeeks")
      ? moment(
        JSON.parse(localStorage.getItem("dashboardWeeks")).endOfWeek
      ).endOf("isoWeek")._d
      : moment(moment(new Date()).endOf("isoWeek")._d).format(),
    reminders: [],
    otherReminders: []
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    localStorage.setItem("i18nextLng", "en-EN");

    if (this.mounted) {
      const prev = new Date(localStorage.getItem("lastDayOfGettingEvents"));
      let differ = this.dateDiffInDays(prev);
      if (!differ) {
        this.getAllEvents();
      }
    }
    // Temp
    if (this.mounted) {
      this.getPhysiciansOnDashboard();
    }

    // let storedDiseases = localStorage.getItem("diseases");
    // if (storedDiseases && storedDiseases.length > 0) {
    // storedDiseases = JSON.parse(storedDiseases);
    // this.props.loadDiseasesSuccess(storedDiseases);
    // }
    if (!this.props.diseases || this.props.diseases.length === 0) {
      this.props.loadDiseases();
    }

    this.mounted &&
      InmunityApi.getAllStudies().then(trials => {
        let trialsOptions = [];
        if (this.mounted && trials.hasOwnProperty("length")) {
          trials.forEach((t, i) => {
            let obj = {};
            obj.label = t.studyName;
            obj.studyId = t.studyID;
            obj.value = i;
            trialsOptions.push(obj);
          });
          this.mounted && this.setState({ trialsOptions });
        }
      });
    this.mounted && this.getAllReminders();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.reminders !== state.reminders ||
      props.otherReminders !== state.otherReminders
    ) {
      return {
        reminders: props.reminders,
        otherReminders: props.otherReminders
      };
    } else {
      return null;
    }
  }

  dateDiffInDays = prev => {
    return moment(prev).isSame(moment(), "day");
  };

  handleChangeTrial = trialOption => {
    this.setState({ trialOption }, () => {
      let trials = this.state.trialOption;
      let trialsToLocal = JSON.stringify(trials);
      localStorage.setItem("trials", trialsToLocal);
    });
  };

  handleChangePhysician = physicianChoosed => {
    this.setState({ physicianChoosed }, () => {
      let physicians = this.state.physicianChoosed;
      let physiciansToLocal = JSON.stringify(physicians);
      localStorage.setItem("physicians", physiciansToLocal);
    });
  };

  eventColorUp = (event, start, end, isSelected) => {
    let colorClass = event.studyTags.color
      ? `diff-color-${event.studyTags.color.slice(1)}`
      : "";
    return { className: colorClass };
  };

  getPhysiciansOnDashboard = () => {
    let { startOfWeek, endOfWeek } = this.state;
    let obj = { from: startOfWeek, to: endOfWeek };
    InmunityApi.getPhysiciansDashboard(obj).then(res => {
      res.status === 200 &&
        res.text().then(obj => {
          let physicians = JSON.parse(obj);
          let physiciansOptions = [];
          physicians.forEach((p, i) => {
            let obj = {};
            obj.label = p.name;
            obj.physicianId = p.id;
            obj.value = p.id;
            physiciansOptions.push(obj);
          });
          localStorage.setItem("physiciansOptions", JSON.stringify(physiciansOptions));
          this.mounted && this.setState({ physiciansOptions });
        });
    });
  };

  getAllReminders = () => {
    const { trialOption, physicianChoosed } = this.state;
    let { startOfWeek, endOfWeek } = this.state;
    let obj = { from: startOfWeek, to: endOfWeek };
    if (trialOption.length === 0 && physicianChoosed.length === 0) return;
    InmunityApi.getReminders(obj)
      .then(res => {
        if (res.status === 404) {
          this.setState({ reminders: [], otherReminders: [] }, () => {
            this.props.setReminders([]);
            this.props.setOtherReminders([]);
          });
          return false;
        } else if (res.status === 200) {
          res.text().then(obj => {
            let reminders = JSON.parse(obj);
            let otherReminders = [];
            reminders.forEach(el => {
              if (el.type === "Other") {
                el.timeOfVisit = el.date;
                el.endOfVisit = moment(el.date)
                  .add(30, "minutes")
                  .format();
                el.studyTags = {
                  color: "#reminder"
                };
                el.immunity = el.description;
                otherReminders.push(el);
              }
            });
            this.setState({ reminders, otherReminders }, () => {
              this.props.setReminders(reminders);
              this.props.setOtherReminders(otherReminders);
            });
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  getAllEvents = () => {
    const { trialOption, physicianChoosed } = this.state;
    let obj = {};
    if (!this.candidates) return;
    obj.status = 1;
    if (this.candidates.checked && this.matches.checked) {
      obj.status = 3;
    } else if (this.candidates.checked && !this.matches.checked) {
      obj.status = 1;
    } else if (!this.candidates.checked && this.matches.checked) {
      obj.status = 2;
    }
    obj.studies = [];
    obj.physicians = [];
    obj.from = this.state.startOfWeek;
    obj.to = this.state.endOfWeek;

    this.state.trialOption &&
      this.state.trialOption.map(el => {
        obj.studies.push(el.studyId);
        return null;
      });
    this.state.physicianChoosed &&
      this.state.physicianChoosed.map(el => {
        obj.physicians.push(el.value);
        return null;
      });

    if (trialOption.length > 0 || physicianChoosed.length > 0) {
      this.setState({ calendarSpinner: true });
      InmunityApi.getDashboardEventsNew(obj)
        .then(response => {
          if (response.status !== 200) {
            if (response.status === 404) {
              this.setState(
                {
                  allEvents: [],
                  calendarSpinner: false
                },
                () => {
                  const now = new Date();
                  localStorage.setItem("lastDayOfGettingEvents", now);
                  let eventsJSON = JSON.stringify(this.state.allEvents);
                  localStorage.setItem("allEvents", eventsJSON);
                  localStorage.setItem("whatWasChecked", obj.status);
                }
              );
            }
            throw response;
          }
          return response.json();
        })
        .then(allEvents => {
          this.setState(
            {
              allEvents,
              calendarSpinner: false
            },
            () => {
              const now = new Date();
              localStorage.setItem("lastDayOfGettingEvents", now);
              let eventsJSON = JSON.stringify(allEvents);
              localStorage.setItem("allEvents", eventsJSON);
              localStorage.setItem("whatWasChecked", obj.status);
            }
          );
        })
        .catch(error => {
          if (error.status !== 404) {
            console.log("Error occured while receiving events", error);
          }
        });
      this.getCalendarData(obj);
    }
  };

  getCalendarData = obj => {
    InmunityApi.getVisitsEventsNew(obj)
      .then(response => {
        if (response.status !== 200) {
          if (response.status === 404) {
            this.setState(
              {
                calendarEvents: [],
                calendarSpinner: false
              },
              () => {
                let calendarEventsJSON = JSON.stringify(
                  this.state.calendarEvents
                );
                localStorage.setItem("calendarEvents", calendarEventsJSON);
              }
            );
          }
          throw response;
        }
        return response.json();
      })
      .then(calendarEvents => {
        this.setState(
          {
            calendarEvents
          },
          () => {
            let calendarEventsJSON = JSON.stringify(calendarEvents);
            localStorage.setItem("calendarEvents", calendarEventsJSON);
          }
        );
      })
      .catch(error => {
        if (error.status !== 404) {
          console.log("Error occured while receiving events", error);
        }
      });
  };

  render() {
    let components = {
      week: {
        header: WeekHeaders,
        event: WeekEvents
      },
      toolbar: ToolBar
    };

    let eventsСalList = [...this.state.allEvents];
    let events = [...this.state.allEvents].concat(this.state.otherReminders);
    if (this.state.physiciansOptions.length === 0) {
      this.getPhysiciansOnDashboard();
    }

    if (this.props.globalSpinner) {
      return (<GlobalSpinner />)
    }

    return (
      <div className="container-n">
        {this.state.physiciansOptions.length >= 0 && (
          <div className="calendar-container">
            <div className="calendar-container__header">
              <h1>Dashboard</h1>
              <div className="calendar-container__headerRS">
                <div className="newTrialWrapp wrappForTwoSelects">
                  <Select
                    value={this.state.trialOption}
                    closeMenuOnSelect={false}
                    onChange={this.handleChangeTrial}
                    options={this.state.trialsOptions}
                    className="sort_select withInput"
                    isMulti={true}
                    components={makeAnimated()}
                    placeholder={"Select Trial"}
                  />
                  <Select
                    value={this.state.physicianChoosed}
                    closeMenuOnSelect={false}
                    onChange={this.handleChangePhysician}
                    options={this.state.physiciansOptions}
                    className="sort_select withInput"
                    isMulti={true}
                    components={makeAnimated()}
                    placeholder={"Select Physician"}
                  />
                </div>
                <div className="newTrialWrapp wrappForTwoSelects forCheckboxes">
                  <div className="wrapForTwoCheckboxes">
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="candidates"
                        id="candidates"
                        ref={input => (this.candidates = input)}
                        defaultChecked={
                          (localStorage.getItem("whatWasChecked") &&
                            +localStorage.getItem("whatWasChecked") !== 2) ||
                            !localStorage.getItem("whatWasChecked")
                            ? true
                            : false
                        }
                      />
                      <label
                        htmlFor="candidates"
                        className="gray-small-text checkbox-label remeber__block"
                      >
                        <span />
                        <div className="remeber__text">Candidates</div>
                      </label>
                    </div>
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="matches"
                        id="matches"
                        ref={input => (this.matches = input)}
                        defaultChecked={
                          localStorage.getItem("whatWasChecked") &&
                            +localStorage.getItem("whatWasChecked") !== 1
                            ? true
                            : false
                        }
                      />
                      <label
                        htmlFor="matches"
                        className="gray-small-text checkbox-label remeber__block"
                      >
                        <span />
                        <div className="remeber__text">Matches</div>
                      </label>
                    </div>
                  </div>
                  <div
                    className="mdForHeaderAndArrow__btn"
                    onClick={this.getAllEvents}
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>

            {this.state.calendarSpinner && (
              <div className="spinner-container">
                <img
                  className="small-spinner calendar-spinner"
                  style={{ height: "50px" }}
                  src={require("../../images/spinner.gif")}
                  alt="Spinner"
                />
              </div>
            )}
            <div className="big-calendar">
              <BigCalendar
                components={components}
                events={events}
                startAccessor={event => {
                  return new Date(event.timeOfVisit);
                }}
                endAccessor={event => {
                  return new Date(event.endOfVisit);
                }}
                min={new Date(2017, 10, 0, 8, 0, 0)}
                max={new Date(2017, 10, 0, 23, 0, 0)}
                defaultView={"week"}
                views={["week"]}
                defaultDate={
                  localStorage.getItem("dashboardWeeks")
                    ? moment(
                      JSON.parse(localStorage.getItem("dashboardWeeks"))
                        .startOfWeek
                    ).startOf("isoWeek")._d
                    : null
                }
                eventPropGetter={this.eventColorUp}
                onNavigate={(date, view) => {
                  this.setState(
                    {
                      startOfWeek: moment(
                        moment(date).startOf("isoWeek")._d
                      ).format(),
                      endOfWeek: moment(
                        moment(date).endOf("isoWeek")._d
                      ).format()
                    },
                    () => {
                      let dashboardWeeks = {
                        startOfWeek: this.state.startOfWeek,
                        endOfWeek: this.state.endOfWeek
                      };
                      localStorage.setItem(
                        "dashboardWeeks",
                        JSON.stringify(dashboardWeeks)
                      );
                      this.getPhysiciansOnDashboard();
                      this.getAllEvents();
                      this.getAllReminders();
                    }
                  );
                }}
              />
            </div>
          </div>
        )}
        <div className="dashboard-tables">
          <PreScreening />
          <CalendarList
            spinner={this.state.calendarSpinner}
            events={this.state.calendarEvents}
            getAllEvents={this.getAllEvents}
            getAllReminders={this.getAllReminders}
          />
        </div>
        {/* <DashboardFooter /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state,
    trialMatches: state.trialMatches,
    trialCandidates: state.trialCandidates,
    physicians: state.physicians,
    userToken: state.userToken,
    currentUser: state.currentUser,
    reminders: state.reminders,
    otherReminders: state.otherReminders,
    diseases: state.diseases,
    globalSpinner: state.globalSpinner
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setReminders: reminders => dispatch(setReminders(reminders)),
    setOtherReminders: reminders => dispatch(setOtherReminders(reminders)),
    loadDiseases: () => dispatch(loadDiseases()),
    loadDiseasesSuccess: diseases => dispatch(loadDiseasesSuccess(diseases))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
