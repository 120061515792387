import React from "react";
import { connect } from "react-redux";

import "./styles/EmailTemplate.css";

class EmailTemplate extends React.Component {
  render() {
    return (
      <div className="eContainer">
        <div className="eGradient">
          <img src={require("../images/logo-w.png")} className="eLogo" alt="" />
        </div>
        <div className="eContent">
          <h3 className="eTitle">Lorem ipsum dolor sit amet.</h3>
          <p className="eDescription">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras enim
            ligula, interdum eu turpis vel, aliquam suscipit lorem. In varius ac
            nunc sit amet placerat.
          </p>

          <div className="eButton">OPEN</div>

          <img
            src={require("../images/blackPhone.jpg")}
            className="eImage"
            alt=""
          />

          <p className="eDescription">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras enim
            ligula, interdum eu turpis vel, aliquam suscipit lorem. In varius ac
            nunc sit amet placerat.
          </p>
        </div>
        <div className="eFooter">
          <div className="eLinks">
            <div className="eLink">Unsubscribe from this list</div>
            <div className="eLink">Update subscription preferences</div>
          </div>
          <div className="eCopy">
            Copyright © 2018 Immunity Health, All rights reserved. Address 123
            34th Street, New York, NY, USA
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

export default connect(mapStateToProps)(EmailTemplate);
