import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function trialReducer (state = initialState.trials, action) {
  switch (action.type) {
    case types.LOAD_TRIALS_SUCCESS:
      let trials = action.trials.sort((a, b) => {
        return a.studyName.localeCompare(b.studyName)
      })
      return trials
    default:
      return state
  }
}
