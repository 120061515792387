import React from 'react'
import Select from "react-select";
import LabFilterPicker from "../../../LabFilterPicker";
import LabCalendar from './LabCalendar'
import LabRange from './LabRange'
import classes from './lab.module.css'
{
    /* obj: {option, arrayOptions, func} 
    priorityData,
    dateRangeData,
    uomData,
    rangeData,

    obj: { handleFromChange, handleToChange, from, to}
    calendarData,

    setLabValue,
    testOption,
    nameSearch,
    openLabPicker,

    criteria,
    rangeValues  : array: [min, max] ,

    handleLabValueChange,
    handleSetInfo
    */
}


const MyLabSelect = ({
    data /* obj: {option, array, func} */,
    title,
    className = "ntw__ti-input ntw__ti-iTrialNCT ntw__ti-irange forGettingValueFields"
}) => {
    return (
        <div
            className={className}
        >
            <span
                className="ntw__ti-inputlabel">
                {title}
            </span>
            <Select
                className="sort_select"
                value={data.option}
                onChange={data.handler}
                options={data.options}
            />
        </div>
    )
}


const Lab = ({
    /* obj: {option, arrayOptions, func} */
    priorityData,
    dateRangeData,
    uomData,
    rangeData,
    calendarData,

    setLabValue,
    testOption,
    nameSearch,
    openLabPicker,

    criteria,
    rangeValues   /* : array: [min, max] */,

    handleLabValueChange,
    handleSetInfo,
    show
}, ref) => {

    if (!show) return null;

    const filerShow =
        (criteria === "Lab Filters" && testOption.label === "Lab Filters"); // ghost#delete
    return (
        <React.Fragment>
            <LabFilterPicker setLabValue={setLabValue} />
            {
                filerShow &&
                <React.Fragment>
                    <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ilabname forGettingValueFields">
                        <span className="ntw__ti-inputlabel">Laboratory Name</span>
                        <input
                            type="text"
                            // onChange={this.findLabName}
                            name="Lab Filters"
                            readOnly
                            // ref={this.labRef} ghost#issue
                            value={nameSearch}
                            className="get-freetext-name"
                            onClick={openLabPicker}
                        />
                    </div>
                    <MyLabSelect
                        title='Range'
                        data={rangeData}
                    />
                    <LabRange
                        rangeOption={rangeData.option}
                        rangeValues={rangeValues}
                        handleLabValueChange={handleLabValueChange}
                        handleSetInfo={handleSetInfo}
                    />
                    <MyLabSelect
                        title='Lab UoM'
                        className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ilabuom forGettingValueFields"
                        data={{
                            ...uomData,
                            options: uomData.options.filter(e => e && e.label)
                        }}
                    />
                    <LabCalendar
                        ref={ref}
                        data={calendarData}
                    />
                    <MyLabSelect
                        className="ntw__ti-input ntw__ti-priority nextLine lastField forGettingValueFields"
                        title='Include/Exclude'
                        data={dateRangeData}
                    />
                    <MyLabSelect
                        className="ntw__ti-input ntw__ti-priority nextLine priorityField forGettingValueFields"
                        title='Priority'
                        data={priorityData}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default React.forwardRef(Lab)