import React, { useState } from "react";
import styled from 'styled-components'
import warningIcon from '../../images/warning.svg'
import "./style.css";
import '../../ui/input.css'


const StyledPicker = styled.div`
width: 100% !important;
display: flex;
align-items: flex-start;
justify-content: center;
flex-direction: column;
position: absolute;
top: 70px;
background: white;
border-radius: 5px;
font-family: sans-serif;
padding: 5px 0 5px 0;
width: 200px;
max-height: 130px;
overflow-y: scroll;
// border: 1px rgba(0,0,0, 0.2) solid;
box-shadow: 4px 4px 10px rgba(0,0,0, 0.3);
`
const StyledPickerItem = styled.div`
width: 100%;
color: #3C3C3C;
margin-top: 5px;
padding: 5px;
cursor: pointer;
&:hover {
  background: #E8F0FE;
}
`

const StyledInputField = styled.div`
    width: 95%;
    position: relative;
    height: 30px;
    margin-bottom: 50px;
`

const StyledContainer = styled.div`
    position: relative;
`


const inputStyle = {
    // backgroundColor: '#E8F0FE',
    height: 33,
    shadowOffset: { width: 10, height: 10, },
    shadowColor: 'black',
    shadowOpacity: 0.1,
}
const CompanyPicker = ({ options, onSelect, show }) => {
    const [selected, select] = useState(null);
    const [showModal, setShowModal] = useState(false);

    if (!show || !Array.isArray(options)) return null;
    const error = true;

    const handleClick = (index) => {
        setShowModal(false);
        select(index);
        onSelect(index);
    }
    return (
        <StyledContainer>
            <label className='text-small '>Pick a Company</label>
            <StyledInputField
                onClick={() => setShowModal(true)}
            >
                <input
                    disabled={true}
                    value={selected ? options[selected].name : '-'}
                    placeholder="-"
                    type={'text'}
                    className={`text-field simple ${error && 'border-red'}`}
                    style={inputStyle}
                />
                {error && (
                    <img src={warningIcon} alt='Error' className={'img-warning'} />
                )}

            </StyledInputField>
            {
                showModal &&
                <StyledPicker>
                    {
                        options.map((e, index) =>
                            <StyledPickerItem
                                key={index}
                                onClick={() => handleClick(index)}
                            >
                                {e.name}
                            </StyledPickerItem>
                        )
                    }

                </StyledPicker>
            }
        </StyledContainer>
    );
}

export default CompanyPicker
