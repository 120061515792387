import React from 'react'
import Select from "react-select"
import MedicationTooltip from "../../../../trials/NewTrial/MedicationFilters/MedicationTooltip";


const MyNameField = ({
    title,
    nameSearch,
    medicationTooltip,
    medPicked,
    show = true, // bool
    handler

}) => {
    if (!show) return null;


    return (
        <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ifreetext forGettingValueFields medicationName">
            <span className="ntw__ti-inputlabel">{title}</span>
            <input
                type="text"
                name="Medication Filters"
                onChange={handler}
                // ref={this.medicationRef} ghost#issue
                defaultValue={nameSearch}
                className="get-freetext-name"
            />
            {
                medicationTooltip.length > 0 && (
                    <MedicationTooltip
                        medicationNames={medicationTooltip}
                        Mpicked={medPicked}
                    />
                )}
        </div>
    )
}

const MySelect = ({
    title,
    show = true, // bool
    data /* obj: {option:{label, value}, options:array, handler:fn}*/,


    //ghost#delete
    criteria = ''

}) => {


    /* ghost#delete */
    const criteriaTypeCrazyClassName = data.option.value === "medication"
        ? "ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields medication"
        : data.option.value !== "lab" &&
            (criteria !== "Lab Filters" ||
                data.option.value !== "Lab Filters")
            ? "ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields"
            : "ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext width100Lab forGettingValueFields";

    const harcodedClassNames = {
        "Include/Exclude": 'ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields iewrapp',
        "Priority": 'ntw__ti-input ntw__ti-priorityForEL priorityField forGettingValueFields prioritywrapp',
        "Criteria Type": criteriaTypeCrazyClassName,
    }

    /* ghost#delete */

    if (!show) return null;
    return (
        <div className={harcodedClassNames[title]}>
            <span className="ntw__ti-inputlabel">
                {title}
            </span>
            <Select
                value={data.option}
                onChange={data.handler}
                options={data.options}
                className="sort_select"
            />
        </div>
    )
}


const StandartMedication = ({
    testData, //obj: {option:{label, value}, options:array, handler:fn}*/
    priorityData, // obj: {option:{label, value}, options:array, handler:fn}*/
    medicData, //obj: {option:{label, value}, options:array, handler:fn}*/
    nameData
    /*obj:
                search:,
                medicationTooltip:,
                medPicked:,
                handler:,
    */,
    criteria,
    searchPage,
}) => {

    if (searchPage) return null;
    return (
        <div
            className={
                testData.option.value === "medication"
                    ? "ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields medicationSelect"
                    : testData.option.value !== "lab" &&
                        (criteria !== "Lab Filters" || testData.option.value !== "Lab Filters")
                        ? "width28"
                        : "width100"
            }
        >
            <MySelect
                title="Criteria Type"
                show={
                    testData.option.value === "medication" ||
                    (testData.option.label === "Medication Filters" &&
                        criteria === "Medication Filters")
                }
                data={{
                    option: testData.option,
                    options: testData.options,
                    handler: testData.handler,
                }}
            />

            <MySelect
                title="Include/Exclude"
                show={
                    testData.option.value === "medication" ||
                    (testData.option.label === "Medication Filters" &&
                        criteria === "Medication Filters")
                }
                data={{
                    option: medicData.option,
                    options: medicData.options,
                    handler: medicData.handler,
                }}
            />
            <MySelect
                title="Priority"
                show={
                    testData.option.value === "medication" ||
                    (testData.option.label === "Medication Filters" &&
                        criteria === "Medication Filters")
                }
                data={{
                    option: priorityData.option,
                    options: priorityData.options,
                    handler: priorityData.handler,
                }}
            />

            <MyNameField
                title="Name"
                show={testData.option.label === "Medication Filters"}
                nameSearch={nameData.search}
                medicationTooltip={nameData.medicationTooltip}
                medPicked={nameData.medPicked}
                handler={nameData.handler}
            />
        </div>
    )
}

export default StandartMedication