import React, { useState } from "react";
import moment from "moment";
import ReactTable from "react-table";

import "react-table/react-table.css";
import "./index.css";

const LabTable = ({ labValues }) => {
  const [expanded, setExpanded] = useState({});
  return (
    <div className="ntw__ti ntw__tablePlaceboDrugs labPopup">
      <ReactTable
        filterable={true}
        resizable={false}
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? String(row[id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            : true;
        }}
        expanded={expanded}
        onExpandedChange={newExpanded => {
          setExpanded(newExpanded);
        }}
        pivotBy={["category"]}
        data={labValues}
        columns={[
          {
            Header: "Category",
            accessor: "category"
          },
          {
            Header: "Lab Name",
            accessor: "label"
          },
          {
            Header: "Lab Value",
            accessor: "value"
          },
          {
            Header: "UoM",
            accessor: "uom"
          },
          {
            id: "chartTime",
            Header: "Date",
            accessor: d => {
              return moment(d.chartTime).format("MM/DD/YYYY");
            }
          },
          {
            Header: "Code",
            accessor: "code"
          }
        ]}
        showPagination={false}
      />
    </div>
  );
};

export default LabTable;
