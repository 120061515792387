import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";
import translations_en from "./locales/en/translations_en.json";
import translations_es from "./locales/es/translations_es.json";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    ns: ["translations"],
    defaultNS: "translations",

    resources: {
      en: {
        translations: translations_en
      },
      es: {
        translations: translations_es
      }
    },

    interpolation: {
      escapeValue: false // not needed for react!!
    },

    react: {
      wait: true
    }
  });

export default i18n;
