import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setMedicationsLabel(
    state = "",
    action
) {
    switch (action.type) {
        case types.SET_MEDICATIONS_LABEL:
            return action.label;
        default:
            return state;
    }
}
