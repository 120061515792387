import * as types from '../actions/actionTypes';
import initialState from './initialState';


export default function getTrialDescriptionReducer(state = initialState.trial, action) {
  switch (action.type) {
    case types.TRIAL_DESCRIPTION_SUCCESS:
      return action.trial
    case types.UPDATE_TRIAL:
      let trial = action.trial
      return trial
    default:
      return state;
  }
}
