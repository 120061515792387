import React, { Component } from 'react'
import moment from 'moment'
import Calendar from 'react-calendar'
import Popup from 'reactjs-popup'

import './index.css'

class ToolBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showCalendar: false,
      date: props.date
    }
  }
  componentDidMount () {}
  showSmallCalendar = () => {
    this.setState({ showCalendar: true })
    document.querySelector('.calendar-bars').classList.add('active')
  }
  closeSmallCalendar = () => {
    this.setState({ showCalendar: false })
    document.querySelector('.calendar-bars').classList.remove('active')
  }
  goToBack = () => {
    let date = this.props.date.setDate(this.props.date.getDate() - 7)
    this.setState({ date })
    this.props.onNavigate('prev')
  }

  goToNext = () => {
    let date = this.props.date.setDate(this.props.date.getDate() + 7)
    this.setState({ date })
    this.props.onNavigate('next')
  }

  onChangeCalendar = date => {
    this.setState({ date })
    this.props.onNavigate(null, date)
  }

  label = () => {
    const date = moment(this.props.date)
    let startWeek = moment(this.props.date)
      .startOf('week')
      .format('DD')
    let endWeek = moment(this.props.date)
      .endOf('week')
      .format('DD')
    return (
      <span>
        <b>
          {date.format('MMMM')} {startWeek}-{endWeek}, {date.format('YYYY')}
        </b>
      </span>
    )
  }
  render () {
    let { showCalendar } = this.state

    return (
      <div className='toolbar-container'>
        <div className='toolbar__left'>
          <div className={'back-next-buttons'}>
            <div className='btn-back' onClick={this.goToBack}>
              &#8249;
            </div>
            <label className='label-date'>{this.label()}</label>
            <div className='btn-next' onClick={this.goToNext}>
              &#8250;
            </div>
          </div>
        </div>
        <div onClick={this.showSmallCalendar} className='calendar-bars'>
          <div className='calendar-bar' />
        </div>
        <Popup
          open={showCalendar}
          closeOnDocumentClick
          onClose={this.closeSmallCalendar}
          className='calendar-bars'
        >
          <div>
            <div
              className='wrappForCloseCalendar'
              onClick={this.closeSmallCalendar}
            />
            <Calendar onChange={this.onChangeCalendar} locale='en-GB' />
          </div>
        </Popup>
      </div>
    )
  }
}
export default ToolBar
