import React from 'react'

const MyEdit = ({ edit, onClick }) => {

    if (!edit) return null;
    return (
        <div className="editORsave" onClick={onClick}>
            <div className="ntw__headerWrapp-btn">Save</div>
        </div>
    )
}

export default MyEdit;