import React from "react";
import drug_administration from "./../../../../../images/drug_administration.svg";

export default function DrugAdminItem({ drug_admin }) {
  return (
    <div className="infoItem">
      <img src={drug_administration} alt="Drug administration" />
      <div className="tfc-tc">
        <div className="tfc-title">Drug Administration</div>
        <div className="tfc-content">
          <span data-test="drugAdmin">
            {drug_admin ? drug_admin : "no data"}
          </span>
        </div>
      </div>
    </div>
  );
}
