import React, { PureComponent } from 'react'
import checkmark from '../../../../images/icon-check-green.svg'
import crossicon from '../../../../images/icon-close-grey.svg'
import './index.css'

class PermissionEl extends PureComponent {
  state = {
    roleAllowed: this.props.editStatus ? this.props.editStatus : false
  }

  changePermission = () => {
    this.setState({ roleAllowed: !this.state.roleAllowed }, () => {
      this.props.permissionEmitter(this.props.roleName, this.state.roleAllowed)
    })
  }

  render () {
    let { roleAllowed } = this.state
    return (
      <div
        className={
          this.props.first
            ? 'first permission-list__item'
            : 'permission-list__item'
        }
      >
        <div className='permission-list__item-title block-item block-item-text popupTextCreateRole'>
          {this.props.roleName}
        </div>
        <div
          className='permission-list__item-status'
          onClick={this.changePermission}
        >
          <img
            src={roleAllowed ? checkmark : crossicon}
            height='12px'
            alt='Permsion Icon'
          />
        </div>
      </div>
    )
  }
}

export default PermissionEl
