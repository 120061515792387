
import { NoJSONRequest } from './request'

export const deleteData = (url) => {
  const headers = Object.assign({ 'Content-Type': 'application/json' })
  const request = new Request(`${process.env.REACT_APP_API_PATH}/${url}`, {
    method: 'DELETE',
    headers: headers
  })
  return NoJSONRequest(request)
}

export const deleteAuthData = (url, additionalHeaders = null) => {
  let token = localStorage.getItem('token')


  let headers;

  if (additionalHeaders && Array.isArray(additionalHeaders)) {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
      [additionalHeaders[0]]: additionalHeaders[1]
    });
  } else {
    headers = Object.assign({
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    });
  }

  const request = new Request(`${process.env.REACT_APP_API_PATH}/${url}`, {
    method: 'DELETE',
    headers: headers
  })
  return NoJSONRequest(request)
}
