export const logout = () => {
  localStorage.removeItem("token");
  window.location.replace("/");
};

export const simpleRequest = request => {
  return fetch(request)
    .then(response => {
      if (response.status === 401 || response.status === 403) {
        logout();
      } else if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202
      ) {
        return response.json();
      } else {
        throw new Error(response);
      }
    })
    .catch(error => {
      return { error };
    });
};

export const NoJSONRequest = request => {
  return fetch(request)
    .then(response => {
      if (response.status === 401) {
        logout();
      } else {
        return response;
      }
    })
    .catch(error => {
      return { error };
    });
};
