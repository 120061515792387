import React from "react";
import Popup from "reactjs-popup";
import crossicon from "../../../images/icon-close-w.svg";

const NoResultsPopup = ({ status, onClose }) => {
  return (
    <Popup
      open={status}
      closeOnDocumentClick
      onClose={onClose}
      className="popup-newtrial"
    >
      <div>
        <div className="closePendingPopupCross" onClick={onClose}>
          <img src={crossicon} alt="Cross" />
        </div>
        <div className="newTrialPopupWrapper">
          <div className="saveUserPopupText">No patients found</div>
        </div>
      </div>
    </Popup>
  );
};
export default NoResultsPopup;
