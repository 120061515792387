import React from "react";
import { Switch, Route } from "react-router-dom";
import "../../node_modules/material-components-web/dist/material-components-web.css";
import "./styles/Main.css";
import TopBar from "./home/TopBar";
import TrialBar from "./home/TrialBar/";
import MatchDetails from "./MatchDetails";
import Matches from "./Matches/";
import Dashboard from "./dashboard/";
import Patients from "./Patients";
import PatientsSearch from "./PatientsSearch";
import Candidates from "./Candidates";
import Exclusions from "./Exclusions";
import Enrolled from "./Enrolled";
import AnalyticsTrial from "./AnalyticsTrial";
import Trials from "./trials/";
import AllTrials from "./allTrials/";
import TrialsMatches from "./TrialsMatches";
import Info from "./trials/TrialInformation";
import NewTrial from "./trials/NewTrial";
import Login from "./Login/Login";
import PatientDetails from "./PatientDetails";
import ReasonDecline from "./ReasonDecline";
import ReasonDeclineLogIn from "./ReasonDeclineLogIn";
import WishContinue from "./WishContinue";
import ThankYou from "./ThankYou";
import ThankYouError from "./ThankYouError";
import ResetP from "./ResetP";
import PatientAuthorization from "./PatientAuthorization";
import AccountRegistration from "./RegisterPage";
import Settings from "../settings";
import { withRouter } from "react-router";
import EmailTemplate from "./EmailTemplate";
import ForgotP from "./ForgotPassword";
import { GlobalPopup } from '../ui'

const App = () => {
  return (
    <React.Fragment>
      <GlobalPopup />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/forgot-password" component={ForgotP} />
        <Route path="/patient-details" component={PatientDetails} />
        <Route path="/reason-decline" component={ReasonDecline} />
        <Route exact path="/reason-decline-login/:token" component={ReasonDeclineLogIn} />
        <Route exact path="/reason-decline-login/" component={ReasonDeclineLogIn} />
        {/* <Route path="/reason-decline-login" component={ReasonDeclineLogIn} /> */}
        <Route path="/wish-continue" component={WishContinue} />
        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/thank-you/:company/:token" component={ThankYou} />
        <Route path="/physician-consented" component={ThankYou} />
        <Route path="/patient-consented-error/:error" component={ThankYouError} />
        <Route path="/patient-consented" component={ThankYou} />
        <Route path="/patient-authorization" component={PatientAuthorization} />
        <Route exact path="/reset-password" component={ResetP} />
        <Route
          exact
          path="/account-registration"
          component={AccountRegistration}
        />
        <Route path="/email-template" component={EmailTemplate} />
        <Route path="/" component={AuthApp} />
      </Switch>
    </React.Fragment>
  );
}
const AuthApp = ({ location }) => {
  return (
    <div className="app">
      <div className="navigation">
        {!location.pathname.startsWith("/settings") && <TrialBar />}
        <TopBar />
      </div>
      <main className="container">
        <Switch>
          <Route exact path="/" component={Info} />
          <Route exact path="/matches/details/:id/:search" component={MatchDetails} />
          <Route exact path="/matches/details/:id" component={MatchDetails} />
          <Route path="/trial/:id/matches/details/:id" component={MatchDetails} />
          <Route path="/trials/:id/matches" component={Matches} />
          <Route path="/trials/:id/candidates" component={Candidates} />
          <Route path="/trials/:id/analytics" component={AnalyticsTrial} />
          <Route path="/trials/:id/exclusions" component={Exclusions} />
          <Route path="/trials/:id/enrolled" component={Enrolled} />
          <Route path="/trial/:id/edit" component={NewTrial} />
          <Route path="/all-analytics" component={AnalyticsTrial} />
          <Route path="/trials/:id" component={Info} />
          <Route path="/all-trials" component={AllTrials} />
          <Route path="/trials-matches" component={TrialsMatches} />
          <Route path="/trials-exclusions" component={Exclusions} />
          <Route path="/trials-enrolled" component={Enrolled} />
          <Route path="/trials" component={Trials} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/trial/new" component={NewTrial} />
          <Route path="/settings" component={Settings} />
          <Route path="/patients" component={Patients} />
          <Route path="/patients-search/:id" component={PatientsSearch} />
        </Switch>
      </main>
    </div>
  );
}

export default withRouter(App);
