import React, { Component } from "react";
import "create-react-class";
import ELBlockInput from "../ELBlockInput";

class ELBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elItem: props.elItem,
      edit: false,
      arrayEditELBlockInput: []
    }
      this.firstRender = false
  }

  remove = e => {
    if (e.target.className === "red-cross") {
      let ul = e.target.parentNode.parentNode.parentNode;
      let lists = ul.querySelectorAll(".width100");

      lists.forEach((li, i) => {
        if (e.target.parentNode.parentNode === li) {
          li.parentNode.removeChild(li);
          this.props.delete(i);
        }
      });
    }
  };

  editBlock = ({ target }) => {
    this.setState({
      edit: !this.state.edit
    });
    this.setState(
      {
        arrayEditELBlockInput: []
      },
      () => {
        this.state.elItem.arrayOfBlocks.map((li, i) => {
          let arrayEditELBlockInput = this.state.arrayEditELBlockInput;
          arrayEditELBlockInput.push(
            <ELBlockInput
              status={this.state.edit}
              criteria={li.criteria}
              nameValue={li.name}
              include={li.include}
              range={li.range}
              labvalue={li.labvalue}
              minValue={li.minValue}
              maxValue={li.maxValue}
              labUoM={li.labUoM}
              dateRangeFrom={li.dateRangeFrom}
              dateRangeTo={li.dateRangeTo}
              dateRangeIE={li.dateRangeIE}
              priority={li.priority}
              delete={this.removeELBlockInput}
              key={i}
            />
          );
          this.setState({
            arrayEditELBlockInput
          });
          return  null
        });
      }
    );

    if (target.textContent === "Save") {
      let divs;
      let divswidth100;
      let freetext;
      let arrOfObj = [];
      let bigObj = {};

      divswidth100 = target.parentNode.previousSibling.previousSibling.querySelectorAll(
        ".width100"
      );
      freetext = target.parentNode.previousSibling.children[1].value;
      target.parentNode.previousSibling.children[1].value = "";

      divswidth100.forEach(wrapp => {
        let obj = {};
        divs = wrapp.querySelectorAll(".forGettingValueFields");

        if (divs[0].children[1].textContent !== "Lab Filters") {
          divs.forEach(div => {
            if (div.children[0].textContent === "Criteria Type") {
              obj.criteria = div.querySelector(
                ".sort_select"
              ).children[0].children[0].children[0].textContent;
            } else if (div.children[0].textContent === "Name") {
              obj.name = div.children[1].value;
              div.children[1].value = "";
            } else if (div.children[0].textContent === "Include/Exclude") {
              obj.include = div.querySelector(
                ".sort_select"
              ).children[0].children[0].children[0].textContent;
            } else if (div.children[0].textContent === "Priority") {
              obj.priority = parseFloat(
                div.querySelector(".sort_select").children[0].children[0]
                  .children[0].textContent
              );
            }
          });
        } else {
          divs.forEach(div => {
            if (div.classList.contains("dateRange")) {
              obj.dateRangeFrom = div.querySelectorAll(
                ".DayPickerInput"
              )[0].children[0].value;
              div.querySelectorAll(".DayPickerInput")[0].children[0].value = "";
              obj.dateRangeTo = div.querySelectorAll(
                ".DayPickerInput"
              )[1].children[0].value;
              div.querySelectorAll(".DayPickerInput")[1].children[0].value = "";
            } else if (div.classList.contains("lastField")) {
              obj.dateRangeIE = div.querySelector(
                ".sort_select"
              ).children[0].children[0].children[0].textContent;
            } else if (div.classList.contains("priorityField")) {
              obj.priority = parseFloat(
                div.querySelector(".sort_select").children[0].children[0]
                  .children[0].textContent
              );
            } else if (div.querySelector(".ntw__ti-inputlabel")) {
              if (
                div.querySelector(".ntw__ti-inputlabel").textContent ===
                "Criteria Type"
              ) {
                obj.criteria = div.querySelector(
                  ".sort_select"
                ).children[0].children[0].children[0].textContent;
              } else if (
                div.querySelector(".ntw__ti-inputlabel").textContent ===
                "Laboratory Name"
              ) {
                obj.name = div.children[1].value;
                div.children[1].value = "";
              } else if (
                div.querySelector(".ntw__ti-inputlabel").textContent === "Range"
              ) {
                obj.range = div.querySelector(
                  ".sort_select"
                ).children[0].children[0].children[0].textContent;
              } else if (
                div.querySelector(".ntw__ti-inputlabel").textContent ===
                "Lab Value"
              ) {
                obj.labvalue = parseFloat(div.children[0].children[1].value);
              } else if (
                div.querySelector(".ntw__ti-inputlabel").textContent ===
                "Min Value"
              ) {
                obj.minValue = parseFloat(div.children[0].children[1].value);
                div.children[0].children[1].value = "";
              } else if (
                div.querySelector(".ntw__ti-inputlabel").textContent ===
                "Max Value"
              ) {
                obj.maxValue = parseFloat(div.children[0].children[1].value);
                div.children[0].children[1].value = "";
              } else if (
                div.querySelector(".ntw__ti-inputlabel").textContent ===
                "Lab UoM"
              ) {
                obj.labUoM = div.children[1].value;
                div.children[1].value = "";
              }
            }
          });
        }
        arrOfObj.push(obj);
      });

      bigObj.arrayOfBlocks = arrOfObj;
      bigObj.freetext = freetext;
      this.setState({
        elItem: bigObj
      });
    }
  };

  addELBlockInput = e => {
    let arrayEditELBlockInput = this.state.arrayEditELBlockInput;
    arrayEditELBlockInput.push(
      <ELBlockInput delete={this.removeELBlockInput} />
    );
    this.setState({
      arrayEditELBlockInput
    });
  };

  removeELBlockInput = index => {
    let arrayEditELBlockInput = this.state.arrayEditELBlockInput;
    arrayEditELBlockInput.slice(index, 1);
    this.setState({
      arrayEditELBlockInput
    });
  };

  render() {
    let { elItem } = this.state;
    return (
      <div className="list" onClick={this.remove}>
        {!this.state.edit &&
          elItem.arrayOfBlocks.map((li, i) => {
            if (!li.range) {
              return (
                <div className="preList" key={i}>
                  <div className="list-block">
                    <span className="ntw__ti-inputlabel">Criteria Type</span>
                    <p className="list-block__p33 cryt">
                      {li ? li.criteria : ""}
                    </p>
                  </div>
                  <div className="list-block">
                    <span className="ntw__ti-inputlabel">Name</span>
                    <p className="list-block__p33 name">{li ? li.name : ""}</p>
                  </div>
                  <div className="list-block list-blockIE">
                    <span className="ntw__ti-inputlabel">Include/Exclude</span>
                    <p className="list-block__p33 in-ex">
                      {li ? li.include : ""}
                    </p>
                  </div>
                  <div className="list-block list-blockPriority">
                    <span className="ntw__ti-inputlabel">Priority</span>
                    <p className="list-block__p33 prior">
                      {li ? li.priority : ""}
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="preList" key={i}>
                  <div className="list-block width100">
                    <span className="ntw__ti-inputlabel">Criteria Type</span>
                    <p className="list-block__p33 cryt">
                      {li ? li.criteria : ""}
                    </p>
                  </div>
                  <div className="list-block ntw__ti-ilabname">
                    <span className="ntw__ti-inputlabel">Laboratory Name</span>
                    <p className="list-block__p33 name">{li ? li.name : ""}</p>
                  </div>
                  <div className="list-block ntw__ti-irange">
                    <span className="ntw__ti-inputlabel">Range</span>
                    <p className="list-block__p33 range">
                      {li ? li.range : ""}
                    </p>
                  </div>

                  {li.range && li.labvalue ? (
                    <div className="list-block labvalue">
                      <span className="ntw__ti-inputlabel">Lab Value</span>
                      <p className="list-block__p33 val">
                        {li ? li.labvalue : ""}
                      </p>
                    </div>
                  ) : null}
                  {li.range && li.minValue ? (
                    <div className="list-block qwer10">
                      <span className="ntw__ti-inputlabel">Min Value</span>
                      <p className="list-block__p33 min-val">
                        {li ? li.minValue : ""}
                      </p>
                    </div>
                  ) : null}

                  {li.range && li.maxValue ? (
                    <div className="list-block qwer10">
                      <span className="ntw__ti-inputlabel">Max Value</span>
                      <p className="list-block__p33 max-val">
                        {li ? li.maxValue : ""}
                      </p>
                    </div>
                  ) : null}

                  <div className="list-block ntw__ti-ilabuom">
                    <span className="ntw__ti-inputlabel">Lab UoM</span>
                    <p className="list-block__p33 uom">{li ? li.labUoM : ""}</p>
                  </div>
                  <div className="list-block fromTo">
                    <span className="ntw__ti-inputlabel">From</span>
                    <p className="list-block__p33 date-from">
                      {li ? li.dateRangeFrom : ""}
                    </p>
                  </div>
                  <div className="list-block fromTo">
                    <span className="ntw__ti-inputlabel">To</span>
                    <p className="list-block__p33 date-to">
                      {li ? li.dateRangeTo : ""}
                    </p>
                  </div>
                  <div className="list-block ntw__ti-iTrialNCT">
                    <span className="ntw__ti-inputlabel">Include/Exclude</span>
                    <p className="list-block__p33 in-ex">
                      {li ? li.dateRangeIE : ""}
                    </p>
                  </div>
                  <div className="list-block list-blockPriority">
                    <span className="ntw__ti-inputlabel">Priority</span>
                    <p className="list-block__p33 prior">
                      {li ? li.priority : ""}
                    </p>
                  </div>
                </div>
              );
            }
          })}

        {this.state.edit && (
          <div className="ntw__ti-inputs wrappForInputLists onlyForEditWrapper">
            <div className="EC-main__list inputList">
              <div className="EC-main__listfields-extrawrapp">
                {this.state.arrayEditELBlockInput.map((component, i) => {
                  return (
                    <div className="width100" key={i}>
                      {component}
                    </div>
                  );
                })}
              </div>
              <div className="green-plus" onClick={this.addELBlockInput}>
                +
              </div>
            </div>
          </div>
        )}

        {!this.state.edit && (
          <div className="list-block list-block100">
            <span className="ntw__ti-inputlabel">
              Eligibility Criterion (free-text)
            </span>
            <p className="list-block__pBorder free-text-from-array">
              {elItem.freetext ? elItem.freetext : ""}
            </p>
          </div>
        )}

        {this.state.edit && (
          <div className="ntw__ti-inputs ntw__ti-input ntw__ti-iDescr  ntw__ti-ifreetext ntw__ti-ifreetext93 forGettingValueFields">
            <span className="ntw__ti-inputlabel">
              Eligibility Criterion (free-text)
            </span>
            <textarea
              className="get_form"
              defaultValue={elItem.freetext}
              maxLength="10000"
            />
          </div>
        )}

        <div className="editORsave">
          <div className="ntw__headerWrapp-btn" onClick={this.editBlock}>
            {!this.state.edit ? "Edit" : "Save"}
          </div>
        </div>

        <div className="red-cross" />
      </div>
    );
  }
}
export default ELBlock;
