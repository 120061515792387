import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'
import { setPopup } from '../actions/trialActions'
import crossicon from '../images/icon-close-w.svg'

function GlobalPopup ({ popup, closePopup }) {
  return (
    <Popup
      open={popup.show}
      onClose={closePopup}
      closeOnDocumentClick
      className='saveUserPopup'
    >
      <div>
        <div className='global__popup-cross' onClick={closePopup}>
          <img src={crossicon} alt='Cross' />
        </div>
        <div>
          <div className='global__popup-text'>{popup.text}</div>
        </div>
      </div>
    </Popup>
  )
}

const mapStateToProps = state => ({
  popup: state.popup
})

const mapDispatchToProps = dispatch => ({
  closePopup: () => dispatch(setPopup({ text: '', show: false }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalPopup)
