import React, { Fragment } from "react";
import Select from "react-select";

export default function Geography({
  stateOption,
  handleChangeState,
  stateList,
  cityOption,
  handleChangeCity,
  cityList,
  hospitalOption,
  handleChangeHospital,
  hospitalList,
  radius,
  handleChangeRadius,
  priorityOption,
  handleChangePriority,
  priorityOptions
}) {
  return (
    <Fragment>
      <div className="ntw__ti-input ntw__ti-ifreetext forGeoInput">
        <span className="ntw__ti-inputlabel">State</span>
        <Select
          value={stateOption}
          onChange={handleChangeState}
          options={stateList}
          className="sort_select withInput"
        />
      </div>
      <div className="ntw__ti-input ntw__ti-ifreetext forGeoInput">
        <span className="ntw__ti-inputlabel">City</span>
        <Select
          value={cityOption}
          onChange={handleChangeCity}
          options={cityList ? cityList : []}
          className="sort_select withInput"
        />
      </div>

      <div className="ntw__ti-input nextLine forGeoSelect">
        <span className="ntw__ti-inputlabel">Hospital</span>
        <Select
          value={hospitalOption}
          onChange={handleChangeHospital}
          options={hospitalList ? hospitalList : []}
          className="sort_select withInput"
        />
      </div>

      <div className="ntw__ti-input nextLine forGeoSelect">
        <span className="ntw__ti-inputlabel">Radius (miles)</span>
        <input
          type="number"
          name="Geography"
          className="get-freetext-name"
          defaultValue={radius}
          onChange={handleChangeRadius}
        />
      </div>

      <div className="ntw__ti-input nextLine forGeoSelect">
        <span className="ntw__ti-inputlabel">Priority</span>
        <Select
          value={priorityOption}
          onChange={handleChangePriority}
          options={priorityOptions}
          className="sort_select withInput"
        />
      </div>
    </Fragment>
  );
}
