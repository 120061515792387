import React, { Component } from "react";
import { withRouter } from "react-router";
import Notes from "./Notes";
import i18n from "../../../i18n";
import InmunityApi from "../../../api/InmunityApi";
import { Spinner } from "./../../../ui";
import "./index.css";
import ChartContainer from "./ChartBox";


function SearchTitleGroup({ handleInputSearchWord }) {
  return (
    <div className="heading">
      <h1>Patient Chart</h1>
      <div className="search-word-container">
        <img
          className="search-word-icon"
          src={require("../../../images/search.svg")}
          alt=""
        />
        <input
          type="text"
          className="search-word"
          name="search-word"
          id="search-word"
          placeholder={`${i18n.t("general.search")}`}
          onChange={handleInputSearchWord}
        />
      </div>
    </div>
  )
}


class PatientChart extends Component {
  state = {
    allergiesString: "",
    diseases: {},
    medications: {},
    procedures: {},
    searchText: [],
    spinner: false,
    deidentified: "",
    identified: "",
  };

  componentDidMount() {
    let { hadmId, studyID, searchId } = this.props;
    this.getPatientChart(hadmId, studyID, searchId);
  }

  getPatientChart = (hadmId, studyID, searchId) => {
    this.setState({ spinner: true });
    InmunityApi.getPatientChart(hadmId, studyID, searchId)
      .then(res => {
        this.setState({
          diseases: res.diseases,
          medications: res.medications,
          procedures: res.procedures
        });
        console.log(res);
      })
      .catch(error => {
        console.log("error", error);
        this.setState({
          diseases: {},
          medications: {},
          procedures: {}
        });
      })
      .finally(() => this.setState({ spinner: false }));
  };



  handleInputSearchWord = event => {
    var value = event.target.value;
    if (value.length > 1) {
      let searchText = [value];
      this.setState({ searchText });
    } else {
      this.setState({ searchText: [] });
    }
  };

  render() {

    const {
      allergiesString,
      searchText,
      medications,
      diseases,
      procedures,
      identified,
      deidentified,
      spinner,
    } = this.state;

    const {
      hadmId,
      nurseNotes,
      progressNotes,
    } = this.props.details
    const {
      searchId
    } = this.props
    return (
      <div className="pcWrapp" id="patientChartContent">
        <SearchTitleGroup
          handleInputSearchWord={this.handleInputSearchWord}
        />
        {
          spinner ? <Spinner /> :
            <ChartContainer
              searchText={searchText}
              medications={medications}
              diseases={diseases}
              searchId={searchId}
              procedures={procedures}
              allergiesString={allergiesString}
              trialName={this.props.trialName}
              identifiedData={[deidentified, identified]}
              hadmId={hadmId}
            />
        }
        <Notes
          nurseNotes={nurseNotes}
          progressNotes={progressNotes}
          id={hadmId}
          searchText={searchText}
        />
      </div >
    );
  }
}

export default withRouter(PatientChart);
