import React from 'react'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Select from 'react-select'
import Toggle from 'react-toggle'

import i18n from '../../../i18n'
import InmunityApi from '../../../api/InmunityApi'

import crossicon from '../../../images/icon-close-w.svg'

import './index.css'

class AddNotesContact extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      showButtons: false,
      action: 0,
      contactDate: moment(),
      contactedViaOptions: [
        { value: 'Phone', label: 'Phone' },
        { value: 'Email', label: 'Email' }
      ],
      contactedVia: { value: 'Phone', label: 'Phone' },
      reminderSet: false,
      rotate: false
    }
  }

  setAction = value => {
    this.setState({ action: value })
  }

  addNote = () => {
    if (this.noteTextarea.value !== '') {
      this.noteTextarea.classList.remove('redBorder')
      this.setState({ action: 0, showButtons: false })
      if (this.state.action === 2) {
        let text = { text: this.noteTextarea.value }
        InmunityApi.postCoordinatorNotes(
          this.props.currentHadmId,
          this.props.studyId,
          text
        ).then(() => {
          this.props.updateWorkflow()
          this.setAction(0)
        })
      }
    } else {
      this.noteTextarea.classList.add('redBorder')
    }
  }

  addContactNote = () => {
    if (!this.contactNoteTextarea.value) {
      this.contactNoteTextarea.classList.add('redBorder')
    } else {
      this.contactNoteTextarea.classList.remove('redBorder')
      let obj = {}
      obj.contact_method = this.state.contactedVia.label
      obj.contact_time = moment(this.state.contactDate).format()
      obj.note = this.contactNoteTextarea.value
      obj.reminder = this.state.reminderSet
      this.props.currentHadmId &&
        this.props.studyId &&
        InmunityApi.sendContactNotes(
          this.props.currentHadmId,
          this.props.studyId,
          obj
        ).then(() => {
          this.props.updateWorkflow()
          this.setAction(0)
        })
    }
  }

  handleChangeCalendar = date => {
    this.setState({ contactDate: date })
  }

  handleChangeContactedVia = selectedOption => {
    this.setState({ contactedVia: selectedOption })
  }

  handleReminderSet = event => {
    this.setState({
      reminderSet: !this.state.reminderSet
    })
  }

  clickOnPlusBtn = () => {
    this.setState({
      showButtons: !this.state.showButtons,
      rotate: !this.state.rotate
    })
  }

  render () {
    return (
      <div className='add-notes-contact-container'>
        {this.state.showButtons && (
          <div>
            <div className='contact-button' onClick={() => this.setAction(1)}>
              <img
                src={require('../../../images/phone-trial.svg')}
                alt='Contact'
                height='14px'
              />
            </div>
            <div className='note-button' onClick={() => this.setAction(2)}>
              <img
                src={require('../../../images/doc-icon.svg')}
                alt='Contact'
                height='14px'
              />
            </div>
          </div>
        )}
        <div className='plus-button-notes' onClick={this.clickOnPlusBtn}>
          <div className={this.state.rotate ? 'rotatable' : 'non-rotatable'}>
            +
          </div>
        </div>
        <Popup
          open={this.state.action !== 0}
          closeOnDocumentClick
          onClose={() => this.setAction(0)}
          className='add-note-contact-popup'
        >
          <div className='add-note-contact-container'>
            <div
              className='closePendingPopupCross'
              onClick={() => this.setAction(0)}
            >
              <img src={crossicon} alt='Cross' />
            </div>
            <h3 className='title'>
              {this.state.action === 1
                ? i18n.t('general.newContactNote')
                : i18n.t('general.newNote')}
            </h3>
            {this.state.action === 1 ? (
              <div className='add-contact-container'>
                <div className='row'>
                  <div className='title-field'>
                    {i18n.t('general.dateContact')}
                  </div>
                  <DatePicker
                    name='reminderDate'
                    className='date-picker-add-contact'
                    selected={this.state.contactDate}
                    onChange={this.handleChangeCalendar}
                    showTimeSelect
                    timeFormat='HH:mm'
                    dateFormat='LLL'
                    timeCaption='time'
                  />
                </div>
                <div className='row'>
                  <div className='title-field'>
                    {i18n.t('general.contactedVia')}
                  </div>
                  <Select
                    classNamePrefix='select-contactedVia'
                    name='contactedVia'
                    options={this.state.contactedViaOptions}
                    value={this.state.contactedVia}
                    onChange={this.handleChangeContactedVia}
                  />
                </div>
                <div className='row'>
                  <div className='title-field'>
                    {i18n.t('general.reminderSet')}
                  </div>
                  <Toggle
                    className='toggle-add-contact'
                    defaultChecked={this.state.reminderSet}
                    icons={false}
                    onChange={this.handleReminderSet}
                  />
                  <div className='title-description'>
                    {i18n.t('general.reminderSetDescription')}
                  </div>
                </div>

                <div className='note-field-container'>
                  <div className='title-field'>{i18n.t('general.notes')}</div>
                  <textarea
                    maxLength={300}
                    className='note-textarea'
                    ref={textarea => (this.contactNoteTextarea = textarea)}
                  />
                </div>

                <div className='submit-button' onClick={this.addContactNote}>
                  {i18n.t('general.save')}
                </div>
              </div>
            ) : (
              <form
                action='#'
                onSubmit={e => {
                  e.preventDefault()
                  this.addNote()
                }}
                className='form-note-contact'
              >
                <textarea
                  maxLength={300}
                  className='note-textarea'
                  ref={textarea => (this.noteTextarea = textarea)}
                />
                <input
                  type='submit'
                  className='submit-button'
                  value={i18n.t('general.save')}
                />
              </form>
            )}
          </div>
        </Popup>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {}
}

export default connect(mapStateToProps)(AddNotesContact)
