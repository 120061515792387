import React from "react";

export default function DataArray({ data, title }) {
  return data && data.length > 0 ? (
    <div className="ntw__EC-main" data-test="dataArray">
      <span className="filters-social__title">{title}</span>
      <div className="filter-wrapper">
        <span className="ntw__ti-inputlabel" data-test="dataTitle">
          {title.split(" ")[0] + " Name"}
        </span>
      </div>
      <div className="ntw__ti ntw__tablePlaceboDrugs">
        {data.map((text, i) => {
          return (
            <div className="ntw__tableRow" key={i}>
              <span
                className="ntw__table-title filter-table-title"
                data-test={`elName${i}`}
              >
                {/* Temp */}
                {text.name}
              </span>
              <span
                className="ntw__table-value filter-table-inc"
                data-test={`elInclude${i}`}
              >
                {text.include ? "Include" : "Exclude"}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}
