import React from "react";
import { NavLink } from "react-router-dom";
import { MicroSpinner as Spinner } from "./../../ui";
import "./index.css";

const TrialsNav = ({ paths }) => {
  return (
    <nav className="navigation-all-trials">
      <ul className="all-trials-nav">
        {paths.map((el, i) => (
          <li className="all-trials-item" key={i}>
            <NavLink to={el.path} exact>
              <span>
                {el.name + " "}
                {el.name === "Candidates" && el.trial}
                {el.name === "Matches" && el.trial}
              </span>
              {el.spinner && <Spinner />}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default TrialsNav;
