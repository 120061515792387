import React, { useState, useEffect } from 'react'
import classes from './diseases.module.css'
import SelectedTreeComponent from "../../../TreeSelect/SelectedTreeComponent";

const chooseDot = (i, { atc, epc, moa }) => (
    i === 0 ? atc.length :
        i === 1 ? epc.length :
            moa.length
)

const PrimaryCriteriaField = ({
    title,
    show, // bool
    pickerArray, // array
    pickerResult, // array
    tabs, // obj: {atc, epc, moa}
    activeTab, // int
    toggleTabs, // this,
    setVeryFarLabelFromBlock // dispatch

}) => {
    const [open, setOpen] = useState({});

    useEffect(() => {
        let obj = {};
        pickerResult.forEach(e => {
            obj[e.parentId] = true;
        })
        setOpen(obj);
    }, [pickerResult])

    useEffect(() => {
        if (!pickerArray || !pickerArray[activeTab] || !show) return;
        setVeryFarLabelFromBlock(pickerArray[activeTab].label);
    }, [pickerArray, activeTab])

    let parentIndex = 0;
    if (!show) return null;

    return (
        <div className="bigAreaForPicker-wrapper disPopup ppWrapper med-view-wr">
            <span className="bigAreaForPicker-wrapper__title">
                {title}
            </span>
            <div className="searchBlockWrapper__selector selected_view">
                {
                    Array.isArray(pickerArray) &&
                    pickerArray.map((el, i) => {
                        let dot = chooseDot(i, tabs)
                        return (
                            <div
                                className={`searchBlockWrapper__item${
                                    i === activeTab
                                        ? " searchBlockWrapper__active searchBlockWrapper__active__dis"
                                        : ""
                                    }`}
                                style={{
                                    borderLeft:
                                        activeTab === 0 && i === 1
                                            ? "none"
                                            : activeTab === 1 && i === 2
                                                ? "none"
                                                : "1px solid #bebebe "
                                }}
                                id="recentSearches"
                                key={el.id}
                                onClick={() => toggleTabs(i)}
                            >
                                {el.className}
                                {dot > 0 ? <div className="search-ball" /> : null}
                            </div>
                        );
                    })}
            </div>
            <div className="bigAreaForPicker">
                <div>
                    {
                        pickerResult.map((el, index) => {
                            if (!el.isLeaf)
                                parentIndex++;
                            return (
                                <SelectedTreeComponent
                                    key={el.id}
                                    name={el.name}
                                    id={el.id}
                                    include={el.include}
                                    children={el.hasLeafs}
                                    forSending={[]}
                                    selectedView={true}
                                    lastLevel={el.isLeaf}
                                    setOpen={() => setOpen({ ...open, [el.id]: !open[el.id] })}
                                    parentIndex={parentIndex}
                                    isLeaf={el.isLeaf}
                                    open={open[el.parentId] || !el.isLeaf}
                                />
                            );
                        })}
                </div>
                <div className="green-plus">+</div>
            </div>
        </div>
    )
}

export default PrimaryCriteriaField