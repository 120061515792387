import React, { Component } from 'react'
import ImageView from "./ImageView";
import Inputs from './Inputs';
import './index.css';

class Profile extends Component {

    render() {
        window.scrollTo(0, 0);
        return (
            <div className='profile'>
                <h1 className='profile__title'>My Profile</h1>
                <div className ='profile__info'>
                    <ImageView />
                    <Inputs />
                </div>
            </div>
        )
    }
}
export default Profile;
