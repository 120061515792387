import React, { PureComponent } from "react";
import Popup from "reactjs-popup";
import crossicon from "../../images/icon-close-w.svg";
import NotificationList from "./NotificationList";
import "./index.css";
import NotificationSample from "./NotificationSample";
import checkPermission from '../../utils/checkPermission'
import InmunityApi from "../../api/InmunityApi";
import { connect } from "react-redux";

const AdminEdit = ({ editNotification }) => {
  return (
    checkPermission() &&
    <img
      src={require("../../images/edit.png")}
      onClick={editNotification}
      alt="Edit Icon"
    />
  )
}

const objects = [
  {
    notificationName: "Physician has been sent initial email",
    desc:
      "An email has been sent to the physician inquiring about patient’s interest in clinical trials",
    notificationStatus: false,
    emailStatus: false
  },
  {
    notificationName: "Physician has opened email",
    desc:
      "Physician has opened the email inquiring about patient’s interest in clinical trials",
    notificationStatus: false,
    emailStatus: false
  },
  {
    notificationName: "Physician has responded",
    desc:
      "Physician has indicated whether or not the patient is interested in learning about clinical trials",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Reminder to send physician follow-up email",
    desc:
      "A reminder to resend an email to the physician inquiring about patient’s interest in clinical trials",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Reason for decline from physician",
    desc:
      "The physician indicates why the patient is not interested in hearing more about clinical trials at this time",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Time-out notification for physician email link",
    desc:
      "Notification that the link has expired for the physician to indicate whether or not the patient is interested in hearing more about clinical trials",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Authorization consent form sent to patient",
    desc:
      "Patient has been sent authorization consent form to indicate whether they are interested in hearing more about clinical trials",
    notificationStatus: false,
    emailStatus: false
  },
  {
    notificationName: "Authorization consent form opened by patient",
    desc: "Patient has opened the email with authorization consent form",
    notificationStatus: false,
    emailStatus: false
  },
  {
    notificationName: "Authorization consent form signed by patient",
    desc:
      "Patient has signed the authorization consent form indicating they are interested in hearing about clinical trials they may be eligible for",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Reason for decline from patient",
    desc:
      "The patient indicates why they are not interested in learning more about clinical trials at this time",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName:
      "Reminder to resend authorization consent form to patient",
    desc:
      "A reminder to resend email with authorization consent form to the patient inquiring about their interest in clinical trials",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Reminder to contact patient",
    desc:
      "Reminder to follow-up with a patient about interest in specific trials",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "New matches alert",
    desc: "Daily notification of new matches",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Candidates with physician appointments",
    desc:
      "Notification with candidates who have a physician scheduled visit each day",
    notificationStatus: true,
    emailStatus: false
  },
  {
    notificationName: "Coordinator assigned a task",
    desc: "Notification that a user has been assigned a task by another user",
    notificationStatus: true,
    emailStatus: false
  }
];

class Notification extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      condition: "",
      objects: [],
      editSuccess: "",
      showEditNotifications: false
    };
  }

  closeModal = () => {
    let open = false;
    this.setState({ open });
  };

  editNotification = condition => {
    let open = true;
    this.setState({ open, condition });
  };

  saveNotification = array => {
    this.setState({ objects: array });
  };

  saveNotes = () => {
    let { condition, objects } = this.state;
    let { id } = this.props.currentUser;
    let data = {};
    objects.forEach(el => {
      switch (el.notificationName) {
        case "Physician has been sent initial email":
          condition === "not"
            ? (data.sent_email = el.notificationStatus)
            : (data.sent_email = el.emailStatus);
          break;
        case "Physician has opened email":
          condition === "not"
            ? (data.opened_email = el.notificationStatus)
            : (data.opened_email = el.emailStatus);
          break;
        case "Physician has responded":
          condition === "not"
            ? (data.responded = el.notificationStatus)
            : (data.responded = el.emailStatus);
          break;
        case "Reminder to send physician follow-up email":
          condition === "not"
            ? (data.follow_email = el.notificationStatus)
            : (data.follow_email = el.emailStatus);
          break;
        case "Reason for decline from physician":
          condition === "not"
            ? (data.decline_phys = el.notificationStatus)
            : (data.decline_phys = el.emailStatus);
          break;
        case "Time-out notification for physician email link":
          condition === "not"
            ? (data.email_link = el.notificationStatus)
            : (data.email_link = el.emailStatus);
          break;
        case "Authorization consent form sent to patient":
          condition === "not"
            ? (data.auth_consent = el.notificationStatus)
            : (data.auth_consent = el.emailStatus);
          break;
        case "Authorization consent form opened by patient":
          condition === "not"
            ? (data.auth_consent_open = el.notificationStatus)
            : (data.auth_consent_open = el.emailStatus);
          break;
        case "Authorization consent form signed by patient":
          condition === "not"
            ? (data.auth_consent_signed = el.notificationStatus)
            : (data.auth_consent_signed = el.emailStatus);
          break;
        case "Reason for decline from patient":
          condition === "not"
            ? (data.decline_patient = el.notificationStatus)
            : (data.decline_patient = el.emailStatus);
          break;
        case "Reminder to resend authorization consent form to patient":
          condition === "not"
            ? (data.resend_consent = el.notificationStatus)
            : (data.resend_consent = el.emailStatus);
          break;
        case "Reminder to contact patient":
          condition === "not"
            ? (data.contact_patient = el.notificationStatus)
            : (data.contact_patient = el.emailStatus);
          break;
        case "New matches alert":
          condition === "not"
            ? (data.new_matches = el.notificationStatus)
            : (data.new_matches = el.emailStatus);
          break;
        case "Candidates with physician appointments":
          condition === "not"
            ? (data.candidates_app = el.notificationStatus)
            : (data.candidates_app = el.emailStatus);
          break;
        case "Coordinator assigned a task":
          condition === "not"
            ? (data.task_coordinator = el.notificationStatus)
            : (data.task_coordinator = el.emailStatus);
          break;
        default:
          return null;
      }
    });
    if (condition === "not") {
      data.settings_type = "notification";
    } else {
      data.settings_type = "email";
    }
    data.user = id;
    InmunityApi.editUserSettings(id, data)
      .then(res => {
        if (res.status !== 200) {
          this.setState({ editSuccess: "An error occured while editing!" });
          throw res;
        }
        this.setState({ open: false, showEditNotifications: false });
      })
      .catch(error => console.log("Error occured by saving", error));
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    InmunityApi.getUserSettings(this.props.currentUser.id)
      .then(res => {
        if (res && Array.isArray(res)) {
          res.forEach(type => {
            if (type.settings_type === "notification") {
              objects.forEach(el => {
                switch (el.notificationName) {
                  case "Physician has been sent initial email":
                    el.notificationStatus = type.sent_email;
                    break;
                  case "Physician has opened email":
                    el.notificationStatus = type.opened_email;
                    break;
                  case "Physician has responded":
                    el.notificationStatus = type.responded;
                    break;
                  case "Reminder to send physician follow-up email":
                    el.notificationStatus = type.follow_email;
                    break;
                  case "Reason for decline from physician":
                    el.notificationStatus = type.decline_phys;
                    break;
                  case "Time-out notification for physician email link":
                    el.notificationStatus = type.email_link;
                    break;
                  case "Authorization consent form sent to patient":
                    el.notificationStatus = type.auth_consent;
                    break;
                  case "Authorization consent form opened by patient":
                    el.notificationStatus = type.auth_consent_open;
                    break;
                  case "Authorization consent form signed by patient":
                    el.notificationStatus = type.auth_consent_signed;
                    break;
                  case "Reason for decline from patient":
                    el.notificationStatus = type.decline_patient;
                    break;
                  case "Reminder to resend authorization consent form to patient":
                    el.notificationStatus = type.resend_consent;
                    break;
                  case "Reminder to contact patient":
                    el.notificationStatus = type.contact_patient;
                    break;
                  case "New matches alert":
                    el.notificationStatus = type.new_matches;
                    break;
                  case "Candidates with physician appointments":
                    el.notificationStatus = type.candidates_app;
                    break;
                  case "Coordinator assigned a task":
                    el.notificationStatus = type.task_coordinator;
                    break;
                  default:
                    return null;
                }
              });
            } else {
              objects.forEach(el => {
                switch (el.notificationName) {
                  case "Physician has been sent initial email":
                    el.emailStatus = type.sent_email;
                    break;
                  case "Physician has opened email":
                    el.emailStatus = type.opened_email;
                    break;
                  case "Physician has responded":
                    el.emailStatus = type.responded;
                    break;
                  case "Reminder to send physician follow-up email":
                    el.emailStatus = type.follow_email;
                    break;
                  case "Reason for decline from physician":
                    el.emailStatus = type.decline_phys;
                    break;
                  case "Time-out notification for physician email link":
                    el.emailStatus = type.email_link;
                    break;
                  case "Authorization consent form sent to patient":
                    el.emailStatus = type.auth_consent;
                    break;
                  case "Authorization consent form opened by patient":
                    el.emailStatus = type.auth_consent_open;
                    break;
                  case "Authorization consent form signed by patient":
                    el.emailStatus = type.auth_consent_signed;
                    break;
                  case "Reason for decline from patient":
                    el.emailStatus = type.decline_patient;
                    break;
                  case "Reminder to resend authorization consent form to patient":
                    el.emailStatus = type.resend_consent;
                    break;
                  case "Reminder to contact patient":
                    el.emailStatus = type.contact_patient;
                    break;
                  case "New matches alert":
                    el.emailStatus = type.new_matches;
                    break;
                  case "Candidates with physician appointments":
                    el.emailStatus = type.candidates_app;
                    break;
                  case "Coordinator assigned a task":
                    el.emailStatus = type.task_coordinator;
                    break;
                  default:
                    return null;
                }
              });
            }
          });
          this.setState({ objects });
        }
      })
      .catch(error => {
        throw new Error(error);
      });
  }

  handleCloseStatus = () => {
    this.setState({
      showEditNotifications: false,
      editSuccess: ""
    });
  };

  openConfirm = () => {
    this.setState({
      showEditNotifications: true
    });
  };

  render() {
    return (
      <div className="profile forImportPopup notificationSettings">
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          className="popup-newtrial role-popup notification-popup"
        >
          <div>
            <div className="closePendingPopupCross" onClick={this.closeModal}>
              <img src={crossicon} alt="Cross" />
            </div>
            <div className="ntw__ti-inputs">
              <h1 style={{ marginBottom: "30px" }}>
                {this.state.condition === "not"
                  ? "Edit Notifications"
                  : "Edit Email Notifications"}
              </h1>
              <NotificationList
                type={this.state.condition}
                saveNotification={this.saveNotification}
                array={this.state.objects}
              />
              <div className="role__container">
                <button
                  className="search-container__plusbtn"
                  onClick={this.openConfirm}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Popup>
        <h1>Notification Settings</h1>
        <div className="notificationBlock">
          <div className="notificationNavBar">
            <p className="notificationCol">Notification Type</p>
            <p className="smsemailCol">
              <span className="role">Notification</span>
              <AdminEdit editNotification={() => this.editNotification("not")} />
            </p>
            <p className="smsemailCol">
              <span className="role">E-mail</span>
              <AdminEdit editNotification={() => this.editNotification("email")} />
            </p>
          </div>
          {this.state.objects.length !== 0 ? (
            this.state.objects.map((el, i) => {
              return (
                <div className="notificationRow" key={i}>
                  <NotificationSample
                    name={el.notificationName}
                    desc={el.desc}
                    sms={el.notificationStatus}
                    email={el.emailStatus}
                  />
                </div>
              );
            })
          ) : (
              <div id="loading-candidates" className="loadingCandidates">
                <img
                  className="big-spinner"
                  src={require("../../images/spinner.gif")}
                  alt="Big Spinner"
                />
              </div>
            )}
        </div>
        <Popup
          open={this.state.showEditNotifications}
          closeOnDocumentClick
          onClose={this.handleCloseStatus}
          className="popup-newtrial wider"
        >
          <div>
            <div
              className="closePendingPopupCross"
              onClick={this.handleCloseStatus}
            >
              <img src={crossicon} alt="Cross" />
            </div>
            <div className="newTrialPopupWrapper">
              {this.state.editSuccess === "" ? (
                <div className="import-container__header">
                  <h1>
                    Notifications will update according to selection. Please
                    confirm the change of notification settings.
                  </h1>
                  <div className="forNTWBtns">
                    <div
                      className="forNTWBtn__easy-btn"
                      onClick={this.saveNotes}
                    >
                      Confirm
                    </div>
                    <div
                      className="forNTWBtn__easy-btn"
                      onClick={this.handleCloseStatus}
                    >
                      Decline
                    </div>
                  </div>
                </div>
              ) : (
                  <div className="import-container__header">
                    {this.state.editSuccess.length ? (
                      <h1>{this.state.editSuccess}</h1>
                    ) : (
                        <h1>
                          <img
                            className="small-spinner"
                            src={require("../../images/spinner.gif")}
                            alt=""
                          />
                        </h1>
                      )}
                  </div>
                )}
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser
  };
}

export default connect(mapStateToProps)(Notification);
