const formatRole = role => {
  let result;
  if (role === "Create new trials") {
    result = "create_trials";
  } else if (role === "View existing trials") {
    result = "view_trials";
  } else if (role === "Edit existing trials") {
    result = "edit_trials";
  } else if (role === "Delete existing trials") {
    result = "delete_trials";
  } else if (role === "Add/delete users") {
    result = "add_user";
  } else if (role === "Add institutions") {
    result = "add_includion";
  } else if (role === "Analytics on coordinator behavior") {
    result = "analytics_coordinator";
  } else if (role === "Analytics on physician/patient behavior") {
    result = "analytics_patient";
  } else if (role === "Analytics on trials and sites") {
    result = "analytics_trials";
  } else if (role === "Give a coordinator or physican access to a trial") {
    result = "give_access_trial";
  } else if (role === "Import a trial if your have been given access") {
    result = "import_trial";
  } else if (role === "View identified data - preconsent (own patient)") {
    result = "view_data_own";
  } else if (role === "View identified data - postconsent") {
    result = "view_data";
  } else if (role === "View de-identified data") {
    result = "view_deind_data";
  } else if (role === "Schedule oriented view of patients") {
    result = "schedule_view";
  }

  return result;
};

export default formatRole;
