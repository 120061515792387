import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { SpinnerSmall, Spinner, ArrowUp } from '../index'

export class CustomInfinityScroll extends Component {
  state = {
    anchor: false
  }

  handleScroll = e => {
    e > 500 ? this.setState({ anchor: true }) : this.setState({ anchor: false })
  }

  moveToTop = e => {
    document.getElementById('scroolTop__list').scrollTop = 0
  }

  render () {
    let {
      data,
      searching,
      loading,
      textNoResult,
      textNoResultSearch,
      isMore,
      children,
      loadMoreData,
      loadingNext
    } = this.props
    let { anchor } = this.state
    return (
      <React.Fragment>
        <div
          id='scroolTop__list'
          className='candidatesWrapper'
          onScroll={this.handleScroll}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={!loadingNext ? loadMoreData : () => {}}
            hasMore={isMore}
            useWindow={false}
            loader={!loading ? <SpinnerSmall key={0} /> : <div key={0} />}
          >
            {children}
            {anchor && <ArrowUp click={this.moveToTop} />}
            {data.length === 0 && searching && !loading && (
              <div className='noResFound'>{textNoResultSearch}</div>
            )}
            {loading && <Spinner />}
            {data.length === 0 && !searching && !loading && (
              <div id='loading-candidates' className='loadingCandidates'>
                <div className='noResFound'>{textNoResult}</div>
              </div>
            )}
          </InfiniteScroll>
        </div>
      </React.Fragment>
    )
  }
}
