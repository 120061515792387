import React, { Fragment, useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import {
  getAllSubstances,
  getAllSubstancesSearch
} from "./../../../../../actions/trialActions";
import { connect } from "react-redux";

const SubstanceUse = React.memo(
  ({
    substances,
    substancesSearch,
    getAllSubstances,
    getAllSubstancesSearch,
    setValue,
    setInclude,
    include,
    option,
    fromSearch,
    sendLabs
  }) => {
    const [drugOptions, setDrugOptions] = useState([]);
    const inExOptions = [
      { value: "Include", label: "Include" },
      { value: "Exclude", label: "Exclude" }
    ];

    const customStyles = {
      control: base => ({
        ...base,
        maxHeight: "43px",
        "&:hover": { borderColor: "lightgray" },
        border: "1px solid lightgray",
        boxShadow: "none"
      })
    };
    const sendOnBlur = () => {
      if (fromSearch) return;
      sendLabs("name", option.map(el => el.value));
    };
    useEffect(() => {
      if (fromSearch) {
        if (substancesSearch.length === 0) {
          getAllSubstancesSearch();
        }
      } else {
        if (substances.length === 0) {
          getAllSubstances();
        }
      }
    }, [
      substances,
      substancesSearch,
      getAllSubstances,
      getAllSubstancesSearch,
      fromSearch
    ]);
    useEffect(() => {
      const optionsMaker = substances => {
      
        const result = substances.map(el => ({
          value: el.name,
          label: el.name
        }));
        setDrugOptions(result);
      };
      if (fromSearch) {
        if (substancesSearch.length > 0) {
          optionsMaker(substancesSearch);
        }
      } else {
        if (substances.length > 0) {
          optionsMaker(substances);
        }
      }
    }, [substances, substancesSearch, fromSearch]);

    return (
      <Fragment>
        <div className="ntw__ti-input ntw__ti-ifreetext forGeoInput">
          <span className="ntw__ti-inputlabel">Substances</span>
          <Select
            value={option}
            name="substances"
            styles={customStyles}
            onChange={setValue}
            onBlur={sendOnBlur}
            options={drugOptions}
            className="sort_select withInput"
            isMulti={true}
            closeMenuOnSelect={false}
          />
        </div>
        <div className="ntw__ti-input ntw__ti-ifreetext forGeoInput">
          <span className="ntw__ti-inputlabel">Include/Exclude</span>
          <Select
            value={include}
            name="include"
            onChange={setInclude}
            options={inExOptions}
            className="sort_select withInput smoke_sel"
          />
        </div>
      </Fragment>
    );
  }
);

const mapStateToProps = state => ({
  substances: state.substances,
  substancesSearch: state.substancesSearch
});

const mapDispatchToProps = dispatch => ({
  getAllSubstances: async () => {
    dispatch(await getAllSubstances());
  },
  getAllSubstancesSearch: async () => {
    dispatch(await getAllSubstancesSearch());
  }
});

SubstanceUse.propTypes = {
  substances: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubstanceUse);
