import React from 'react'
import Popup from 'reactjs-popup'
import crossicon from '../../../images/icon-close-w.svg'

const NewTrialPopup = ({ status = true, onClose, importTrial, relocate }) => {
  return (
    <Popup
      open={status}
      closeOnDocumentClick
      onClose={onClose}
      className='popup-newtrial'
    >
      <div>
        <div className='closePendingPopupCross' onClick={onClose}>
          <img src={crossicon} alt='Cross' />
        </div>
        <div className='newTrialPopupWrapper'>
          <div className='import-container__header'>
            <h1>Create a new trial</h1>
            <p>
              Please select one of the following options to set up a new trial:
            </p>
            <div className='forNTWBtns'>
              <div className='fromScratch' onClick={importTrial}>
                Import Trial
              </div>
              <div className='fromScratch' onClick={relocate}>
                New Trial Set-Up
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}
export default NewTrialPopup
