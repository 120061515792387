import React, { Component } from "react";
import { connect } from "react-redux";
import AllMatches from "./AllMatches";
import TrialsNav from "../TrialsNav";
import inmunityApi from "../../api/InmunityApi";
import {
  setCurrentNavigationLink,
  setCurrentNavigationSubLink,
  getTrialDescription
} from "../../actions/trialActions";
import { URLGenerator } from "../../utils/trailsNavigation";
import { Sort, CustomInfinityScroll } from "../../ui";
import "../styles/AllTrial.css";
import "../allTrials/index.css";

const options = [
  { value: "age", label: "Age" },
  // { value: "encounter", label: "Last Encounter" }, ghost#hidden
  { value: "rate", label: "Match Rate" },
  { value: "gender", label: "Gender" },
  { value: "immunity", label: "Immunity ID" },
  { value: "name", label: "Name" }
];

class Matches extends Component {
  state = {
    matches: [],
    initialMatches: [],
    selectedOption: options[0],
    loading: false,
    searching: false,
    isMore: null,
    link: null,
    paths: [],
    isReverseSort: true,
    input: "",
    searchLengthResult: null,
    currentTrial: "",
    title: "",
    loadingNext: false,
    matchesInProgress: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.mounted = true;
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink,
      getTrialDescription
    } = this.props;
    let currentTrial = location.pathname.split("/")[2];
    getTrialDescription(currentTrial);
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial);
    this.setState(
      {
        loading: true,
        paths: URLGenerator({
          id: currentTrial,
          count: { name: "matches", count: "" }
        }),
        currentTrial: currentTrial
      },
      () => this.getDefaultTrial()
    );
  }

  getDefaultTrial = () => {
    let { currentTrial } = this.state;
    inmunityApi
      .getAllTrialMatches(currentTrial)
      .then(response => {
        if (response.status === 202) {
          setTimeout(this.getDefaultTrial, 4000);
          this.setState({
            matchesInProgress: true,
            loading: false,
            paths: URLGenerator({
              id: currentTrial,
              count: { name: "matches", count: "", spinner: true }
            })
          });
          return Promise.reject("Search in progress...");
        } else if (response.status === 500) {
          return Promise.reject("Connection lost");
        } else {
          return response.json();
        }
      })
      .then(matches => {
        if (this.mounted) {
          matches.results.sort((a, b) => b.age - a.age);
          this.setState({
            matchesInProgress: false,
            matches: matches.results,
            initialMatches: matches.results,
            loading: false,
            isMore: !!matches.next,
            link: matches.next,
            paths: URLGenerator({
              id: currentTrial,
              count: { name: "matches", count: matches.count }
            })
          });
        }
      })
      .catch(e => console.log("error: ", e));
  };

  sortMatchesApi = () => {
    this.setState({ loading: true });
    let flag = this.state.selectedOption.value;
    let sign = this.state.isReverseSort ? "-" : "";
    let query = this.state.input !== "" ? this.state.input : null;
    inmunityApi
      .getMatchesSorted(this.state.currentTrial, sign, query, flag)
      .then(response => {
        this.setState({
          matches: response.results,
          loading: false,
          isMore: !!response.next,
          link: response.next,
          searchLengthResult: response.count
        });
      });
  };

  reverseSort = () => {
    this.setState({ isReverseSort: !this.state.isReverseSort }, () => {
      this.sortMatchesApi();
    });
  };

  handleChange = selectedOption => {
    this.setState(
      {
        selectedOption,
        matches: [],
        isMore: false
      },
      () => {
        this.sortMatchesApi();
      }
    );
  };

  loadMoreData = () => {
    this.setState({ loadingNext: true }, () => {
      if (this.state.isMore) {
        inmunityApi
          .getPatientSearchPagination(this.state.link)
          .then(response => {
            let link = response.next;
            let isMore = !!link;
            let oldMatches = [...this.state.matches];
            let newMatches = oldMatches.concat(response.results);
            this.setState({
              matches: newMatches,
              initialMatches: newMatches,
              link,
              isMore,
              loadingNext: false
            });
          });
      }
    });
  };

  handleChangeInput = e => {
    if (e.target.value.length === 0) {
      this.setState(
        {
          input: e.target.value,
          matches: [],
          searchLengthResult: null,
          searching: false
        },
        () => {
          this.sortMatchesApi();
        }
      );
    } else {
      this.setState({
        input: e.target.value
      });
    }
  };

  searchMatches = () => {
    this.setState(
      {
        matches: [],
        isMore: false,
        searching: true
      },
      () => {
        this.sortMatchesApi();
      }
    );
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    let {
      selectedOption,
      matches,
      loading,
      searching,
      isReverseSort,
      searchLengthResult,
      paths,
      isMore,
      loadingNext,
      matchesInProgress
    } = this.state;
    let { name } = this.props.trial;
    return (
      <div className="content-body-matches" id="list">
        <div className="study-name">{name}</div>
        <TrialsNav paths={paths} />
        <Sort
          options={options}
          selectedOption={selectedOption}
          isRevert={isReverseSort}
          result={searchLengthResult}
          revert={this.reverseSort}
          select={this.handleChange}
          change={this.handleChangeInput}
          press={this.searchMatches}
          searching={searching}
        />
        <CustomInfinityScroll
          data={matches}
          searching={searching}
          loading={loading}
          isMore={isMore}
          textNoResult={
            matchesInProgress
              ? "Search in progress. Please check back later."
              : "No matches yet for this trial."
          }
          textNoResultSearch="No results found"
          loadMoreData={this.loadMoreData}
          loadingNext={loadingNext}
        >
          {matches.length > 0 &&
            matches.map((m, i) => (
              <AllMatches
                key={"trial-candidate-slice-" + m["hadmId"] + i}
                id={"trial-candidate-slice-" + m["hadmId"]}
                idd={"trial-match-slice-" + m["hadmId"]}
                idE={`trial-match-enroll-${m["hadmId"]}`}
                mat={m}
              />
            ))}
        </CustomInfinityScroll>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state.trial
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getTrialDescription: id => dispatch(getTrialDescription(id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Matches);
