import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Header from './header'
import { withRouter } from 'react-router'
import Profile from './Profile'
import Users from './Users'
import Notification from './Notification'
import NewUser from './NewUser'
import Permission from './Permission'
import Nav from './navigation'
import NewUserInvite from './NewUser/NewUserInvite'
import './index.css'
import { connect } from 'react-redux'

class Settings extends Component {
  newRoleName = (roleName, permissionObject) => {
    // this.setState({ roleName, permissionObject })
  }

  render () {
    return (
      <div className='settings'>
        <Header newRoleNameEmitter={this.newRoleName} />
        <div className='settings__container'>
          <Nav />
          <Switch>
            <Route
              exact
              path='/settings/notification'
              component={Notification}
            />
            <Route exact path='/settings/profile' component={Profile} />
            <Route exact path='/settings/permission' component={Permission} />
            <Route exact path='/settings/users' component={Users} />
            <Route exact path='/settings/users/newuser' component={NewUser} />
            <Route exact path='/settings/users/edituser' component={NewUser} />
            <Route
              exact
              path='/settings/users/newuserinvite'
              component={NewUserInvite}
            />
          </Switch>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    currentUser: state.currentUser
  }
}

export default withRouter(connect(mapStateToProps)(Settings))
