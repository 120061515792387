import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function setPatientsResult(state = initialState.patientsResult, action) {
  switch(action.type) {
    case types.PATIENTS_RESULT:
      return action.patientsResult
    default:
      return state;
  }
}
