import React from 'react'
import { connect } from 'react-redux'
import {
  setCurrentTrial,
  getTrialDescription,
  setCurrentNavigationSubLink
} from '../../../actions/trialActions'
import { push } from 'react-router-redux'
import Trial from './Trial'

class Trials extends React.Component {
  state = {
    trials: []
  }

  setInitTrial = () => {
    let id = this.props.trials[0]['studyID']
    this.props.setCurrentTrial(id)
    this.props.getTrialDescription(id)
  }

  componentDidUpdate (prevProps) {
    let trials = this.props.trials
    if (prevProps.current_trial !== this.props.current_trial) {
      for (let index in trials) {
        if (trials[index].studyID === this.props.current_trial) {
          setTimeout(() => {
            document.getElementById('trials').scrollTop = index * 50 - 50
          }, 200)
        }
      }
    }
  }

  render () {
    return this.props.trials.map((obj, i) => {
      return (
        <div key={i} className='nav-trial-list'>
          <Trial
            key={'trial_bar_key_' + obj['studyID']}
            trial_id={obj['studyID']}
            href={'/trials/' + obj['studyID'] + '/candidates'}
            title={obj['studyName']}
          />
        </div>
      )
    })
  }
}

function mapStateToProps (state) {
  return {
    trials: state.trials,
    current_trial: state.currentTrial
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getTrialDescription: id => dispatch(getTrialDescription(id)),
    setCurrentTrial: id => dispatch(setCurrentTrial(id)),
    relocate: path => dispatch(push(path)),
    setCurrentNavigationSubLink: id =>
      dispatch(setCurrentNavigationSubLink(id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trials)
