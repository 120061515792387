import * as types from '../actions/actionTypes'
import initialState from './initialState'

export default function popupReducer (state = initialState.popup, action) {
  switch (action.type) {
    case types.SET_POPUP:
      return {
        ...state,
        text: action.popup.text,
        show: action.popup.show
      }
    default:
      return state
  }
}
