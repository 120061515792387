import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TrialsNav from "../TrialsNav";
import RecruitmentGoal from "./RecruitmentGoal";
import SecondRow from "./SecondRow";
import InmunityApi from "../../api/InmunityApi";
import Behavior from "./Behavior";
import LineChart from "./LineChart";
import { URLGenerator } from "../../utils/trailsNavigation";
import {
  setCurrentNavigationLink,
  setCurrentNavigationSubLink,
  getTrialDescription
} from "../../actions/trialActions";
import { Spinner } from "./../../ui";
import "./index.css";

class AnalyticsTrial extends Component {
  state = {
    trials: "",
    recruitment_goal: null,
    exclusion_reasons: null,
    workflow_stages: null,
    criteria_exclusion: null,
    physician_behavior: null,
    patient_behavior: null,
    finish: false,
    paths: [],
    id: ""
  };

  componentDidMount() {
    InmunityApi.getAllStudies().then(res => {
      let trials = [];
      for (let index in res) {
        trials[res[index].studyID] = res[index].name;
      }
      this.setState({ trials });
    });
    window.scrollTo(0, 0);
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink,
      getTrialDescription
    } = this.props;
    let currentTrial = location.pathname.split("/")[2];
    getTrialDescription(currentTrial);
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial || "all");
    currentTrial = currentTrial || "";
    this.setState(
      {
        loading: true,
        paths: URLGenerator({ id: currentTrial, count: {} }),
        id: currentTrial
      },
      () => {
        Promise.all([
          InmunityApi.getChartsGoal(currentTrial),
          InmunityApi.getChartsExclusion(currentTrial),
          InmunityApi.getPhysicianBehavior(currentTrial),
          InmunityApi.getPatientBehavior(currentTrial)
        ]).then(res => {
          this.setState({
            recruitment_goal: res[0].recruintemt_goal,
            workflow_stages: res[0].workflow_stages,
            exclusion_reasons: res[1].exclusion_reasons,
            criteria_exclusion: res[1].criteria_exclusion,
            physician_behavior: res[2],
            patient_behavior: res[3],
            finish: true
          });
        });
      }
    );
  }

  render() {
    const {
      paths,
      finish,
      recruitment_goal,
      workflow_stages,
      exclusion_reasons,
      criteria_exclusion,
      physician_behavior,
      patient_behavior
    } = this.state;

    return (
      <div className="content-body-matches analyticsPage" id="list">
        <div className="study-name">{this.props.trial.name}</div>
        <TrialsNav paths={paths} />
        {finish && (
          <div>
            <RecruitmentGoal recruitment_goal={recruitment_goal} />
            <SecondRow
              workflow_stages={workflow_stages}
              exclusion_reasons={exclusion_reasons}
              criteria_exclusion={criteria_exclusion}
            />
            <Behavior
              title="Physician Behavior"
              second_stat_name="Lowest Response Time"
              third_stat_name="Highest Response Time"
              first_response_name="Patient Interested"
              second_response_name="Patient not interested"
              response_cart_name="Reasons patient not approached"
              data={physician_behavior}
            />
            <Behavior
              title="Patient Behavior"
              second_stat_name="Shortest Response Time"
              third_stat_name="Longest Response Time"
              first_response_name="Patient Proceeds"
              second_response_name="Patient declines"
              response_cart_name="Reasons for declining"
              data={patient_behavior}
            />
            <LineChart />
          </div>
        )}
        {!finish && (
          <div style={{ marginTop: 50 }}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trials: state.trials,
    trial: state.trial,
    currentNavigation: state.currenNavigation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getTrialDescription: id => dispatch(getTrialDescription(id))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AnalyticsTrial)
);
