import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Popup from 'reactjs-popup'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import PhysiciansTooltip from '../../trials/NewTrial/PhysiciansTooltip'
import InmunityApi from '../../../api/InmunityApi'
import { setOtherReminders } from '../../../actions/trialActions'

import i18n from '../../../i18n'

import crossicon from '../../../images/icon-close-w.svg'

import './index.css'

const WeekEvents = props => {
  const [popup, togglePopup] = useState(false)
  const [responsibleOptions, changeResponsibleOptions] = useState([])

  const [reminderDate, handleChangeCalendar] = useState(moment())
  const [responsibleTooltip, changeResponsibleTooltip] = useState([])
  const [description, changeDescription] = useState(undefined)
  const [reminderId, changeReminderId] = useState(null)

  if (props.responsibles !== responsibleOptions) {
    changeResponsibleOptions(props.responsibles)
  }

  // ------ Responsible Tooltip -------- //
  const findResponsibleName = ({ target }) => {
    let responsibleNames = responsibleOptions
    if (
      target.value.length > 0 &&
      responsibleNames &&
      responsibleNames.length > 0
    ) {
      let query = target.value.toLowerCase()
      let result = responsibleNames
        .filter(el => {
          return el.name.toLowerCase().startsWith(query)
        })
        .slice(0, 10)
      if (result.length > 0) {
        target.classList.remove('redBorder')
        target.onkeypress = function (e) {}
        changeResponsibleTooltip(result)
      } else {
        result.length = 0
        changeResponsibleTooltip(result)
        target.classList.add('redBorder')
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault()
        }
      }
    } else {
      changeResponsibleTooltip([])
      target.classList.add('redBorder')
    }
  }

  const responsiblePicked = (obj, whereToFill) => {
    whereToFill.value = obj.name
    changeResponsibleTooltip(obj)
  }

  const goToInfo = ({ target }) => {
    let hadmId = props.event.hadmId
    let { history } = props
    history.push({
      pathname: `/matches/details/${hadmId}`,
      id: hadmId,
      detailTitle: 'Candidate Details'
    })
  }

  const editReminder = () => {
    let obj = {}
    obj.id = reminderId
    obj.date = moment(reminderDate).format()
    obj.responsible_id = responsibleTooltip.id
    obj.description = description
    obj.type = 2
    InmunityApi.patchReminder(props.event.id, obj).then(res => {
      getReminders()
    })
  }

  const getReminders = () => {
    let objS = {
      from: moment(
        JSON.parse(localStorage.getItem('dashboardWeeks')).startOfWeek
      ).format(),
      to: moment(
        JSON.parse(localStorage.getItem('dashboardWeeks')).endOfWeek
      ).format()
    }
    InmunityApi.getReminders(objS)
      .then(res => {
        if (res.status === 404) {
          props.dispatch(setOtherReminders([]))
          togglePopup(false)
          return false
        } else if (res.status === 200) {
          res.text().then(obj => {
            let reminders = JSON.parse(obj)
            let otherReminders = []
            reminders.forEach(el => {
              if (el.type === 'Other') {
                el.timeOfVisit = el.date
                el.endOfVisit = moment(el.date)
                  .add(30, 'minutes')
                  .format()
                el.studyTags = {
                  color: '#reminder'
                }
                el.immunity = el.description
                otherReminders.push(el)
              }
            })
            props.dispatch(setOtherReminders(otherReminders))
            togglePopup(false)
          })
        }
      })
      .catch(err => {})
  }

  const prepopReminder = () => {
    togglePopup(true)
    InmunityApi.getPrepopulationOfReminder(props.event.id).then(res => {
      changeResponsibleTooltip({
        name: res.responsible_name,
        id: res.responsible_id
      })
      changeReminderId(res.id)
      handleChangeCalendar(moment(res.date))
      changeDescription(res.description)
    })
  }

  const handleChangeDescription = ({ target }) => {
    changeDescription(target.value)
  }

  const deleteReminder = () => {
    InmunityApi.deleteReminder(props.event.id).then(res => {
      if (res.status === 204) {
        getReminders()
      }
    })
  }

  return (
    <div
      className='big-calendar-id forImportPopup'
      onClick={!props.event.id ? goToInfo : prepopReminder}
    >
      <span>{props.event.immunity}</span>
      <Popup
        open={popup}
        closeOnDocumentClick
        onClose={() => togglePopup(false)}
        className='reminderPopup'
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='contact-form createReminderPopup'>
            <div
              className='closePendingPopupCross'
              onClick={() => togglePopup(false)}
            >
              <img src={crossicon} alt='Pupup Cross' />
            </div>
            <h3 className='title-form'>Reminder</h3>
            <form
              className='new-reminder__form'
              action='#'
              onSubmit={e => {
                e.preventDefault()
              }}
            >
              <div className='input-container newTrialWrapp'>
                <label htmlFor='reminderType' className='label-modal'>
                  {i18n.t('calendarList.reminderType')}
                </label>
                <div className='reminderType'>Reminder type</div>
              </div>
              <div className='input-container'>
                <label htmlFor='reminderDate' className='label-modal'>
                  {i18n.t('calendarList.dateReminder')}
                </label>
                <DatePicker
                  name='reminderDate'
                  className='date-picker-calendar'
                  selected={reminderDate}
                  onChange={handleChangeCalendar}
                  showTimeSelect
                  timeFormat='HH:mm'
                  dateFormat='LLL'
                  timeCaption='time'
                />
              </div>

              <div className='input-container'>
                <label htmlFor='description' className='label-modal'>
                  {i18n.t('calendarList.description')}
                </label>
                <textarea
                  className='textarea-modal'
                  name='description'
                  placeholder={i18n.t('calendarList.addDescription')}
                  onChange={handleChangeDescription}
                  value={description}
                />
              </div>

              <div className='hr-container'>
                <hr className='form-separator' />
              </div>

              <div className='input-container newTrialWrapp ntw__ti-inputs forReminderToolTip'>
                <label htmlFor='responsible' className='label-modal'>
                  {i18n.t('calendarList.responsible')}
                </label>
                <input
                  type='text'
                  name='responsible'
                  className='get-freetext-name'
                  onChange={findResponsibleName}
                  defaultValue={responsibleTooltip.name}
                />
                {responsibleTooltip.length > 0 && (
                  <PhysiciansTooltip
                    physiciansNames={responsibleTooltip}
                    Mpicked={responsiblePicked}
                    isReminder
                  />
                )}
              </div>
            </form>
            <div className='button-container'>
              <div className='cancel-button' onClick={deleteReminder}>
                Delete
              </div>
              <div onClick={editReminder} className='mdForHeaderAndArrow__btn'>
                Edit reminder
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  )
}

function mapStateToProps (state, ownProps) {
  return {
    responsibles: state.responsibles,
    userToken: state.userToken.token
  }
}

export default withRouter(connect(mapStateToProps)(WeekEvents))
