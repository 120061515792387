import React, { Component } from 'react'
import { Circle } from 'rc-progress'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'
import PatientOverview from './../PatientOverview'
import inmunityApi from '../../api/InmunityApi'
import { InfoIcon } from '../../ui'
import './index.css'

class AllTrialsMatches extends Component {
  state = {
    showOverview: false,
    details: {}
  }

  goToDetails = ({ target }) => {
    if (
      !target.classList.contains('button-center') &&
      !target.closest('.pat-over-wrapp') &&
      (!target.classList.contains('button__icon') &&
        !target.classList.contains('popup-overlay'))
    ) {
      this.redirect()
    }
  }

  redirect = () => {
    let {
      history,
      mat: { hadmId }
    } = this.props
    history.push({
      pathname: `/matches/details/${hadmId}`,
      id: hadmId,
      detailTitle: 'Match Details'
    })
  }

  makeTrials = obj => {
    let result = ''
    obj.sort((a, b) => b.matchrate - a.matchrate)
    obj.forEach(trial => {
      let precntage = trial.matchrate
      result += ` ${trial.studyName} ${Math.ceil(precntage)}%<br>`
    })
    return result
  }

  showPatientOverview = () => {
    this.setState({ showOverview: true })
    inmunityApi
      .getPatientWorkflow(this.props.mat.hadmId, undefined)
      .then(details => {
        this.setState({
          details
        })
      })
  }

  closePatientOverview = () => {
    this.setState({ showOverview: false })
  }

  makeMatchTags = obj => {
    let result =
      obj.length > 0
        ? `Matched for ${obj.length} ${
          obj.length > 1 ? 'trials' : 'trial'
        }:<br>`
        : ''
    obj.sort((a, b) => b.matchrate - a.matchrate)
    obj.forEach(trial => {
      let precntage = trial.matchrate
      result += ` ${trial.studyName} ${Math.ceil(precntage)}%<br>`
    })
    return result
  }

  makeEnrollTags = obj => {
    let result =
      obj.length > 0
        ? `Enrolled in ${obj.length} ${
          obj.length > 1 ? 'trials' : 'trial'
        }:<br>`
        : ''
    obj.sort((a, b) => b.name - a.name)
    obj.forEach(trial => {
      result += ` ${trial.name} - ${trial.status}<br>`
    })
    return result
  }

  render () {
    const {
      gender,
      age,
      lastEncounter,
      matchrate,
      name,
      immunity,
      studyTags,
      matchTags: mTags,
      enrollTags: eTags
    } = this.props.mat
    var trials = this.makeTrials(studyTags)
    var matchTags = this.makeMatchTags(mTags)
    const enrollTags = this.makeEnrollTags(eTags)

    return (
      <div
        className='candidatesWr matchesWr forAMTooltip'
        onClick={this.goToDetails}
      >
        <div className='candidates hadmId'>
          <span className='imm-id'>Immunity ID</span>
          <span className='td-content'>{immunity}</span>
          {this.props.mat.studyTags.length !== 0 && (
            <span
              className='td-content candidatesAmTrial'
              data-tip={trials}
              data-class='match'
              data-multiline
              data-for={this.props.id}
            >
              {this.props.mat.studyTags.length > 1
                ? 'Candidate for ' + this.props.mat.studyTags.length + ' Trials'
                : ''}
              {this.props.mat.studyTags.length === 1 &&
                'Candidate for ' + this.props.mat.studyTags.length + ' Trial'}
            </span>
          )}
          <ReactTooltip
            id={this.props.id}
            place='bottom'
            type='light'
            effect='solid'
          />
        </div>

        <div className='candidates forname'>
          <div className='forgam-g'>
            <span className=''>Name</span>
            <span className='td-content'>{name || '–'}</span>
          </div>
        </div>

        <div className='candidates forgam'>
          <div className='forgam-g'>
            <span className=''>Gender</span>
            <span className='td-content'>
              {gender === 'M' ? 'Male' : 'Female'}
            </span>
          </div>
          <div className='forgam-a'>
            <span>Age</span>
            <span className='td-content'>{age}</span>
          </div>
        </div>
        <div className='candidates lastenc'>
          <div className='lastenc-forIcon'>
            <span>Last Encounter</span>
            <span className='td-content'>
              {lastEncounter
                .slice(0, 10)
                .split('-')
                .reverse()
                .join('/')}
            </span>
          </div>
          <div className='vertical-center'>
            <InfoIcon click={this.showPatientOverview} />
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            <div className='lastencForCircle all-matches-tags'>
              <Circle
                percent={matchrate}
                strokeWidth='5'
                trailWidth='0'
                strokeColor='#5BBEBF'
                className='lastenCircle'
              />
              <span className='circleNumber'>{Math.round(matchrate)}%</span>
              {this.props.mat.matchTags ? (
                <span
                  className='multiIndicator'
                  data-tip={matchTags}
                  data-class='match'
                  data-for={this.props.idd}
                  data-multiline
                >
                  +{this.props.mat.matchTags.length}
                </span>
              ) : (
                ''
              )}
              <ReactTooltip
                id={this.props.idd}
                place='bottom'
                type='light'
                effect='solid'
              />
              {eTags ? (
                <span
                  className='multiIndicator multiEnroll'
                  data-tip={enrollTags}
                  data-class='match'
                  data-for={this.props.idE}
                  data-multiline
                >
                  +{eTags.length}
                </span>
              ) : (
                ''
              )}
              <ReactTooltip
                id={this.props.idE}
                place='bottom'
                type='light'
                effect='solid'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    globalState: state,
    userToken: state.userToken
  }
}

export default withRouter(connect(mapStateToProps)(AllTrialsMatches))
