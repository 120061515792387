import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as IconInfo } from "./../../../../images/icon-info.svg";
import styled from 'styled-components'

import "../index.css";

const StyledInfoIconConteiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  justify-self: flex-end;
  position: relative;
`
const StyledInfoText = styled.div`
  width: 95%;
  color: #454545;
  font-family: Gotham;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 300;
`

const InfoText = ({ show }) => {
    if (!show) return <> </>;

    return (
        <div className="my-lab-tooltip">
            The ranks in the Top 2000+ laboratory results table were produced by LOINC and originally based on three large institutions' statistics.
      </div>
    )
}

export default function InfoBlock() {
    const [hover, setHover] = useState(false);

    const toggleHover = () => {
        setHover(!hover);
    }
    return (
        <StyledInfoIconConteiner>
            <IconInfo
                style={{ zIndex: 999, cursor: 'pointer' }}
                className="patient-info-icon"
                width={12}
                height={12}
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                fill={hover ? "#2FB3A9" : '#BCBDBE'}
            />
            <InfoText
                show={hover}
            />
        </StyledInfoIconConteiner>
    )
}
