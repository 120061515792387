import React, { Component } from "react";
import { connect } from "react-redux";
import {
  showPicker as showP,
  getMedicationsPicker,
  getDiseasesPicker,
  getRootProcedures,
  setMedicationsATC,
  setMedicationsATCList,
  setDiseasesDO,
  setDiseasesICD9CM,
  setProcedures,
  setDiseasesDOList,
  setDiseasesICD9CMList,
  setProceduresList,
  setMedicationsEPC,
  setMedicationsMOA,
  setMedicationsEPCList,
  setMedicationsMOAList,
  setPickerLabel
} from "../../../actions/trialActions";
import PickerSearch from "./PickerSearch";
import MedicationTabs from "./MedicationTabs";
import Spinner from "./Spinners/Spinner";
import CategorySpinner from "./Spinners/LeafSpinner";
import CategoriesList from "./CategoriesList";
import DiseaseList from "./DiseaseList";
import Footer from "./Footer";
import Popup from "reactjs-popup";
import ImmunityApi from "../../../api/InmunityApi";
import crossicon from "./../../../images/icon-close-w.svg";
import { PickerSendProvider } from "./PickerContext";
import "./index.css";

class TreeSelect extends Component {
  constructor() {
    super();
    this.state = {
      disPopupOpen: false,
      spinner: false,
      leafSpinner: false,
      activeTab: 0,
      categoryId: "",
      activeATC: null,
      activeMOA: null,
      activeEPC: null,
      forSending: [],
      itemsCount: 0,
      find: ""
    };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  componentWillUnmount() {
    const { find } = this.state;
    const { dispatch } = this.props;
    // debugger ;
    if (find !== "") {
      dispatch(setMedicationsATC([]));
      dispatch(setMedicationsATCList([]));
      dispatch(setMedicationsEPC([]));
      dispatch(setMedicationsEPCList([]));
      dispatch(setMedicationsMOA([]));
      dispatch(setMedicationsMOAList([]));
      dispatch(setDiseasesDO([]));
      dispatch(setDiseasesDOList([]));
      dispatch(setDiseasesICD9CM([]));
      dispatch(setDiseasesICD9CMList([]));
      dispatch(setProcedures([]));
      dispatch(setProceduresList([]));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (state.disPopupOpen !== props.showPicker.status) {
      return {
        disPopupOpen: props.showPicker.status
      };
    } else return null;
  }

  closeDiseasePopup = () => {
    this.setState({ disPopupOpen: false });
    this.props.dispatch(showP({ status: false, filter: "", showLab: false }));
  };

  fetchInitialData = async () => {
    const {
      dispatch,
      rootMedications,
      diseasesPickerRoot,
      rootProcedures,
      medicationsATC,
      proceduresPicker,
      diseasesDO,
      showPicker: { filter }
    } = this.props;
    this.setState({ spinner: true });
    if (filter === "Medication Filters") {
      !(rootMedications.length > 0) && (await dispatch(getMedicationsPicker()));
      const id1 = await this.props.rootMedications[0].id;
      if (medicationsATC.length === 0) {
        ImmunityApi.getMedicationsChildren(id1)
          .then(async medications => {
            dispatch(setPickerLabel(medications[0].label));
            await dispatch(setMedicationsATC(medications));
          })
          .catch(e => {
            console.log("Error getting children: ", e);
          });
      }
      dispatch(setMedicationsATCList([{ id: id1, leafs: [] }]));
      this.setState({ categoryId: id1 });
    } else if (filter === "Disease Filters") {
      !(diseasesPickerRoot.length > 0) && (await dispatch(getDiseasesPicker()));
      const id1 = await this.props.diseasesPickerRoot[0].id;
      const label = this.props.diseasesPickerRoot[0].label;
      if (diseasesDO.length === 0) {
        ImmunityApi.getDiseasesChildren(id1, label)
          .then(async diseases => {
            dispatch(setPickerLabel(diseases[0].label));
            await dispatch(setDiseasesDO(diseases));
          })
          .catch(e => {
            console.log("Error getting children: ", e);
          });
      }
      dispatch(setDiseasesDOList([{ id: id1, leafs: [] }]));
      this.setState({ categoryId: id1 });
    } else if (filter === "Procedure History") {
      if (proceduresPicker.length === 0) {
        if (rootProcedures.length > 0) {
          dispatch(setProcedures(rootProcedures));
        } else {
          await dispatch(getRootProcedures());
          dispatch(setPickerLabel(this.props.rootProcedures[0].label));
          dispatch(setProcedures(this.props.rootProcedures));
        }
      }
      const id = this.props.rootProcedures[0].parent_id;
      dispatch(setProceduresList([{ id, leafs: [] }]));
      this.setState({ categoryId: id });
    }
    this.setState({ spinner: false });
  };

  makeCheck = object => {
    const { id, remove } = object;
    const value = `category${id}`;
    remove
      ? this.setState(prevState => {
        delete prevState[value];
        return { ...prevState };
      })
      : this.setState({ [value]: object });
  };

  setSpinner = show => {
    this.setState({ leafSpinner: show });
  };

  setCategorySpinner = show => {
    this.setState({ categorySpinner: show });
  };

  setTab = activeTab => {
    this.setState({ activeTab });
  };

  setCategoryId = categoryId => {
    this.setState({ categoryId });
  };




  makeFindActive = (find, active) => {
    // this.scrollToElement(active);
    if (find === 0) {
      this.setState({ activeTab: find, find, activeATC: active });
    } else if (find === 1) {
      this.setState({ activeTab: find, find, activeEPC: active });
    } else if (find === 2) {
      this.setState({ activeTab: find, find, activeMOA: active });
    }
  };

  makeActive = (id, activeTab) => {
    switch (activeTab) {
      case 0:
        this.setState({ activeATC: id });
        break;
      case 1:
        this.setState({ activeEPC: id });
        break;
      case 2:
        this.setState({ activeMOA: id });
        break;
      default:
        console.log("activeTab???");
    }
  };

  setArrayOfCodes = array => {
    let send = [...this.state.forSending];
    const parentId = array.length > 0 ? array[0].parentId : null;
    send = send.filter(el => el.parentId !== parentId);
    let codes = [...send, ...array];
    this.setState({ forSending: codes }, () => {
      this.itemsCount();
    });
  };

  setCodes = object => {
    let codes = [...this.state.forSending];
    let index = codes.findIndex(el => el.id === object.id);
    if (index >= 0) {
      codes.splice(index, 1, object);
    } else {
      codes.push(object);
    }
    codes = codes.map(el => {
      if (el.criteria === "Disease") {
        return {
          ...el,
          code: el.code ? el.code : (el.code = {})
        };
      } else {
        return el;
      }
    });
    this.setState({ forSending: codes }, () => {
      this.itemsCount();
    });
  };

  itemsCount = () => {
    let { forSending } = this.state;
    let arr = forSending.filter(el => el.include !== "delete");
    this.setState({ itemsCount: arr.length });
  };

  render() {
    const {
      disPopupOpen,
      spinner,
      leafSpinner,
      activeTab,
      categoryId,
      categorySpinner,
      activeATC,
      activeEPC,
      activeMOA,
      itemsCount,
      forSending,
      find
    } = this.state;
    const {
      showPicker: { filter, freeText },
      rootMedications,
      diseasesPickerRoot,
      medicationsATC,
      medicationsEPC,
      medicationsMOA,
      proceduresPicker,
      diseasesDO,
      diseasesICD9CM,
      diseasesDOList,
      diseasesICD9CMList,
      proceduresList,
      medicationsATCList,
      medicationsEPCList,
      medicationsMOAList,
      sendForMedication,
      allData
    } = this.props;
    let items,
      leafs,
      active,
      sourceDis = "DO",
      listName;
    const roots =
      filter === "Medication Filters" ? rootMedications : diseasesPickerRoot;
    if (activeTab === 0) {
      if (filter === "Medication Filters") {
        items = medicationsATC;
        leafs = medicationsATCList;
        listName = "ATC";
      } else if (filter === "Disease Filters") {
        items = diseasesDO;
        leafs = diseasesDOList;
        if (roots.length > 0) {
          sourceDis = roots[0].className;
        }
        listName = "DO";
      } else if (filter === "Procedure History") {
        items = proceduresPicker;
        leafs = proceduresList;
      }
      active = activeATC;
    } else if (activeTab === 1) {
      if (filter === "Medication Filters") {
        items = medicationsEPC;
        leafs = medicationsEPCList;
        listName = "EPC";
      } else if (filter === "Disease Filters") {
        items = diseasesICD9CM;
        leafs = diseasesICD9CMList;
        if (roots.length > 0) {
          sourceDis = roots[1].className;
        }
        listName = "ICD9CM";
      }
      active = activeEPC;
    } else if (activeTab === 2) {
      items = medicationsMOA;
      leafs = medicationsMOAList;
      active = activeMOA;
      listName = "MOA";
    }
    let include = this.state[`category${active}`];
    return (
      <PickerSendProvider value={this.state.forSending}>
        <Popup
          open={disPopupOpen}
          closeOnDocumentClick
          onClose={this.closeDiseasePopup}
          className="popup-import"
        >
          <div>
            <div
              className="closePendingPopupCross"
              onClick={this.closeDiseasePopup}
            >
              <img src={crossicon} alt="Cross" />
            </div>
            <div className="import-container ppWrapper disPWrapp">
              <div className="import-container__header headerDisPopup">
                <h1>Please select one or more of the following options.</h1>
              </div>
              {filter !== "Disease Filters" && (
                <PickerSearch
                  makeFindActive={this.makeFindActive}
                  setSpinner={this.setSpinner}
                  setCategorySpinner={this.setCategorySpinner}
                  source={sourceDis}
                />
              )}
              <div className="searchBlockWrapper__selector">
                {!spinner &&
                  (filter === "Medication Filters" ||
                    filter === "Disease Filters") && (
                    <MedicationTabs
                      rootMedications={roots}
                      filter={filter}
                      setTab={this.setTab}
                      setSpinner={this.setSpinner}
                      setCategoryId={this.setCategoryId}
                      find={find}
                    />
                  )}
              </div>
              {spinner ? (
                <Spinner />
              ) : (
                  <div className="searchBlockWrapper">
                    {filter === "Disease Filters" && (
                      <PickerSearch
                        makeFindActive={this.makeFindActive}
                        setSpinner={this.setSpinner}
                        setCategorySpinner={this.setCategorySpinner}
                        source={sourceDis}
                        disease={true}
                      />
                    )}
                    <div className="dis-pop-container">
                      <div className="dis-cat-container">
                        {categorySpinner && <CategorySpinner />}
                        <CategoriesList
                          medications={items}
                          activeTab={activeTab}
                          setCategoryId={this.setCategoryId}
                          setSpinner={this.setSpinner}
                          setCategorySpinner={this.setCategorySpinner}
                          makeActive={this.makeActive}
                          active={active}
                          setCodes={this.setCodes}
                          filter={filter}
                          categoryId={categoryId}
                          makeCheck={this.makeCheck}
                          listName={listName}
                          makeFindActive={this.makeFindActive}
                          setArrayOfCodes={this.setArrayOfCodes}
                        />
                      </div>
                      <DiseaseList
                        spinner={leafSpinner}
                        setSpinner={this.setSpinner}
                        disNames={leafs}
                        categoryId={categoryId}
                        active={active}
                        activeTab={activeTab}
                        include={include}
                        setCodes={this.setCodes}
                        showInclusionExclusion={this.showInclusionExclusion}
                        filter={filter}
                        makeCheck={this.makeCheck}
                      />
                    </div>
                  </div>
                )}
              <Footer
                itemsSelected={itemsCount}
                closePopup={this.closeDiseasePopup}
                sendForMedication={sendForMedication}
                filter={filter}
                removeItem={this.setCodes}
                makeCheck={this.makeCheck}
                setCodes={this.setCodes}
                freeText={freeText}
                allData={allData}
              />
            </div>
          </div>
        </Popup>
      </PickerSendProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    showPicker: state.showPicker,
    rootMedications: state.medicationsPicker,
    diseasesPickerRoot: state.diseasesPickerRoot,
    rootProcedures: state.rootProcedures,
    diseasesDO: state.diseasesDO,
    diseasesICD9CM: state.diseasesICD9CM,
    diseasesDOList: state.diseasesDOList,
    diseasesICD9CMList: state.diseasesICD9CMList,
    medicationsATC: state.medicationsATC,
    medicationsEPC: state.medicationsEPC,
    medicationsMOA: state.medicationsMOA,
    medicationsATCList: state.medicationsATCList,
    medicationsEPCList: state.medicationsEPCList,
    medicationsMOAList: state.medicationsMOAList,
    diseasesPicker: state.diseasesPicker,
    proceduresPicker: state.proceduresPicker,
    diseasesList: state.diseasesList,
    proceduresList: state.proceduresList
  };
};

export default connect(mapStateToProps)(TreeSelect);
