import React, { Component } from 'react'
import Note from './../Note'

class NotificationList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      notificationsList: this.props.array
    }
  }

  saveNotification = (notificationName, status) => {
    let arr = [...this.state.notificationsList]
    if (this.props.type === 'not') {
      arr.forEach(el => {
        if (el.notificationName === notificationName) {
          el.notificationStatus = status
        }
      })
    } else {
      arr.forEach(el => {
        if (el.notificationName === notificationName) {
          el.emailStatus = status
        }
      })
    }
    this.setState(
      {
        notificationsList: arr
      },
      () => {
        this.props.saveNotification(arr)
      }
    )
  }

  render () {
    let { notificationsList } = this.state
    return (
      <div className={`permission-list__items`}>
        {notificationsList.map((el, key) => {
          return this.props.type === 'not' ? (
            <Note
              notificationName={el.notificationName}
              status={el.notificationStatus}
              saveNotification={this.saveNotification}
              key={key}
            />
          ) : (
            <Note
              notificationName={el.notificationName}
              status={el.emailStatus}
              saveNotification={this.saveNotification}
              key={key}
            />
          )
        })}
      </div>
    )
  }
}

export default NotificationList
