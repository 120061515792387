import React, { Component } from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import PinInput from "react-pin-input";
import { push } from "react-router-redux";
import i18n from "../../i18n";
import {
  sendSmsType,
  verifyAccount,
  reSendSms
} from "../../actions/trialActions";
import InmunityApi from "../../api/InmunityApi";

import crossicon from "../../images/icon-close-w.svg";
import warning from "../../images/warning.svg";
import logo from "../../images/logo-w.png";

import "../styles/ForgotP.css";

let timer;

class ForgotP extends Component {
  state = {
    email: "",
    regexEmail: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    validEmail: false,
    count: 120,
    countStatus: "stoped",
    message: "",
    open: false
  };

  componentDidUpdate() {
    if (this.props.userToken.validEmail === true) {
      switch (this.state.countStatus) {
        case "stoped":
          this.startTimer();
          break;
        case "started":
          if (this.state.count === 0) {
            clearInterval(timer);
            timer = undefined;
            this.setState({ countStatus: "finished" });
          }
          break;
        default:
          break;
      }
    }
  }

  handleInputChange = event => {
    let value = event.target.value;
    let name = event.target.name;
    let button = document.getElementById("forgotButton");
    let state = this.state;

    if (name === "email") {
      if (state.regexEmail.test(value)) {
        button.classList.remove("buttonDisableGreen");
        state.validEmail = true;
      } else {
        button.classList.add("buttonDisableGreen");
        state.validEmail = false;
      }
    }
    state[name] = value;
    this.setState(state);
  };

  handleSubmit = e => {
    if (e.keyCode === 13) {
      this.sendSms();
    }
  };

  sendSms = () => {
    if (this.state.validEmail) {
      let obj = {};
      obj.email = this.state.email;
      InmunityApi.resetPassword(obj)
        .then(resolve => {
          if (resolve.status !== 200) {
            this.setState({
              open: true,
              message: "Sorry, that email address is not associated with an account. Please contact your system administrator."
            });
          } else {
            this.setState({
              open: true,
              message:
                "Password reset link was successfully sent to your email."
            });
          }
        })
        .catch(error =>
          console.log("Error occured while sending reset link: ", error)
        );
    } else {
      let inputEmail = document.getElementById("email");
      let warningEmail = document.getElementById("warningEmail");
      if (inputEmail) inputEmail.classList.add("border-red");
      if (warningEmail) warningEmail.classList.add("img-warning-show");

      this.props.sendSmsType({
        validEmail: false,
        response: i18n.t("login.errors.validFail")
      });
    }
  };

  verifyAccount = (value, index) => {
    this.propsverifyAccount(value);
  };

  reSendSms = () => {
    this.props.reSendSms(this.state.email);
    this.setState({ count: 120, countStatus: "stoped" });
  };

  formatSeconds = totalSeconds => {
    let seconds = totalSeconds % 60;
    let minutes = Math.floor(totalSeconds / 60);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return minutes + ":" + seconds;
  };

  startTimer = () => {
    timer = setInterval(() => {
      let newCount = this.state.count - 1;
      this.setState({
        count: newCount >= 0 ? newCount : 0,
        countStatus: "started"
      });
    }, 1000);
  };

  accountRegistration = () => {
    this.props.relocate("/account-registration");
  };

  closeModal = () => {
    this.setState({ open: false, message: "" });
  };

  render() {
    let { userToken } = this.props;
    if (userToken.validEmail === false) {
      let inputEmail = document.getElementById("email");
      let warningEmail = document.getElementById("warningEmail");
      if (inputEmail) inputEmail.classList.add("border-red");
      if (warningEmail) warningEmail.classList.add("img-warning-show");
    }

    return (
      <div className="base-container forBlueBC">
        <div className="base-container">
          <div className="login-container">
            <div className="left-login">
              <img src={logo} alt="Logo" />
            </div>
            <div className="right-login">
              <div className="forgot-fields forImportPopup">
                <h1 className="title-h1">
                  {userToken.validEmail === undefined
                    ? i18n.t("forgot.title")
                    : userToken.validEmail === false
                    ? i18n.t("login.errors.titleAuthFail")
                    : i18n.t("resetPassword.title")}{" "}
                </h1>
                <h4 className="title-h4">
                  {userToken.validEmail === undefined
                    ? i18n.t("forgot.subTitle")
                    : userToken.validEmail === false
                    ? userToken.response
                    : i18n.t("resetPassword.subTitle")}{" "}
                </h4>
                <Popup
                  open={this.state.open}
                  closeOnDocumentClick
                  onClose={this.closeModal}
                  className="saveUserPopup"
                >
                  <div>
                    <div
                      className="closePendingPopupCross"
                      onClick={this.closeModal}
                    >
                      <img src={crossicon} alt="Cross" />
                    </div>
                    <div className="saveUserPopupText">
                      {this.state.message}
                    </div>
                  </div>
                </Popup>
                {userToken.validEmail || userToken.validCode === false ? (
                  <div>
                    <div>
                      <p className="gray-small-text">
                        {i18n.t("resetPassword.expire")}{" "}
                        {this.formatSeconds(this.state.count)}
                      </p>
                    </div>
                    <div className="pin-input">
                      <PinInput
                        length={6}
                        onChange={(value, index) => {}}
                        type="numeric"
                        inputStyle={{ borderColor: "#F4F4F4" }}
                        onComplete={(value, index) => {
                          this.verifyAccount(value, index);
                        }}
                      />
                    </div>
                    <div className="re-sendContainer">
                      <p className="text-small re-send">
                        {i18n.t("resetPassword.code")}
                        <span
                          className="text-bold color-blueLink re-send"
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            this.reSendSms();
                          }}
                        >
                          {" "}
                          {i18n.t("resetPassword.resend")}
                        </span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <label htmlFor="email" className="text-small">
                      {i18n.t("login.eMail")}
                    </label>
                    <div className="input-field">
                      <input
                        type="email"
                        className="text-field login"
                        name="email"
                        id="email"
                        placeholder={`${i18n.t("login.eMailPlaceHolder")}`}
                        onChange={this.handleInputChange}
                        onKeyDown={this.handleSubmit}
                      />
                      <img
                        src={warning}
                        alt="Warning"
                        className="img-warning"
                        id="warningEmail"
                      />
                    </div>
                    <span
                      className="login-button buttonDisableGreen"
                      href=""
                      id="forgotButton"
                      onClick={e => {
                        e.preventDefault();
                        this.sendSms();
                      }}
                    >
                      {i18n.t("forgot.button")}
                    </span>
                  </div>
                )}
              </div>
              {/* <div className="singContainer">
                <p className="text-small sign-up-login">
                  {i18n.t("login.account")}
                  <span
                    className="text-bold color-blueLink td-none"
                    href=""
                    onClick={e => {
                      e.preventDefault();
                      this.accountRegistration();
                    }}
                  >
                    {" "}
                    {i18n.t("login.sing")}
                  </span>
                </p>
              </div> ghost#hidden */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSmsType: response =>
      dispatch(
        sendSmsType({
          validEmail: false,
          response: i18n.t("login.errors.validFail")
        })
      ),
    verifyAccount: value => dispatch(verifyAccount(value)),
    reSendSms: email => dispatch(reSendSms(email)),
    relocate: url => dispatch(push(url))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotP);
