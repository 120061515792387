import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import ImmunityApi from "./../../../../../api/InmunityApi";

const AllHospitals = ({ active, setActive }) => {
  return (
    <div
      className={active === 'all' ? "trial-button selected" : "trial-button"}
      key={'all'}
      onClick={() => setActive('all', {name: 'All Sites', id: null})}
    >
      All Sites
    </div>
  )
}
const HospitalRows = ({
  hospitals,
  location,
  makeHospital,
  setGraphsData,
  setAge,
  setEthnicity,
  setGender,
  setSpinner,
  setGraphSpinner,
  searching
}) => {
  const [active, setActiveHospital] = useState('all');
  const [hospitalsSorted, setHospitalsSorted] = useState([]);
  const [activeSearch, setActiveSearch] = useState('all');

  const setActive = (active, el) => {
    setActiveHospital(active);
    setActiveSearch(active);
    fetchHospital(el);
  };

  const fetchAdditionalData = (searchId, name, id) => {
    ImmunityApi.getHospitalsAge(searchId, id).then(res => {
      setAge(res);
      setGraphSpinner(false);
    });
    ImmunityApi.getHospitalsGender(searchId, id).then(res => {
      setGender(res);
      setGraphSpinner(false);
    });
    ImmunityApi.getHospitalsEthnicity(searchId, id).then(res => {
      setEthnicity(res);
      setGraphSpinner(false);
    });
  }

  const fetchHospital = ({name, id}) => {
    const searchId = parseInt(location.pathname.split("/")[2], 10);
    if (name === "All Sites") {
      setGraphSpinner(true);
      makeHospital("all");
      ImmunityApi.getHospitalPatientInfo(searchId, name, null).then(res => {
        setGraphsData(res);
        setGraphSpinner(false);
      });
      fetchAdditionalData(searchId, name, null);
    } else {
      setSpinner(true);
      setGraphSpinner(true);
      fetchAdditionalData(searchId, name, id);
      ImmunityApi.getHospitalPatientInfo(searchId, name, id).then(res => {
        makeHospital(res.criteriaDistribution);
        setGraphsData(res);
            setSpinner(false);
        setGraphSpinner(false);
      });
    }
  };

  useEffect(() => {
    fetchHospital({name:"All Sites", id: null});
  }, []);
  useEffect(() => {
    if (!Array.isArray(hospitals)) return;
    const array = [...hospitals];
    if (array.length === 2) {
      const index = array.findIndex(el => el.name === "All Sites");
      const el = array[index];
      array.splice(index, 1);
      array.splice(0, 0, el);
    } else {
      array.sort((a, b) => {
        return b.count - a.count;
      });
    }
    setHospitalsSorted(array);
  }, [hospitals]);
  useEffect(() => {
    if (searching) {
      setActiveHospital(-1);
    } else {
      setActiveHospital(activeSearch);
    }
  }, [searching]);


  return (
    <div className="hospitals-pat-container workflow-multiple-container ">
      <AllHospitals active={active} setActive={setActive} />
      {hospitalsSorted.map((el, i) => (
        <div
          className={active === i ? "trial-button selected" : "trial-button"}
          key={i}
          onClick={() => setActive(i, el)}
        >
          {el.name}
        </div>
      ))}
    </div>
  );
};

export default withRouter(HospitalRows);
