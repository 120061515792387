import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import i18n from "../../i18n";
import inmunityApi from "../../api/InmunityApi";
import InmunityApi from "../../api/InmunityApi";

class PreScreening extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tabActive: 1,
      matches: [],
      candidates: [],
      rows: [],
      trialSort: "up",
      matchSort: "up",
      dateSort: "up",
      linkMatches: null,
      isMoreMatches: null,
      loadingMatches: false,
      linkCandidates: null,
      isMoreCandidates: null,
      loadingCandidates: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.setState({ loadingCandidates: true, loadingMatches: true });
    inmunityApi.getRecentMatches().then(matches => {
      if (this.mounted) {
        this.setState({
          matches: matches.results,
          linkMatches: matches.next,
          isMoreMatches: !!matches.next,
          loadingMatches: false,
          rows: matches.results
        });
      }
    });
    inmunityApi.getRecentCandidates().then(candidates => {
      if (this.mounted) {
        this.setState({
          candidates: candidates.results,
          linkCandidates: candidates.next,
          isMoreCandidates: !!candidates.next,
          loadingCandidates: false
        });
      }
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setMatches = () => {
    this.setState({ tabActive: 1, rows: this.state.matches });
  };

  setCandidates = () => {
    this.setState({ tabActive: 2, rows: this.state.candidates });
  };

  sorter = (sign, flag, query) => {
    flag === "matches"
      ? this.setState({
          matches: [],
          loadingMatches: true,
          isMoreMatches: false,
          linkMatches: null
        })
      : this.setState({
          candidates: [],
          loadingCandidates: true,
          isMoreCandidates: false,
          linkCandidates: null
        });
    InmunityApi.getNewFromPrescreeningSorted(sign, flag, query).then(
      response => {
        flag === "matches"
          ? this.setState({
              matches: response.results,
              loadingMatches: false,
              isMoreMatches: !!response.next,
              linkMatches: response.next
            })
          : this.setState({
              candidates: response.results,
              loadingCandidates: false,
              isMoreCandidates: !!response.next,
              linkCandidates: response.next
            });
      }
    );
  };

  sortByTrial = () => {
    if (this.state.tabActive === 1) {
      if (this.state.trialSort === "up") {
        this.sorter("", "matches", "study");
        this.setState({ trialSort: "down" });
      } else {
        this.sorter("-", "matches", "study");
        this.setState({ trialSort: "up" });
      }
    } else {
      if (this.state.trialSort === "up") {
        this.sorter("", "candidates", "study");
        this.setState({ trialSort: "down" });
      } else {
        this.sorter("-", "candidates", "study");
        this.setState({ trialSort: "up" });
      }
    }
  };

  sortByMatch = () => {
    if (this.state.tabActive === 1) {
      if (this.state.matchSort === "up") {
        this.sorter("", "matches", "rate");
        this.setState({ matchSort: "down" });
      } else {
        this.sorter("-", "matches", "rate");
        this.setState({ matchSort: "up" });
      }
    } else {
      if (this.state.matchSort === "up") {
        this.sorter("", "candidates", "rate");
        this.setState({ matchSort: "down" });
      } else {
        this.sorter("-", "candidates", "rate");
        this.setState({ matchSort: "up" });
      }
    }
  };

  sortByDate = () => {
    if (this.state.tabActive === 1) {
      if (this.state.dateSort === "up") {
        this.sorter("", "matches", "date");
        this.setState({ dateSort: "down" });
      } else {
        this.sorter("-", "matches", "date");
        this.setState({ dateSort: "up" });
      }
    } else {
      if (this.state.dateSort === "up") {
        this.sorter("", "candidates", "date");
        this.setState({ dateSort: "down" });
      } else {
        this.sorter("-", "candidates", "date");
        this.setState({ dateSort: "up" });
      }
    }
  };

  goToDetails = (id, e) => {
    e.preventDefault();
    let { history } = this.props;
    history.push({
      pathname: `/matches/details/${id}`,
      id: id,
      detailTitle: "Match Details"
    });
  };

  loadMoreData = flag => {
    if (flag === 1 && this.state.isMoreMatches) {
      inmunityApi
        .getPatientSearchPagination(this.state.linkMatches)
        .then(response => {
          let linkMatches = response.next;
          let isMoreMatches = !!linkMatches;
          let oldMatches = [...this.state.matches];
          let newMatches = oldMatches.concat(response.results);
          this.setState({
            matches: newMatches,
            linkMatches,
            isMoreMatches
          });
        });
    }
    if (flag === 2 && this.state.isMoreCandidates) {
      inmunityApi
        .getPatientSearchPagination(this.state.linkCandidates)
        .then(response => {
          let linkCandidates = response.next;
          let isMoreCandidates = !!linkCandidates;
          let oldCandidates = [...this.state.candidates];
          let newCandidates = oldCandidates.concat(response.results);
          this.setState({
            candidates: newCandidates,
            linkCandidates,
            isMoreCandidates
          });
        });
    }
  };

  render() {
    return (
      <div className="prescreening-container">
        <h3 className="title-section">
          {i18n.t("preScreening.newPreScreening")}
        </h3>
        <div className="prescreening-data">
          <div className="prescreening-tabs">
            <Link
              to=""
              onClick={e => {
                e.preventDefault();
                this.setMatches();
              }}
            >
              <div
                className={
                  this.state.tabActive === 1
                    ? "prescreening-tab tab-active"
                    : "prescreening-tab"
                }
                onClick={this.setMatches}
              >
                {i18n.t("menu.matches")}
              </div>
            </Link>
            <Link
              to=""
              onClick={e => {
                e.preventDefault();
                this.setCandidates();
              }}
            >
              <div
                className={
                  this.state.tabActive === 2
                    ? "prescreening-tab tab-active"
                    : "prescreening-tab"
                }
                onClick={this.setCandidates}
              >
                {i18n.t("menu.candidates")}
              </div>
            </Link>
          </div>
          <div className="prescreening-headers">
            <div className="prescreening-head">{i18n.t("preScreening.id")}</div>
            <div className="prescreening-head" onClick={this.sortByTrial}>
              {i18n.t("preScreening.trial")}{" "}
              <img
                className="arrow-sort"
                src={require("../../images/arrow-sort.svg")}
                alt="Sort Arrow"
              />
            </div>
            <div className="prescreening-head" onClick={this.sortByMatch}>
              {i18n.t("preScreening.match")}{" "}
              <img
                className="arrow-sort"
                src={require("../../images/arrow-sort.svg")}
                alt="Sort Arrow"
              />
            </div>
            <div className="prescreening-head" onClick={this.sortByDate}>
              {this.state.tabActive === 1 ? "MATCH " : null}
              {this.state.tabActive === 2 ? "CANDIDATE " : null}
              {i18n.t("preScreening.date")}{" "}
              <img
                className="arrow-sort"
                src={require("../../images/arrow-sort.svg")}
                alt="Sort Arrow"
              />
            </div>
          </div>
          {this.state.tabActive === 1 && (
            <div
              className="prescreening-table"
              ref={ref => (this.scrollParentRefOne = ref)}
            >
              {this.state.tabActive === 1 && this.state.loadingMatches ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="medium-spinner"
                    src={require("../../images/spinner.gif")}
                    alt="Spinner"
                  />
                </div>
              ) : null}
              {this.state.tabActive === 1 && (
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  getScrollParent={() => this.scrollParentRefOne}
                  loadMore={() => this.loadMoreData(1)}
                  hasMore={this.state.isMoreMatches}
                  loader={
                    <div className="loader " key={0}>
                      <img
                        className="small-spinner"
                        src={require("../../images/spinner.gif")}
                        alt="Spinner"
                      />
                    </div>
                  }
                >
                  {this.state.matches &&
                    this.state.matches.length > 0 &&
                    this.state.matches.map((row, i) => {
                      let date = row.date;
                      let rate = row.rate;
                      let id = row.hadmId;
                      let path = `/matches/details/${id}`;
                      if (date) {
                        date = new Date(date).toLocaleDateString("en-us");
                      }
                      return (
                        <NavLink
                          key={i}
                          to={path}
                          onClick={e => this.goToDetails(id, e)}
                          exact
                        >
                          <div className="prescreening-row">
                            <div className="prescreening-cell">
                              {row.immunity}
                            </div>
                            <div className="prescreening-cell">
                              <span
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                {row.trial}
                              </span>
                            </div>
                            <div className="prescreening-cell">
                              {rate.toFixed()}%
                            </div>
                            <div className="prescreening-cell">{date}</div>
                          </div>
                        </NavLink>
                      );
                    })}
                </InfiniteScroll>
              )}
            </div>
          )}
          {this.state.tabActive === 2 && (
            <div
              className="prescreening-table"
              ref={ref => (this.scrollParentRef = ref)}
            >
              {this.state.tabActive === 2 && this.state.loadingCandidates ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    className="medium-spinner"
                    src={require("../../images/spinner.gif")}
                    alt="Spinner"
                  />
                </div>
              ) : null}
              {this.state.tabActive === 2 && (
                <InfiniteScroll
                  pageStart={0}
                  useWindow={false}
                  getScrollParent={() => this.scrollParentRef}
                  loadMore={() => this.loadMoreData(2)}
                  hasMore={this.state.isMoreCandidates}
                  loader={
                    <div className="loader " key={0}>
                      <img
                        className="small-spinner"
                        src={require("../../images/spinner.gif")}
                        alt="Spinner"
                      />
                    </div>
                  }
                >
                  {this.state.candidates.length > 0 &&
                    this.state.candidates.map((row, i) => {
                      let date = row.date;
                      let rate = row.rate;
                      let id = row.hadmId;
                      let path = `/matches/details/${id}`;
                      if (date) {
                        date = new Date(date).toLocaleDateString("en-us");
                      }
                      return (
                        <NavLink
                          key={i}
                          to={path}
                          onClick={e => this.goToDetails(id, e)}
                          exact
                        >
                          <div className="prescreening-row">
                            <div className="prescreening-cell">
                              {row.immunity}
                            </div>
                            <div className="prescreening-cell">
                              <span
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden"
                                }}
                              >
                                {row.trial}
                              </span>
                            </div>
                            <div className="prescreening-cell">
                              {rate.toFixed()}%
                            </div>
                            <div className="prescreening-cell">{date}</div>
                          </div>
                        </NavLink>
                      );
                    })}
                </InfiniteScroll>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken
  };
}

export default withRouter(connect(mapStateToProps)(PreScreening));
