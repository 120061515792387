import React from "react";
import { withRouter } from "react-router";
import logo from "../images/logo-w.png";
import InmunityApi from '../api/InmunityApi'
import "./styles/PatientDetails.css";

class ThankYou extends React.Component {
    state = {
        isChecking: true,
        error: false
    }

    goToLogin = () => {
        let { history } = this.props;
        history.push({
            pathname: "/"
        });
    };

    componentDidMount() {
        // const token = this.props.match.params.token;
        // const company = this.props.match.params.company;
        // if (token && company)
        //     InmunityApi.createCompany(company, token).then(res => {
        //         if (res.status === 200 || res.status === 201) {
        //             this.setState({ isChecking: false, error: false });
        //         }
        //         else {
        //             this.setState({ isChecking: false, error: true });
        //         }
        //     }
        //     )
        // else {
        //     this.setState({ isChecking: false })
        // }
    }

    render() {

        return (
            <div className="base-container forPD forBlueBC thankYouPage">
                <div className="base-container">
                    <div className="login-container patientDetails reasonDecline">
                        <img src={logo} alt="Immunity" />
                        <p className="helloName">
                            {this.props.match.params.error === "Error" ? "Thank you!" : ""}
                        </p>
                        <span>

                        </span>
                        <div className="closeWindow" onClick={this.goToLogin}>
                            Close window
            </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ThankYou);
