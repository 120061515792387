import React, { useEffect, useState, useContext } from "react";
import ItemsPopup from "./ItemsPopup";
import WarningPopup from "./WarningPopup";
import PickerSendContext from "./../PickerContext";
import _ from "lodash";

export default function Footer(props) {
  const {
    itemsSelected,
    sendForMedication,
    closePopup,
    filter,
    removeItem,
    makeCheck,
    setCodes,
    freeText,
    allData
  } = props;
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [list, setList] = useState("");

  const forSending = useContext(PickerSendContext);

  const continueWithDisease = () => {
    let codes = makeResult(forSending);
    if (allData && allData.length > 0) {
      let sameData = _.filter(allData, allItem =>
        _.some(codes, item => {
          if (item.name === allItem.name) {
            return allItem.include !== item.include;
          }
        })
      );
      if (sameData.length > 0) {
        let list = "";
        sameData.forEach(
          (el, i) =>
            (list +=
              sameData.length > 1 && i !== sameData.length - 1
                ? el.name + ", "
                : el.name)
        );
        setShowWarningPopup(true);
        setList(list);
        return null;
      }
    }
    sendForMedication(codes, freeText);
    closePopup();
  };

  const makeResult = data => {
    let codes = data.filter(e => e.include !== "delete");
    return codes;
  };

  const showPopup = () => {
    setShow(true);
  };

  const closeItemsPopup = () => {
    setShow(false);
  };

  const closeWarningPopup = () => {
    setShowWarningPopup(false);
  };

  useEffect(() => {
    const items = makeResult(forSending);
    setItems(items);
  }, [forSending]);

  const itemsNames =
    itemsSelected > 1
      ? itemsSelected + " " + filter.split(" ")[0].toString() + "s Selected"
      : itemsSelected + " " + filter.split(" ")[0].toString() + " Selected";
  return (
    <div className="dis-footer">
      <ItemsPopup
        open={show}
        closePopup={closeItemsPopup}
        filter={filter}
        forSending={items}
        removeItem={removeItem}
        makeCheck={makeCheck}
        setCodes={setCodes}
      />
      <WarningPopup
        open={showWarningPopup}
        closeWarningPopup={closeWarningPopup}
        list={list}
      />
      {itemsSelected ? (
        <div className="dis-footer-el" onClick={showPopup}>
          {/* <div className="items-selected">
            {itemsSelected ? itemsNames : null}
            #hidden
          </div> */}
        </div>
      ) : (
          <div className="dis-footer-el" />
        )}
      <div className="dis-footer-el">
        <div
          className="search-container__plusbtn dis-pop-btn"
          onClick={continueWithDisease}
        >
          Continue
        </div>
      </div>
    </div>
  );
}
