import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function modalReducer(state = initialState.modalGeneric, action) {
  switch(action.type) {
    case types.TOGGLE_MODAL_GENERIC:
      return action.modalGeneric
    default:
      return state;
  }
}