import React, { useState, useEffect, useContext } from "react";
import checkInFalse from "./../../../../../images/exclusion_inclusion/plus_empty.svg";
import checkExFalse from "./../../../../../images/exclusion_inclusion/minus_empty.svg";
import checkInTrue from "./../../../../../images/exclusion_inclusion/plus_full.svg";
import checkExTrue from "./../../../../../images/exclusion_inclusion/minus_full.svg";
import checkInNotAll from "./../../../../../images/exclusion_inclusion/plus_not_full.svg";
import checkExNotAll from "./../../../../../images/exclusion_inclusion/minus_not_full.svg";
import PickerSendContext from "./../../PickerContext";

export default function IncludeExclude({
  id,
  setCodes,
  filter,
  activeTab,
  include,
  name,
  makeCheck,
  showDiseases,
  hasChildren,
  hasLeafs,
  parentId
}) {
  const [checkInclude, setCheckInclude] = useState(false);
  const [checkExclude, setCheckExclude] = useState(false);
  const [param, setParameter] = useState("");
  const forSending = useContext(PickerSendContext);

  const handleCheckboxChange = e => {
    const target = e.target;
    const attrName = target.parentNode.getAttribute("name");
    let incl = checkInclude;
    let excl = checkExclude;
    if (attrName === "include") {
      incl = !checkInclude;
      setCheckInclude(incl);
      if (incl) {
        setCheckExclude(false);
        excl = false;
      }
    } else if (attrName === "exclude") {
      excl = !checkExclude;
      setCheckExclude(excl);
      if (excl) {
        setCheckInclude(false);
        incl = false;
      }
    } else {
      console.log("Wrong attribute name!");
    }
    (async () => {
      await sendIncludeExclude(incl, excl);
      await showDiseases(target, id, false, incl, excl);
    })();
  };

  const sendIncludeExclude = (include, exclude, parameter = false) => {
    let criteria = checkFilter();
    if (include || exclude) {
      setCodes({
        id,
        hasChildren,
        hasLeafs,
        include,
        criteria,
        activeTab,
        name,
        parentId
      });
      // parameter
      //   ? makeCheck({ id, remove: true })
      //   : makeCheck({ id, include, exclude });
    } else {
      setCodes({
        id,
        hasChildren,
        hasLeafs,
        include: "delete",
        criteria,
        activeTab,
        name,
        parentId
      });
      // parameter
      //   ? makeCheck({ id, remove: true })
      //   : makeCheck({ id, include: false, exclude: false });
    }
  };

  useEffect(() => {
    if (
      param === "Include some & exclude 0" ||
      param === "Exclude some & include 0" ||
      param === "Some include & some exclude"
    ) {
      if (checkInclude || checkExclude) {
        setCheckInclude(false);
        setCheckExclude(false);
        sendIncludeExclude(false, false, true);
      }
    }
  }, [param]);

  useEffect(() => {
    if (forSending.length > 0) {
      let array = [];
      let count = 0;
      forSending.forEach(el => {
        if (el.id === id) {
          if (el.include === true) {
            setCheckInclude(true);
            setCheckExclude(false);
          } else if (el.include === false) {
            setCheckExclude(true);
            setCheckInclude(false);
          } else if (el.include === "delete") {
            setCheckExclude(false);
            setCheckInclude(false);
          }
        } else if (el.parentId === id && el.include !== "delete") {
          count = el.itemsLength;
          array.push(el.include);
        }
      });
      setParameterIncludeExclude(array, count);
    }
  }, [forSending]);

  const setParameterIncludeExclude = (array, count) => {
    if (array.includes(true) && array.includes(false)) {
      setParameter("Some include & some exclude");
    } else if (array.includes(true) && array.length !== count) {
      setParameter("Include some & exclude 0");
    } else if (array.includes(false) && array.length !== count) {
      setParameter("Exclude some & include 0");
    } else if (array.includes(true) && array.length === count) {
      setParameter("Include all");
    } else if (array.includes(false) && array.length === count) {
      setParameter("Exclude all");
    } else {
      setParameter("No include, no exclude");
    }
  };

  const checkFilter = () => {
    let res;
    switch (filter) {
      case "Medication Filters":
        res = "Medication";
        break;
      case "Disease Filters":
        res = "Disease";
        break;
      case "Procedure History":
        res = "Procedure";
        break;
      default:
        return null;
    }
    return res;
  };

  return (
    <div className="checkboxes-container">
      <div
        name="exclude"
        onClick={handleCheckboxChange}
        className="plus_minus_wrapp"
      >
        {include === undefined && (
          <img
            src={
              checkExclude || param === "Exclude all"
                ? checkExTrue
                : param === "Exclude some & include 0" ||
                  param === "Some include & some exclude"
                ? checkExNotAll
                : checkExFalse
            }
            alt="exclusion"
          />
        )}
      </div>
      <div
        name="include"
        onClick={handleCheckboxChange}
        className="plus_minus_wrapp"
      >
        {include === undefined && (
          <img
            src={
              checkInclude || param === "Include all"
                ? checkInTrue
                : param === "Include some & exclude 0" ||
                  param === "Some include & some exclude"
                ? checkInNotAll
                : checkInFalse
            }
            alt="inclusion"
          />
        )}
      </div>
      {include === false && <img src={checkExTrue} alt="exclusion" />}
      {include === true && <img src={checkInTrue} alt="exclusion" />}
    </div>
  );
}
