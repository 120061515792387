export const URLGenerator = ({ id, count }) => {
  return id !== ""
    ? [
        {
          name: `Information`,
          path: `/trials/${id}`,
          trial: id
        },
        {
          name: `Matches ${
            count && count.name === "matches"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: `/trials/${id}/matches`,
          spinner: count.spinner,
          trial: id
        },
        {
          name: `Candidates ${
            count && count.name === "candidates"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: `/trials/${id}/candidates`,
          trial: id
        },
        {
          name: `Exclusions ${
            count && count.name === "exclusions"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: `/trials/${id}/exclusions`,
          trial: id
        },
        {
          name: `Enrolled ${
            count && count.name === "enrolled"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: `/trials/${id}/enrolled`,
          trial: id
        },
        {
          name: "Analytics",
          path: `/trials/${id}/analytics`,
          trial: id
        }
      ]
    : [
        {
          name: `Candidates ${
            count && count.name === "candidates"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: "/all-trials",
          trial: id === ""
        },
        {
          name: `Matches ${
            count && count.name === "matches"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: "/trials-matches",
          trial: id === ""
        },
        {
          name: `Exclusions ${
            count && count.name === "exclusions"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: "/trials-exclusions",
          trial: id === ""
        },
        {
          name: `Enrolled ${
            count && count.name === "enrolled"
              ? count.count === ""
                ? ""
                : `(${count.count})`
              : ""
          }`,
          path: "/trials-enrolled",
          trial: id === ""
        },
        {
          name: "Analytics",
          path: "/all-analytics",
          trial: id === ""
        }
      ];
};
