import React, { useState, useEffect } from "react";
import CountChartBlock from "./CountChartBlock";
import InclusionCriteria from "./../../InclusionCriteria";

export default function HospitalPatientsCountRow({
  hospital,
  spinner,
  sites,
  graphsData,
  graphSpinner
}) {
  const [miArray, setMiArray] = useState([]);
  const [inArray, setInArray] = useState([]);
  const [exArray, setExArray] = useState([]);
  const [allHospitals, setAllHospitals] = useState({});
  const [num, setNum] = useState([]);
  useEffect(() => {
    makeData(sites);
    setAllHospitals(sites);
  }, [sites]);
  useEffect(() => {
    if (hospital === "all") {
      makeData(allHospitals);
    } else {
      makeData(hospital);
    }
  }, [hospital, allHospitals, graphsData]);
  const makeData = object => {
    if (Object.keys(object).length > 0) {
      const { missing, matchingInclude, matchingExclude, count } = object;
      setMiArray(missing);
      setInArray(matchingInclude);
      setExArray(matchingExclude);
      setNum(count);
    } else return;
  };
  return (
    <div className="hos_pat_row-wrapp">
      <CountChartBlock
        count={num}
        spinner={spinner}
        graphsData={graphsData}
        graphSpinner={graphSpinner}
      />
      <div className="workflow_chart">
        <InclusionCriteria
          mismatch="Missing"
          data={miArray ? miArray : []}
          spinner={spinner}
        />
      </div>
      <div className="workflow_chart">
        <InclusionCriteria
          mismatch="Matching"
          data={inArray ? inArray : []}
          dataExclude={exArray ? exArray : []}
          spinner={spinner}
        />
      </div>
    </div>
  );
}
