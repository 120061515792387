import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setProfilePhoto(
  state = initialState.workFlowStudyId,
  action
) {
  switch (action.type) {
    case types.WORKFLOW_STUDY_ID:
      return action.workFlowStudyId;
    default:
      return state;
  }
}
