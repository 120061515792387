import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { URLGenerator } from "../../utils/trailsNavigation";
import AllCandidates from "./AllCandidates";
import inmunityApi from "../../api/InmunityApi";
import TrialsNav from "../TrialsNav";
import {
  getTrialDescription,
  setCurrentTrial,
  setCurrentNavigationLink,
  setCurrentNavigationSubLink
} from "../../actions/trialActions";
import { Sort, CustomInfinityScroll } from "../../ui";
import "./index.css";

const options = [
  // { value: "visit", label: "Upcoming Visit" } ghost#hidden,
  { value: "consented", label: "Contant Consent" },
  { value: "immunity", label: "Immunity ID" },
  { value: "name", label: "Name" }
];

class Candidates extends Component {
  state = {
    candidates: [],
    selectedOption: options[0],
    loading: false,
    searching: false,
    isMore: null,
    link: null,
    isReverseSort: true,
    input: "",
    searchLengthResult: null,
    paths: [],
    currentTrial: "",
    anchor: false,
    loadingNext: false
  };

  componentDidMount() {
    this.mounted = true;
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink,
      getTrialDescription,
      setCurrentTrial
    } = this.props;
    let currentTrial = location.pathname.split("/")[2];
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial);
    this.setState(
      {
        paths: URLGenerator({
          id: currentTrial,
          count: { name: "candidates", count: "" }
        }),
        currentTrial: currentTrial
      },
      () => this.defaultCandidates()
    );
    getTrialDescription(currentTrial);
    setCurrentTrial(currentTrial);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location.pathname !== this.props.location.pathname) {
      let currentTrial = newProps.location.pathname.split("/")[2];
      this.setState(
        {
          paths: URLGenerator({
            id: currentTrial,
            count: { name: "candidates", count: 0 }
          }),
          currentTrial: currentTrial
        },
        () => this.defaultCandidates()
      );
    }
  }

  defaultCandidates = () => {
    this.setState({ loading: true });
    let { currentTrial } = this.state;
    inmunityApi.getAllPatients(currentTrial).then(candidates => {
      if (this.mounted) {
        if (candidates.results) {
          candidates.results.sort(
            (a, b) => new Date(b.upcomingVisit) - new Date(a.upcomingVisit)
          );
          this.setState({
            candidates: candidates.results,
            loading: false,
            isMore: !!candidates.next,
            link: candidates.next,
            paths: URLGenerator({
              id: currentTrial,
              count: { name: "candidates", count: candidates.count }
            })
          });
        }
      }
    });
  };

  handleChange = selectedOption => {
    this.setState(
      {
        selectedOption,
        candidates: []
      },
      () => {
        this.sortCandidatesApi();
      }
    );
  };

  reverseSort = () => {
    this.setState({ isReverseSort: !this.state.isReverseSort }, () => {
      this.sortCandidatesApi();
    });
  };

  sortCandidatesApi = () => {
    this.setState({ loading: true });
    let flag = this.state.selectedOption.value;
    let sign = this.state.isReverseSort ? "-" : "";
    let query = this.state.input !== "" ? this.state.input : null;
    inmunityApi
      .getCandidatesSorted(this.state.currentTrial, sign, query, flag)
      .then(response => {
        this.setState({
          candidates: response.results,
          loading: false,
          isMore: !!response.next,
          link: response.next,
          searchLengthResult: response.count
        });
      });
  };

  loadMoreData = () => {
    this.setState({ loadingNext: true }, () => {
      if (this.state.isMore) {
        inmunityApi
          .getPatientSearchPagination(this.state.link)
          .then(response => {
            let link = response.next;
            let isMore = !!link;
            let oldCandidates = [...this.state.candidates];
            let newCandidates = oldCandidates.concat(response.results);
            this.setState({
              candidates: newCandidates,
              link,
              isMore,
              loadingNext: false
            });
          });
      }
    });
  };

  handleChangeInput = ({ target }) => {
    if (target.value.length === 0) {
      this.setState(
        {
          input: target.value,
          candidates: [],
          searching: false
        },
        () => {
          this.sortCandidatesApi();
        }
      );
    } else {
      this.setState({
        input: target.value
      });
    }
  };

  searchCands = () => {
    this.setState(
      {
        candidates: [],
        isMore: false,
        searching: true
      },
      () => {
        this.sortCandidatesApi();
      }
    );
  };

  render() {
    let {
      selectedOption,
      candidates,
      loading,
      currentTrial,
      searching,
      paths,
      isReverseSort,
      searchLengthResult,
      isMore,
      loadingNext
    } = this.state;
    let { name } = this.props.trial;
    return (
      <div className="content-body-matches" id="list">
        <div className="study-name">{name}</div>
        <TrialsNav paths={paths} />
        <Sort
          options={options}
          selectedOption={selectedOption}
          isRevert={isReverseSort}
          result={searchLengthResult}
          revert={this.reverseSort}
          select={this.handleChange}
          change={this.handleChangeInput}
          press={this.searchCands}
          searching={searching}
        />
        <CustomInfinityScroll
          data={candidates}
          searching={searching}
          loading={loading}
          isMore={isMore}
          textNoResult="No candidates yet for this trial."
          textNoResultSearch="No results found"
          loadMoreData={this.loadMoreData}
          loadingNext={loadingNext}
        >
          {candidates.length > 0 &&
            candidates.map((candidate, i) => (
              <AllCandidates
                key={"trial-candidate-slice-" + candidate["hadmId"] + i}
                id={"trial-candidate-slice-" + candidate["hadmId"]}
                candidate={candidate}
                studyID={currentTrial}
              />
            ))}
        </CustomInfinityScroll>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state.trial
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getTrialDescription: id => dispatch(getTrialDescription(id)),
    setCurrentTrial: id => dispatch(setCurrentTrial(id))
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Candidates)
);
