import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setPickerLabel(
    state = "some",
    action
) {
    switch (action.type) {
        case types.SET_DISEASES_LABEL:
            console.log("action =- ", action);
            return action.label;
        default:
            return state;
    }
} 
