import React from 'react'
import { withRouter } from 'react-router'
import Popup from 'reactjs-popup'

import InmunityApi from '../api/InmunityApi'

import './styles/PatientDetails.css'

class PatientDetails extends React.Component {
  state = {
    token: null,
    loading: false,
    error: false,
    hadm: null,
    immunity: null,
    patientEmail: null,
    patientName: null,
    patientPhone: null,
    studyId: null,
    studyName: null,
    studyPhase: null
  }

  componentDidMount() {
    this.setState({
      loading: true
    })
    let urlString = window.location.href
    let url = new URL(urlString)
    let token = url.searchParams.get('token')
    this.setState(
      {
        token
      },
      () => {
        token &&
          InmunityApi.getPatientDetails(token)
            .then(res => {
              if (res.status !== 200) {
                let { history } = this.props
                this.setState(
                  {
                    error: true
                  },
                  () => {
                    setTimeout(() => {
                      history.push({
                        pathname: '/'
                      })
                    }, 3000)
                  }
                )
                throw res
              }
              return res.json()
            })
            .then(resp => {
              this.setState({
                immunity: resp.immunity,
                hadm: resp.hadm,
                patientEmail: resp.patientEmail,
                patientName: resp.patientName,
                patientPhone: resp.patientPhone,
                studyId: resp.studyId,
                studyName: resp.studyName,
                studyPhase: resp.studyPhase,
                studyDescription: resp.studyDescription,
                loading: false,
                error: false
              })
            })
            .catch(error => {
              this.setState({
                loading: false
              })
              console.log('Error', error)
            })
      }
    )
  }

  closeModal = () => {
    this.setState({
      error: false
    })
  }

  setPatientDetail = () => {
    let obj = {}
    obj.hadm = this.state.hadm
    obj.name = this.state.patientName
    obj.email = this.state.patientEmail
    obj.phone = this.state.patientPhone
    obj.study = this.state.studyId
    obj.token = this.state.token
    InmunityApi.setPatientDetails(obj).then(res => {
      if (res.status === 201) {
        let { history } = this.props
        history.push({
          pathname: '/physician-consented'
        })
        return res;
      }
      else if (res.status === 403) {
        return res.json();
      }
    })
      .then(j => {
        let { history } = this.props
        const error = "Error! ff";
        history.push({
          pathname: `/patient-consented-error/${j.detail || 'Error'}`
        })
      })
  }

  decline = () => {
    let obj = {}
    obj.name = this.state.patientName
    obj.email = this.state.patientEmail
    obj.phone = this.state.patientPhone
    obj.token = this.state.token
    let dest;
    InmunityApi.declinePatientDetails(obj).then(res => {
      if (res.status === 201) {
        res.text().then(res => {
          let { history } = this.props;
          console.log(res);
          if (window.location.pathname === '/patient-details/') {
            let urlString = window.location.href
            let url = new URL(urlString)
            let token = url.searchParams.get('token')
            dest = {
              pathname: `/reason-decline-login/?token=${token}`,
              token,
              obj: JSON.parse(res)
            }
          }
          else {
            dest = {
              pathname: '/reason-decline-login',
              obj: JSON.parse(res)
            }
          }
          history.push(dest);
        })
      }
      else if (res.status === 403) {
        let { history } = this.props
        const error = "Error easd"
        history.push({
          pathname: `/patient-consented-error/${res.detail || 'Error'}`
        })
        return (res.json());
      }
    })
    // .then(j => {
    //   console.log(j);
    //
    // })
  }

  changeDetails = ({ target }) => {
    this.setState({ [target.id]: target.value })
  }

  render() {

    let phase = null
    if (this.state.studyPhase === '1') {
      phase = 'I'
    } else if (this.state.studyPhase === '2') {
      phase = 'II'
    } else if (this.state.studyPhase === '3') {
      phase = 'III'
    } else if (this.state.studyPhase === '4') {
      phase = 'IV'
    } else {
      phase = ''
    }

    return (
      <div className='base-container forPD forBlueBC forImportPopup'>
        {this.state.loading && (
          <div className='spinner-container'>
            <img
              className='small-spinner'
              style={{ height: '50px' }}
              src={require('../images/spinner.gif')}
              alt='Spinner'
            />
          </div>
        )}
        {!this.state.loading && (
          <div className='base-container'>
            <div className='login-container patientDetails'>
              <div className='left-login'>
                <img src={require('../images/logo-w.png')} alt='' />
                <div className='patientDetails__leftText'>
                  <span>The study:</span>

                  <span>
                    {this.state.studyName} # {this.state.studyId} Merck: A Phase{' '}
                    {phase}, {this.state.studyDescription}
                  </span>

                  <span>
                    If you think the patient would be interested in learning
                    more about clinical trials, please confirm or enter the
                    appropriate contact information and press “Submit”
                  </span>

                  <span>
                    If you do not think the patient is interested in learning
                    more about clinical trials, please press "Decline"
                  </span>
                </div>
              </div>

              <div className='right-login'>
                <div className='inputFields'>
                  <h1 className='heading'>Enter Patient Details</h1>

                  <label htmlFor='name' className='text-small'>
                    Name *
                  </label>
                  <div className='input-field'>
                    <input
                      disabled //ghost#temp
                      onChange={this.changeDetails}
                      type='name'
                      className='text-field login'
                      name='name'
                      id='patientName'
                      placeholder=''
                      defaultValue={this.state.patientName}
                    />
                    <img
                      src={require('../images/warning.svg')}
                      alt='Warning'
                      className='img-warning'
                      id='warningEmail'
                    />
                  </div>

                  <label htmlFor='email' className='text-small'>
                    E-mail address *
                  </label>
                  <div className='input-field'>
                    <input
                      onChange={this.changeDetails}
                      type='email'
                      className='text-field login'
                      name='email'
                      id='patientEmail'
                      placeholder=''
                      defaultValue={this.state.patientEmail}
                    />
                    <img
                      src={require('../images/warning.svg')}
                      alt='Warning'
                      className='img-warning'
                      id='warningEmail'
                    />
                  </div>

                  <label htmlFor='phone' className='text-small'>
                    Phone Number *
                  </label>
                  <div className='input-field'>
                    <input
                      disabled //ghost#temp
                      onChange={this.changeDetails}
                      type='text'
                      className='text-field login'
                      name='phone'
                      id='patientPhone'
                      placeholder=''
                      defaultValue={this.state.patientPhone}
                    />
                    <img
                      src={require('../images/warning.svg')}
                      alt='Warning'
                      className='img-warning'
                      id='warningEmail'
                    />
                  </div>
                </div>

                <div className='btnsWrapp'>
                  <div className='decline' onClick={this.decline}>
                    Decline
                  </div>
                  <div className='submit' onClick={this.setPatientDetail}>
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.state.loading && (
          <Popup
            open={this.state.error}
            onClose={this.closeModal}
            className='saveUserPopup'
          >
            <div>
              <div className='saveUserPopupText'>Invalid token</div>
            </div>
          </Popup>
        )}
      </div>
    )
  }
}

export default withRouter(PatientDetails)
