import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Field,
  reduxForm,
  getFormValues,
  getFormSubmitErrors,
  change
} from 'redux-form'
import InmunityApi from '../api/InmunityApi'
import {
  SettingsInput,
  InputWithMask,
  GreenSubmitButton,
  InfoPopup,
  PasswordInput
} from '../ui'
import { validateRegistration as validate } from '../utils'
import i18n from '../i18n'
import './styles/AccountRegistration.css'

const phoneMask = [
  '+',
  '1',
  ' ',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/
]
class AccountRegistration extends Component {
  state = {
    error: '',
    loading: false
  }

  relocateToSuccess() {
    let url = new URL(window.location.href)
    let token = url.searchParams.get('token')
    let company = url.searchParams.get('company')

    if (token && company) {
      let { history } = this.props;
      history.push({
        pathname: `/thank-you/${company}/${token}`,
        token
      });
    }
  }

  componentDidMount() {
    this.relocateToSuccess();
    let url = new URL(window.location)
    let email = url.searchParams.get('email')
    this.props.change('email', email)
  }
  handleSubmit = values => {
    values.preventDefault()

    let { register_data, valid } = this.props
    if (valid) {
      if (register_data.FirstPassword === register_data.SecondPassword) {
        let data = register_data
        data.password = register_data.FirstPassword
        let searchParams = new URLSearchParams(window.location.href);
        const company = searchParams.get('company');
        data.company = company;
        InmunityApi.externalCreateAccount(data)
          .then(async resolve => {
            if (resolve.status !== 201) {
              let res = await resolve.json()
              var re = new RegExp(`'`, 'g')
              let text = JSON.parse(res.responseText.replace(re, `"`))
              let error = ''
              Object.values(text).map(text => (error += text + '\n'))
              return this.setState({ error })
            }
            let { history } = this.props
            history.push({
              pathname: '/thank-you'
            })
          })
          .catch(err => {
            console.log('ERROR WHILE ACCOUNT CREATING: ', err)
          })
      }
    } else {
      this.setState({ error: "Confirm password don't match password" })
    }
  }

  closePopup = () => {
    this.setState({ loading: false, error: '' })
  }

  render() {
    let { error, loading } = this.state

    return (
      <div className='base-container forBlueBC forImportPopup'>
        <InfoPopup
          loading={loading}
          message={error}
          close={this.closePopup}
        />
        <div className='login-container'>
          <div className='left-accountR'>
            <h3 className='title-h3-white accountleftT'>
              {i18n.t('accountRegistration.titleLeft')}
            </h3>
            <h4 className='title-h4-white accountleftSt'>
              {i18n.t('accountRegistration.subTitleLeft')}
            </h4>
            <img
              className='logo-account'
              src={require('../images/logo-w.png')}
              alt='Account Logo'
            />
          </div>
          <form onSubmit={this.handleSubmit} className='right-accountR'>
            <div className='account-fields'>
              <h3 className='title-h3'>
                {i18n.t('accountRegistration.title')}
              </h3>

              <div className='field-section'>
                <Field
                  class_name='two-fields'
                  name='firstName'
                  type='text'
                  component={SettingsInput}
                  label={i18n.t('accountRegistration.firstName')}
                  placeholder={`${i18n.t(
                    'accountRegistration.placeHfirstName'
                  )}`}
                />
                <Field
                  class_name='two-fields'
                  name='lastName'
                  type='text'
                  component={SettingsInput}
                  label={i18n.t('accountRegistration.lastName')}
                  placeholder={`${i18n.t(
                    'accountRegistration.placeHlastName'
                  )}`}
                />
              </div>

              <div className='field-section'>
                <Field
                  class_name='one-field'
                  name='email'
                  type='email'
                  component={SettingsInput}
                  label={i18n.t('accountRegistration.email')}
                  placeholder={`${i18n.t('accountRegistration.placeHemail')}`}
                />
              </div>
              <div className='field-section'>
                <Field
                  class_name='one-field'
                  name='FirstPassword'
                  type='password'
                  component={PasswordInput}
                  label={i18n.t('accountRegistration.password')}
                  placeholder={'•••••'}
                />
              </div>
              <div className='field-section'>
                <Field
                  class_name='one-field'
                  name='SecondPassword'
                  type='password'
                  component={PasswordInput}
                  label={i18n.t('accountRegistration.confirmPassword')}
                  placeholder={'•••••'}
                />
              </div>

              <div className='field-section'>
                <Field
                  class_name='one-field'
                  name='firstPhone'
                  type='phone'
                  component={InputWithMask}
                  label={i18n.t('accountRegistration.phone')}
                  placeholder={`${i18n.t('accountRegistration.placeHphone')}`}
                  mask={phoneMask}
                />
              </div>

              <div className='field-section'>
                <Field
                  class_name='one-field'
                  name='secondPhone'
                  type='phone'
                  component={InputWithMask}
                  label={i18n.t('accountRegistration.phoneTwo')}
                  placeholder={`${i18n.t('accountRegistration.placeHphone')}`}
                  mask={phoneMask}
                />
              </div>
              <GreenSubmitButton
                wrapper_class='account-button__wrapp'
                text={i18n.t('accountRegistration.button')}
                width={134}
              />
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const Registration = reduxForm({
  form: 'register',
  validate
})(AccountRegistration)

const mapStateToProps = state => ({
  register_data: getFormValues('register')(state),
  submitErrors: getFormSubmitErrors('register')(state)
})
const mapDispatchToProps = (dispatch, props) => ({
  change: (name, value) => dispatch(change(props.formName, name, value))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration)
