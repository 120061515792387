import React, { PureComponent } from "react";
import ImageView from "./ImageView";
import Inputs from "./Inputs";
import { withRouter } from "react-router";

class Profile extends PureComponent {
  render() {
    window.scrollTo(0, 0);
    let isEditUser = this.props.location.pathname.endsWith("edituser");
    return (
      <div className="profile">
        <h1 className="profile__title">
          {!isEditUser ? "New User" : "Edit User"}
        </h1>
        <div className="profile__info">
          <ImageView />
          <Inputs newUser={!isEditUser}/>
        </div>
      </div>
    );
  }
}
export default withRouter(Profile);
