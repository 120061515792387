import React, { useState, useRef, useEffect } from "react";
import Popup from "reactjs-popup";
import crossicon from "./../../../images/icon-close-w.svg";
import ImmunityApi from "../../../api/InmunityApi";
import { connect } from "react-redux";
import SearchLabResults from "./SearchLabResults";
import { showPicker } from "../../../actions/trialActions";
import "./index.css";

const LabFilterPicker = ({ token, open, dispatch, setLabValue }) => {
  const [searchValue, setSearchValue] = useState("");
  const [result, setResult] = useState([]);
  const [items, setItems] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [initial, setInitial] = useState(true);
  const inputRef = useRef();

  const searchLab = e => {
    const { value } = e.target;
    if (Array.isArray(result)) {
      if (value) {
        const newItems = result.filter(item => {
          return item.longName.toLowerCase().search(value.toLowerCase()) !== -1;
        });
        setItems(newItems);
      } else {
        setItems(result);
      }
    } else {
      console.log('error, wrong format, not "array"');
    }
    setSearchValue(value);
  };

  const handleKeyPress = e => {
    if (e.keyCode === 13) {
      labSearch();
    }
  };

  const labSearch = () => {
    setSpinner(true);
    ImmunityApi.labPickerSearch(searchValue)
      .then(res => {
        setResult(res);
        setItems(res);
        inputRef.current.value = "";
        setSpinner(false);
        setInitial(false);
      })
      .catch(error => {
        console.log("error occured while getting search results: ", error);
        setSpinner(false);
      });
  };

  const closeLabPopup = () => {
    dispatch(showPicker({ status: false, filter: "", showLab: false }));
  };

  const setLabValueAndClose = obj => {
    setLabValue(obj);
    closeLabPopup();
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();
  }, [open]);

  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={closeLabPopup}
      className="is_popup"
    >
      <div>
        <div className="closePendingPopupCross" onClick={closeLabPopup}>
          <img src={crossicon} alt="Cross" />
        </div>
        <div className="import-container ppWrapper disPWrapp">
          <div className="header_items_sel_pop">
            Please select one of the following options
          </div>
          <div className="trial-search-container space-btw search-lab-container">
            <div className="dis-center forSearchResult">
              <img
                className="icon-left-search"
                src={require("./../../../images/search.svg")}
                alt="Search"
                onClick={labSearch}
              />
              <input
                ref={inputRef}
                type="text"
                className="trial-search-field"
                name="trialName"
                id="trialName"
                placeholder="Search"
                onChange={searchLab}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div
              className={`search-result${
                items.length === 1 ? " plus-margin" : ""
              }`}
            >
              {items.length > 0
                ? items.length === 1
                  ? `${items.length} Result`
                  : `${items.length} Results`
                : null}
            </div>
          </div>
          <SearchLabResults
            result={items}
            spinner={spinner}
            initial={initial}
            setLabValue={setLabValueAndClose}
          />
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = state => ({
  token: state.userToken.token,
  open: state.showPicker.showLab
});

export default connect(mapStateToProps)(LabFilterPicker);
