import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setMedicationsEPCReducer(
  state = initialState.medicationsEPC,
  action
) {
  switch (action.type) {
    case types.SET_MEDICATIONS_EPC:
      return action.medicationsEPC;
    default:
      return state;
  }
}
