import React from "react";
import TrialColor from "./TrialColor";
import InfoInputs from "./InfoInputs";

export default function TrialInfoBlock({
  setInfo,
  defaultColor,
  defaultName,
  nameError,
  defaultNCT,
  nctError,
  defaultBrief,
  defaultLongDescription,
  brief_descriptionError,
  long_descriptionError,
  defaultSponsor,
  defaultPI,
  sponsorError,
  principal_investigatorError
}) {

  return (
    <section id="info">
      <div className="ntw__trialInformation">
        <TrialColor setInfo={setInfo} defaultColor={defaultColor} />
        <InfoInputs
          setInfo={setInfo}
          defaultName={defaultName}
          nameError={nameError}
          defaultNCT={defaultNCT}
          nctError={nctError}
          defaultBrief={defaultBrief}
          brief_descriptionError={brief_descriptionError}
          defaultLongDescription={defaultLongDescription}
          long_descriptionError={long_descriptionError}
          defaultSponsor={defaultSponsor}
          sponsorError={sponsorError}
          defaultPI={defaultPI}
          principal_investigatorError={principal_investigatorError}
        />
      </div>
    </section>
  );
}
