import React from "react";
import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";

export default function TrialDetails({
  searchText = [],
  name = "",
  nct = "",
  brief_description = "",
  long_description = "",
  sponsor = "",
  color = "",
  principal_investigator = {}
}) {
  return (
    <div className="trial-details" id="trials-details-id">
      <h2 className="td-header">
        <Highlighter
          highlightClassName="found"
          searchWords={searchText}
          autoEscape={true}
          textToHighlight="Trial Details"
        />
      </h2>
      <div className="td">
        <div className="wrapForTV">
          <span className="tdtitle">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight="Trial Name"
            />
          </span>
          <span className="tdtitlevalue">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight={name}
            />
          </span>
        </div>
        <div className="wrapForTV">
          <span className="tdtitle">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight="Trial NCT"
            />
          </span>
          <span className="tdtitlevalue">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight={nct !== undefined ? nct : ""}
            />
          </span>
        </div>
      </div>
      <div className="description" style={{ marginBottom: "16px" }}>
        <span className="tdtitle d-title">
          <Highlighter
            highlightClassName="found"
            searchWords={searchText}
            autoEscape={true}
            textToHighlight="Description"
          />
        </span>
        <div className="pln__value d-content">
          <div>
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight={
                brief_description !== undefined ? brief_description : ""
              }
            />
          </div>
        </div>
      </div>
      <div className="description">
        <span className="tdtitle d-title">
          <Highlighter
            highlightClassName="found"
            searchWords={searchText}
            autoEscape={true}
            textToHighlight="Overview"
          />
        </span>
        <div className="pln__value d-content">
          <div>
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight={
                long_description !== undefined ? long_description : ""
              }
            />
          </div>
        </div>
      </div>
      <div className="td">
        <div className="wrapForTV">
          <span className="tdtitle">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight="Sponsor"
            />
          </span>
          <span className="tdtitlevalue">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight={sponsor !== undefined ? sponsor : ""}
            />
          </span>
        </div>
        <div className="wrapForTV">
          <span className="tdtitle">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight="P.I."
            />
          </span>
          <span className="tdtitlevalue">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight={
                Object.keys(principal_investigator).length > 0
                  ? principal_investigator.name
                  : ""
              }
            />
          </span>
        </div>
        <div className="wrapForTV">
          <span className="tdtitle">
            <Highlighter
              highlightClassName="found"
              searchWords={searchText}
              autoEscape={true}
              textToHighlight="Trial color"
            />
          </span>
          <div
            style={{
              backgroundColor: color,
              width: "17px",
              height: "17px",
              borderRadius: "50%"
            }}
          />
        </div>
      </div>
    </div>
  );
}

TrialDetails.propTypes = {
  searchText: PropTypes.array,
  name: PropTypes.string,
  nct: PropTypes.string,
  brief_description: PropTypes.string,
  long_description: PropTypes.string,
  sponsor: PropTypes.string,
  color: PropTypes.string,
  principal_investigator: PropTypes.object
};
