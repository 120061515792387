import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";

import { logOutUser, sendEmailNotification } from "../../actions/trialActions";
import i18n from "../../i18n";
import store from "./../../store";
import Contacts from "./Contacs";
import Notification from "./Notification";

import "../styles/TopBar.css";
import { Spinner } from "./../../ui";
import check from "./../../images/icon-check-green.svg";
import cross from "./../../images/icon-close-grey.svg";
import spinner from "./../../images/spinner.gif";
import defautUserPhoto from "../../images/user-default-photo.jpg";

class TopBar extends React.Component {
  state = {
    dropdown: false,
    dropdownContact: false,
    dropdownNotification: false,
    photoURL: "",
    emailNotification: {}
  };

  logoutUser = () => {
    localStorage.clear();
    this.props.relocate("/");
    this.props.logOutUser();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (Object.keys(nextProps.emailNotification).length !== 0) {
      document.querySelector(".emailNotification").classList.remove("hidden");
      setTimeout(() => {
        document.querySelector(".emailNotification").classList.add("hidden");
      }, 3500);
      store.dispatch(sendEmailNotification({}));
      return {
        emailNotification: nextProps.emailNotification,
        photoURL: nextProps.currentUser.photo
      };
    }
    return {
      photoURL: nextProps.currentUser.photo
    };
  }

  showDropdownContact = () => {
    this.setState({ dropdownContact: true });
  };

  closeDropdownContact = () => {
    this.setState({ dropdownContact: false });
  };

  showDropdown = () => {
    this.setState({ dropdown: true });
  };

  closeDropdown = () => {
    this.setState({ dropdown: false });
  };

  showDropdownNotification = () => {
    this.setState({ dropdownNotification: true });
  };

  closeDropdownNotification = () => {
    this.setState({ dropdownNotification: false });
  };

  render() {
    if (this.props.globalSpinner) return (null);
    return (
      <header className="mdc-toolbar">
        <div className="mdc-toolbar__row">
              <div className="nav-button" onClick={this.showDropdownContact}>
                <img
                  className="navtop-icon"
                  src={require("../../images/people-icon.svg")}
                  alt="People Icon"
                />
              </div>
              <Popup
                open={this.state.dropdownContact}
                closeOnDocumentClick
                onClose={this.closeDropdownContact}
                className="topBarPopup"
              >
                <Contacts closeContacts={this.closeDropdownContact} />
              </Popup>

              <div className="nav-button" onClick={this.showDropdownNotification}>
                <img
                  className="navtop-icon"
                  src={require("../../images/shape.svg")}
                  alt="Shape"
                />
                <img
                  className="navtop-icon-oval"
                  src={require("../../images/oval.svg")}
                  alt="Oval"
                />
              </div>
              <Popup
                open={this.state.dropdownNotification}
                closeOnDocumentClick
                onClose={this.closeDropdownNotification}
                className="topBarPopup"
              >
                <Notification />
              </Popup>
              <div className="nav-user-block">
                <img
                  className="user-avatar"
                  src={this.state.photoURL ? this.state.photoURL : defautUserPhoto}
                  alt="Avatar"
                />
                <div className="nav-user-name">
                  {this.props.currentUser.firstName
                    ? this.props.currentUser.firstName +
                    " " +
                    this.props.currentUser.lastName
                    : ""}
                  <div className="nav-user-company">
                    {" "}
                    {localStorage.getItem("company")}{" "}
                  </div>
                </div>
                <div className="nav-arrow" onClick={this.showDropdown}>
                  <img
                    className="nav-arrow-img"
                    src={require("../../images/arrow-down.svg")}
                    alt="Arrow Down"
                  />
                </div>
              </div>
              <div
                className={
                  this.state.emailNotification.animation
                    ? "emailNotification hidden animatedEN fadeOutRight"
                    : "emailNotification hidden animatedEN fadeOutRight"
                }
              >
                <img
                  src={
                    this.state.emailNotification.success === undefined
                      ? spinner
                      : this.state.emailNotification.success
                        ? check
                        : cross
                  }
                  className="user-avatar"
                  alt="user avatar"
                />
                <div className="emNotBody">
                  <span className="emNotBodyTitle">Email Notification</span>
                  <span className="emNotBodyText">
                    {this.state.emailNotification.messageSuccess}
                  </span>
                </div>
              </div>
              <Popup
                open={this.state.dropdown}
                closeOnDocumentClick
                onClose={this.closeDropdown}
                className="topBarPopup"
              >
                <div className="nav-dropdown">
                  <Link to="/settings/profile">
                    <div
                      className="nav-dropdown-option"
                      onClick={e => {
                        this.setState({ dropdown: !this.state.dropdown });
                      }}
                    >
                      <img
                        className="navtop-icon-dropdown"
                        src={require("../../images/settings-icon.svg")}
                        alt="Settings"
                      />
                      {i18n.t("menu.settings")}
                    </div>
                  </Link>
                  <hr className="nav-s" />
                  <Link
                    to=""
                    onClick={e => {
                      this.logoutUser();
                    }}
                  >
                    <div className="nav-dropdown-option">
                      <img
                        className="navtop-icon-dropdown"
                        src={require("../../images/log-out-icon.svg")}
                        alt="Log Out"
                      />
                      {i18n.t("menu.logOut")}
                    </div>
                  </Link>
                </div>
              </Popup>
        </div>
      </header>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.currentUser,
    emailNotification: state.emailNotification,
    globalSpinner: state.globalSpinner
  };
}

function mapDispatchToProps(dispatch) {
  return {
    relocate: path => dispatch(push(path)),
    logOut: () => dispatch(logOutUser())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TopBar)
);
