import React, { Component } from "react";
import InmunityApi from "../../api/InmunityApi";
import PatientOverview from "./../PatientOverview";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router";
import { MailButton, InfoIcon } from "../../ui";
class AllTrialCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      letterPopup: false,
      notesPopup: false,
      notes: [],
      showOverview: false,
      details: {}
    };
    this.goToDetails = this.goToDetails.bind(this);
  }

  makeTrials = obj => {
    let result = "";
    obj.sort((a, b) => b.matchrate - a.matchrate);
    obj.forEach(trial => {
      let precntage = trial.protocolMatch;
      result += ` ${trial.protocol} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  goToDetails = ({ target }) => {
    if (
      !target.classList.contains("button-center") &&
      !target.closest(".pat-over-wrapp") &&
      (!target.classList.contains("button__icon") &&
        !target.classList.contains("popup-overlay"))
    ) {
      let hadmId = this.props.candidate.hadmId;
      this.redirect(hadmId);
    }
  };

  redirect = hadmId => {
    let { history } = this.props;
    history.push({
      pathname: `/matches/details/${hadmId}`,
      id: hadmId,
      detailTitle: "Candidate Details"
    });
  };

  showLetterPopup = () => {
    let letterPopup = true;
    this.setState({ letterPopup });
  };

  closeLetterPopup = () => {
    let letterPopup = false;
    this.setState({ letterPopup });
  };

  closeTrialPopup = () => {
    let notesPopup = false;
    this.setState({ notesPopup });
  };

  showPatientOverview = () => {
    this.setState({ showOverview: true });
    InmunityApi.getPatientWorkflow(this.props.candidate.hadmId, undefined).then(
      details => {
        this.setState({
          details
        });
      }
    );
  };

  closePatientOverview = () => {
    this.setState({ showOverview: false });
  };

  render() {
    var candidate = this.props.candidate;
    var trials = this.makeTrials(this.props.candidate.protocol);
    var contactConsent = candidate.contactConsent;
    var proceedToTrial = candidate.proceedToTrial;
    let name = candidate.name;
    // if (candidate.contactNotes && candidate.contactNotes.length > 1) {
    //   var contactNotes = (
    //     <div>
    //       <img
    //         className="candidate-icon"
    //         src={require("../../images/info.png")}
    //         alt={candidate.contactNotes}
    //       />
    //       <div className="image-text">{candidate.contactNotes}</div>
    //     </div>
    //   );
    // }

    var le = candidate.lastSeen;
    if (le) {
      le = new Date(le).toLocaleDateString("en-us");
    }
    var uv = candidate.upcomingVisit;
    if (uv) {
      uv = new Date(uv).toLocaleDateString("en-us");
    }

    return (
      <div className="candidatesWr allCand" onClick={this.goToDetails}>
        <div className="candidates hadmId forACTooltip">
          <span className="imm-id">Candidate Information</span>
          <span className="td-content">{candidate.immunity}</span>
          <span className="td-content">{name}</span>
          {candidate.protocol.length !== 0 && (
            <span
              className="td-content candidatesAmTrial"
              data-tip={trials}
              data-for={this.props.id}
              data-class="toolTip"
              data-multiline
            >
              {candidate.protocol.length > 1
                ? "Candidate for " + candidate.protocol.length + " Trials"
                : ""}
              {candidate.protocol.length === 1 &&
                "Candidate for " + candidate.protocol.length + " Trial"}
            </span>
          )}
          <ReactTooltip
            id={this.props.id}
            place="bottom"
            type="light"
            effect="solid"
          />
        </div>
        <div className="candidates forlsuv">
          <div className="c-lastSeen">
            <span>Last Seen</span>
            <span className="td-content">{le || ''}</span>
          </div>
          <div className="c-upcomingVisit">
            <span>Upcoming Visit</span>
            <span className="td-content">{uv || "-"}</span>
          </div>
        </div>
        <div className="candidates c-physician">
          <span className="forIcon">
            {candidate.physician.length === 1 ? "Physician" : "Physicians"}
          </span>
          <span className="td-content">
            {candidate.physician.map((el, index) => {
              return (
                <span key={index} className="pad0">
                  {el}
                </span>
              );
            })}
          </span>
        </div>
        <div
          className={
            "candidates " +
            (contactConsent ? "contact-consent-can" : "non-contact-consent-can")
          }
        >
          <span>Contact Consent</span>
        </div>
        <div
          className={
            "candidates " +
            (proceedToTrial ? "proceed-to-trial" : "non-proceed-to-trial")
          }
        >
          <span>Proceed to Screening</span>
          <div className="forTwoIcons">
            <InfoIcon click={this.showPatientOverview} />
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            <MailButton click={this.showLetterPopup} />
            {/* <Popup
              open={this.state.letterPopup}
              closeOnDocumentClick
              onClose={this.closeLetterPopup}
            >
              <EmailPopup
                candidate={candidate}
                currentUser={this.props.currentUser}
                closePopup={this.closeLetterPopup}
              />
            </Popup> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AllTrialCandidate);
