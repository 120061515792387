import React, { Fragment } from "react";

export default function PhaseOfStudy({ setInfo, defaultPhase, phaseError }) {
  const setPhase = item => {
    let newPhase = [...defaultPhase];
    if (newPhase.includes(item)) {
      for (let key in newPhase) {
        if (newPhase[key] === item) {
          newPhase.splice(key, 1);
        }
      }
    } else if (newPhase.length < 2) {
      if (Math.abs(newPhase[0] - item) !== 1) {
        newPhase.length = 0;
      }
      newPhase.push(item);
    } else {
      newPhase.length = 0;
      newPhase.push(item);
    }
    setInfo("phase", newPhase);
  };

  return (
    <Fragment>
      <span className="filters-sexes__message">Phase of study</span>
      <div
        className={
          phaseError
            ? "filters-sexes_container redBorder"
            : "filters-sexes_container"
        }
      >
        <div
          className={
            defaultPhase.includes(1)
              ? "filters-sexes_element get_phase active_sex"
              : "filters-sexes_element get_phase"
          }
          onClick={() => setPhase(1)}
        >
          Phase I
        </div>
        <div
          className={
            defaultPhase.includes(2)
              ? "filters-sexes_element get_phase active_sex"
              : "filters-sexes_element get_phase"
          }
          onClick={() => setPhase(2)}
        >
          Phase II
        </div>
        <div
          className={
            defaultPhase.includes(3)
              ? "filters-sexes_element get_phase active_sex"
              : "filters-sexes_element get_phase"
          }
          onClick={() => setPhase(3)}
        >
          Phase III
        </div>
        <div
          className={
            defaultPhase.includes(4)
              ? "filters-sexes_element get_phase active_sex"
              : "filters-sexes_element get_phase"
          }
          onClick={() => setPhase(4)}
        >
          Phase IV
        </div>
      </div>
    </Fragment>
  );
}
