import React, { Component } from "react";
import Popup from "reactjs-popup";
import { withRouter } from "react-router";
import InmunityApi from "../../api/InmunityApi";
import PermissionList from "./PermissionList";
import "./index.css";
import crossicon from "../../images/icon-close-w.svg";
import { connect } from "react-redux";
import checkPermission from '../../utils/checkPermission'
import { getUserSuccess } from '../../actions/trialActions'
import store from "../../store";
import { setUserPhoto, getRoles } from "../../actions/trialActions";

import { fromJS } from "immutable";

const AdminNewUser = ({ pathname, newUserRedirect, newUserRole }) => {

  let data = null;

  if (!checkPermission()) {
    return null;
  }
  if (pathname.endsWith("users")) {
    data =
      <div className="new-user-btn">
        <span className="new-user-text">New User</span>
        <div
          className="mdForHeaderAndArrow__btn newUser-btn"
          onClick={newUserRedirect}
        >
          +
    </div>
      </div>
  }
  else if (pathname.endsWith("permission")) {
    data =
      <div className="new-user-btn">
        <span className="new-user-text">Create role</span>
        <div
          className="mdForHeaderAndArrow__btn newUser-btn"
          onClick={newUserRole}
        >
          +
              </div>
      </div>
  }
  return (data)
}


const AdminEditUser = ({ pathname, saveChanges, deleteUserPopup }) => {
  return (
    pathname.endsWith("edituser") && checkPermission() &&
    (
      <>
        <div
          className="mdForHeaderAndArrow__btn del-user"
          onClick={deleteUserPopup}
        >
          Delete User
      </div>
        <div
          className="mdForHeaderAndArrow__btn"
          onClick={saveChanges}
        >
          Save Changes
      </div>
      </>
    )
  )
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savePopup: false,
      deletePopup: false,
      open: false,
      validEmail: false,
      validPhone: false,
      validPassword: false,
      errorMessage: "",
      regexEmail: /^([a-zA-Z0-9_])+(([a-zA-Z0-9])+)+([a-zA-Z0-9]{2,4})+$/,
      regexPhone: /1[0-9]{10}/,
      createUser: false,
      editAccount: false,
      message: ""
    };
  }

  showDeletePopup = () => {
    this.setState({
      deletePopup: true
    });
  };

  closeDeletePopup = () => {
    this.setState({
      deletePopup: false
    });
  };

  closeSavePopup = () => {
    this.setState({
      savePopup: false
    });
  };

  newUserRedirect = () => {
    let { history } = this.props;
    history.push({ pathname: "/settings/users/newuserinvite" });
  };

  newUserRole = () => {
    localStorage.removeItem("roleName");
    let open = true;
    this.setState({ open });
  };

  saveChanges = () => {
    let inputs = document.querySelectorAll(".forSending");
    inputs.forEach(input => {
      // check for inputs
      if (input.value === "") {
        if (this.props.location.pathname.endsWith("edituser")) {
          if (input.id !== "password") {
            input.classList.add("redBorder");
          }
        } else {
          input.classList.add("redBorder");
        }
      } else {
        input.classList.remove("redBorder");
      }
    });
    if (this.props.location.pathname.endsWith("profile")) {
      if (document.querySelectorAll(".redBorder").length === 0) {
        this.setState({
          savePopup: true,
          editAccount: true,
          message: "Please confirm to save changes"
        });
      }
    } else if (this.props.location.pathname.endsWith("newuser")) {
      if (document.querySelectorAll(".redBorder").length === 0) {
        this.setState({
          savePopup: true,
          createUser: true,
          message: "Please confirm to save"
        });
      }
    } else if (this.props.location.pathname.endsWith("edituser")) {
      if (document.querySelectorAll(".redBorder").length === 0) {
        this.setState({
          savePopup: true,
          createUser: true,
          message: "Please confirm to edit user"
        });
      }
    }
  };

  deleteUserPopup = () => {
    this.setState({
      deletePopup: true,
      editAccount: true,
      message: "Confirm to delete current user"
    });
  };

  getUserInfo = () => {
    //ghost#debug
    InmunityApi
      .getUser()
      .then(user => {
        store.dispatch(getUserSuccess(user));
      })
      .catch(error => {
        throw error;
      });
  }

  closeModal = () => {
    let open = false;
    this.setState({ open, message: "" });
  };

  addRole = () => {
    let sendObject = {};
    let val = document.querySelector(".role-popup__input").value;
    let obj = localStorage.getItem("permissionObj")
      ? JSON.parse(localStorage.getItem("permissionObj"))
      : {};
    sendObject = { ...obj };
    sendObject.name = val;
    if (val !== "") {
      document
        .querySelector(".role-popup__input")
        .classList.remove("redBorder");
      InmunityApi.createNewRole(sendObject)
        .then(resolve => {
          if (resolve.status !== 201) {
            return resolve.text().then(text => {
              if (text === '{"name":["role with this name already exists."]}') {
                this.setState({
                  savePopup: true,
                  message: `Role with name ${sendObject.name} already exists.
                  Please choose a different name for this role.`
                });
              } else {
                this.setState({
                  message: "An error occurred while saving"
                });
              }
              throw new Error(text);
            });
          } else {
            this.props.newRoleNameEmitter(val, obj);
            store.dispatch(getRoles());
            let open = false;
            this.setState({ open }, () => {
              localStorage.removeItem("permissionObj");
            });
          }
        })
        .catch(error =>
          console.log("Error occured while creating new role", error)
        );
    } else {
      document.querySelector(".role-popup__input").classList.add("redBorder");
    }
  };

  sendData = () => {
    // show spinner
    this.setState({ message: "spinner" });

    if (this.state.editAccount) {
      let objectMyProfile = JSON.parse(localStorage.getItem("myProfile"));

      delete objectMyProfile.photo;

      if (this.props.profilePhoto.name) {
        let formData = new FormData();
        formData.append("file", this.props.profilePhoto);
        let id = this.props.currentUser.id;

        let xhr = new XMLHttpRequest();
        xhr.open("PATCH", `${process.env.REACT_APP_API_PATH}/users/uploadPhoto/${id}/`, true);
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.props.userToken.token}`
        );
        xhr.setRequestHeader('SCHEMA', 'public');
        xhr.send(formData);
        //InmunityApi.sendProfilePhoto(formData, id, this.props.userToken.token)
      }
      InmunityApi.editAccount(objectMyProfile)
        .then(resolve => {
          if (resolve.status !== 200) {
            this.setState({ message: "An error occurred while saving" });
            throw resolve;
          }
          this.setState({ message: "Profile edited successfully" });
          this.getUserInfo();
        })
        .catch(err => {
          console.log("ERROR EDITING PROFILE: ", err);
        });
      this.setState({ editAccount: false });
    } else if (this.state.createUser) {
      let newUser = {};
      newUser.firstName = document.getElementById("firstName").value;
      newUser.lastName = document.getElementById("lastName").value;
      newUser.email = document.getElementById("email").value;
      newUser.firstPhone = document
        .getElementById("firstPhone")
        .value.split(" ")
        .map(el => {
          if (el[0] === "(") {
            return el.slice(1, -1);
          }
          return el;
        })
        .join("");
      newUser.secondPhone = document
        .getElementById("secondPhone")
        .value.split(" ")
        .map(el => {
          if (el[0] === "(") {
            return el.slice(1, -1);
          }
          return el;
        })
        .join("");
      newUser.password = document.getElementById("password").value;
      newUser.role = document.getElementById("role").value;

      InmunityApi.createAccount(newUser)
        .then(response => {
          if (response.status !== 200) {
            this.setState({
              message: "An error occurred while creating new account"
            });
            throw response;
          }
          this.setState({ message: "Profile created successfully" });
          return response.json();
        })
        .then(user_id => {
          if (this.props.newUserPhoto.name) {
            let formData = new FormData();
            formData.append("file", this.props.newUserPhoto);
            let id = user_id.user_id;

            let xhr = new XMLHttpRequest();
            xhr.open("PATCH", `${process.env.REACT_APP_API_PATH}/users/uploadPhoto/${id}/`, true);
            xhr.setRequestHeader(
              "Authorization",
              `Bearer ${localStorage.getItem("token")}`
            );
            xhr.setRequestHeader('SCHEMA', 'public');
            xhr.send(formData);
          }
        })
        .catch(err => {
          console.log("ERROR SAVING PROFILE: ", err);
        });
      this.setState({ createUser: false });
    }
  };

  deleteUser = () => {
    // show spinner
    this.setState({ message: "spinner" });

    let user = JSON.parse(localStorage.getItem("infoAboutUser"));
    let userId = user.id;
    InmunityApi.deleteUserById(userId)
      .then(response => {
        if (response.status !== 204) {
          this.setState({
            message: "An error occurred while deleting current user"
          });
          throw response;
        }
        this.setState({ message: "User deleted successfully" });
        this.props.history.push({ pathname: "/settings/users" });
      })
      .catch(error => {
        throw new Error(error);
      });
  };

  editUser = () => {
    // show spinner
    this.setState({ message: "spinner" });

    let user = JSON.parse(localStorage.getItem("infoAboutUser"));
    let userId = user.id;
    user.role = user.role.id;

    if (this.props.userPhoto.name) {
      let formData = new FormData();
      formData.append("file", this.props.userPhoto);
      let xhr = new XMLHttpRequest();
      xhr.open("PATCH", `${process.env.REACT_APP_API_PATH}/users/uploadPhoto/${userId}/`, true);
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.props.userToken.token}`
      );
      xhr.setRequestHeader('SCHEMA', 'public');
      xhr.send(formData);
      xhr.onerror = e => {
        console.log(
          "Error " + e.target.status + " occurred while changing photo."
        );
        this.setState({ message: "An error occurred while changing photo" });
      };
      this.props.dispatch(setUserPhoto(""));
    }


    InmunityApi.editUserRole(userId, user.role)
      .then(resolve => {
        if (resolve.status !== 200) {
          this.setState({ message: "An error occurred while editing" });
          throw resolve;
        }
        this.setState({ message: "Users profile edited successfully" });
      })
      .catch(err => {
        console.log("ERROR EDITING USERS ROLE: ", err);
      });


    if (window.location.pathname !== '/settings/users/edituser') {
      InmunityApi.editUserById(user, userId)
        .then(resolve => {
          if (resolve.status !== 200) {
            this.setState({ message: "An error occurred while editing" });
            throw resolve;
          }
          this.setState({ message: "Users profile edited successfully" });
        })
        .catch(err => {
          console.log("ERROR EDITING USERS PROFILE: ", err);
        });
      this.setState({ editAccount: false });
    };
  }


  render() {
    return (
      <div className="header">
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closeModal}
          className="popup-newtrial role-popup"
        >
          <div>
            <div className="closePendingPopupCross" onClick={this.closeModal}>
              <img src={crossicon} alt="Cross" />
            </div>
            <div className="ntw__ti-inputs">
              <h1>New role</h1>
              <input
                type="text"
                className="TrialInformationWrapper role-popup__input"
              />
              <PermissionList />
              <div className="role__container">
                <button
                  className="search-container__plusbtn"
                  onClick={this.addRole}
                >
                  Add Role
                </button>
              </div>
            </div>
          </div>
        </Popup>
        <div className="header__first">
          <img
            src={require("../../images/left.png")}
            onClick={() => {
              this.props.history.push("/dashboard");
            }}
            className="header__back"
            alt="Left Icon"
          />
          <span className="header__title">Settings</span>
        </div>
        <div className="messageAccount">{this.state.errorMessage}</div>
        <div className="header_btns">
          {
            <AdminEditUser
              pathname={this.props.location.pathname}
              deleteUserPopup={this.deleteUserPopup}
              saveChanges={this.saveChanges}
            />
          }

          {!this.props.location.pathname.endsWith("permission") &&
            !this.props.location.pathname.endsWith("users") &&
            !this.props.location.pathname.endsWith("edituser") &&
            !this.props.location.pathname.endsWith("notification") &&
            !this.props.location.pathname.endsWith("newuserinvite") && (
              <div
                className="mdForHeaderAndArrow__btn"
                onClick={this.saveChanges}
              >
                Save Changes
              </div>
            )}

          <AdminNewUser
            pathname={this.props.location.pathname}
            newUserRedirect={this.newUserRedirect}
            newUserRole={this.newUserRole}
          />

          <Popup
            open={this.state.savePopup}
            closeOnDocumentClick
            onClose={this.closeSavePopup}
            className="saveUserPopup"
          >
            <div>
              <div
                className="closePendingPopupCross"
                onClick={this.closeSavePopup}
              >
                <img src={crossicon} alt="Cross" />
              </div>
              {this.state.message === "spinner" ? (
                <h1 className="header-spinner">
                  <img
                    className="small-spinner"
                    src={require("../../images/spinner.gif")}
                    alt=""
                  />
                </h1>
              ) : (
                  <div className="saveUserPopupText">{this.state.message}</div>
                )}
              {this.state.message === "Please confirm to save changes" ||
                this.state.message === "Please confirm to save" ? (
                  <div className="newTrialPopupWrapper">
                    <div className="forNTWBtns">
                      <div className="fromScratch" onClick={this.sendData}>
                        Confirm
                    </div>
                      <div className="fromTemplate" onClick={this.closeSavePopup}>
                        Cancel
                    </div>
                    </div>
                  </div>
                ) : null}
              {this.state.message === "Please confirm to edit user" && (
                <div className="newTrialPopupWrapper">
                  <div className="forNTWBtns">
                    <div className="fromScratch" onClick={this.editUser}>
                      Confirm
                    </div>
                    <div className="fromTemplate" onClick={this.closeSavePopup}>
                      Cancel
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Popup>
          <Popup
            open={this.state.deletePopup}
            closeOnDocumentClick
            onClose={this.closeDeletePopup}
            className="deletePopup"
          >
            {this.state.message === "spinner" ? (
              <div>
                <div
                  className="closePendingPopupCross"
                  onClick={this.closeDeletePopup}
                >
                  <img src={crossicon} alt="Cross" />
                </div>
                <h1 className="header-spinner">
                  <img
                    className="small-spinner"
                    src={require("../../images/spinner.gif")}
                    alt=""
                  />
                </h1>
              </div>
            ) : this.state.message ===
              "An error occurred while deleting current user" ||
              this.state.message === "User deleted successfully" ? (
                  <div>
                    <div
                      className="closePendingPopupCross"
                      onClick={this.closeDeletePopup}
                    >
                      <img src={crossicon} alt="Cross" />
                    </div>
                    <div className="saveUserPopupText">{this.state.message}</div>
                  </div>
                ) : (
                  <div className="deletePopupWrapp">
                    <h1>You’re going to delete an existing user.</h1>
                    <p>Are you sure you want to do this?</p>
                    <span>This action is permanent and cannot be undone.</span>
                    <div className="forDeleteBtns">
                      <div className="cancelBtn" onClick={this.closeDeletePopup}>
                        Cancel
                  </div>
                      <div className="deleteBtn" onClick={this.deleteUser}>
                        Delete
                  </div>
                    </div>
                  </div>
                )}
          </Popup>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userToken,
    profilePhoto: state.profilePhoto,
    userPhoto: state.userPhoto,
    currentUser: state.currentUser,
    newUserPhoto: state.newUserPhoto
  };
}

export default withRouter(connect(mapStateToProps)(Header));
