import React, { Component } from "react";
import ImmunityApi from "./../../../../api/InmunityApi";
import iconCollapse from "./../../../../images/arrow-collapse.svg";
import { connect } from "react-redux";
import IncludeExclude from "./IncludeExclude";
import {
  setMedicationsATCList,
  setMedicationsEPCList,
  setMedicationsMOAList,
  setDiseasesDOList,
  setProceduresList,
  setMedicationsATC,
  setMedicationsEPC,
  setMedicationsMOA,
  setDiseasesDO,
  setProcedures,
  setDiseasesICD9CMList,
  setDiseasesICD9CM,
  setPickerLabel
} from "../../../../actions/trialActions";
import capitalize from "./../../../../utils/capitalize";

class TreeComponent extends Component {
  constructor() {
    super();
    this.state = {
      checkInclude: false,
      checkExclude: false,
      initial: true,
      medications: [],
      show: false,
      showDisList: false,
      ids: []
    };
    this.myRef = React.createRef();
    this.showDisRef = React.createRef();
  }

  componentDidMount() {
    const { open, search, searchCategory } = this.props;
    open && this.setState({ show: true });
    search && this.showDisRef.current.click();
    searchCategory &&
      this.showDisRef.current.parentNode.parentNode.parentNode.classList.add(
        "activeValue"
      );
  }


  scrollView = (active) => {
    if (!this.props.myRef || !this.props.myRef.current || !active) {
      return;
    }

    let element = this.props.myRef.current;
    let interval = window.setInterval(
      function () {
        element.scrollTop += 10;
        if (active.offsetTop - element.clientHeight + 50 <= element.scrollTop) {
          window.clearInterval(interval);
        }
      }, 5
    )
  }

  makeActive = (event, id) => {
    if (this.props.selectedView) return;
    const { makeActive, activeTab } = this.props;
    const active = document.querySelector(".activeValue");
    this.scrollView(active);
    active && active.classList.remove("activeValue");
    event.classList.add("activeValue");
    makeActive(id, activeTab);
  };

  checkLeafs = (include, exclude, id) => {
    const {
      filter,
      activeTab,
      diseasesDOList,
      diseasesICD9CMList,
      medicationsATCList,
      medicationsEPCList,
      medicationsMOAList,
      proceduresList
    } = this.props;
    const incl = include === true ? true : exclude === true ? false : "delete";
    let list;
    if (filter === "Medication Filters") {
      activeTab === 0 && (list = [...medicationsATCList]);
      activeTab === 1 && (list = [...medicationsEPCList]);
      activeTab === 2 && (list = [...medicationsMOAList]);
    } else if (filter === "Disease Filters") {
      activeTab === 0 && (list = [...diseasesDOList]);
      activeTab === 1 && (list = [...diseasesICD9CMList]);
    } else if (filter === "Procedure History") {
      list = [...proceduresList];
    }
    this.checkLeafsFiller(list, incl, id);
  };

  checkLeafsFiller = (list, incl, id) => {
    const { setArrayOfCodes, activeTab } = this.props;
    list.forEach(el => {
      if (el.id === id) {
        const itemsLength = el.leafs.length;
        const leafs = el.leafs.map(el => ({
          ...el,
          itemsLength,
          include: incl,
          activeTab
        }));
        setArrayOfCodes(leafs);
      }
    });
  };

  showDiseases = (e, id, showMore = false, ...rest) => {
    if (this.props.selectedView) return;
    const eve = showMore ? e : e.closest(".items-wrapp");
    const [incl, excl] = rest;
    let {
      medicationsATCList,
      medicationsEPCList,
      medicationsMOAList,
      diseasesDOList,
      diseasesICD9CMList,
      proceduresList,
      activeTab,
      showPicker: { filter },
      dispatch,
      setCategoryId,
      setSpinner,
      isLeaf
    } = this.props;
    if (filter === "Medication Filters") {
      let names, list;
      if (activeTab === 0) {
        list = [...medicationsATCList];
      } else if (activeTab === 1) {
        list = [...medicationsEPCList];
      } else if (activeTab === 2) {
        list = [...medicationsMOAList];
      }
      names = list.filter(el => el.id === id);
      if (!isLeaf) {
        list.push({ id, leafs: [] });
        if (activeTab === 0) {
          dispatch(setMedicationsATCList(list));
        } else if (activeTab === 1) {
          dispatch(setMedicationsEPCList(list));
        } else if (activeTab === 2) {
          dispatch(setMedicationsMOAList(list));
        }
      } else {
        if (names.length === 0) {
          setSpinner(true);
          ImmunityApi.getMedicationsLeaf(id)
            .then(leafs => {
              dispatch(setPickerLabel(leafs[0].label));
              list.push({ id, leafs });
              if (activeTab === 0) {
                dispatch(setMedicationsATCList(list));
              } else if (activeTab === 1) {
                dispatch(setMedicationsEPCList(list));
              } else if (activeTab === 2) {
                dispatch(setMedicationsMOAList(list));
              }
              if (incl !== undefined && excl !== undefined) {
                this.checkLeafs(incl, excl, id);
              }
            })
            .catch(e => console.log("Error getting leaf: ", e));
        } else {
          if (incl !== undefined && excl !== undefined) {
            this.checkLeafs(incl, excl, id);
          }
        }
      }
    } else if (filter === "Disease Filters") {
      let list, names;
      if (activeTab === 0) {
        list = [...diseasesDOList];
      } else if (activeTab === 1) {
        list = [...diseasesICD9CMList];
      }
      names = list.filter(el => el.id === id);
      if (isLeaf) {
        if (names.length === 0) {
          setSpinner(true);
          ImmunityApi.getDiseasesLeaf(id, this.props.pickerLabel)
            .then(leafs => {
              dispatch(setPickerLabel(leafs[0].label));
              list.push({ id, leafs });
              if (activeTab === 0) {
                dispatch(setDiseasesDOList(list));
              } else if (activeTab === 1) {
                dispatch(setDiseasesICD9CMList(list));
              }
              if (incl !== undefined && excl !== undefined) {
                this.checkLeafs(incl, excl, id);
              }
            })
            .catch(e => console.log("Error getting leaf: ", e));
        } else {
          if (incl !== undefined && excl !== undefined) {
            this.checkLeafs(incl, excl, id);
          }
        }
      } else {
        list.push({ id, leafs: [] });
        if (activeTab === 0) {
          dispatch(setDiseasesDOList(list));
        } else if (activeTab === 1) {
          dispatch(setDiseasesICD9CMList(list));
        }
      }
    } else if (filter === "Procedure History") {
      let list = [...proceduresList];
      let names = list.filter(el => el.id === id);
      if (isLeaf) {
        if (names.length === 0) {
          setSpinner(true);
          ImmunityApi.getProceduresLeaf(id)
            .then(leafs => {
              dispatch(setPickerLabel(leafs[0].label));
              list.push({ id, leafs });
              dispatch(setProceduresList(list));
              if (incl !== undefined && excl !== undefined) {
                this.checkLeafs(incl, excl, id);
              }
            })
            .catch(e => console.log("Error getting leaf: ", e));
        } else {
          if (incl !== undefined && excl !== undefined) {
            this.checkLeafs(incl, excl, id);
          }
        }
      } else {
        list.push({ id, leafs: [] });
        dispatch(setProceduresList(list));
      }
    }
    setCategoryId(id);
    this.makeActive(eve, id);
  };
  showMore = (e, id) => {
    let {
      medicationsATC,
      medicationsEPC,
      medicationsMOA,
      diseasesDO,
      diseasesICD9CM,
      diseasesPicker,
      proceduresPicker,
      activeTab,
      showPicker: { filter },
      dispatch,
      setCategorySpinner
    } = this.props;
    const event = e.target.nextSibling;
    const { show } = this.state;
    const { current } = this.myRef;
    let showTrue = !show;
    current.classList.toggle("arrow-more");

    this.showDiseases(event, id, true);

    if (showTrue) {
      setCategorySpinner && setCategorySpinner(true);
      if (filter === "Medication Filters") {
        let list;
        if (activeTab === 0) {
          list = [...medicationsATC];
        } else if (activeTab === 1) {
          list = [...medicationsEPC];
        } else if (activeTab === 2) {
          list = [...medicationsMOA];
        }
        ImmunityApi.getMedicationsChildren(id)
          .then(medications => { 
            this.makeList(list, id, medications);
            if (activeTab === 0) {
              dispatch(setMedicationsATC(list));
            } else if (activeTab === 1) {
              dispatch(setMedicationsEPC(list));
            } else if (activeTab === 2) {
              dispatch(setMedicationsMOA(list));
            }
            setCategorySpinner(false);
          })
          .catch(e => console.log("Error getting children: ", e));
      } else if (filter === "Disease Filters") {
        let list;
        if (activeTab === 0) {
          list = [...diseasesDO];
        } else if (activeTab === 1) {
          list = [...diseasesICD9CM];
        }
        console.log("picker = ", this.props.pickerLabel);
        ImmunityApi.getDiseasesChildren(id, this.props.pickerLabel)
          .then(diseases => {
            this.makeList(list, id, diseases);
            if (activeTab === 0) {
              dispatch(setDiseasesDO(list));
            } else {
              dispatch(setDiseasesICD9CM(list));
            }
            setCategorySpinner(false);
          })
          .catch(e => console.log("Error getting children: ", e));
      } else if (filter === "Procedure History") {
        let list = [...proceduresPicker];
        ImmunityApi.getProceduresChildren(id)
          .then(procedures => {
            this.makeList(list, id, procedures);
            dispatch(setProcedures(list));
            setCategorySpinner(false);
          })
          .catch(e => console.log("Error getting children: ", e));
      }
    } else {
      let list;
      if (filter === "Medication Filters") {
        if (activeTab === 0) {
          list = [...medicationsATC];
          this.removeElements(list, id);
          dispatch(setMedicationsATC(list));
        } else if (activeTab === 1) {
          list = [...medicationsEPC];
          this.removeElements(list, id);
          dispatch(setMedicationsEPC(list));
        } else if (activeTab === 2) {
          list = [...medicationsMOA];
          this.removeElements(list, id);
          dispatch(setMedicationsMOA(list));
        }
      } else if (filter === "Disease Filters") {
        if (activeTab === 0) {
          list = [...diseasesDO];
          this.removeElements(list, id);
          dispatch(setDiseasesDO(list));
        } else {
          list = [...diseasesICD9CM];
          this.removeElements(list, id);
          dispatch(setDiseasesICD9CM(list));
        }
      } else if (filter === "Procedure History") {
        list = [...proceduresPicker];
        this.removeElements(list, id);
        dispatch(setProcedures(list));
      }
    }
    this.setState({ show: showTrue });
    this.makeActive(event, id);
  };
  makeList = (array, id, data) => {
    for (let el of array) {
      if (el.id === id) {
        el.children = data;
      } else {
        if (el.children) {
          this.makeList(el.children, id, data);
        }
      }
    }
    return array;
  };
  removeElements = (array, id) => {
    let resArray = array.map(el => {
      if (el.id === id) {
        el.children = undefined;
      } else {
        if (el.children) {
          this.removeElements(el.children, id);
        }
      }
      return null;
    });
    return resArray;
  };
  render() {
    const {
      name,
      id,
      code,
      children,
      selectedView,
      lastLevel,
      search,
      open,
      active,
      searchCategory,
      filter,
      activeTab,
      include,
      categoryId,
      makeCheck,
      isLeaf,
      rootId,
      setCodes
    } = this.props;

    return (
      <li className="flex">
        {children && (
          <img
            src={iconCollapse}
            alt="icon-collapse"
            className={open ? "arrow-collapse arrow-more" : "arrow-collapse"}
            onClick={e => this.showMore(e, id)}
            ref={this.myRef}
          />
        )}
        <div
          className={
            active || search || searchCategory
              ? "items-wrapp activeValue"
              : "items-wrapp"
          }
        >
          <div
            className={`${
              selectedView ? "wrapp-cat wrapp-item-view" : "wrapp-cat"
              }`}
          >
            <div className="cat-wrapp">
              <span
                className="search-value"
                onClick={e => this.showDiseases(e.target, id)}
                style={{
                  paddingLeft: children ? 0 : "7px",
                  maxWidth: selectedView ? "none" : "18vw",
                  fontWeight: lastLevel ? "normal" : "bold"
                }}
                ref={this.showDisRef}
              >
                {filter === "Medication Filters" ? capitalize(name) : name}
              </span>
              {(search || searchCategory) && <div className="search-ball" />}
            </div>
            <IncludeExclude
              setCodes={setCodes}
              id={id}
              hasChildren={children}
              hasLeafs={isLeaf}
              code={code}
              filter={filter}
              activeTab={activeTab}
              include={include}
              name={name}
              categoryId={categoryId}
              makeCheck={makeCheck}
              showDiseases={this.showDiseases}
              parentId={rootId}
              checkLeafs={this.checkLeafs}
            />
          </div>
        </div>
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {
    medicationsATC: state.medicationsATC,
    medicationsEPC: state.medicationsEPC,
    medicationsMOA: state.medicationsMOA,
    diseasesDO: state.diseasesDO,
    diseasesDOList: state.diseasesDOList,
    diseasesICD9CMList: state.diseasesICD9CMList,
    diseasesICD9CM: state.diseasesICD9CM,
    diseasesPicker: state.diseasesPicker,
    pickerLabel: state.pickerLabel,
    proceduresPicker: state.proceduresPicker,
    medicationsATCList: state.medicationsATCList,
    medicationsEPCList: state.medicationsEPCList,
    medicationsMOAList: state.medicationsMOAList,
    diseasesList: state.diseasesList,
    proceduresList: state.proceduresList,
    showPicker: state.showPicker
  };
}

export default connect(mapStateToProps)(TreeComponent);
