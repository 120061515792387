import React, { Component } from "react";
import "create-react-class";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import LabTooltip from "../LabFilters/LabTooltip";
import DiseasesTooltip from "../DiseasesTooltip";
import PhysiciansTooltip from "../PhysiciansTooltip";
import MedicationTooltip from "../MedicationFilters/MedicationTooltip";
import ProceduresTooltip from "./../Procedure/ProceduresTooltip";
import moment from "moment";
import { connect } from "react-redux";
import "react-day-picker/lib/style.css";
import InmunityApi from "../../../../api/InmunityApi";
import ReactSlider from "react-slider";

const proceduresOptions = [
  { value: "Include", label: "Include" },
  { value: "Exclude", label: "Exclude" }
];

const testOptions = [
  { value: "medication", label: "Medication Filters" },
  { value: "disease", label: "Disease Filters" },
  { value: "lab", label: "Lab Filters" },
  { value: "procedure", label: "Procedure History" }
];

const medicOptions = [
  { value: "Include", label: "Include" },
  { value: "Exclude", label: "Exclude" }
];

const diseaseOptions = [
  { value: "Include", label: "Include" },
  { value: "Exclude", label: "Exclude" }
];

const rangeOptions = [
  { value: "GreaterThan", label: "Greater than" },
  { value: "SmallerThan", label: "Smaller than" },
  { value: "Between", label: "Between" }
];

const dateRangeOptions = [
  { value: "Include", label: "Include" },
  { value: "Exclude", label: "Exclude" }
];

const priorityOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" }
];

const searchOptions = [
  { value: "medication", label: "Medication Filters" },
  { value: "disease", label: "Disease Filters" },
  { value: "lab", label: "Lab Filters" },
  { value: "procedure", label: "Procedure History" },
  { value: "immunity_id", label: "ID Number/Name" },
  { value: "geography", label: "Geography" },
  { value: "physician", label: "Physician" },
  { value: "demographic", label: "Demographic" }
];

const sexOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "both", label: "Both" }
];

class ELBlockInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testOptions: this.props.searchOptions
        ? [...searchOptions]
        : [...testOptions],
      includeOption: this.props.include
        ? { value: this.props.include, label: this.props.include }
        : { value: "Include", label: "Include" },
      testOption: this.props.criteria
        ? { value: this.props.criteria, label: this.props.criteria }
        : { value: "medication", label: "Medication Filters" },
      rangeOption:
        this.props.range !== undefined
          ? { value: this.props.range, label: this.props.range }
          : { value: "GreaterThan", label: "Greater than" },
      dateRangeOption: this.props.dateRangeIE
        ? { value: this.props.dateRangeIE, label: this.props.dateRangeIE }
        : { value: "Include", label: "Include" },
      priorityOption: this.props.priority
        ? { value: this.props.priority, label: this.props.priority }
        : { value: "1", label: "1" },
      from: undefined,
      to: undefined,
      fromForPat: undefined,
      toForPat: undefined,
      labNamesToolTip: [],
      labName: "",
      UomNamesToolTip: [],
      labUoMOption: "",
      diseasesTooltip: [],
      medicationTooltip: [],
      newMedicationTooltip: [],
      proceduresNames: [],
      proceduresTooltip: [],
      physicianNames: [],
      physicianTooltip: [],
      minValue: 18,
      maxValue: 35,
      sexOption: { value: "male", label: "Male" },
      includeSearch: "",
      id: "",
      nameSearch: "",
      rxcui: "",
      medId: "",
      // geo state //
      geographyData: null,
      stateList: [],
      stateOption: "",
      cityList: [],
      cityOption: "",
      hospitalList: [],
      hospitalOption: "",
      radius: 0
    };
    this.refStart = React.createRef();
    this.refEnd = React.createRef();
    this.refFirstValue = React.createRef();
    this.refSecondValue = React.createRef();
  }

  getPositionAtCenter = element => {
    const { top, left, width, height } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2
    };
  };

  getDistanceBetweenElements = (a, b) => {
    const aPosition = this.getPositionAtCenter(a);
    const bPosition = this.getPositionAtCenter(b);

    return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    this.mounted = true;

    let stateList = [];
    let geoData = this.props.geoData ? [...this.props.geoData] : null;

    geoData &&
      geoData.map((el, i) => {
        let obj = {
          label: el.state,
          value: i
        };
        stateList.push(obj);
        return null
      });
    this.setState({
      stateList,
      geographyData: geoData
    });

    InmunityApi.getPhysicians().then(res => {
      let physicianNames = [];
      res.forEach(el => {
        physicianNames.push(el);
      });
      if (this.mounted) {
        this.setState({
          physicianNames
        });
      }
    });
    setTimeout(() => {
      this.setState({ includeSearch: false });
    }, 300);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id === state.id) {
      return null;
    }
    if (props.criteriaSearch) {
      let testOption;
      let labName = "";
      let medId;
      let rxcui;
      if (props.criteriaSearch === "Disease") {
        testOption = { value: "disease", label: "Disease Filters" };
      } else if (props.criteriaSearch === "Medication") {
        medId = props.medId ? props.medId : null;
        rxcui = props.rxcui ? props.rxcui : null;
        testOption = { value: "medication", label: "Medication Filters" };
      } else if (props.criteriaSearch === "Lab") {
        testOption = { value: "lab", label: "Lab Filters" };
        labName = props.nameSearch;
      } else if (props.criteriaSearch === "Procedure") {
        testOption = { value: "procedure", label: "Procedure History" };
      } else if (props.criteriaSearch === "Immunityid") {
        testOption = { value: "immunity_id", label: "ID Number/Name" };
      } else if (props.criteriaSearch === "Geography") {
        testOption = { value: "geography", label: "Geography" };
      } else if (props.criteriaSearch === "Physician") {
        testOption = { value: "physician", label: "Physician" };
      } else if (props.criteriaSearch === "Demographic") {
        testOption = { value: "demographic", label: "Demographic" };
      }
      let nameSearch = props.nameSearch;
      let priorityOption;
      if (props.prioritySearch) {
        priorityOption = {
          value: props.prioritySearch,
          label: props.prioritySearch
        };
      }
      if (props.includeSearch !== undefined) {
        let includeOption;
        let dateRangeOption;
        let rangeOption;
        if (props.includeSearch === true) {
          includeOption = { value: "Include", label: "Include" };
          dateRangeOption = { value: "Include", label: "Include" };
        } else if (props.includeSearch === false) {
          includeOption = { value: "Exclude", label: "Exclude" };
          dateRangeOption = { value: "Exclude", label: "Exclude" };
        } else {
          dateRangeOption = { value: "Include", label: "Include" };
        }
        if (props.range) {
          if (props.range === "Between") {
            rangeOption = { value: "Between", label: "Between" };
          } else if (props.range === "Greater than") {
            rangeOption = { value: "GreaterThan", label: "Greater than" };
          } else if (props.range === "Smaller than") {
            rangeOption = { value: "SmallerThan", label: "Smaller than" };
          }
        } else {
          rangeOption = { value: "GreaterThan", label: "Greater than" };
        }
        return {
          medId,
          rxcui,
          testOption,
          includeOption,
          dateRangeOption,
          priorityOption,
          rangeOption,
          id: props.id,
          nameSearch,
          labName
        };
      } else {
        let minValue = parseInt(props.min, 10);
        let maxValue = parseInt(props.max, 10);
        let sexOption;
        //geo
        let cityOption;
        let stateOption;
        let radius;
        let hospitalOption;
        let cityList = [];
        let hospitalList = [];
        if (props.geoData) {
          radius = props.radius;
          hospitalOption = { value: props.hospital, label: props.hospital };
          if (props.stateOption) {
            let stateIndex = props.geoData
              .map(e => e.state)
              .indexOf(props.stateOption);
            let indexCity = props.geoData[stateIndex].cities
              .map(el => el.city)
              .indexOf(props.city);
            cityOption = { value: indexCity, label: props.city };
            stateOption = {
              value: stateIndex,
              label: props.stateOption
            };
            if (props.geoData[stateIndex]) {
              props.geoData[stateIndex].cities.map((el, i) => {
                let obj = {
                  label: el.city,
                  value: i
                };
                cityList.push(obj);
                return null
              });
              if (props.geoData[stateIndex].cities[indexCity]) {
                props.geoData[stateIndex].cities[indexCity].hospitals.map(
                  (el, i) => {
                    let obj = {
                      label: el,
                      value: i
                    };
                    hospitalList.push(obj);
                    return null
                  }
                );
              }
            }
          }
        }

        if (props.sex === "Male") {
          sexOption = { value: "male", label: "Male" };
        } else if (props.sex === "Female") {
          sexOption = { value: "female", label: "Female" };
        } else if (props.sex === "Both") {
          sexOption = { value: "both", label: "Both" };
        }

        return {
          nameSearch,
          id: props.id,
          testOption,
          minValue,
          maxValue,
          sexOption,
          cityOption,
          stateOption,
          radius,
          hospitalOption,
          cityList,
          hospitalList,
          medId,
          rxcui
        };
      }
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.criteriaSearch !== prevProps.criteriaSearch) {
      if (this.props.criteriaSearch === "Demographic") {
        this.setMarginsForAgeSlider();
      }
    }
  }

  setMarginsForAgeSlider = () => {
    let start = this.refStart.current;
    let end = this.refEnd.current;
    let distance = this.getDistanceBetweenElements(start, end);
    if (distance < 21 && distance > 0) {
      this.refFirstValue.current.style.marginLeft = "-5px";
      this.refSecondValue.current.style.marginLeft = "10px";
    } else {
      this.refFirstValue.current.style.marginLeft = "0";
      this.refSecondValue.current.style.marginLeft = "0";
    }
  };

  changeSliderValue = value => {
    let minValue = value[0];
    let maxValue = value[1];
    this.setMarginsForAgeSlider();
    this.setState({ minValue, maxValue });
  };

  handleChangeSex = sexOption => {
    this.setState({ sexOption });
  };

  handleChangeProcedures = proceduresOption => {
    this.setState({ includeOption: proceduresOption });
  };

  handleChangeTest = testOption => {
    this.setState({ testOption, nameSearch: "" });
  };

  handleChangeMedic = medicationOption => {
    this.setState({ includeOption: medicationOption });
  };

  handleChangeDisease = diseaseOption => {
    this.setState({ includeOption: diseaseOption });
  };

  handleChangeRange = rangeOption => {
    this.setState({ rangeOption });
  };

  handleChangeDateRange = dateRangeOption => {
    this.setState({ dateRangeOption });
  };

  handleChangePriority = priorityOption => {
    this.setState({ priorityOption });
  };

  showFromMonth() {
    if (!this.state.from) {
      return;
    }
    if (moment(this.state.to).diff(moment(this.state.from), "months") < 2) {
      this.to.getDayPicker().showMonth(this.state.from);
    }
  }

  handleFromChange = from => {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  };

  handleToChange = to => {
    this.setState({ to }, this.showFromMonth);
  };

  // ------ start datepicker for patients page ----- //

  showFromMonthForPat() {
    if (!this.state.fromForPat) {
      return;
    }
    if (
      moment(this.state.toForPat).diff(
        moment(this.state.fromForPat),
        "months"
      ) < 2
    ) {
      this.to.getDayPicker().showMonth(this.state.fromForPat);
    }
  }

  handleFromChangeForPat = fromForPat => {
    // Change the from date and focus the "to" input field
    this.setState({ fromForPat });
    // let clndr = document.getElementsByClassName(
    //   ".DayPickerInput-OverlayWrapper"
    // );
  };

  handleToChangeForPat = toForPat => {
    this.setState({ toForPat }, this.showFromMonthForPat);
  };

  // ------ end datepicker for patients page ----- //

  GeoIdPhysHandleChange = ({ target }) => {
    if (target.length === 0) {
      target.classList.add("redBorder");
    } else {
      target.classList.remove("redBorder");
    }
  };

  //------ Physicians Tooltip -------- //
  findPhysicianName = ({ target }) => {
    let physicianNames = this.state.physicianNames;
    if (
      target.value.length >= 0 &&
      physicianNames &&
      physicianNames.length > 0
    ) {
      let query = target.value.toLowerCase();
      let result = physicianNames
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        this.setState({ physicianTooltip: result });
      } else {
        result.length = 0;
        this.setState({ physicianTooltip: result });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({ physicianTooltip: [] });
    }
  };

  physicianPicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    this.setState({ physicianTooltip: obj });
  };

  //------ Labs Tooltip -------- //
  findLabName = ({ target }) => {
    this.setState({ labUoMOption: "" });
    let labNames = this.props.labNames;
    if (target.value.length >= 0 && labNames && labNames.length > 0) {
      let query = target.value.toLowerCase();
      let result = labNames
        .filter(el => {
          return el.label.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        this.setState({ labNamesToolTip: result });
      } else {
        result.length = 0;
        this.setState({ labNamesToolTip: result });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({ labNamesToolTip: [] });
    }
  };

  labPicked = (obj, whereToFill) => {
    whereToFill.value = obj.label;
    this.setState({ labNamesToolTip: obj });
  };

  setLabName = name => {
    this.setState({ labName: name }, () => {
      let labWithUom = this.props.labWithUom.filter(
        el => el.label === this.state.labName
      );
      let result = labWithUom[0].uom;
      let UomNamesToolTip = [];
      result.forEach(el => UomNamesToolTip.push({ label: el, value: el }));
      if (UomNamesToolTip.length === 1) {
        this.setState({ labUoMOption: UomNamesToolTip[0] });
      }
      this.setState({ UomNamesToolTip });
    });
  };

  //------ UoM Tooltip -------- //
  findUom = labUoMOption => {
    this.setState({ labUoMOption });
  };

  //------ Disease Tooltip -------- //
  findDisease = ({ target }) => {
    let diseases = this.props.diseases;
    if (target.value.length >= 0 && diseases && diseases.length > 0) {
      let query = target.value.toLowerCase();
      let result = diseases
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        this.setState({ diseasesTooltip: result });
      } else {
        result.length = 0;
        this.setState({ diseasesTooltip: result });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({ diseasesTooltip: [] });
    }
  };

  diseasePicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    this.setState({ diseasesTooltip: obj });
  };

  //------ Medication Tooltip -------- //
  findMedication = e => {
    e.preventDefault();
    let target = e.target;
    let medications = !this.props.fromSearch
      ? this.props.medications
      : this.props.newMedications;
    if (target.value.length >= 0 && medications && medications.length > 0) {
      let query = target.value.toLowerCase();
      let result = medications
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        !this.props.fromSearch
          ? this.setState({ medicationTooltip: result })
          : this.setState({ newMedicationTooltip: result });
      } else {
        !this.props.fromSearch
          ? this.setState({ medicationTooltip: [] })
          : this.setState({ newMedicationTooltip: [] });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      !this.props.fromSearch
        ? this.setState({ medicationTooltip: [] })
        : this.setState({ newMedicationTooltip: [] });
    }
  };

  medPicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    !this.props.fromSearch
      ? this.setState({ medicationTooltip: obj })
      : this.setState({ newMedicationTooltip: obj });
  };

  findProcedure = ({ target }) => {
    let procedures = this.props.procedures;
    if (target.value.length >= 0 && procedures && procedures.length > 0) {
      let query = target.value.toLowerCase();
      let result = procedures
        .filter(el => {
          return el.long_title.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        this.setState({ proceduresTooltip: result });
      } else {
        this.setState({ proceduresTooltip: [] });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({ proceduresTooltip: [] });
    }
  };

  prodPicked = (obj, whereToFill) => {
    whereToFill.value = obj.long_title;
    this.setState({ proceduresTooltip: obj });
  };

  remove = e => {
    e.stopPropagation();
    if (this.props.searchOptions && e.target.className === "red-cross") {
      let ul = e.target.parentNode.parentNode.parentNode;
      let lists = ul.querySelectorAll(".width100");
      let arr = [];
      lists.forEach(el => (el.style.display !== "none" ? arr.push(el) : ""));
      if (arr.length !== 1) {
        arr.forEach(li => {
          if (e.target.parentNode.parentNode === li) {
            li.style.display = "none";
          }
        });
      }
    } else {
      if (e.target.className === "red-cross") {
        let ul = e.target.parentNode.parentNode.parentNode;
        let lists = ul.querySelectorAll(".width100");
        if (lists.length !== 1) {
          lists.forEach((li, i) => {
            if (e.target.parentNode.parentNode === li) {
              li.parentNode.removeChild(li);
              this.props.delete(i);
            }
          });
        }
      }
    }
  };

  handleLabValueChange = ({ target }) => {
    if (target.value.length > 0) {
      target.classList.remove("redBorder");
    } else {
      target.classList.add("redBorder");
    }
  };

  // ---- Geo select changes ---- //

  handleChangeState = stateOption => {
    let cityList = [];
    this.state.geographyData[stateOption.value].cities.map((el, i) => {
      let obj = {
        label: el.city,
        value: i
      };
      cityList.push(obj);
      return null
    });
    cityList.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    this.setState({
      stateOption,
      cityList,
      cityOption: "",
      hospitalOption: ""
    });
  };

  handleChangeCity = cityOption => {
    let hospitalList = [];
    this.state.geographyData[this.state.stateOption.value].cities[
      cityOption.value
    ].hospitals.map((el, i) => {
      let obj = {
        label: el,
        value: i
      };
      hospitalList.push(obj);
      return null
    });
    this.setState({
      cityOption,
      hospitalList,
      hospitalOption: ""
    });
  };

  handleChangeHospital = hospitalOption => {
    this.setState({
      hospitalOption
    });
  };

  handleChangeRadius = ({ target }) => {
    if (target.length === 0) {
      target.classList.add("redBorder");
    } else {
      target.classList.remove("redBorder");
    }
  };

  render() {
    const { from, to, nameSearch } = this.state;

    const fromDate = !this.props.status ||
      (this.props.status && this.props.dateRangeFrom)
      ? this.props.dateRangeFrom
      : from;

      const dateTo = !this.props.status ||
      (this.props.status && this.props.dateRangeTo)
        ? this.props.dateRangeTo
        : to

    return (
      <div className="EC-main__list" onClick={this.remove}>
        <div className="EC-main__listfields freetext-collect">
          <div
            className={
              this.state.testOption.value !== "lab" &&
                (this.props.criteria !== "Lab Filters" ||
                  this.state.testOption.value !== "Lab Filters")
                ? "ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields"
                : "ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext width100Lab forGettingValueFields"
            }
          >
            <span className="ntw__ti-inputlabel">Criteria Type</span>
            <Select
              value={this.state.testOption}
              onChange={this.handleChangeTest}
              options={this.state.testOptions}
              className="sort_select"
            />
            <input
              type="hidden"
              value={this.state.testOption.value}
              className="get-cr-type"
              readOnly
            />
          </div>

          {/*  ------------------ Standard Medication filter ----------------- */}

          {this.state.testOption.label === "Medication Filters" && (
            <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ifreetext forGettingValueFields">
              <span className="ntw__ti-inputlabel">Name</span>
              <input
                type="text"
                name="Medication Filters"
                onChange={this.findMedication}
                ref={this.medicationRef}
                defaultValue={nameSearch ? nameSearch : this.props.nameValue}
                className="get-freetext-name"
              />
              {this.state.medicationTooltip.length > 0 && (
                <MedicationTooltip
                  medicationNames={this.state.medicationTooltip}
                  Mpicked={this.medPicked}
                />
              )}
              {this.state.newMedicationTooltip.length > 0 && (
                <MedicationTooltip
                  medicationNames={this.state.newMedicationTooltip}
                  Mpicked={this.medPicked}
                />
              )}
              {this.props.fromSearch && (
                <input
                  type="hidden"
                  value={
                    this.props.rxcui
                      ? JSON.stringify({
                        id: this.props.medId,
                        rxcui: this.props.rxcui
                      })
                      : JSON.stringify(this.state.newMedicationTooltip)
                  }
                  className="get-new-medication"
                  readOnly
                />
              )}
            </div>
          )}

          {(this.state.testOption.value === "medication" ||
            (this.state.testOption.label === "Medication Filters" &&
              this.props.criteria === "Medication Filters")) && (
              <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields">
                <span className="ntw__ti-inputlabel">Include/Exclude</span>
                <Select
                  value={this.state.includeOption}
                  onChange={this.handleChangeMedic}
                  options={medicOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.include
                      ? this.props.include
                      : this.state.includeOption.value
                  }
                  className="freetext-in-ex"
                  readOnly
                />
              </div>
            )}

          {(this.state.testOption.value === "medication" ||
            (this.state.testOption.label === "Medication Filters" &&
              this.props.criteria === "Medication Filters")) && (
              <div className="ntw__ti-input ntw__ti-priorityForEL priorityField forGettingValueFields">
                <span className="ntw__ti-inputlabel">Priority</span>
                <Select
                  value={this.state.priorityOption}
                  onChange={this.handleChangePriority}
                  options={priorityOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.priority
                      ? this.props.priority
                      : this.state.priorityOption.value
                  }
                  className="freetext-priority"
                  readOnly
                />
              </div>
            )}

          {/*  ------------------ For Patients page Medication filter -----------------

          { this.props.searchOptions && (this.state.testOption.value === "medication" ||
            (this.state.testOption.label === "Medication Filters" &&
              this.props.criteria === "Medication Filters")) && (
            <div className="ntw__ti-input ntw__ti-iTrialName nextLine dateRange forGettingValueFields">
              <span className="InputFromTo-from">
                <span className="ntw__ti-inputlabel">Start date</span>
                <DayPickerInput
                  value={from}
                  placeholder="From"
                  format="LL"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to },
                    toMonth: to,
                    numberOfMonths: 2,
                    onDayClick: () => this.to.getInput().focus()
                  }}
                  onDayChange={this.handleFromChangeForPat}
                />
              </span>{" "}
                —{" "}
                <span className="InputFromTo-to">
                  <span className="ntw__ti-inputlabel">End date</span>
                  <DayPickerInput
                    ref={el => (this.to = el)}
                    value={to}
                    placeholder="To"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      selectedDays: [from, { from, to }],
                      disabledDays: { before: from },
                      month: from,
                      fromMonth: from,
                      numberOfMonths: 2
                    }}
                    onDayChange={this.handleToChangeForPat}
                  />
                  <input
                    type="hidden"
                    value={from}
                    className="get-date-from"
                    readOnly
                  />
                  <input
                    type="hidden"
                    value={to}
                    className="get-date-to"
                    readOnly
                  />
                </span>
            </div>
          )}

          { this.props.searchOptions && (this.state.testOption.value === "medication" ||
            (this.state.testOption.label === "Medication Filters" &&
              this.props.criteria === "Medication Filters")) && (
            <div className="ntw__ti-input ntw__ti-priority nextLine strengthField forGettingValueFields">
              <span className="ntw__ti-inputlabel">Strength</span>
              <input
                type="text"
                name="Medication Strength"
                className="get-freetext-name"
              />
            </div>
          )}

          { this.props.searchOptions && (this.state.testOption.value === "medication" ||
            (this.state.testOption.label === "Medication Filters" &&
              this.props.criteria === "Medication Filters")) && (
            <div className="ntw__ti-input ntw__ti-priority nextLine dosageField forGettingValueFields">
              <span className="ntw__ti-inputlabel">Dosage</span>
              <input
                type="text"
                name="Medication Dosage"
                className="get-freetext-name"
              />
            </div>
          )}

          { this.props.searchOptions && (this.state.testOption.value === "medication" ||
            (this.state.testOption.label === "Medication Filters" &&
              this.props.criteria === "Medication Filters")) && (
            <div className="ntw__ti-input ntw__ti-iTrialName nextLine routeField forGettingValueFields">
              <span className="ntw__ti-inputlabel">Route</span>
              <input
                type="text"
                name="Medication Route"
                className="get-freetext-name"
              />
            </div>
          )}

          { this.props.searchOptions && (this.state.testOption.value === "medication" ||
            (this.state.testOption.label === "Medication Filters" &&
              this.props.criteria === "Medication Filters")) && (
            <div className="ntw__ti-input ntw__ti-iTrialName nextLine frequencyField forGettingValueFields">
              <span className="ntw__ti-inputlabel">Frequency</span>
              <input
                type="text"
                name="Medication Frequency"
                className="get-freetext-name"
              />
            </div>
          )} */}

          {/* ------------------ Disease filter ----------------- */}

          {(this.state.testOption.value === "disease" ||
            (this.props.criteria === "Disease Filters" &&
              this.state.testOption.value === "Disease Filters")) && (
              <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ifreetext forGettingValueFields">
                <span className="ntw__ti-inputlabel">Name</span>
                <input
                  type="text"
                  name="Disease Filters"
                  onChange={this.findDisease}
                  ref={this.diseaseRef}
                  defaultValue={nameSearch ? nameSearch : this.props.nameValue}
                  className="get-freetext-name"
                />
                {this.state.diseasesTooltip.length > 0 && (
                  <DiseasesTooltip
                    diseases={this.state.diseasesTooltip}
                    Dpicked={this.diseasePicked}
                  />
                )}
              </div>
            )}

          {(this.state.testOption.value === "disease" ||
            (this.props.criteria === "Disease Filters" &&
              this.state.testOption.value === "Disease Filters")) && (
              <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields">
                <span className="ntw__ti-inputlabel">Include/Exclude</span>
                <Select
                  value={this.state.includeOption}
                  onChange={this.handleChangeDisease}
                  options={diseaseOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.include
                      ? this.props.include
                      : this.state.includeOption.value
                  }
                  className="freetext-in-ex"
                  readOnly
                />
              </div>
            )}

          {(this.state.testOption.value === "disease" ||
            (this.props.criteria === "Disease Filters" &&
              this.state.testOption.value === "Disease Filters")) && (
              <div className="ntw__ti-input ntw__ti-priorityForEL priorityField forGettingValueFields">
                <span className="ntw__ti-inputlabel">Priority</span>
                <Select
                  value={this.state.priorityOption}
                  onChange={this.handleChangePriority}
                  options={priorityOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.priority
                      ? this.props.priority
                      : this.state.priorityOption.value
                  }
                  className="freetext-priority"
                  readOnly
                />
              </div>
            )}

          {/* ------------------ Lab filter ----------------- */}

          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ilabname forGettingValueFields">
                <span className="ntw__ti-inputlabel">Laboratory Name</span>
                <input
                  type="text"
                  onChange={this.findLabName}
                  name="Lab Filters"
                  ref={this.labRef}
                  defaultValue={nameSearch ? nameSearch : this.props.nameValue}
                  className="get-freetext-name"
                />
                {this.state.labNamesToolTip.length > 0 && (
                  <LabTooltip
                    labNames={this.state.labNamesToolTip}
                    Lpicked={this.labPicked}
                    setLabName={this.setLabName}
                  />
                )}
              </div>
            )}

          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-irange forGettingValueFields">
                <span className="ntw__ti-inputlabel">Range</span>
                <Select
                  value={this.state.rangeOption}
                  onChange={this.handleChangeRange}
                  options={rangeOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.range === undefined
                      ? this.props.range
                      : this.state.rangeOption.value
                  }
                  className="get-range"
                  readOnly
                />
              </div>
            )}

          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div
                className={
                  this.state.rangeOption.value !== "Between"
                    ? "qwer forGettingValueFields"
                    : "qwer10 forGettingValueFields"
                }
              >
                {this.state.rangeOption.value !== "Between" ? (
                  <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ilabvalue forDynadding">
                    <span className="ntw__ti-inputlabel">Lab Value</span>
                    <input
                      type="number"
                      defaultValue={this.props.labvalue}
                      className="get-lab-max"
                      onChange={this.handleLabValueChange}
                    />
                    <input type="hidden" className="get-lab-min" readOnly />
                  </div>
                ) : (
                    <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ilabvalueMinMax forDynadding">
                      <span className="ntw__ti-inputlabel">Min Value</span>
                      <input
                        type="text"
                        placeholder="Min"
                        defaultValue={this.props.minValue}
                        className="get-lab-min"
                        onChange={this.handleLabValueChange}
                      />
                    </div>
                  )}
              </div>
            )}
          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div
                className={
                  this.state.rangeOption.value !== "Between"
                    ? "qwer forGettingValueFields"
                    : "qwer10 forGettingValueFields"
                }
              >
                {this.state.rangeOption.value !== "Between" ? null : (
                  <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ilabvalueMinMax forDynadding">
                    <span className="ntw__ti-inputlabel">Max Value</span>
                    <input
                      type="number"
                      placeholder="Max"
                      defaultValue={this.props.maxValue}
                      className="get-lab-max"
                      onChange={this.handleLabValueChange}
                    />
                  </div>
                )}
              </div>
            )}

          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ilabuom forGettingValueFields">
                <span className="ntw__ti-inputlabel">Lab UoM</span>
                <Select
                  value={this.state.labUoMOption}
                  onChange={this.findUom}
                  options={this.state.UomNamesToolTip}
                  className="sort_select withInput"
                />
                <input
                  type="hidden"
                  value={this.state.labUoMOption.value}
                  className="get-uom"
                />
              </div>
            )}

          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div className="ntw__ti-input ntw__ti-iTrialName nextLine dateRange forGettingValueFields">
                <span className="InputFromTo-from">
                  <span className="ntw__ti-inputlabel">Start date</span>
                  <DayPickerInput
                    value={
                      !fromDate ? '-' : fromDate
                    }
                    placeholder="From"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      selectedDays: [from, { from, to }],
                      disabledDays: { after: to },
                      toMonth: to,
                      numberOfMonths: 2,
                      onDayClick: () => this.to.getInput().focus()
                    }}
                    onDayChange={this.handleFromChange}
                  />
                </span>{" "}
                —{" "}
                <span className="InputFromTo-to">
                  <span className="ntw__ti-inputlabel">End date</span>
                  <DayPickerInput
                    ref={el => (this.to = el)}
                    value={
                      !dateTo ? '-' : dateTo
                    }
                    placeholder="To"
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                      selectedDays: [from, { from, to }],
                      disabledDays: { before: from },
                      month: from,
                      fromMonth: from,
                      numberOfMonths: 2
                    }}
                    onDayChange={this.handleToChange}
                  />
                  <input
                    type="hidden"
                    value={
                      this.props.dateRangeFrom ? this.props.dateRangeFrom : from
                    }
                    className="get-date-from"
                    readOnly
                  />
                  <input
                    type="hidden"
                    value={this.props.dateRangeTo ? this.props.dateRangeTo : to}
                    className="get-date-to"
                    readOnly
                  />
                </span>
              </div>
            )}

          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div className="ntw__ti-input ntw__ti-priority nextLine lastField forGettingValueFields">
                <span className="ntw__ti-inputlabel">Include/Exclude</span>
                <Select
                  value={this.state.dateRangeOption}
                  onChange={this.handleChangeDateRange}
                  options={dateRangeOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.dateRangeIE
                      ? this.props.dateRangeIE
                      : this.state.dateRangeOption.value
                  }
                  className="freetext-in-ex"
                  readOnly
                />
              </div>
            )}

          {(this.state.testOption.value === "lab" ||
            (this.props.criteria === "Lab Filters" &&
              this.state.testOption.value === "Lab Filters")) && (
              <div className="ntw__ti-input ntw__ti-priority nextLine priorityField forGettingValueFields">
                <span className="ntw__ti-inputlabel">Priority</span>
                <Select
                  value={this.state.priorityOption}
                  onChange={this.handleChangePriority}
                  options={priorityOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.priority
                      ? this.props.priority
                      : this.state.priorityOption.value
                  }
                  className="freetext-priority"
                  readOnly
                />
              </div>
            )}

          {/* ------------------ Procedure filter ----------------- */}

          {(this.state.testOption.value === "procedure" ||
            (this.props.criteria === "Procedure History" &&
              this.state.testOption.value === "Procedure History")) && (
              <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ifreetext forGettingValueFields">
                <span className="ntw__ti-inputlabel">Name</span>
                <input
                  type="text"
                  name="Procedure History"
                  className="get-freetext-name"
                  ref={this.procedureRef}
                  defaultValue={nameSearch ? nameSearch : this.props.nameValue}
                  onChange={this.findProcedure}
                />
                {this.state.proceduresTooltip.length > 0 && (
                  <ProceduresTooltip
                    proceduresNames={this.state.proceduresTooltip}
                    Mpicked={this.prodPicked}
                  />
                )}
              </div>
            )}

          {(this.state.testOption.value === "procedure" ||
            (this.props.criteria === "Procedure History" &&
              this.state.testOption.value === "Procedure History")) && (
              <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGettingValueFields">
                <span className="ntw__ti-inputlabel">Include/Exclude</span>
                <Select
                  value={
                    this.props.include
                      ? { value: this.props.include, label: this.props.include }
                      : this.state.includeOption
                  }
                  onChange={this.handleChangeProcedures}
                  options={proceduresOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.include
                      ? this.props.include
                      : this.state.includeOption.value
                  }
                  className="freetext-in-ex"
                  readOnly
                />
              </div>
            )}

          {(this.state.testOption.value === "procedure" ||
            (this.props.criteria === "Procedure History" &&
              this.state.testOption.value === "Procedure History")) && (
              <div className="ntw__ti-input ntw__ti-priorityForEL priorityField forGettingValueFields">
                <span className="ntw__ti-inputlabel">Priority</span>
                <Select
                  value={this.state.priorityOption}
                  onChange={this.handleChangePriority}
                  options={priorityOptions}
                  className="sort_select"
                />
                <input
                  type="hidden"
                  value={
                    this.props.priority
                      ? this.props.priority
                      : this.state.priorityOption.value
                  }
                  className="freetext-priority"
                  readOnly
                />
              </div>
            )}

          {/* ------------------ ImmunityID filter ----------------- */}

          {this.state.testOption.value === "immunity_id" && (
            <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ifreetext forGettingValueFields searchPatientInput">
              <span className="ntw__ti-inputlabel">ID Number/Name</span>
              <input
                type="text"
                name="Immunity ID"
                className="get-freetext-name"
                defaultValue={nameSearch ? nameSearch : this.props.nameValue}
                onChange={this.GeoIdPhysHandleChange}
              />
            </div>
          )}

          {/* ------------------ Geography filter ----------------- */}

          {this.state.testOption.value === "geography" && (
            <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGeoInput forGettingValueFields">
              <span className="ntw__ti-inputlabel">State</span>
              <Select
                value={this.state.stateOption}
                onChange={this.handleChangeState}
                options={this.state.stateList}
                className="sort_select withInput"
              />
            </div>
          )}
          {this.state.testOption.value === "geography" && (
            <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGeoInput forGettingValueFields">
              <span className="ntw__ti-inputlabel">City</span>
              <Select
                value={this.state.cityOption}
                onChange={this.handleChangeCity}
                options={this.state.cityList ? this.state.cityList : []}
                className="sort_select withInput"
              />
            </div>
          )}

          {this.state.testOption.value === "geography" && (
            <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext nextLine forGeoSelect forGettingValueFields">
              <span className="ntw__ti-inputlabel">Hospital</span>
              <Select
                value={this.state.hospitalOption}
                onChange={this.handleChangeHospital}
                options={this.state.hospitalList ? this.state.hospitalList : []}
                className="sort_select withInput"
              />
            </div>
          )}

          {this.state.testOption.value === "geography" && (
            <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext nextLine forGeoSelect forGettingValueFields">
              <span className="ntw__ti-inputlabel">Radius (miles)</span>
              <input
                type="text"
                name="Geography"
                className="get-freetext-name"
                defaultValue={this.state.radius}
                onChange={this.handleChangeRadius}
              />
            </div>
          )}

          {this.state.testOption.value === "geography" && (
            <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext nextLine forGeoSelect forGettingValueFields">
              <span className="ntw__ti-inputlabel">Priority</span>
              <Select
                value={this.state.priorityOption}
                onChange={this.handleChangePriority}
                options={priorityOptions}
                className="sort_select withInput"
              />
            </div>
          )}

          {/* ------------------ Physician filter ----------------- */}

          {this.state.testOption.value === "physician" && (
            <div className="ntw__ti-input ntw__ti-iTrialName ntw__ti-ifreetext forGettingValueFields searchPatientInput">
              <span className="ntw__ti-inputlabel">Name</span>
              <input
                type="text"
                name="Physician"
                className="get-freetext-name"
                defaultValue={nameSearch ? nameSearch : this.props.nameValue}
                onChange={this.findPhysicianName}
              />
              {this.state.physicianTooltip.length > 0 && (
                <PhysiciansTooltip
                  physiciansNames={this.state.physicianTooltip}
                  Mpicked={this.physicianPicked}
                />
              )}
            </div>
          )}

          {/* ------------------ Demographic filter ----------------- */}

          {this.state.testOption.value === "demographic" && (
            <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGeoInput forGeoInput forGettingValueFields">
              <span className="ntw__ti-inputlabel">Age</span>
              <div className="filters-sexes__sliderWrap">
                <ReactSlider
                  min={0}
                  max={99}
                  withBars
                  onChange={this.changeSliderValue}
                  value={[this.state.minValue, this.state.maxValue]}
                >
                  <div>
                    <div
                      className="sexSlider filterSlider"
                      ref={this.refStart}
                    />
                    <span ref={this.refFirstValue} className="slider-values">
                      {this.state.minValue}
                    </span>
                  </div>
                  <div>
                    <div className="sexSlider filterSlider" ref={this.refEnd} />
                    <span ref={this.refSecondValue} className="slider-values">
                      {this.state.maxValue}
                    </span>
                  </div>
                </ReactSlider>
                <div className="filters-value">
                  <input
                    type="hidden"
                    value={this.state.minValue}
                    id="min-age"
                    readOnly
                  />
                  <input
                    type="hidden"
                    value={this.state.maxValue}
                    id="max-age"
                    readOnly
                  />
                </div>
              </div>
            </div>
          )}

          {this.state.testOption.value === "demographic" && (
            <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGeoInput forGeoInput forGettingValueFields">
              <span className="ntw__ti-inputlabel">Sex</span>
              <Select
                value={this.state.sexOption}
                onChange={this.handleChangeSex}
                options={sexOptions}
                className="sort_select"
              />
              <input
                type="hidden"
                value={
                  this.props.priority
                    ? this.props.priority
                    : this.state.priorityOption.value
                }
                className="freetext-priority"
                readOnly
              />
            </div>
          )}
        </div>
        {this.props.edit ? (
          <div className="editORsave" onClick={this.editBlock}>
            <div className="ntw__headerWrapp-btn">Save</div>
          </div>
        ) : null}

        <div className="red-cross" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    medications: state.medications,
    newMedications: state.newMedications,
    labNames: state.labNames,
    diseases: state.diseases,
    procedures: state.procedures,
    labWithUom: state.labWithUom,
    geoData: state.geoData
  };
}

export default connect(mapStateToProps)(ELBlockInput);
