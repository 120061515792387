import React, { Fragment, useState, useEffect, useRef } from "react";
import ReactSlider from "react-slider";
import Select from "react-select";

export default function Demographic({
  minValue: min,
  maxValue: max,
  sexOption: optionSex,
  statusMenOption: statusMenOption,
  handleChangeStatus: handleChangeStatus,
  handleChangeSex: handleChange,
  handleChangeMaxValue,
  handleChangeMinValue
}) {
  const [sexOption, setSexOption] = useState(
    optionSex || { value: "M", label: "Male" }
  );
  const [statusOption, setStatusOption ] = useState(
    statusMenOption || { value: "both", label: "All" },
  )
  const [minValue, setMinValue] = useState(min === undefined ? 18 : min);
  const [maxValue, setMaxValue] = useState(max === undefined ? 35 : max);
  const refFirstValue = useRef();
  const refSecondValue = useRef();
  const refStart = useRef();
  const refEnd = useRef();
  const handleChangeSex = sexOption => {
    setSexOption(sexOption);
    handleChange(sexOption);
  };
  const handleChangeStatusOption = statusOption => {
    setStatusOption(statusOption);
    handleChangeStatus(statusOption)
  };
  const sexOptions = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "B", label: "Both" }
  ];

  const statusOptions = [
    { value: "both", label: "All" },
    { value: "living", label: "Living" },
    { value: "death", label: "Death" },

  ]

  const getPositionAtCenter = element => {
    const { top, left, width, height } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2
    };
  };
  const getDistanceBetweenElements = (a, b) => {
    const aPosition = getPositionAtCenter(a);
    const bPosition = getPositionAtCenter(b);

    return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
  };
  const makeMargins = () => {
    let start = refStart.current;
    let end = refEnd.current;
    let distance = getDistanceBetweenElements(start, end);
    if (distance < 21 && distance > 0) {
      refFirstValue.current.style.marginLeft = "-5px";
      refSecondValue.current.style.marginLeft = "10px";
    } else if (distance === 0) {
      refFirstValue.current.style.marginLeft = "2px";
      refSecondValue.current.style.marginLeft = "2px";
    } else {
      refFirstValue.current.style.marginLeft = "0";
      refSecondValue.current.style.marginLeft = "0";
    }
  };
  const changeSliderValue = value => {
    let minValue = value[0];
    let maxValue = value[1];
    makeMargins();
    setMinValue(minValue);
    handleChangeMinValue(minValue);
    setMaxValue(maxValue);
    handleChangeMaxValue(maxValue);
  };
  useEffect(() => {
    makeMargins();
  }, [makeMargins]);
  return (
    <div className="search-filter">
      <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGeoInput forGeoInput forGettingValueFields">
        <span className="ntw__ti-inputlabel">Age</span>
        <div className="filters-sexes__sliderWrap">
          <ReactSlider
            min={0}
            max={99}
            withBars
            onChange={changeSliderValue}
            defaultValue={[minValue, maxValue]}
          >
            <div>
              <div className="sexSlider filterSlider" ref={refStart} />
              <span ref={refFirstValue} className="slider-values">
                {minValue}
              </span>
            </div>
            <div>
              <div className="sexSlider filterSlider" ref={refEnd} />
              <span ref={refSecondValue} className="slider-values">
                {maxValue}
              </span>
            </div>
          </ReactSlider>
        </div>
      </div>
      <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGeoInput forGeoInput forGettingValueFields">
        <span className="ntw__ti-inputlabel">Sex</span>
        <Select
          value={sexOption}
          onChange={handleChangeSex}
          options={sexOptions}
          className="sort_select"
        />
      </div>
      <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext forGeoInput forGeoInput forGettingValueFields">
        <span className="ntw__ti-inputlabel">Status</span>
        <Select
          value={statusOption}
          onChange={handleChangeStatusOption}
          options={statusOptions}
          className="sort_select"
        />
      </div>
    </div>
  );
}
