import React from "react";
const MedicationTooltip = ({ medicationNames, Mpicked }) => {
  function picked(e, obj) {
    if (e.target.classList.contains("diseases-container__item")) {
      let whereToFill = e.target.parentNode.previousSibling;
      Mpicked(obj, whereToFill);
    }
  }
  return (
    <div className="diseases-container">
      {medicationNames.map((d, i) => {
        return (
          <div
            className="diseases-container__item"
            onClick={e => picked(e, d)}
            key={i}
          >
            {d.name}
          </div>
        );
      })}
    </div>
  );
};
export default MedicationTooltip;
