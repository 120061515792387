import React from "react";
import { Circle } from "rc-progress";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTooltip from "react-tooltip";
import "./index.css";

import inmunityApi from "../../api/InmunityApi";
import PatientOverview from "./../PatientOverview";
import { InfoIcon } from "../../ui";
import checkInc from "../../images/exclusion_inclusion/plus_full.svg";
import checkEx from "../../images/exclusion_inclusion/minus_full.svg";

class AllMatches extends React.Component {
  state = {
    showOverview: false,
    details: {}
  };

  closePatientOverview = () => {
    this.setState({ showOverview: false });
  };

  showPatientOverview = () => {
    this.setState({ showOverview: true });
    inmunityApi
      .getPatientWorkflow(this.props.mat.hadmId, this.props.mat.studyId)
      .then(details => {
        this.setState({
          details
        });
      });
  };

  goToDetails = ({ target }) => {
    if (
      !target.classList.contains("button-center") &&
      !target.closest(".pat-over-wrapp") &&
      !target.classList.contains("button__icon") &&
      !target.classList.contains("popup-overlay")
    ) {
      this.redirect();
    }
  };

  redirect = () => {
    let { history } = this.props;
    history.push({
      pathname: `/trial/${this.props.mat.studyId}/matches/details/${
        this.props.mat.hadmId
        }`,
      id: this.props.mat.hadmId,
      detailTitle: "Match Details"
    });
  };

  makeTrials = obj => {
    let result = "";
    obj.sort((a, b) => b.matchrate - a.matchrate);
    obj.forEach(trial => {
      let precntage = trial.matchrate;
      result += ` ${trial.studyName} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  makeMatchTags = obj => {
    let result =
      obj.length > 0
        ? `Matched for ${obj.length} other ${
        obj.length > 1 ? "trials" : "trial"
        }:<br>`
        : "";
    obj.sort((a, b) => b.matchrate - a.matchrate);
    obj.forEach(trial => {
      let precntage = trial.matchrate;
      result += ` ${trial.studyName} ${Math.ceil(precntage)}%<br>`;
    });
    return result;
  };

  makeEnrollTags = obj => {
    let result =
      obj.length > 0
        ? `Enrolled in ${obj.length} other ${
        obj.length > 1 ? "trials" : "trial"
        }:<br>`
        : "";
    obj.sort((a, b) => b.name - a.name);
    obj.forEach(trial => {
      result += ` ${trial.name} - ${trial.status}<br>`;
    });
    return result;
  };

  showMCInfo = e => {
    if (!this.props.mat.matchCriteria || this.props.mat.matchCriteria.length <= 0)
      return;
    e.target.previousSibling.previousSibling.style.display = "block";
  };

  closeMCInfo = e => {
    e.target.previousSibling.previousSibling.style.display = "none";
  };

  render() {
    const {
      gender,
      age,
      lastEncounter,
      matchrate,
      name,
      immunity,
      studyTags,
      matchTags: mTags,
      enrollTags: eTags
    } = this.props.mat;

    var trials = this.makeTrials(studyTags);
    var matchTags = this.makeMatchTags(mTags);
    const enrollTags = this.makeEnrollTags(eTags);

    return (
      <div
        className="candidatesWr matchesWr"
        key={this.props.key}
        onClick={this.goToDetails}
      >
        <div className="candidates hadmId forMatchesTooltip">
          <span className="imm-id">Immunity ID</span>
          <span className="td-content">{immunity}</span>
          {this.props.mat.studyTags.length !== 0 ? (
            <span
              className="td-content candidatesAmTrial"
              data-tip={trials}
              data-for={this.props.id}
              data-class="match"
              data-multiline
            >
              {this.props.mat.studyTags.length > 1
                ? "Candidate for " +
                this.props.mat.studyTags.length +
                " other Trials"
                : ""}
              {this.props.mat.studyTags.length === 1 &&
                "Candidate for " +
                this.props.mat.studyTags.length +
                " other Trial"}
            </span>
          ) : (
              ""
            )}
          <ReactTooltip
            id={this.props.id}
            place="bottom"
            type="light"
            effect="solid"
          />
        </div>
        <div className="candidates forname">
          <div className="forgam-g">
            <span className="">Name</span>
            <span className="td-content">{name || "–"}</span>
          </div>
        </div>
        <div className="candidates forgam">
          <div className="forgam-g">
            <span className="">Gender</span>
            <span className="td-content">
              {gender === "M" ? "Male" : "Female"}
            </span>
          </div>
          <div className="forgam-a">
            <span>Age</span>
            <span className="td-content">{age}</span>
          </div>
        </div>
        <div className="candidates lastenc">
          <div className="lastenc-forIcon">
            <span>Last Encounter</span>
            <span className="td-content">
              {lastEncounter
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("/")}
            </span>
          </div>
          <div className="vertical-center">
            <InfoIcon click={this.showPatientOverview} />
            {this.state.showOverview && (
              <PatientOverview
                close={this.closePatientOverview}
                details={this.state.details}
                redirect={this.redirect}
              />
            )}
            <div className="lastencForCircle all-matches-tags">

              <div className="forMatchedCriteria">
                {this.props.mat.matchCriteria.map((el, i) => {
                  return (
                    <div key={i} className="forMatchedCriteria-elem">
                      <span className="forMatchedCriteria-title">
                        {el.name}
                      </span>
                      <img
                        src={el.inclusion ? checkInc : checkEx}
                        className="forMatchedCriteria-icon"
                        alt="Criteria"
                      />
                    </div>
                  );
                })}
              </div>
              <Circle
                percent={matchrate}
                strokeWidth="5"
                trailWidth="0"
                strokeColor="#5BBEBF"
                className="lastenCircle"
              />
              <span
                className="circleNumber"
                onMouseOver={e => this.showMCInfo(e)}
                onMouseLeave={e => this.closeMCInfo(e)}
              >
                {Math.round(matchrate)}%
              </span>
              {this.props.mat.matchTags ? (
                <span
                  className="multiIndicator"
                  data-tip={matchTags}
                  data-class="match"
                  data-for={this.props.idd}
                  data-multiline
                >
                  +{this.props.mat.matchTags.length}
                </span>
              ) : (
                  ""
                )}
              <ReactTooltip
                id={this.props.idd}
                place="bottom"
                type="light"
                effect="solid"
              />
              {eTags ? (
                <span
                  className="multiIndicator multiEnroll"
                  data-tip={enrollTags}
                  data-class="match"
                  data-for={this.props.idE}
                  data-multiline
                >
                  +{eTags.length}
                </span>
              ) : (
                  ""
                )}
              <ReactTooltip
                id={this.props.idE}
                place="bottom"
                type="light"
                effect="solid"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    globalState: state,
    userToken: state.userToken
  };
}

export default withRouter(connect(mapStateToProps)(AllMatches));
