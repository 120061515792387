import React, { Component } from "react";
import { withRouter } from "react-router";
import Overview from "./Overview";
import PatientChart from "./PatientChart";
import WorkFlow from "./WorkFlow";
import "./index.css";
import arrowleft from "../../images/left.png";
import crossicon from "../../images/icon-close-w.svg";
import InmunityApi from "../../api/InmunityApi";
import moment from "moment";
import Popup from "reactjs-popup";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import {
  getExclusionReasons,
  getWFActions,
  setCurrentNavigationSubLink,
  setCurrentNavigationLink
} from "../../actions/trialActions";
import { connect } from "react-redux";
import SearchPopup from "./SearchPopup";
import { Spinner } from "../../ui";

class MatchDetails extends Component {
  state = {
    studyID: "",
    hadmId: "",
    searchId: this.props.match.params.search,
    isRemider: false,
    details: "",
    workflowIndex: 0,
    loadSearch: false,
    patientChartContent: "",
    open: false,
    SaveChanges: false,
    saved: false,
    candidate: {
      hadmId: 0,
      upcomingVisit: "",
      lastSeen: "",
      physician: "",
      contactConsent: false,
      contactNotes: "",
      protocol: [],
      proceedToTrial: false
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let hadmId = this.props.location.pathname.split("/")[5];
    let studyID = this.props.location.pathname.split("/")[2];
    if (isNaN(studyID)) {
      studyID = null;
      hadmId = this.props.location.pathname.split("/")[3];
    }
    this.mounted = true;
    this.props.setCurrentNavigationSubLink(studyID || "all");
    this.props.setCurrentNavigationLink(3);
    this.setState({ studyID, hadmId });
    InmunityApi.getPatientDescription(hadmId).then(details => {
      if (this.mounted) {
        let candidate = {
          hadmId: details.hadmId,
          upcomingVisit: details.upcomingVisit,
          lastSeen: details.lastEncounter,
          physician: details.phyisicianName,
          contactConsent: false,
          contactNotes: details.progressNotes,
          protocol: details.protocol,
          proceedToTrial: false
        };
        studyID
          ? InmunityApi.getWorkFlow(details.hadmId, studyID).then(workFlow => {
            details.workflow = workFlow;
            if (Object.keys(this.props.actions).length > 0) {
              details.workFlowActions = this.props.actions;
              this.setState({ details, candidate });
            } else {
              (async () => {
                await this.props.getWFActions();
                details.workFlowActions = this.props.actions;
                this.setState({ details, candidate });
              })();
            }
          })
          : InmunityApi.getWorkFlowAll(details.hadmId).then(workFlow => {
            details.workflow = workFlow;
            if (Object.keys(this.props.actions).length > 0) {
              details.workFlowActions = this.props.actions;
              this.setState({ details, candidate });
            } else {
              (async () => {
                await this.props.getWFActions();
                details.workFlowActions = this.props.actions;
                this.setState({ details, candidate });
              })();
            }
          });
      }
    });
    this.props.getExclusionReasons();
  }

  componentWillUnmount() {
    this.mounted = false;
  }


  handleChangeWorkflowIndex = (e) => {
    this.setState({ workflowIndex: e })
  }

  saveTrialNotes = () => {
    let { location } = this.props;
    localStorage.removeItem("ChangeDetected");
    if (this.state.open) {
      this.setState({ open: false });
    }
    let hadmId = location.id;
    let notes = document.querySelectorAll(".newNotes");
    let constacted = document.querySelectorAll(".newContacted");
    let data = [];
    if (notes.length > 0) {
      notes.forEach(el => {
        let obj = {};
        obj.contactMethod = "";
        obj.contactTime = moment(
          el.querySelector(".po-auth__data").textContent.split("-")[0],
          "DD/MM/YYYY"
        ).format("MM-DD-YYYY");
        obj.notes = el.querySelector("textarea").value;
        data.push(obj);
      });
    }
    if (constacted.length > 0) {
      constacted.forEach(el => {
        let obj = {};
        obj.contactMethod = el
          .querySelector(".css-va7pk8")
          .textContent.toLocaleLowerCase();
        obj.contactTime = moment(
          el.querySelector(".checkedCo").textContent.split("-")[0],
          "DD/MM/YYYY"
        ).format("MM-DD-YYYY");
        obj.notes = el.querySelector("textarea").value;
        obj.setReminder =
          el
            .querySelector(".react-switch-handle")
            .getAttribute("aria-checked") === "true";

        data.push(obj);
      });
    }
    if (data.length > 0 || this.state.isRemider) {
      let result = InmunityApi.saveTrialNotes(hadmId, data);
      let SaveChanges = true;
      this.setState({ SaveChanges });
      if (result) {
        this.setState({ saved: false });
      } else {
        this.setState({ saved: false });
      }
    }
  };

  closeModal = () => {
    let open = false;
    this.setState({ open });
  };

  handleChangeRemider = () => {
    this.setState({ isRemider: true });
  };

  SaveChangesDone = () => {
    let SaveChanges = false;
    this.setState({ SaveChanges });
  };

  cancelChanges = () => {
    localStorage.removeItem("ChangeDetected");
    if (this.state.open) {
      let open = false;
      this.setState({ open });
    }
  };

  updateWorkflow = () => {
    let details = this.state.details;
    const element = document.getElementsByClassName("custom-select-container")[0];
    const parrent = element.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
    const params = parrent.scrollTop
    this.state.studyID
      ? InmunityApi.getWorkFlow(details.hadmId, this.state.studyID).then(
        workFlow => {
          details.workflow = workFlow;
          this.setState({ details });
          parrent.scrollTo({top: params+1000 ,behavior: "smooth"})
        }
      )
      : InmunityApi.getWorkFlowAll(details.hadmId).then(workFlow => {
        details.workflow = workFlow;
        parrent.scrollTo({top: params+150 ,behavior: "smooth"})
        this.setState({ details });
      });

  };

  updateWF = workflow => {
    let details = this.state.details;
    details.workflow = workflow;
    this.setState({ details });
  };

  render() {
    let { details, hadmId, studyID, open } = this.state;
    const i = this.state.workflowIndex;
    const trialName = details.workflow && details.workflow[i] ? details.workflow[i].trial : null;
    const trialStudyId = details.workflow && details.workflow[i] ? details.workflow[i].id : null;

    return details ? (
      <div className="content-body-matches matchDetailsWrapper forImportPopup">
        <Modal isOpen={open} className="modal" overlayClassName="overlay">
          <div className="modal-container">
            <div className="contact-form">
              <h3 className="title-form">
                Please confirm leaving this page. All unsaved changes will be
                discarded.
              </h3>
              <div className="button-container unsaved-btn-container">
                <Link
                  to=""
                  onClick={e => {
                    e.preventDefault();
                    window.history.back();
                    this.closeModal();
                  }}
                >
                  <div className="submit-button">Confirm</div>
                </Link>
                <div
                  className="cancel-button"
                  onClick={() => this.closeModal()}
                >
                  Continue Editing
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className="mdForHeaderAndArrow forImportPopup">
          <div className="mdWrappers__leftBlock">
            <img
              src={arrowleft}
              alt="Back"
              className="mdarrowback"
              onClick={() => {
                if (localStorage.hasOwnProperty("ChangeDetected")) {
                  let open = true;
                  this.setState({ open });
                } else {
                  this.props.history.goBack();
                }
              }}
            />
            <h1 className="mdHeader">
              {this.props.location.detailTitle || "Match Details"}
            </h1>
          </div>
          {/* <div ghost#hidden
            className="mdForHeaderAndArrow__btn"
            onClick={this.saveTrialNotes}
          >
            Save changes
          </div> */}
          <Popup
            open={this.state.SaveChanges}
            closeOnDocumentClick
            onClose={this.SaveChangesDone}
            className="popup-status"
          >
            <div>
              <div
                className="closePendingPopupCross"
                onClick={this.SaveChangesDone}
              >
                <img src={crossicon} alt="Pupup Cross" />
              </div>
              <div className="popup-status__wrapper">
                {this.state.saved === true
                  ? "All changes are saved"
                  : "Saving failed. Please try again!"}
              </div>
            </div>
          </Popup>
        </div>
        <div className="mdpotn">
          <Overview
            details={details}
            updateWorkflow={this.updateWF}
            studyId={this.props.Id.id}
          />
          <WorkFlow
            candidate={this.state.candidate}
            studyId={studyID}
            onClick={this.handleChangeWorkflowIndex}
            workFlow={
              this.state.details.workflow ? this.state.details.workflow : null
            }
            workFlowActions={
              this.state.details.workFlowActions
                ? this.state.details.workFlowActions
                : null
            }
            currentHadmId={hadmId}
            updateWorkflow={this.updateWorkflow}
          />
          {/* <TrialNotes isRemindClick={this.handleChangeRemider.bind(this)} id={this.props.location.id} /> */}
          <PatientChart
            details={details}
            studyID={trialStudyId}
            searchId={this.state.searchId}
            hadmId={hadmId}
            trialName={trialName}
          />
          <SearchPopup />
        </div>
      </div>
    ) : (
        <Spinner />
      );
  }
}

function mapStateToProps(state) {
  return {
    Id: state.workFlowStudyId,
    actions: state.actions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationSubLink: id =>
      dispatch(setCurrentNavigationSubLink(id)),
    setCurrentNavigationLink: id => dispatch(setCurrentNavigationLink(id)),
    getWFActions: () => dispatch(getWFActions()),
    getExclusionReasons: () => dispatch(getExclusionReasons())
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MatchDetails)
);
