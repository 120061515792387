import React, { Component } from 'react'
import Select from 'react-select'
import './index.css'
import Filter from './../../Exclusions/Filter'
import { connect } from 'react-redux'

const options = [
  { value: 'upcoming', label: 'Upcoming Visit' },
  { value: 'contact', label: 'Contact Consent' },
  // { value: "physician", label: "Physician" },
  { value: 'lastseen', label: 'Last Seen' },
  { value: 'immunity', label: 'Immunity ID' },
  { value: 'age', label: 'Age' },
  { value: 'status', label: 'Status'},
  { value: 'score', label: 'Score' }
]

class SearchSort extends Component {
  state = {
    selectedOption: {label: 'Score', value: 'score'},
    patients: [],
    initialResults: [],
    isReverseSort: true,
    filtersArray: [],
    url: ``
  }

  static getDerivedStateFromProps(props, state) {
    if (props.patients !== state.patients) {
      return {
        patients: props.patients,
        initialResults: props.initialPatients,
        loadMore: props.patients.length === props.initialPatients.length
      }
    } else {
      return null
    }
  }

  componentDidMount() {
    this.setState({
      patients: [...this.props.patients],
      initialResults: [...this.props.initialPatients]
    })
  }

  handleChange = selectedOption => {
    this.sortPatients(
      selectedOption.value,
      this.state.isReverseSort,
      this.state.url
    )
    this.setState({ selectedOption })
  }

  reverseSort = () => {
    this.setState({ isReverseSort: !this.state.isReverseSort }, () => {
      if (this.state.selectedOption.value) {
        this.sortPatients(
          this.state.selectedOption.value,
          this.state.isReverseSort,
          this.state.url
        )
      }
    })
  }

  sortPatients = (flag, reverse, url) => {
    if (flag) {
      if (reverse) {
        flag = '-' + flag
      }
      if (url.length !== 0) {
        url += `&ordering=${flag}`
      } else {
        url += `ordering=${flag}`
      }
    }
    this.props.sortPatients(url.replace('&&', '&'))
  }

  applyFilter = filters => {
    let url = ``
    filters.map(filter => {
      switch (filter.filterName) {
        case 'Score':
          url += `score_min=${filter.value.min}&score_max=${filter.value.max}&`
          break
        case 'Age':
          url += `age_min=${filter.value.min}&age_max=${filter.value.max}&`
          break
        case 'Status':
          url += `status=${filter.value.min}&`
          break
        case 'Gender':
          if (filter.value.min === 'Male') {
            url += `gender=M&`
          } else {
            url += `gender=F&`
          }
          break
        case 'Last seen':
          url += `lastseen_min=${filter.dateFrom}&lastseen_max=${
            filter.dateTo
            }&`
          break
        case 'Next visit':
          url += `upcoming_min=${filter.dateFrom}&upcoming_max=${
            filter.dateTo
            }&`
          break
        default:
          return null
      }
      return null
    })
    url = url.replace('&&', '&');
    this.setState({ url })
    this.sortPatients(
      this.state.selectedOption.value,
      this.state.isReverseSort,
      url
    )
  }

  render() {
    let { isReverseSort, selectedOption } = this.state
    return (
      <div className='sorter_addFilter trialsPage'>
        <div
          className='isimginputWrapp'
          style={{ justifyContent: 'flex-start' }}
        >
          <Filter type='searchFilter' applyFilter={this.applyFilter} />
        </div>
        <div className='isssWrapp'>
          <div className='ntw__headerWrapp-btn' onClick={this.reverseSort}>
            {isReverseSort
              ? selectedOption.value === 'upcoming' ||
                selectedOption.value === 'lastseen' ||
                selectedOption.value === 'immunity' ||
                selectedOption.value === 'age' ||
                selectedOption.value === 'status' ||
                selectedOption.value === 'score'
                ? '9 - 0'
                : 'Z - A'
              : selectedOption.value === 'upcoming' ||
                selectedOption.value === 'lastseen' ||
                selectedOption.value === 'immunity' ||
                selectedOption.value === 'age' ||
                selectedOption.value === 'status' ||
                selectedOption.value === 'score'
                ? '0 - 9'
                : 'A - Z'}
          </div>
          <span>Sort by:</span>
          <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={options}
            className={'sort_select'}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps() {
  return {}
}

export default connect(mapStateToProps)(SearchSort)
