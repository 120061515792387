import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import crossicon from "./../../../../../images/icon-close-w.svg";
import ItemRow from "./ItemRow";

export default function ItemsPopup({
  open,
  closePopup,
  filter,
  forSending,
  removeItem,
  makeCheck,
  setCodes
}) {
  const [items, setItems] = useState([]);

  const closeItemsPopup = () => {
    closePopup();
  };

  const searchInSelectedItems = e => {
    const newItems = forSending.filter(item => {
      return (
        item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
      );
    });
    setItems(newItems);
  };

  useEffect(() => {
    setItems(forSending);
  }, [forSending]);

  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={closeItemsPopup}
      className="is_popup"
    >
      <div>
        <div className="closePendingPopupCross" onClick={closeItemsPopup}>
          <img src={crossicon} alt="Cross" />
        </div>
        <div className="import-container ppWrapper disPWrapp">
          <div className="header_items_sel_pop">
            {filter.split(" ")[0].toString() + "s selected"}
          </div>
          <div className="trial-search-container space-btw">
            <div className="dis-center forSearchResult">
              <img
                className="icon-left-search"
                src={require("./../../../../../images/search.svg")}
                alt="Search"
              />
              <input
                type="text"
                className="trial-search-field"
                name="trialName"
                id="trialName"
                placeholder="Search"
                onChange={searchInSelectedItems}
              />
            </div>
          </div>
          <div className="si_container">
            {items.map(el => {
              return (
                <ItemRow
                  key={el.id}
                  el={el}
                  removeItem={removeItem}
                  makeCheck={makeCheck}
                  setCodes={setCodes}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Popup>
  );
}
