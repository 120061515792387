import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function authReducer(state = initialState.userToken, action) {
  switch(action.type) {
    case types.LOG_IN_OUT:
      return action.token
    case types.SEND_SMS:
      return action.sendSms
    case types.VERIFY_ACCOUNT:
      return action.verifyCode
    case types.RE_SEND_SMS:
      return action.reSendSms
    default:
      return state;
  }
}
