import React, { Fragment } from "react";
import Select from "react-select";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate } from "react-day-picker/moment";
import _ from "lodash";

const Smoking = React.memo(
  ({
    setValue,
    setInputValue,
    include,
    classification,
    status,
    frequency,
    amount,
    date_quit,
    pack_years,
    classOptions,
    statusOptions,
    frequencyOptions,
    amountOptions,
    inExOptions,
    handleSetInfo
  }) => {
    const past = status.value === "Past";
    const disabled =
      classification &&
      (classification.value === "NonSmoker" ||
        classification.value === "Unknown");
    const quit = status.value === "Past";
    const unknown = status.value === "Unknown";
    return (
      <Fragment>
        <div className="ntw__ti-input nextLine forGeoSelect">
          <span className="ntw__ti-inputlabel">Classification</span>
          <Select
            name="classification"
            value={classification}
            onChange={setValue}
            options={classOptions}
            className="sort_select withInput"
          />
        </div>

        <div className="ntw__ti-input nextLine forGeoSelect">
          <span className={`ntw__ti-inputlabel${disabled ? " sel_dis" : ""}`}>
            Status
          </span>
          <Select
            name="status"
            value={status}
            onChange={setValue}
            options={statusOptions}
            className="sort_select withInput smoke_sel"
            isDisabled={disabled}
          />
        </div>

        <div className="ntw__ti-input nextLine forGeoSelect">
          <span
            className={`ntw__ti-inputlabel${
              disabled || unknown ? " sel_dis" : ""
            }`}
          >
            Amount
          </span>
          <Select
            name="amount"
            value={amount}
            onChange={setValue}
            options={amountOptions}
            placeholder="N/A"
            className="sort_select withInput smoke_sel"
            isDisabled={disabled || unknown}
          />
        </div>

        <div className="ntw__ti-input nextLine smoke_filter">
          <span
            className={`ntw__ti-inputlabel${
              disabled || past || unknown ? " sel_dis" : ""
            }`}
          >
            Frequency
          </span>
          <Select
            name="frequency"
            value={frequency}
            onChange={setValue}
            placeholder="N/A"
            options={frequencyOptions}
            className="sort_select withInput smoke_sel"
            isDisabled={disabled || past || unknown}
          />
        </div>

        <div className="ntw__ti-input nextLine smoke_filter">
          <span
            className={`ntw__ti-inputlabel${
              disabled || unknown ? " sel_dis" : ""
            }`}
          >
            Pack Years
          </span>
          <input
            name="pack_years"
            type="number"
            disabled={disabled || unknown}
            value={pack_years}
            onChange={({ target: { name, value } }) =>
              setInputValue(name, value)
            }
            onBlur={() => handleSetInfo("pack_years")}
            placeholder={disabled ? "N/A" : ""}
          />
        </div>

        <div className="ntw__ti-input nextLine quit_date_cl smoke_filter">
          <span className={`ntw__ti-inputlabel${!quit ? " sel_dis" : ""}`}>
            Date Quit
          </span>
          <DayPickerInput
            name="date_quit"
            value={!date_quit ? '-' : date_quit}
            onDayChange={date => setInputValue("date_quit", date)}
            inputProps={{ disabled: !quit }}
            formatDate={formatDate}
            format="LL"
            placeholder={quit ? "Select date" : "N/A"}
          />
        </div>

        <div className="ntw__ti-input nextLine smoke_filter">
          <span className="ntw__ti-inputlabel">Include/Exclude</span>
          <Select
            name="include"
            value={include}
            onChange={setValue}
            options={inExOptions}
            className="sort_select withInput smoke_sel"
          />
        </div>
      </Fragment>
    );
  }
);

export default Smoking;
