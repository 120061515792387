import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import InmunityApi from "../../../../api/InmunityApi";
import LabTable from "./LabTable";
import Tabs from "./Tabs";

import "./index.css";

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labValues: localStorage.getItem("labValues")
        ? JSON.parse(localStorage.getItem("labValues"))
        : [],
      labOptions: [{ value: "all", label: "All" }],
      selectedOption: { value: "all", label: "All" },
      spinner: false
    };
  }

  componentDidMount() {
    let id = this.props.id;
    this.setState({ spinner: true });
    InmunityApi.getLabValues(id).then(labValues => {
      let categiries = labValues.map(lab => {
        let obj = {};
        obj.value = lab.category.toLowerCase();
        obj.label = lab.category;
        return obj;
      });
      let labOptions = this.state.labOptions;
      let Options = _.uniqBy(categiries, "value");
      labOptions = labOptions.concat(Options);
      this.setState({ labValues, spinner: false });
      localStorage.setItem("labValues", JSON.stringify(labValues));

      this.setState({ labOptions });
    });
  }

  filterLab = category => {
    if (category !== "All") {
      let array = JSON.parse(localStorage.getItem("labValues"));
      let labValues = array.filter(item => item.category === category);
      this.setState({ labValues });
    } else {
      let labValues = JSON.parse(localStorage.getItem("labValues"));
      this.setState({ labValues });
    }
  };

  render() {
    const { searchText } = this.props;
    let { labValues, spinner } = this.state;
    if (searchText[0]) {
      labValues = labValues.filter(row => {
        const searchWord = searchText[0].toLowerCase();
        const { label, value, uom, category, code } = row;
        return (
          (label && label.toLowerCase().includes(searchWord)) ||
          (value && value.toLowerCase().includes(searchWord)) ||
          (uom && uom.toLowerCase().includes(searchWord)) ||
          (category && category.toLowerCase().includes(searchWord)) ||
          (code && code.toLowerCase().includes(searchWord))
        );
      });
    }
    return (
      <div className="wrapperLabAndNurse">
        <div className="pcdownWrapp__block width100">
          <div className="popup-notes">
            <div>
              <div>
                <div className="pcdownWrapp__block-container newTrialWrapp">
                  <h4>Laboratory Tests</h4>
                </div>
                {!spinner ? (
                  <LabTable labValues={labValues} searchText={searchText} />
                ) : (
                  <div className="load-labs">
                    <img
                      className="big-spinner"
                      src={require("../../../../images/spinner.gif")}
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Tabs
          nurse={this.props.nurseNotes}
          progress={this.props.progressNotes}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    hadmId: state.currentTrial
  };
}
export default connect(mapStateToProps)(Notes);
