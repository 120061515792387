import React, { Component } from "react";
import ReactSlider from "react-slider";
import Select from "react-select";
import Switch from "react-switch";
import "./index.css";

const drugOptions = [
  { value: "opiates", label: "opiates" },
  { value: "cocaine", label: "cocaine" },
  { value: "marijuana", label: "marijuana" },
  { value: "PCP", label: "PCP" },
  { value: "benzodiazepines", label: "benzodiazepines" },
  { value: "amphetamines", label: "amphetamines" }
];

const customStyles = {
  control: base => ({
    ...base,
    maxHeight: "43px",
    "&:hover": { borderColor: "lightgray" },
    border: "1px solid lightgray",
    boxShadow: "none"
  })
};

class Sexes extends Component {
  constructor() {
    super();
    // this.state = {
    //   drugOption: [],
    //   values: []
    // };
    this.refStart = React.createRef();
    this.refEnd = React.createRef();
    this.refFirstValue = React.createRef();
    this.refSecondValue = React.createRef();
  }

  getPositionAtCenter = element => {
    const { top, left, width, height } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2
    };
  };

  getDistanceBetweenElements = (a, b) => {
    const aPosition = this.getPositionAtCenter(a);
    const bPosition = this.getPositionAtCenter(b);

    return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);
  };

  handleChangeSelectSmoke = smokeSelect => {
    this.props.setInfo("smoking_status", smokeSelect);
  };

  handleChangeSelectSubstance = substanceSelect => {
    this.props.setInfo("substance_status", substanceSelect);
  };

  handleChangeDrugOption = drugOption => {
    let values = [];
    drugOption.forEach(el => {
      values.push(el.value);
    });
    this.props.setInfo("medication_allergy", values);
  };

  giveActive = ({ target }) => {
    const text = target.innerText;
    const { setInfo } = this.props;
    if (text === "Male") {
      setInfo("gender", "M");
    } else if (text === "Female") {
      setInfo("gender", "F");
    } else if (text === "Both") {
      setInfo("gender", "B");
    }
    let elements = target.parentNode.querySelectorAll(".filters-sexes_element");
    elements.forEach(el => {
      el.classList.remove("active_sex");
    });
    target.classList.add("active_sex");
  };

  changeSliderValue = value => {
    let minValue = value[0];
    let maxValue = value[1];
    const { setInfo } = this.props;

    let start = this.refStart.current;
    let end = this.refEnd.current;
    let distance = this.getDistanceBetweenElements(start, end);
    if (distance < 21 && distance > 0) {
      this.refFirstValue.current.style.marginLeft = "-5px";
      this.refSecondValue.current.style.marginLeft = "10px";
    } else if (distance === 0) {
      this.refFirstValue.current.style.marginLeft = "2px";
      this.refSecondValue.current.style.marginLeft = "2px";
    } else {
      this.refFirstValue.current.style.marginLeft = "0";
      this.refSecondValue.current.style.marginLeft = "0";
    }
    setInfo("age_min", minValue);
    setInfo("age_max", maxValue);
  };

  render() {
    const {
      genderError,
      defaultGender,
      defaultAgeMin,
      defaultAgeMax,
      defaultSmokingStatus,
      defaultSubstanceStatus,
      defaultMedicationAllergy
    } = this.props;
    let drugOption = [];
    defaultMedicationAllergy.forEach(el => {
      drugOption.push({ value: el, label: el });
    });
    return (
      <div className="filters-sexes">
        <span className="filters-sexes__message">Sexes eligible for study</span>
        <div
          className={
            genderError
              ? "filters-sexes_container redBorder"
              : "filters-sexes_container"
          }
        >
          <div
            className={
              defaultGender === "M"
                ? "filters-sexes_element get_sex active_sex"
                : "filters-sexes_element get_sex"
            }
            onClick={this.giveActive}
          >
            Male
          </div>
          <div
            className={
              defaultGender === "F"
                ? "filters-sexes_element get_sex active_sex"
                : "filters-sexes_element get_sex"
            }
            onClick={this.giveActive}
          >
            Female
          </div>
          <div
            className={
              defaultGender === "B"
                ? "filters-sexes_element get_sex active_sex"
                : "filters-sexes_element get_sex"
            }
            onClick={this.giveActive}
          >
            Both
          </div>
        </div>
        <span className="filters-sexes_range">Age range</span>
        <div className="filters-sexes__sliderWrap">
          <ReactSlider
            min={0}
            max={99}
            withBars
            onChange={this.changeSliderValue}
            defaultValue={[defaultAgeMin, defaultAgeMax]}
          >
            <div>
              <div className="sexSlider filterSlider" ref={this.refStart} />
              <span ref={this.refFirstValue}>{defaultAgeMin}</span>
            </div>
            <div>
              <div className="sexSlider filterSlider" ref={this.refEnd} />
              <span ref={this.refSecondValue}>{defaultAgeMax}</span>
            </div>
          </ReactSlider>
        </div>
        <div className="filters-social">
          {/* <span className="filters-social__title">Social History</span>
          <div className="filters-social__container">
            <div className="forSpanSelects">
              <span className="permition">Smoking Status</span>
              <div className="perm-sel-wrapper">
                <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext">
                  <Switch
                    onChange={this.handleChangeSelectSmoke}
                    checked={defaultSmokingStatus}
                    onColor="#5BBEBF"
                    onHandleColor="#FFF"
                    handleDiameter={14}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={16}
                    width={30}
                    className="reminder"
                  />
                </div>
              </div>
            </div>
            <div className="forSpanSelects">
              <span className="permition">Substance Use Status</span>
              <div className="perm-sel-wrapper">
                <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext">
                  <Switch
                    onChange={this.handleChangeSelectSubstance}
                    checked={defaultSubstanceStatus}
                    onColor="#5BBEBF"
                    onHandleColor="#FFF"
                    handleDiameter={14}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={16}
                    width={30}
                    className="reminder"
                  />
                </div>
                <div className="ntw__ti-input ntw__ti-iTrialNCT ntw__ti-ifreetext multi-sel">
                  <Select
                    value={drugOption}
                    onChange={this.handleChangeDrugOption}
                    options={drugOptions}
                    className="sort_select withInput"
                    isMulti={true}
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    joinValues
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default Sexes;
