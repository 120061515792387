import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import ActionsOverview from '../ActionsOverview'

import './index.css'

class Overview extends React.Component {
  state = {
    uploadedFiles: [],
    hadmId: this.props.details.hadmId ? this.props.details.hadmId : '',
    gender: this.props.details.gender ? this.props.details.gender : '',
    age: this.props.details.age ? this.props.details.age : '',
    lastEncounter: this.props.details.lastEncounter
      ? this.props.details.lastEncounter
      : '',
    upcomingVisit: this.props.details.upcomingVisit
      ? this.props.details.upcomingVisit
      : '',
    physicians: this.props.details.physicians
      ? this.props.details.physicians
      : '',
    diagnosis: this.props.details.diagnosis ? this.props.details.diagnosis : '',
    protocol: this.props.details.protocol ? this.props.details.protocol : '',
    patientName: this.props.details.patientName
      ? this.props.details.patientName
      : '',
    immunity: this.props.details.immunity ? this.props.details.immunity : '',
    workflow: this.props.details.workflow ? this.props.details.workflow : ''
  }

  uploadedFile = e => {
    let input = e.target
    let uploadedFiles = [...this.state.uploadedFiles]
    let obj = {}
    obj.file = null
    obj.title = null
    // let textSpan =  e.target.parentNode.previousSibling.children[0].children[0];
    // let textDoc = e.target.parentNode.nextSibling.children[0];
    if (input.files && input.files[0]) {
      let reader = new FileReader()
      reader.onload = function (e) {
        // textSpan.parentNode.classList.remove('hidden')
      }
      obj.title = input.files[0].name
      obj.file = input.files[0]
      uploadedFiles.push(obj)
      this.setState({
        uploadedFiles
      })
      reader.readAsDataURL(input.files[0])
    }
  }

  deleteFile = e => {
    let uploadedFiles = [...this.state.uploadedFiles]
    let files = document
      .querySelector('.filesWrapp')
      .querySelectorAll('.uploadedFile')
    files.forEach((el, i) => {
      if (e.target.parentNode.parentNode === el) {
        uploadedFiles.splice(i, 1)
        this.setState({
          uploadedFiles
        })
      }
    })
  }

  static getDerivedStateFromProps (props, state) {
    if (
      props.details.workflow[0] &&
      props.details.workflow[0].patient !== state.patientName
    ) {
      return {
        patientName: props.details.workflow[0].patient
      }
    } else return null
  }

  render () {
    const {
      hadmId,
      gender,
      age,
      lastEncounter,
      upcomingVisit,
      physicians,
      diagnosis,
      patientName,
      immunity,
      workflow
    } = this.state

    return (
      <div className='patientOverview'>
        <div className='poHeader-container'>
          <div className='poHeader'>Patient Overview</div>
          <ActionsOverview
            hadmID={hadmId}
            workflow={workflow}
            updateWorkflow={this.props.updateWorkflow}
            currentTrial={!!this.props.studyId}
            studyId={this.props.studyId}
          />
        </div>
        <div className='po'>
          <div className='wrapForTV'>
            <span className='potitle'>ID</span>
            <span className='potitlevalue'>{immunity}</span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Name</span>
            <span className='potitlevalue'>{patientName}</span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Age</span>
            <span className='potitlevalue'>{age}</span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Gender</span>
            <span className='potitlevalue'>
              {gender === 'F' ? 'Female' : 'Male'}
            </span>
          </div>
          <div className='wrapForTV'>
            <span className='potitle'>Diagnosis</span>
            <span className='potitlevalue'>{diagnosis}</span>
          </div>
        </div>
        <div className='pograyLine' />
        <div className='poPLN'>
          <div className='poPLN_wrap'>
            <span className='pln__title'>{`${
              physicians && physicians.length > 1 ? 'Physicians' : 'Physician'
            }`}</span>
            <div className='pln__value fdColumn'>
              {physicians && physicians.length > 0
                ? physicians.map((value, i) => {
                  return i === physicians.length - 1 ? (
                    <span key={i}>
                      {value.name} ({value.specialty})
                    </span>
                  ) : (
                    <span key={i}>
                      {value.name} ({value.specialty})
                    </span>
                  )
                })
                : null}
            </div>
          </div>
          <div className='poPLN_wrap'>
            <span className='pln__title'>Last Encounter</span>
            <span className='pln__value'>
              {moment(lastEncounter.date)
                .format('DD/MM/YYYY - hh:mm A')
                .toLowerCase()}{' '}
              ({lastEncounter.name})
            </span>
          </div>
          <div className='poPLN_wrap'>
            <span className='pln__title'>Next Appointment</span>
            <span className='pln__value'>
              {moment(upcomingVisit.date)
                .format('DD/MM/YYYY - hh:mm A')
                .toLowerCase()}{' '}
              ({upcomingVisit.name})
            </span>
          </div>
        </div>
        <div className='pograyLine' />
        <div className='attachmentsWrapp'>
          <span className='attachmentsWrappTitle'>Attachments</span>
          <div className='filesWrapp'>
            {this.state.uploadedFiles &&
              this.state.uploadedFiles.length > 0 &&
              this.state.uploadedFiles.map((el, i) => {
                return (
                  <div className='uploadedFile' key={el.title + i}>
                    <span>{el.title}</span>
                    <div className='cross'>
                      <img
                        src={require('../../../images/close-circle-o-anticon.svg')}
                        alt='Close Circle'
                        onClick={this.deleteFile}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
          <div className='addNew'>
            <img
              src={require('../../../images/clip.svg')}
              alt='Close Circle'
              height='16px'
              style={{ marginRight: '6px' }}
            />
            Add New
            <input
              id='attachment'
              className='forSending'
              type='file'
              onChange={this.uploadedFile}
            />
          </div>
        </div>
        <div className='pograyLine' />
        <div className='last-status-workflow'>
          <div className='title'>Status</div>
          <div className='status'>
            {this.props.globalState.workFlowState &&
              this.props.globalState.workFlowState.status}
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    globalState: state.globalState
  }
}

export default connect(mapStateToProps)(Overview)
