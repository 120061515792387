import React from "react";
import Popup from "reactjs-popup";
import crossicon from "./../../../../../images/icon-close-w.svg";

export default function WarningPopup({ open, closeWarningPopup, list }) {
  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={closeWarningPopup}
      className="is_popup"
    >
      <div>
        <div className="closePendingPopupCross" onClick={closeWarningPopup}>
          <img src={crossicon} alt="Cross" />
        </div>
        <div className="saveUserPopupText">
          {`The following items are selected for both inclusion and exclusion
          criteria. Please amend the search to only include ${list} as either
          inclusion or exclusion criteria`}
        </div>
      </div>
    </Popup>
  );
}
