import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({to, position, click, active, icon, text}) => {
    return (
        <div className="trialbar-title-nav">
    <Link
        to={to}
        onClick={e => {
            e.preventDefault();
            click()
        }}
        >
        <div
            className={
            active
                ? "nav-item nav-item-active"
                : "nav-item"
            }
        >
            <img
            className="navside-icon-img"
            src={icon}
            alt=""
            />
            {text}
        </div>
    </Link>
    </div>
    )
}
export default NavLink