import React, { useEffect, useState } from 'react'
import { Spinner } from './../../../../ui/spinner'
import Switch from 'react-switch'

export default function InclusionCriteria ({
  mismatch,
  data,
  dataExclude,
  spinner
}) {
  const [orderedData, setOrderedData] = useState([])
  const [orderedExData, setOrderedExData] = useState([])
  const [checked, setChecked] = useState(true)
  const handleChange = check => {
    setChecked(check)
  }
  useEffect(
    () => {
      const newData = [...data]
      newData.sort((a, b) => b.count - a.count)
      setOrderedData(newData)
    },
    [data]
  )
  useEffect(
    () => {
      if (dataExclude) {
        const newExData = [...dataExclude]
        newExData.sort((a, b) => b.count - a.count)
        setOrderedExData(newExData)
      } else return
    },
    [dataExclude]
  )
  return (
    <div className='inc_pat_wrapp'>
      <div className='inc_title'>
        <span>
          Common {mismatch} {checked ? 'Inclusion' : 'Exclusion'} Criteria
        </span>
        {dataExclude && (
          <Switch
            onChange={handleChange}
            checked={checked}
            onColor='#0075b3'
            onHandleColor='#FFF'
            handleDiameter={14}
            uncheckedIcon={false}
            checkedIcon={false}
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            height={16}
            width={30}
            className='reminder'
          />
        )}
      </div>
      <div className='criteria_number_wrapp'>
        <div className='criteria_side'>
          <div className='criteria_header'>
            <div className='criteria-header_name'>Criteria</div>
            <div className='criteria-header_count'>Number of patients</div>
          </div>
          {spinner ? (
            <Spinner />
          ) : (
            <div className='criteria_body'>
              {checked
                ? orderedData.map((el, i) => (
                  <div className='criteria_item' key={i}>
                    <div className='count_name'>
                      {el && el.name ? el.name : ''}
                    </div>
                    <div className='count_item'>
                      {el && el.count ? el.count : ''}
                    </div>
                  </div>
                ))
                : orderedExData.map((el, i) => (
                  <div className='criteria_item' key={i}>
                    <div className='count_name'>
                      {el && el.name ? el.name : ''}
                    </div>
                    <div className='count_item'>
                      {el && el.count ? el.count : ''}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
