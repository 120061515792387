import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { push } from "react-router-redux";
import Popup from "reactjs-popup";

import i18n from "../i18n";
import { verifyAccountType } from "../actions/trialActions";
import InmunityApi from "../api/InmunityApi";

import "./styles/ResetP.css";

import crossicon from "./../images/icon-close-w.svg";

class ResetP extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      password: "",
      passwordC: "",
      validPassword: false,
      validPasswordC: false,
      regexPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
      validCharacters: false,
      validUpperCase: false,
      validLowerCase: false,
      validNumber: false,
      regexCharacters: /^([A-Za-z0-9]){8,15}/,
      regexUpperCase: /^(?=.*[A-Z])/,
      regexLowerCase: /^(?=.*[a-z])/,
      regexNumber: /^(?=.*[0-9])/,
      id: "",
      token: "",
      open: false,
      message: ""
    };
    this.resetPassword = this.resetPassword.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.accountRegistration = this.accountRegistration.bind(this);
  }

  componentDidMount() {
    let urlString = window.location.href;
    document.querySelector("body").classList.add("noscroll");
    let url = new URL(urlString);
    let id = url.searchParams.get("id");
    let token = url.searchParams.get("token");
    this.setState({
      id,
      token
    });
  }

  handleInputChange(event) {
    let value = event.target.value;
    let name = event.target.name;
    let state = this.state;

    if (name === "password") {
      if (state.regexPassword.test(value)) {
        state.validPassword = true;
      } else {
        state.validPassword = false;
      }
      if (state.regexCharacters.test(value)) {
        state.validCharacters = true;
      } else {
        state.validCharacters = false;
      }
      if (state.regexUpperCase.test(value)) {
        state.validUpperCase = true;
      } else {
        state.validUpperCase = false;
      }
      if (state.regexLowerCase.test(value)) {
        state.validLowerCase = true;
      } else {
        state.validLowerCase = false;
      }
      if (state.regexNumber.test(value)) {
        state.validNumber = true;
      } else {
        state.validNumber = false;
      }
    }

    if (name === "passwordC") {
      if (state.regexPassword.test(value)) {
        state.validPasswordC = true;
      } else {
        state.validPasswordC = false;
      }
    }

    state[name] = value;
    this.setState(state, () => {
      console.log(state);
    });
  }

  resetPassword() {
    let inputPassword = document.getElementById("password");
    let inputPasswordC = document.getElementById("passwordC");
    if (
      this.state.validPassword &&
      this.state.validCharacters &&
      this.state.validUpperCase &&
      this.state.validLowerCase &&
      this.state.validNumber &&
      this.state.validPassword === this.state.validPasswordC
    ) {
      let obj = {};
      obj.password = this.state.password;
      obj.password_confirm = this.state.password;
      InmunityApi.setNewPassword(obj, this.state.id, this.state.token)
        .then(resolve => {
          if (resolve.status !== 200) {
            this.setState({
              open: true,
              message: "Error occured while changing password!"
            });
          } else {
            this.setState({
              open: true,
              message: "Password was changed successfully."
            });
            setTimeout(() => {
              this.setState({ message: "" });
              this.props.history.push({
                pathname: "/"
              });
            }, 3000);
          }
        })
        .catch(error =>
          console.log("Error occured while reseting password", error)
        );
    } else {
      inputPassword.classList.add("border-red");
      inputPasswordC.classList.add("border-red");
      this.props.dispatch(
        verifyAccountType({ validationError: i18n.t("login.errors.validFail") })
      );
    }
  }

  accountRegistration() {
    this.props.dispatch(push("/account-registration"));
  }

  closeModal = () => {
    this.setState({ open: false, message: "" });
  };

  render() {
    var fireRedirect = false;

    // if (this.props.userToken.token) {
    //   this.currentUser();
    //   fireRedirect = true;
    // }

    // if (this.props.userToken.validationError) {
    // }

    return (
      <div className="base-container forBlueBC">
        <div className="base-container">
          <div className="login-container forImportPopup">
            <div className="left-login">
              <img src={require("../images/logo-w.png")} alt="Logo" />
            </div>
            <Popup
              open={this.state.open}
              closeOnDocumentClick
              onClose={this.closeModal}
              className="saveUserPopup"
            >
              <div>
                <div
                  className="closePendingPopupCross"
                  onClick={this.closeModal}
                >
                  <img src={crossicon} alt="Cross" />
                </div>
                <div className="saveUserPopupText">{this.state.message}</div>
              </div>
            </Popup>
            <div className="right-login">
              <div className="login-fields">
                <h1 className="title-h1">
                  {this.props.userToken.validationError
                    ? i18n.t("login.errors.titleAuthFail")
                    : i18n.t("resetPasswordSuccess.title")}{" "}
                </h1>
                <h4 className="title-h4">
                  {this.props.userToken.validationError
                    ? this.props.userToken.validationError
                    : i18n.t("resetPasswordSuccess.subTitle")}{" "}
                </h4>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    this.resetPassword();
                  }}
                >
                  <label htmlFor="password" className="text-small">
                    {i18n.t("resetPasswordSuccess.password")}
                  </label>
                  <div className="input-field">
                    <input
                      type="password"
                      className="text-field login reset"
                      name="password"
                      id="password"
                      placeholder={`${i18n.t("login.passwordPlaceHolder")}`}
                      maxLength="15"
                      onChange={this.handleInputChange}
                    />
                    <span className="statusValid" id="passwordStatusValid" />
                    <div className="validationStatus">
                      <div className="div-two-items">
                        <span className="text-small">
                          {i18n.t(
                            "resetPasswordSuccess.validations.characters"
                          )}
                        </span>
                        <img
                          className="icon-validation"
                          src={
                            this.state.validCharacters
                              ? require("../images/icon-check.svg")
                              : require("../images/icon-close.svg")
                          }
                          alt={this.state.validCharacters ? "Check" : "Close"}
                        />
                      </div>
                      <div className="div-two-items">
                        <span className="text-small">
                          {i18n.t("resetPasswordSuccess.validations.uppercase")}
                        </span>
                        <img
                          className="icon-validation"
                          src={
                            this.state.validUpperCase
                              ? require("../images/icon-check.svg")
                              : require("../images/icon-close.svg")
                          }
                          alt={this.state.validUpperCase ? "Check" : "Close"}
                        />
                      </div>
                      <div className="div-two-items">
                        <span className="text-small">
                          {i18n.t("resetPasswordSuccess.validations.lowercase")}
                        </span>
                        <img
                          className="icon-validation"
                          src={
                            this.state.validLowerCase
                              ? require("../images/icon-check.svg")
                              : require("../images/icon-close.svg")
                          }
                          alt={this.state.validLowerCase ? "Check" : "Close"}
                        />
                      </div>
                      <div className="div-two-items">
                        <span className="text-small">
                          {i18n.t("resetPasswordSuccess.validations.number")}
                        </span>
                        <img
                          className="icon-validation"
                          src={
                            this.state.validNumber
                              ? require("../images/icon-check.svg")
                              : require("../images/icon-close.svg")
                          }
                          alt={this.state.validNumber ? "Check" : "Close"}
                        />
                      </div>
                    </div>
                  </div>

                  <label htmlFor="passwordC" className="text-small">
                    {i18n.t("resetPasswordSuccess.passwordC")}
                  </label>
                  <div className="input-field">
                    <input
                      type="password"
                      className="text-field login"
                      name="passwordC"
                      id="passwordC"
                      placeholder={`${i18n.t("login.passwordPlaceHolder")}`}
                      maxLength="15"
                      onChange={this.handleInputChange}
                    />
                    <span className="statusValid" id="passwordCStatusValid" />
                  </div>

                  <button type="submit" className="resetP-button">
                    {i18n.t("resetPasswordSuccess.button")}
                  </button>
                </form>
              </div>

              {fireRedirect && <Redirect to={"/trials/"} />}

              <p className="text-small sign-up-login">
                {i18n.t("login.account")}
                <span
                  className="text-bold color-blueLink td-none"
                  href=""
                  onClick={e => {
                    e.preventDefault();
                    this.accountRegistration();
                  }}
                >
                  {" "}
                  {i18n.t("login.sing")}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userToken: state.userToken
  };
}

export default withRouter(connect(mapStateToProps)(ResetP));
