import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function setPatientOverview(state = initialState.patientOverview, action) {
  switch(action.type) {
    case types.PATIENT_OVERVIEW:
      return action.patientOverview
    default:
      return state;
  }
}
