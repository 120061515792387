import React, { Component } from "react";
import ImmunityApi from "./../../../../api/InmunityApi";
import iconCollapse from "./../../../../images/arrow-collapse.svg";
import { connect } from "react-redux";
import IncludeExclude from "./IncludeExclude";
import capitalize from "./../../../../utils/capitalize";

class SelectedTreeComponent extends Component {
  constructor() {
    super();
    this.state = {
      checkInclude: false,
      checkExclude: false,
      initial: true,
      medications: [],
      show: false,
      showDisList: false,
      ids: []
    };
    this.myRef = React.createRef();
    this.showDisRef = React.createRef();
  }

  componentDidMount() {
    const { open, search, searchCategory } = this.props;
    open && this.setState({ show: true });
    search && this.showDisRef.current.click();
    searchCategory &&
      this.showDisRef.current.parentNode.parentNode.parentNode.classList.add(
        "activeValue"
      );
  }


  scrollView = (active) => {
    if (!this.props.myRef || !this.props.myRef.current || !active) {
      return;
    }

    let element = this.props.myRef.current;
    let interval = window.setInterval(
      function () {
        element.scrollTop += 10;
        if (active.offsetTop - element.clientHeight + 50 <= element.scrollTop) {
          window.clearInterval(interval);
        }
      }, 5
    )
  }

  makeActive = (event, id) => {
    if (this.props.selectedView) return;
    const { makeActive, activeTab } = this.props;
    const active = document.querySelector(".activeValue");
    this.scrollView(active);
    active && active.classList.remove("activeValue");
    event.classList.add("activeValue");
    makeActive(id, activeTab);
  };

  checkLeafs = (include, exclude, id) => {
    const {
      filter,
      activeTab,
      diseasesDOList,
      diseasesICD9CMList,
      medicationsATCList,
      medicationsEPCList,
      medicationsMOAList,
      proceduresList
    } = this.props;
    const incl = include === true ? true : exclude === true ? false : "delete";
    let list;
    if (filter === "Medication Filters") {
      activeTab === 0 && (list = [...medicationsATCList]);
      activeTab === 1 && (list = [...medicationsEPCList]);
      activeTab === 2 && (list = [...medicationsMOAList]);
    } else if (filter === "Disease Filters") {
      activeTab === 0 && (list = [...diseasesDOList]);
      activeTab === 1 && (list = [...diseasesICD9CMList]);
    } else if (filter === "Procedure History") {
      list = [...proceduresList];
    }
    this.checkLeafsFiller(list, incl, id);
  };

  checkLeafsFiller = (list, incl, id) => {
    const { setArrayOfCodes, activeTab } = this.props;
    list.forEach(el => {
      if (el.id === id) {
        const itemsLength = el.leafs.length;
        const leafs = el.leafs.map(el => ({
          ...el,
          itemsLength,
          include: incl,
          activeTab
        }));
        setArrayOfCodes(leafs);
      }
    });
  };

  handleShow = (index) => {
    this.props.setOpen();
    this.myRef.current.classList.toggle("arrow-more");
  }

  removeElements = (array, id) => {
    let resArray = array.map(el => {
      if (el.id === id) {
        el.children = undefined;
      } else {
        if (el.children) {
          this.removeElements(el.children, id);
        }
      }
      return null;
    });
    return resArray;
  };
  render() {
    const {
      name,
      id,
      code,
      children,
      selectedView,
      lastLevel,
      search,
      open,
      active,
      searchCategory,
      filter,
      activeTab,
      include,
      categoryId,
      makeCheck,
      isLeaf,
      rootId,
      setCodes,
      parentIndex
    } = this.props;


    if (!open) return null;
    return (
      <li className="flex">
        {children && (
          <img
            src={iconCollapse}
            alt="icon-collapse"
            className={open ? "arrow-collapse arrow-more" : "arrow-collapse"}
            onClick={() => this.handleShow(parentIndex)}
            ref={this.myRef}
          />
        )}
        <div
          className={
            active || search || searchCategory
              ? "items-wrapp activeValue"
              : "items-wrapp"
          }
        >
          <div
            className={`${
              selectedView ? "wrapp-cat wrapp-item-view" : "wrapp-cat"
              }`}
          >
            <div className="cat-wrapp">
              <span
                className="search-value"
                style={{
                  paddingLeft: lastLevel ? '20px' : 0,
                  maxWidth: selectedView ? "none" : "18vw",
                  fontWeight: lastLevel ? "normal" : "bold"
                }}
                ref={this.showDisRef}
              >
                {filter === "Medication Filters" ? capitalize(name) : name}
              </span>
              {(search || searchCategory) && <div className="search-ball" />}
            </div>
            <IncludeExclude
              setCodes={setCodes}
              id={id}
              hasChildren={children}
              hasLeafs={isLeaf}
              code={code}
              filter={filter}
              activeTab={activeTab}
              include={include}
              name={name}
              categoryId={categoryId}
              makeCheck={makeCheck}
              showDiseases={this.showDiseases}
              parentId={rootId}
              checkLeafs={this.checkLeafs}
            />
          </div>
        </div>
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {
    medicationsATC: state.medicationsATC,
    medicationsEPC: state.medicationsEPC,
    medicationsMOA: state.medicationsMOA,
    diseasesDO: state.diseasesDO,
    diseasesDOList: state.diseasesDOList,
    diseasesICD9CMList: state.diseasesICD9CMList,
    diseasesICD9CM: state.diseasesICD9CM,
    diseasesPicker: state.diseasesPicker,
    pickerLabel: state.pickerLabel,
    proceduresPicker: state.proceduresPicker,
    medicationsATCList: state.medicationsATCList,
    medicationsEPCList: state.medicationsEPCList,
    medicationsMOAList: state.medicationsMOAList,
    diseasesList: state.diseasesList,
    proceduresList: state.proceduresList,
    showPicker: state.showPicker
  };
}

export default connect(mapStateToProps)(SelectedTreeComponent);
