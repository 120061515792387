import React from "react";
import "./index.css";

export default  function MyInfoTooltip ({ data, show, onClick })  {
  if (!data || !show) {
    return <></>;
  }
  let content;
  if (data[0] === "loading") {
    content = (
      <img
        style={{ width: 50, height: 50, margin: "auto" }}
        className="small-spinner"
        src={require("../../images/spinner.gif")}
        alt=""
      />
    );
  } 
  else if (data.length < 1) {
    content = <p> no data </p>
  }
  else {
    content = data.map((e, index) => (
      <p key={index}>
        {e.name} <br />
      </p>
    ));
  }
  return (
    <div className="my-info-tooltip" onClick={onClick}>
      {content}
    </div>
  );
};