import React, { useEffect, useState } from "react";
import ImmunityApi from "./../../../../api/InmunityApi";
import {
  setMedicationsEPC,
  setMedicationsMOA,
  setMedicationsEPCList,
  setMedicationsMOAList,
  setDiseasesICD9CM,
  setDiseasesICD9CMList,
  setPickerLabel
} from "./../../../../actions/trialActions";
import { connect } from "react-redux";

const MedicationTabs = ({
  rootMedications,
  setSpinner,
  medicationsEPC,
  diseasesICD9CM,
  medicationsMOA,
  dispatch,
  setTab,
  setCategoryId,
  find,
  filter
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [initialEPC, setInitialEPC] = useState(true);
  const [initialMOA, setInitialMOA] = useState(true);

  const toggleTabs = async i => {
    const id = rootMedications[i].id;
    const len =
      filter === "Medication Filters"
        ? medicationsEPC.length
        : diseasesICD9CM.length;
    setSpinner(true);
    setActiveTab(i);
    const label = rootMedications[i].label;
    dispatch(setPickerLabel(label));
    if (i === 1 && len === 0 && initialEPC) {
      fetchData(i, id, filter, label);
    } else if (i === 2 && medicationsMOA.length === 0 && initialMOA) {
      fetchData(i, id, filter, label);
    }
    setTab(i);
    await setCategoryId(id);
  };

  const fetchData = (i, id, filter, label) => {
    if (filter === "Medication Filters") {
      ImmunityApi.getMedicationsChildren(id)
        .then(medications => {
          if (i === 1) {
            dispatch(setMedicationsEPC(medications));
          } else {
            dispatch(setMedicationsMOA(medications));
          }
        })
        .catch(e => {
          setSpinner(false);
          console.log("Error getting children: ", e);
        });
      if (i === 1) {
        dispatch(setMedicationsEPCList([{ id, leafs: [] }]));
        setInitialEPC(false);
      } else if (i === 2) {
        dispatch(setMedicationsMOAList([{ id, leafs: [] }]));
        setInitialMOA(false);
      }
    } else {
      ImmunityApi.getDiseasesChildren(id, label)
        .then(diseases => {
          if (i === 1) {
            dispatch(setDiseasesICD9CM(diseases));
          }
        })
        .catch(e => {
          setSpinner(false);
          console.log("error getting children", e);
        });
      if (i === 1) {
        dispatch(setDiseasesICD9CMList([{ id, leafs: [] }]));
        setInitialEPC(false);
      }
    }
  };

  useEffect(() => {
    if (find !== "") {
      setActiveTab(find);
    }
  }, [find]);

  return rootMedications.map((el, i) => {
    return (
      <div
        className={`searchBlockWrapper__item${
          i === activeTab
            ? filter === "Disease Filters"
              ? " searchBlockWrapper__active searchBlockWrapper__active__dis"
              : " searchBlockWrapper__active"
            : ""
          }`}
        style={{
          borderLeft:
            activeTab === 0 && i === 1
              ? "none"
              : activeTab === 1 && i === 2
                ? "none"
                : "1px solid #dbdbdb"
        }}
        id="recentSearches"
        key={el.id}
        onClick={() => toggleTabs(i)}
      >
        <div>{el.className}</div>
        {i === find && <div className="search-ball" />}
      </div>
    );
  });
};

const mapStateToProps = state => {
  return {
    medicationsEPC: state.medicationsEPC,
    medicationsMOA: state.medicationsMOA,
    diseasesICD9CM: state.diseasesICD9CM
  };
};


export default connect(mapStateToProps)(MedicationTabs);
