import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function getAllSubstancesReducer(
  state = initialState.substances,
  action
) {
  switch (action.type) {
    case types.GET_ALL_SUBSTANCES:
      return action.substances;
    default:
      return state;
  }
}
