import React, { Component } from "react";
import { connect } from "react-redux";
import AllTrialEnrolled from "./AllTrialEnrolled";
import TrialEnrolled from "./TrialEnrolled";
import inmunityApi from "../../api/InmunityApi";
import TrialsNav from "../TrialsNav";
import {
  setCurrentNavigationLink,
  setCurrentNavigationSubLink,
  getTrialDescription
} from "../../actions/trialActions";
import "./index.css";
import { Sort, CustomInfinityScroll } from "../../ui";
import { URLGenerator } from "../../utils/trailsNavigation";

const options = [
  { value: "enrolled", label: "Date of enrollment" },
  { value: "immunity", label: "Immunity ID" },
  { value: "name", label: "Name" }
];

class Enrolled extends Component {
  state = {
    trialID: "",
    candidates: [],
    initialForSearch: [],
    selectedOption: options[0],
    loading: false,
    searching: false,
    isMore: null,
    link: null,
    isReverseSort: true,
    input: "",
    searchLengthResult: null,
    paths: [],
    loadingNext: false
  };

  componentDidMount() {
    this.mounted = true;
    let {
      location,
      setCurrentNavigationLink,
      setCurrentNavBarSubLink,
      getTrialDescription
    } = this.props;

    let currentTrial = location.pathname.split("/")[2];
    getTrialDescription(currentTrial);
    currentTrial = currentTrial || "";
    this.setState(
      {
        currentTrial: currentTrial,
        loading: true,
        paths: URLGenerator({
          id: currentTrial,
          count: {
            name: "enrolled",
            count: ""
          }
        })
      },
      () => this.loadDefaultEnrolled()
    );
    setCurrentNavigationLink(3);
    setCurrentNavBarSubLink(currentTrial || "all");
  }

  loadDefaultEnrolled = () => {
    let { currentTrial } = this.state;
    inmunityApi
      .getEnrolledPatients(currentTrial)
      .then(candidates => {
        if (this.mounted) {
          this.setState({
            candidates: candidates.results.sort(
              (a, b) => new Date(b.enrollTime) - new Date(a.enrollTime)
            ),
            initialForSearch: candidates,
            loading: false,
            isMore: !!candidates.next,
            link: candidates.next,
            paths: URLGenerator({
              id: currentTrial,
              count: {
                name: "enrolled",
                count: candidates.count
              }
            })
          });
        }
      })
      .catch(error =>
        console.log("Error occured while getting exclusion candidates: ", error)
      );
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = selectedOption => {
    this.setState(
      {
        selectedOption,
        candidates: [],
        isMore: false,
        link: null
      },
      () => {
        this.sortEnrolledApi();
      }
    );
  };

  reverseSort = () => {
    this.setState({ isReverseSort: !this.state.isReverseSort }, () => {
      this.sortEnrolledApi();
    });
  };

  sortEnrolledApi = () => {
    this.setState({ loading: true });
    let { isReverseSort, input, trialID, selectedOption } = this.state;
    let flag = selectedOption.value;
    let sign = isReverseSort ? "-" : "";
    let query = input !== "" ? input : null;
    inmunityApi.getEnrolledSorted(trialID, sign, query, flag).then(response => {
      this.setState({
        candidates: response.results,
        loading: false,
        isMore: !!response.next,
        link: response.next,
        searchLengthResult: response.count
      });
    });
  };

  loadMoreData = () => {
    this.setState({ loadingNext: true }, () => {
      let { link, isMore, candidates } = this.state;
      if (isMore) {
        inmunityApi.getPatientSearchPagination(link).then(response => {
          let link = response.next;
          let isMore = !!link;
          let oldCandidates = [...candidates];
          let newCandidates = oldCandidates.concat(response.results);
          this.setState({
            candidates: newCandidates,
            link,
            isMore,
            loadingNext: false
          });
        });
      }
    });
  };

  handleChangeInput = e => {
    if (e.target.value.length === 0) {
      this.setState(
        {
          loading: true,
          candidates: [],
          searchLengthResult: null,
          searching: false
        },
        () => {
          this.sortEnrolledApi();
        }
      );
    }
    this.setState({
      input: e.target.value
    });
  };

  searchEnrolled = () => {
    this.setState(
      {
        candidates: [],
        searching: true,
        isMore: false,
        link: null
      },
      () => {
        this.sortEnrolledApi();
      }
    );
  };

  render() {
    let {
      candidates,
      loading,
      searching,
      currentTrial,
      paths,
      searchLengthResult,
      isReverseSort,
      selectedOption,
      isMore,
      loadingNext
    } = this.state;
    let { name } = this.props.trial;
    return (
      <div className="content-body-matches" id="list">
        {name && currentTrial && <div className="study-name">{name}</div>}
        <TrialsNav paths={paths} />
        <Sort
          options={options}
          selectedOption={selectedOption}
          isRevert={isReverseSort}
          result={searchLengthResult}
          revert={this.reverseSort}
          select={this.handleChange}
          change={this.handleChangeInput}
          press={this.searchEnrolled}
          searching={searching}
        />
        <CustomInfinityScroll
          data={candidates}
          searching={searching}
          loading={loading}
          isMore={isMore}
          textNoResult="No enrolled yet for this trial."
          textNoResultSearch="No results found"
          loadMoreData={this.loadMoreData}
          loadingNext={loadingNext}
        >
          {candidates.length > 0 &&
            candidates.map((candidate, i) => {
              return currentTrial !== "" ? (
                <AllTrialEnrolled
                  key={i}
                  candidate={candidate}
                  id={"trial-candidate-slice-" + candidate["hadmId"]}
                />
              ) : (
                <TrialEnrolled
                  key={i}
                  candidate={candidate}
                  id={"trial-candidate-slice-" + candidate["hadmId"]}
                />
              );
            })}
        </CustomInfinityScroll>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    trial: state.trial
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentNavigationLink: navID =>
      dispatch(setCurrentNavigationLink(navID)),
    setCurrentNavBarSubLink: id => dispatch(setCurrentNavigationSubLink(id)),
    getTrialDescription: id => dispatch(getTrialDescription(id))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Enrolled);
