import React from 'react'
import TreeComponent from "../../../TreeSelect/TreeComponent";

const Procedure = ({
    pickerResult,
    show
}) => {

    if (!show) return null;

    return (
        <div className="bigAreaForPicker-wrapper">
            <span className="bigAreaForPicker-wrapper__title">
                Procedure selected
            </span>
            <div className="bigAreaForPicker">
                <div>
                    {pickerResult.map(el => {
                        return (
                            <TreeComponent
                                key={el.id}
                                name={el.name}
                                id={el.id}
                                include={el.include}
                                children={false}
                                forSending={[]}
                                selectedView={true}
                                lastLevel={!el.category}
                            />
                        );
                    })}
                </div>
                <div className="green-plus">+</div>
            </div>
        </div>
    )
}

export default Procedure