import React, { Component } from 'react'
import { withRouter } from 'react-router'
import './index.css'
import InmunityApi from './../../../api/InmunityApi'
import Popup from 'reactjs-popup'
import crossicon from './../../../images/icon-close-w.svg'

class NewUserInvite extends Component {
  state = {
    email: '',
    regexEmail: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    emailValid: true,
    open: false,
    message: ''
  }

  redirectNewUser = () => {
    let { history } = this.props
    history.push({ pathname: '/settings/users/newuser' })
  }

  handleClickEnter = e => {
    if (e.keyCode === 13) {
      this.inviteUser()
    }
  }

  handleEmailChange = e => {
    this.setState({ email: e.target.value })
  }

  inviteUser = () => {
    // show spinner
    this.setState({ message: 'spinner' })
    let obj = {}
    obj.email = this.state.email
    InmunityApi.inviteEmail(obj)
      .then(resolve => {
        if (resolve.status !== 200) {
          resolve
            .text()
            .then(text => {
              if (
                text ===
                "Failed to send email. {'email': ['This field must be unique.']}"
              ) {
                this.setState({
                  message:
                    'This email is already in use. Please enter a different email.'
                })
                throw resolve
              } else {
                this.setState({
                  message: 'An error occurred while sending invitation.'
                })
                throw resolve
              }
            })
            .catch(error =>
              console.log('Error occured while sending invitation: ', error)
            )
        }
        this.setState({
          message: `An invitation was successfully sent to ${this.state.email}`
        })
      })
      .catch(error => {
        console.log('Error occured while sending invitation: ', error)
      })
  }

  showModal = () => {
    let { regexEmail, email, emailValid } = this.state
    if (
      regexEmail.test(email) &&
      document.getElementById('email').value !== ''
    ) {
      emailValid = true
      this.setState({ emailValid })
    } else {
      emailValid = false
      this.setState({ emailValid })
      return null
    }
    this.setState({
      open: true,
      message: 'Please confirm to invite user by email.'
    })
  }

  closeModal = () => {
    let open = false
    this.setState({ open, message: '' })
  }

  render () {
    let { message, open } = this.state
    return (
      <div className='profile'>
        <div className='newUserInvite'>
          <div className='inputs_div'>
            <Popup
              open={open}
              closeOnDocumentClick
              onClose={this.closeModal}
              className='popup-newtrial role-popup'
            >
              <div>
                <div
                  className='closePendingPopupCross'
                  onClick={this.closeModal}
                >
                  <img src={crossicon} alt='Cross' />
                </div>
                {message === 'spinner' ? (
                  <h1 className='header-spinner'>
                    <img
                      className='small-spinner'
                      src={require('./../../../images/spinner.gif')}
                      alt=''
                    />
                  </h1>
                ) : message === 'Please confirm to invite user by email.' ? (
                  <div className='newTrialPopupWrapper'>
                    <div className='saveUserPopupText'>
                      {this.state.message}
                    </div>
                    <div className='forNTWBtns'>
                      <div className='fromScratch' onClick={this.inviteUser}>
                        Confirm
                      </div>
                      <div className='fromTemplate' onClick={this.closeModal}>
                        Cancel
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='newTrialPopupWrapper'>
                    <div className='saveUserPopupText'>
                      {this.state.message}
                    </div>
                  </div>
                )}
              </div>
            </Popup>
            <div className='inputs__email-address '>
              <span>E-mail an invite</span>
              <input
                type='email'
                id='email'
                onKeyDown={this.handleClickEnter}
                onChange={this.handleEmailChange}
                className={
                  this.state.emailValid ? 'inviteUser' : 'inviteUser redBorder'
                }
              />
            </div>
            <div className='wrapForRsBtn'>
              <div className='ntw__headerWrapp-btn' onClick={this.showModal}>
                Invite
              </div>
            </div>
          </div>
          {/* <div className='inputs__change-pass' onClick={this.redirectNewUser}>
            Register User Manually ghost#hidden
          </div> */}
        </div>
      </div>
    )
  }
}

export default withRouter(NewUserInvite)
