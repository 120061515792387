import React from 'react'
import moment from 'moment'

import './index.css'

const WeekHeaders = week => {
  return (
    <div>
      {moment(week.date)
        .format('ddd DD')
        .toUpperCase()}
    </div>
  )
}
export default WeekHeaders
