import React from "react";

export default function FilterResult({ name, value, removeFilter }) {
  const deleteResult = e => {
    let filters = document
      .querySelector(".exclusionsResultsWrapper")
      .querySelectorAll(".searchItem");
    filters.forEach((el, i) => {
      if (e.target.parentNode === el) {
        removeFilter(i, value, name);
      }
    });
  };
  return (
    <div className="searchItem">
      <span className="searchCrType">{name}:</span>
      <span className="searchCrType__value">{value}</span>
      <span className="crossIconOnSI" onClick={deleteResult}>
        +
      </span>
    </div>
  );
}
