import React from "react";

export default function ImmunityId({
  searchPage,
  nameSearch,
  GeoIdPhysHandleChange
}) {
  return (
    <div
      className={
        searchPage
          ? "ntw__ti-input forGettingValueFields width100"
          : "ntw__ti-input forGettingValueFields searchPatientInput"
      }
    >
      <span className="ntw__ti-inputlabel">ID Number/Name</span>
      <input
        type="text"
        name="Immunity ID"
        className="get-freetext-name"
        defaultValue={nameSearch}
        onChange={GeoIdPhysHandleChange}
      />
    </div>
  );
}
