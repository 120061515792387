import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import DataStructured from "./../DataStructured";
import crossicon from "./../../../../../images/icon-close-w.svg";
import img from "./../../../../../images/plus-icon.svg";
import WarningPopup from "./../../../../Patients/TreeSelect/Footer/WarningPopup";
import "./index.css";
import _ from "lodash";

let oldScroll = 0;
export default React.memo(function CriteriaPopup({
  criteriaPopup,
  closeCriteriaPopup,
  selectCriteria,
  selectedCriteria,
  freeText,
  showPicker,
  defaultEligibilityCriteria,
  setInfo,
  addFreeTextBlock,
  newFdata,
  allData
}) {
  const [showWarningPopup, openWarningPopup] = useState(false);
  const [list, setList] = useState("");
  const scrollViewRef = useRef(null);
  const saveCriteria = () => {
    let sameInEx = sameInclusionExclusionCheck(allData);
    if (sameInEx.length > 0) {
      sameInEx = sameInEx.join(", ");
      openWarningPopup(true);
      setList(sameInEx);
      return null;
    }
    addFreeTextBlock();
    closeCriteriaPopup();
  };



  const sameInclusionExclusionCheck = labs => {
    if (labs.length > 0) {
      let res = [];
      const mapCallback = (el, lab) => {
        if (el.name === lab.name && el.include !== lab.include) {
          return el.name;
        }
      };
      for (let lab of labs) {
        res = labs.map(el => mapCallback(el, lab));
      }
      res = Array.from(new Set(res));
      res = _.without(res, undefined);
      return res;
    } else {
      return [];
    }
  };


  let interval;
  const scrollView = (element) => {

    interval = window.setInterval(
      function () {
        // const oldScroll;
        if (element.scrollTop < oldScroll) {
          element.scrollTop = oldScroll
        }
        element.scrollTop += 10;
        if (element.scrollHeight - element.clientHeight === element.scrollTop) {
          oldScroll = element.scrollTop;
          window.clearInterval(interval);
        }
      }, 5
    )
  }


  useEffect(() => {
    if (!scrollViewRef || !scrollViewRef.current) {
      return;
    }

    let element = scrollViewRef.current;

    element.scrollTop = element.clientHeight;
    scrollView(element);
  }, [allData])
  const closeWarningPopup = () => {
    openWarningPopup(false);
  };
  return (
    <Popup
      open={criteriaPopup}
      closeOnDocumentClick
      onClose={closeCriteriaPopup}
      className="popup-criterias"
    >
      <div>
        <WarningPopup
          open={showWarningPopup}
          closeWarningPopup={closeWarningPopup}
          list={list}
        />
        <div className="popup-criterias__content">
          <div
            className="criteriasBox criteriaBoxNewTrial"
            onClick={e => selectCriteria(e, true)}
          >
            <div className="selected_textWrapp">
              <div className="criteria_value">Selected Text</div>
              <div className="freeText-value">"{freeText}"</div>
            </div>

            <div
              ref={scrollViewRef}
              className="search_container ntw__ti-inputs newTrialWrapp newWrappElBlockSearch criterias_ch_Wrapp"
            >
              <DataStructured
                data={defaultEligibilityCriteria}
                showPicker={showPicker}
                flag={selectedCriteria}
                setInfo={setInfo}
                freeTextVersion={true}
                newFdata={newFdata}
                scrollView={scrollView}
              />
            </div>
            <div className="criteriasBox__row">
              <div className="criteriasBox-item criteria_item">
                Medication Filters
              </div>
              <div className="criteriasBox-item criteria_item">
                Disease Filters
              </div>
            </div>

            <div className="criteriasBox__row">
              <div className="criteriasBox-item criteria_item">
                Procedure History
              </div>
              <div className="criteriasBox-item criteria_item">Lab Filters</div>
            </div>

            <div className="criteriasBox__row">
              {/* <div className="criteriasBox-item criteria_item">Smoking</div> */}
              <div className="criteriasBox-item criteria_item">
                Substance Use
              </div>
            </div>

            <div className="contactWrapp saveCriteria" onClick={saveCriteria}>
              <img src={img} alt="Plus" />
              <span>Save Criteria</span>
            </div>
          </div>
        </div>

        <div className="closePendingPopupCross" onClick={closeCriteriaPopup}>
          <img src={crossicon} alt="Cross" />
        </div>
      </div>
    </Popup>
  );
});
