import React from "react";
import InclusionCriteria from "./../InclusionCriteria";
import { Spinner } from "./../../../../ui";
import InmunityApi from "./../../../../api/InmunityApi";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

export default function PatientsBySite({
  sites,
  site,
  spinner,
  siteSpinner
}) {
  let getChartOptions = chart => {
    if (!chart) {
      return {};
    }
    let data = [];
    Array.isArray(chart) &&
      chart.forEach(el => {
        data.push({
          name: el.name,
          y: el.count
        });
      });
    const chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie"
      },
      title: {
        text: ""
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                "black"
            }
          },
          showInLegend: true
        }
      },
      series: [
        {
          name: "Patients",
          colorByPoint: true,
          data
        }
      ]
    };
    return chartOptions;
  };
  return (
    <div className="pat_byWrapp">
      <div className="title_pat_bys">Patients by site</div>
      <div className="charts_wrapp">
        <div className="workflow_chart">
          {siteSpinner ? (
            <Spinner />
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={getChartOptions(site)}
            />
          )}
        </div>
        <div className="workflow_chart">
          <InclusionCriteria
            mismatch="Missing"
            data={sites.missing || []}
            spinner={spinner}
          />
        </div>
        <div className="workflow_chart">
          <InclusionCriteria
            mismatch="Matching"
            data={sites.matchingInclude || []}
            dataExclude={sites.matchingExclude || []}
            spinner={spinner}
          />
        </div>
      </div>
    </div>
  );
}
