import * as types from '../actions/actionTypes';

export default function changeEmailPopup(state = {open: false, name: ''}, action) {
  switch(action.type) {
    case types.SET_CHANGE_EMAIL_POPUP:
      return {
        open: action.payload,
        name: action.name
      };
    default:
      return state;
  }
}
