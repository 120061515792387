import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";

export const InfoInputs = props => {
  const {
    setInfo,
    defaultName,
    nameError,
    defaultNCT,
    nctError,
    defaultBrief,
    brief_descriptionError,
    defaultLongDescription,
    long_descriptionError,
    defaultSponsor,
    sponsorError,
    defaultPI,
    principal_investigatorError,
  } = props;

  const [selectedOptionPI, setSelectedOptionPI] = useState("");
  const [physiciansOptions, setPhysiciansOptions] = useState(
    JSON.parse(localStorage.getItem("physiciansOptions"))
  );
  const handleChange = e => {
    const { name, value } = e.target;
    setInfo(name, value);
  };

  const handleChangePI = selectedOptionPI => {
    setSelectedOptionPI(selectedOptionPI);
    setInfo("principal_investigator", selectedOptionPI.value);
  };

  useEffect(() => {
    setSelectedOptionPI(defaultPI);
  }, [defaultPI]);

  return (
    <div className="ntw__ti-inputs">
      <div className="ntw__ti-input ntw__ti-iTrialName">
        <span className="ntw__ti-inputlabel">Trial Name</span>
        <input
          type="text"
          className={
            nameError
              ? "TrialInformationWrapper redBorder"
              : "TrialInformationWrapper"
          }
          id="trial-name"
          name="name"
          onChange={handleChange}
          value={defaultName}
        />
      </div>
      <div className="ntw__ti-input ntw__ti-iTrialNCT">
        <span className="ntw__ti-inputlabel">Trial NCT</span>
        <input
          type="text"
          className={
            nctError
              ? "TrialInformationWrapper redBorder"
              : "TrialInformationWrapper"
          }
          id="trial-nct"
          name="nct"
          onChange={handleChange}
          value={defaultNCT}
        />
      </div>
      <div className="ntw__ti-input ntw__ti-iDescr forSymbolCounter">
        <span className="ntw__ti-inputlabel">
          Description
          <span className="symbolCounter">
            {defaultLongDescription ? defaultLongDescription.length : 0}/30000
          </span>
        </span>
        <textarea
          name="long_description"
          className={
            long_descriptionError
              ? "TrialInformationWrapper redBorder"
              : "TrialInformationWrapper"
          }
          id="description"
          maxLength="30000"
          onChange={handleChange}
          value={defaultLongDescription}
        />
      </div>
      <div className="ntw__ti-input ntw__ti-iDescr ntw__ti-iOverview forSymbolCounter">
        <span className="ntw__ti-inputlabel">
          Overview
          <span className="symbolCounter">
            {defaultBrief ? defaultBrief.length : 0}/1000
          </span>
        </span>
        <textarea
          className={
            brief_descriptionError
              ? "TrialInformationWrapper redBorder"
              : "TrialInformationWrapper"
          }
          id="overview"
          name="brief_description"
          maxLength="1000"
          value={defaultBrief}
          onChange={handleChange}
        />
      </div>
      <div className="ntw__ti-input ntw__ti-iTrialName">
        <span className="ntw__ti-inputlabel">Sponsor</span>
        <input
          type="text"
          className={
            sponsorError
              ? "TrialInformationWrapper redBorder"
              : "TrialInformationWrapper"
          }
          id="sponsor"
          name="sponsor"
          onChange={handleChange}
          value={defaultSponsor}
        />
      </div>
      <div className="ntw__ti-input ntw__ti-iTrialNCT">
        <span className="ntw__ti-inputlabel">Principal Investigator</span>
        <Select
          value={selectedOptionPI}
          onChange={handleChangePI}
          options={physiciansOptions}
          className={
            principal_investigatorError
              ? "sort_select withInput pi-select redBorder"
              : "sort_select withInput pi-select"
          }
          id="princ-invest"
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  physicians: state.physicians
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoInputs);
