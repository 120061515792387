import React, { useState, useEffect, useRef } from "react";
import InmunityApi from "../../api/InmunityApi";
import { ReactComponent as IconInfo } from "../../images/icon-info.svg";
import MyInfoTooltip from "./MyInfoTooltip";
import styled from 'styled-components'

import "./index.css";

const StyledInfoRow = styled.div`
  max-width: 180px;
  mix-width: 100px;
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
  // box-shadow: 1px 1px 10px #eee;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 5px;
`

const StyledInfoIconConteiner = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  justify-self: flex-end;
`
const StyledInfoText = styled.div`
  width: 95%;
  color: #454545;
  font-family: Gotham;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 300;
`

export default function InfoBlock({ el, immunity_id, activeOverview }) {
  const [showInfo, setShowInfo] = useState(false);
  const [info, setInfo] = useState([]);
  const [hover, setHover] = useState(false);
  const thisComponent = useRef(null);

  useEffect(() => {
    window.addEventListener("mousedown", handleClose, false);

    return () => {
      window.removeEventListener("mousedown", handleClose, false);
    };
  }, []);

  const handleClose = event => {

    if (thisComponent.current && !thisComponent.current.contains(event.target)) {
      setShowInfo(false);
    }
  };

  const handleGetInfo = () => {
    setShowInfo(true);
    if (info.length > 0) {
      return;
    }
    activeOverview && activeOverview();
    setInfo(["loading"]);
    InmunityApi.getPatientsInfo(immunity_id, el.code, el.label).then(res => {
      setInfo(res);
    });
  };

  const toggleHover = () => {
    setHover(!hover);
  }

  return (
    <StyledInfoRow ref={thisComponent}>
      {el.include && <span className="signPlus">+</span>}
      {!el.include && <span className="signMinus">–</span>}
      <StyledInfoText>{el.name}</StyledInfoText>
      {(el.category) && (
        <StyledInfoIconConteiner>
          <IconInfo
            style={{ zIndex: 2, cursor: 'pointer' }}
            onClick={() => handleGetInfo()}
            className="patient-info-icon"
            width={18}
            height={18}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            fill={hover ? "#2FB3A9" : '#BCBDBE'}
          ></IconInfo>
          <MyInfoTooltip
            onClick={() => setShowInfo(true)}
            show={showInfo}
            data={info}
          />
        </StyledInfoIconConteiner>
      )}
    </StyledInfoRow>
  );
}
