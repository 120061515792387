import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Spinner } from "./../../../../../../../ui";

export default function PatientMatchBreakdown({
  graphsData: { matchBreakdown },
  graphSpinner: spinner = true
}) {
  const [data, setData] = useState([]);
  const getData = () => {
    if (!data) {
      return null;
    }
    let chartData = [];
    data.forEach(el => {
      chartData.push([el.range, parseInt(el.count, 10)]);
    });
    return chartData;
  };
  const options = {
    chart: {
      type: "bar"
    },
    title: {
      text: ""
    },
    subtitle: {
      text: ""
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -180,
        style: {
          fontSize: "11px",
          fontFamily: "Verdana, sans-serif"
        }
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Patients"
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      pointFormat: "Patients: <b>{point.y}</b>"
    },
    series: [
      {
        name: "Population",
        data: getData(),
        dataLabels: {
          enabled: true,
          color: "#FFFFFF",
          format: "{point.name}: {point.y}",
          style: {
            fontSize: "11px",
            fontFamily: "Verdana, sans-serif"
          }
        }
      }
    ]
  };
  useEffect(() => {
    if (matchBreakdown && matchBreakdown.length > 0) {
      setData(matchBreakdown.sort(function (a, b) {
        if (a > b) {
          return -1;
        }
        if (b > a) {
          return 1;
        }
        return 0;
      }))
    }
  }, [matchBreakdown]);
  return (
    <div className="pat_count_wrapp pat_count_wrapp-graph">
      <div className="inc_title">Patient Match Breakdown</div>
      {spinner ? (
        <Spinner />
      ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{ style: { height: "360px" } }}
          />
        )}
    </div>
  );
}
