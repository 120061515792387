import React from "react";
import disease_or_condition from "./../../../../../images/disease_or_condition.svg";

export default function DiseaseOrConItem({ procedures }) {
  return (
    <div className="infoItem">
      <img src={disease_or_condition} alt="Disease or condition" />
      <div className="tfc-tc">
        <div className="tfc-title">Disease or condition</div>
        <div className="tfc-content">
          <span data-test="procedures">
            {procedures && procedures.length > 0
              ? procedures.map((el, i) => {
                  return (
                    <span key={i} data-test={`procedure${i}span`}>
                      {i !== procedures.length - 1 ? el + ", " : el}
                    </span>
                  );
                })
              : "no data"}
          </span>
        </div>
      </div>
    </div>
  );
}
