import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { Spinner } from '../../ui'
import { withRouter } from "react-router";
import Popup from "reactjs-popup";
import PhysiciansTooltip from "../trials/NewTrial/PhysiciansTooltip";
import PatientIdTooltip from "../trials/NewTrial/PatientIdTooltip";
import crossicon from "../../images/icon-close-w.svg";
import i18n from "../../i18n";
import InmunityApi from "../../api/InmunityApi";

import { setResponsibleNames } from "../../actions/trialActions";

import "../trials/index.css";

import "react-datepicker/dist/react-datepicker.css";

class CalendarList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tabActive: 1,
      modalVisible: false,
      patientVisits: [],
      reminders: [],
      events: [],
      trialSort: "up",
      matchSort: "up",
      dateSort: "up",
      visitsSort: "up",
      idPatSort: "up",
      phNameSort: "up",
      detailsSort: "up",
      reminderType: {
        value: "Physician Followup",
        label: "Physician Followup"
      },
      //form states
      reminderDate: moment(),
      patientId: null,
      physicianName: null,
      description: "",
      responsible: null,

      //erorrs
      reminderTypeError: null,
      patientIdError: true,
      responsibleError: true,
      physicianNameError: true,
      descriptionError: true,

      addNotePopup: false,
      showButtons: false,
      creationOfReminder: false,
      textOfCreationOfReminder: null,
      // ------//
      physicianOptions: null,
      responsibleOptions: null,
      hadmImmunityIdsOptions: null,
      // ------//
      physicianTooltip: [],
      responsibleTooltip: [],
      patientIdTooltip: [],

      rotate: false,
      isCreatePopup: true,
      currentReminderId: null
    };
  }

  componentDidMount() {
    this.mounted = true;

    if (this.mounted) {
      this.mounted &&
        this.setState({
          events: [...this.props.events]
        });
      InmunityApi.getAllPhysicians().then(
        physicianOptions => {
          this.mounted &&
            this.setState({
              physicianOptions
            });
        }
      );
      InmunityApi.getAllHadmAndImmunityIds().then(
        hadmImmunityIdsOptions => {
          this.mounted &&
            this.setState({
              hadmImmunityIdsOptions
            });
        }
      );
      InmunityApi.getResponsibleNames().then(
        responsibleOptions => {
          this.props.dispatch(setResponsibleNames(responsibleOptions));
          this.mounted &&
            this.setState({
              responsibleOptions
            });
        }
      );
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setMatches = () => {
    this.setState({ tabActive: 1 });
  };

  setReminders = () => {
    this.setState({ tabActive: 2, rows: this.state.reminders });
  };

  openModal = () => {
    this.setState({ modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  sortByVisits = () => {
    if (this.state.visitsSort === "up") {
      // let res = this.props.events.sort((a, b) => {
      //   return new Date(b.timeOfVisit) - new Date(a.timeOfVisit);
      // });
      this.setState({
        visitsSort: "down"
      });
    } else {
      // let res = this.props.events.sort((a, b) => {
      //   return new Date(a.timeOfVisit) - new Date(b.timeOfVisit);
      // });
      this.setState({
        visitsSort: "up"
      });
    }
  };

  sortByPatId = () => {
    if (this.state.idPatSort === "up") {
      // let res = this.props.events.sort((a, b) => {
      //   return b.hadmId - a.hadmId;
      // });
      this.setState({
        idPatSort: "down"
      });
    } else {
      // let res = this.props.events.sort((a, b) => {
      //   return a.hadmId - b.hadmId;
      // });
      this.setState({
        idPatSort: "up"
      });
    }
  };

  sortByName = () => {
    if (this.state.phNameSort === "up") {
      // let res = this.props.events.sort((a, b) => {
      //   return a.physicianName.localeCompare(b.physicianName);
      // });
      this.setState({
        phNameSort: "down"
      });
    } else {
      // let res = this.props.events.sort((a, b) => {
      //   return b.physicianName.localeCompare(a.physicianName);
      // });
      this.setState({
        phNameSort: "up"
      });
    }
  };

  sortById = () => {
    if (this.state.idSort === "up") {
      let res = this.state.reminders.sort((a, b) => {
        return b.hadmId - a.hadmId;
      });
      this.setState({
        idSort: "down",
        reminders: res
      });
    } else {
      let res = this.state.reminders.sort((a, b) => {
        return a.hadmId - b.hadmId;
      });
      this.setState({
        idSort: "up",
        reminders: res
      });
    }
  };

  sortByDetails = () => {
    if (this.state.detailsSort === "up") {
      let res = this.state.reminders.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
      this.setState({
        detailsSort: "down",
        reminders: res
      });
    } else {
      let res = this.state.reminders.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
      this.setState({
        detailsSort: "up",
        reminders: res
      });
    }
  };

  goToDetails = hadmId => {
    if (hadmId) {
      let { history } = this.props;
      history.push({
        pathname: `/matches/details/${hadmId}`,
        id: hadmId,
        detailTitle: "Candidate Details"
      });
    }
  };

  addNote = () => {
    this.setState({
      modalVisible: true,
      isCreatePopup: true
    });
  };

  closeAddNotePopup = () => {
    this.setState({
      addNotePopup: false
    });
  };

  handleChangeReminderType = reminderType => {
    this.setState({
      reminderType
    });
  };

  handleChangeCalendar = reminderDate => {
    this.setState({
      reminderDate
    });
  };

  checkFormValidity = (...errors) => {
    return errors.some(el => el === true);
  };

  checkBorders = () => {
    const {
      patientIdError,
      physicianNameError,
      descriptionError
    } = this.state;
    if (patientIdError && document.querySelector("[name=patientId]")) {
      document.querySelector("[name=patientId]").classList.add("redBorder");
    }
    if (physicianNameError && document.querySelector("[name=physicianName]")) {
      document.querySelector("[name=physicianName]").classList.add("redBorder");
    }
    if (descriptionError && document.querySelector("[name=description]")) {
      document.querySelector("[name=description]").classList.add("redBorder");
    }
  };

  addContactNote = () => {
    const {
      patientIdError,
      physicianNameError,
      descriptionError
    } = this.state;
    let isFormValid = false;
    if (this.state.reminderType.label === "Patient Followup") {
      isFormValid = !this.checkFormValidity(patientIdError, descriptionError);
    } else if (this.state.reminderType.label === "Physician Followup") {
      isFormValid = !this.checkFormValidity(
        patientIdError,
        physicianNameError,
        descriptionError
      );
    } else {
      isFormValid = !this.checkFormValidity(descriptionError);
    }

    !isFormValid && this.checkBorders();

    if (isFormValid) {
      let obj = {};
      if (this.state.reminderType.label === "Patient Followup") {
        obj.type = 0;
      } else if (this.state.reminderType.label === "Physician Followup") {
        obj.type = 1;
      } else {
        obj.type = 2;
      }
      obj.date = moment(this.state.reminderDate).format();
      if (this.state.reminderType.label === "Others") {
        obj.physician_id = null;
        obj.hadm_id = null;
      } else if (this.state.reminderType.label === "Patient Followup") {
        obj.physician_id = null;
        obj.hadm_id = this.state.patientIdTooltip.hadm_id;
      } else if (this.state.reminderType.label === "Physician Followup") {
        obj.physician_id = this.state.physicianTooltip.id;
        obj.hadm_id = this.state.patientIdTooltip.hadm_id;
      }
      obj.description = this.state.description;
      obj.responsible_id = this.state.responsibleTooltip.id
        ? this.state.responsibleTooltip.id
        : null;

      this.setState({
        creationOfReminder: true,
        textOfCreationOfReminder: "spinner",
        modalVisible: false
      });
      InmunityApi.createReminder(obj)
        .then(response => {
          if (response.status !== 201) {
            this.setState({
              textOfCreationOfReminder:
                "An error occured while creating a reminder"
            });
          } else if (response.status === 201) {
            this.props.getAllReminders();
            this.setState(
              {
                textOfCreationOfReminder: "Reminder was created successfully!",
                reminderTypeError: null,
                patientIdError: true,
                responsibleError: true,
                physicianNameError: true,
                descriptionError: true
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    creationOfReminder: false
                  });
                }, 2000);
              }
            );
          }
        })
        .catch(err => {
          this.setState({
            textOfCreationOfReminder:
              "An error occured while creating a reminder"
          });
        });
    }
  };

  showBtns = () => {
    this.setState({
      showButtons: !this.state.showButtons,
      rotate: !this.state.rotate
    });
  };

  //------ PatientId Tooltip -------- //
  findPatientId = ({ target }) => {
    let patientIDs = this.state.hadmImmunityIdsOptions;
    if (target.value.length > 0 && patientIDs && patientIDs.length > 0) {
      let query = target.value;
      let result = patientIDs
        .filter(el => {
          return el.immunity_id.toString().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        this.setState({ patientIdTooltip: result, patientIdError: false });
      } else {
        result.length = 0;
        this.setState({ patientIdTooltip: result, patientIdError: true });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({ patientIdTooltip: [], patientIdError: true });
      target.classList.add("redBorder");
    }
  };

  patientPicked = (obj, whereToFill) => {
    whereToFill.value = obj.immunity_id;
    this.setState({ patientIdTooltip: obj });
  };

  //------ Physicians Tooltip -------- //
  findPhysicianName = ({ target }) => {
    let physicianNames = this.state.physicianOptions;
    if (
      target.value.length > 0 &&
      physicianNames &&
      physicianNames.length > 0
    ) {
      let query = target.value.toLowerCase();
      let result = physicianNames
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        this.setState({ physicianTooltip: result, physicianNameError: false });
      } else {
        result.length = 0;
        this.setState({ physicianTooltip: result, physicianNameError: true });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({ physicianTooltip: [], physicianNameError: true });
      target.classList.add("redBorder");
    }
  };

  physicianPicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    this.setState({ physicianTooltip: obj });
  };

  //------ Responsible Tooltip -------- //
  findResponsibleName = ({ target }) => {
    let responsibleNames = this.state.responsibleOptions;
    if (
      target.value.length > 0 &&
      responsibleNames &&
      responsibleNames.length > 0
    ) {
      let query = target.value.toLowerCase();
      let result = responsibleNames
        .filter(el => {
          return el.name.toLowerCase().startsWith(query);
        })
        .slice(0, 10);
      if (result.length > 0) {
        target.classList.remove("redBorder");
        target.onkeypress = function (e) { };
        this.setState({ responsibleTooltip: result, responsibleError: false });
      } else {
        result.length = 0;
        this.setState({ responsibleTooltip: result, responsibleError: true });
        target.classList.add("redBorder");
        target.onkeypress = function (e) {
          target.value.length !== 0 && e.preventDefault();
        };
      }
    } else {
      this.setState({ responsibleTooltip: [], responsibleError: true });
      target.classList.add("redBorder");
    }
  };

  responsiblePicked = (obj, whereToFill) => {
    whereToFill.value = obj.name;
    this.setState({ responsibleTooltip: obj });
  };

  closeCreationOfReminder = () => {
    this.setState({
      creationOfReminder: false
    });
  };

  setDescription = ({ target }) => {
    let description = target.value;
    if (description.length > 0) {
      this.setState({ description, descriptionError: false });
      target.classList.contains("redBorder")
        ? target.classList.remove("redBorder")
        : void 0;
    } else {
      target.classList.add("redBorder");
      this.setState({ descriptionError: true });
    }
  };

  checkReminders = el => {
    return el.reminders && el.reminders.length > 0;
  };

  changeReminder = id => {
    InmunityApi.getPrepopulationOfReminder(id).then(
      res => {
        this.setState({
          currentReminderId: id,
          modalVisible: true,
          isCreatePopup: false,
          reminderType:
            res.type === 1
              ? {
                value: "Physician Followup",
                label: "Physician Followup"
              }
              : {
                value: "Patient Followup",
                label: "Patient Followup"
              },
          physicianTooltip: {
            name: res.physician,
            id: res.physician_id
          },
          responsibleTooltip: {
            name: res.responsible,
            id: res.responsible_id
          },
          patientIdTooltip: {
            immunity_id: res.immunity,
            hadm_id: res.hadm_id
          },
          description: res.description,
          reminderDate: moment(res.date)
        });
      }
    );
  };

  editReminder = () => {
    let obj = {};
    if (this.state.reminderType.label === "Patient Followup") {
      obj.type = 0;
    } else if (this.state.reminderType.label === "Physician Followup") {
      obj.type = 1;
    } else {
      obj.type = 2;
    }
    obj.date = moment(this.state.reminderDate).format();
    if (this.state.reminderType.label === "Others") {
      obj.physician_id = null;
      obj.hadm_id = null;
    } else if (this.state.reminderType.label === "Patient Followup") {
      obj.physician_id = null;
      obj.hadm_id = this.state.patientIdTooltip.hadm_id;
    } else if (this.state.reminderType.label === "Physician Followup") {
      obj.physician_id = this.state.physicianTooltip.id;
      obj.hadm_id = this.state.patientIdTooltip.hadm_id;
    }
    obj.description = this.state.description;
    obj.responsible_id = this.state.responsibleTooltip.id
      ? this.state.responsibleTooltip.id
      : null;

    InmunityApi.patchReminder(
      this.state.currentReminderId,
      obj
    ).then(res => {
      this.props.getAllReminders();
      this.setState({
        modalVisible: false
      });
    });
  };

  deleteReminder = () => {
    InmunityApi.deleteReminder(
      this.state.currentReminderId
    ).then(res => {
      this.props.getAllReminders();
      this.setState({
        modalVisible: false
      });
    });
  };

  render() {
    const reminderTypes = [
      { value: "Physician Followup", label: "Physician Followup" },
      { value: "Patient Followup", label: "Patient Followup" },
      { value: "Others", label: "Others" }
    ];



    return (
      <div className="calendar-list-container forImportPopup newTrialWrapp">
        <h3 className="title-section">{i18n.t("calendarList.calendarList")}</h3>
        <div className="prescreening-data">
          <div className="calendar-list-tabs">
            <div className="tabs-calendar-list">
              <Link
                to=""
                onClick={e => {
                  e.preventDefault();
                  this.setMatches();
                }}
              >
                <div
                  className={
                    this.state.tabActive === 1
                      ? "calendar-list-tab tab-active"
                      : "calendar-list-tab"
                  }
                >
                  {i18n.t("calendarList.patientVisits")}
                </div>
              </Link>

              <Link
                to=""
                onClick={e => {
                  e.preventDefault();
                  this.setReminders();
                }}
              >
                <div
                  className={
                    this.state.tabActive === 2
                      ? "calendar-list-tab tab-active"
                      : "calendar-list-tab"
                  }
                >
                  {i18n.t("calendarList.reminders")}
                </div>
              </Link>
            </div>
          </div>

          {this.state.tabActive === 1 ? (
            <div className="prescreening-headers">
              <div
                className="prescreening-head pat-visits"
                onClick={this.sortByVisits}
              >
                Visits
                {/* <img
                  className="arrow-sort"
                  src={require("../../images/arrow-sort.svg")}
                  alt="Sort"
                /> hidden*/}
              </div>
              <div
                className="prescreening-head pat-id"
                onClick={this.sortByPatId}
              >
                Patient ID
                {/* <img
                  className="arrow-sort"
                  src={require("../../images/arrow-sort.svg")}
                  alt="Sort"
                  #hidden
                /> */}
              </div>
              <div
                className="prescreening-head pat-name"
                onClick={this.sortByName}
              >
                Physician Name
                {/* <img
                  className="arrow-sort"
                  src={require("../../images/arrow-sort.svg")}
                  alt=""
                  #hidden
                /> */}
              </div>
              <div className="prescreening-head pat-overview">
                Patient Overview
                {/* <img className="arrow-sort"  src={require("../../images/arrow-sort.svg")} alt="Sort"/> */}
              </div>
            </div>
          ) : (
              <div className="prescreening-headers">
                <div className="prescreening-head rem-type">Type</div>
                <div className="prescreening-head rem-id" onClick={this.sortById}>
                  ID
                <img
                    className="arrow-sort"
                    src={require("../../images/arrow-sort.svg")}
                    alt="Sort Arrow"
                  />
                </div>
                <div
                  className="prescreening-head rem-det"
                  onClick={this.sortByDetails}
                >
                  Details
                <img
                    className="arrow-sort"
                    src={require("../../images/arrow-sort.svg")}
                    alt="Sort Arrow"
                  />
                </div>
                <div className="prescreening-head rem-note">
                  Reminder Note
                {/* <img className="arrow-sort"  src={require("../../images/arrow-sort.svg")} alt="Sort Arrow"/> */}
                </div>
              </div>
            )}
          <div className="calendar-list-table">
            {
              this.props.spinner &&
              <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img
                className="small-spinner calendar-spinner"
                style={{ height: "50px"}}
                src={require("../../images/spinner.gif")}
                alt="Spinner"
              />
              </div>
            }
            {this.state.tabActive === 1 &&
              (this.props.events.length > 0 ? (
                this.props.events.map((row, i) => {
                  let id = row.hadmId;
                  if (new Date(row.timeOfVisit) > new Date()) {
                    return (
                      <div key={i} onClick={() => this.goToDetails(id)}>
                        <div className="calendar-list-row" key={i}>
                          <div className="cl-cell-event-type">
                            {row.timeOfVisit
                              ? moment(row.timeOfVisit).format(
                                "DD/MM/YY - hh:mm A"
                              )
                              : "-"}
                          </div>
                          <div className="cl-cell-event-type row-name">
                            {row.immunity}
                          </div>
                          <div className="cl-cell-physician-date">
                            {row.physicianName}
                          </div>
                          <div className="cl-cell-notes">{row.summary}</div>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                  <div className="spinner-container filters-social__title">
                    Please select a trial name or physician name to display
                    upcoming appointments
                </div>
                ))}{" "}
            {this.state.tabActive === 2 &&
              this.props.reminders.length > 0 &&
              this.props.reminders.map((row, i) => {
                if (row.type !== "Other") {
                  if (new Date(row.date) > new Date()) {
                    return (
                      <div
                        className="calendar-list-row"
                        key={row.id}
                        onClick={() => this.changeReminder(row.id)}
                      >
                        <div className="cl-cell-event-type">{row.type}</div>
                        <div className="cl-cell-event-type">
                          {row.immunity ? row.immunity : "-"}
                        </div>
                        <div className="cl-cell-physician-date">
                          <div className="cell-physician">
                            {row.date
                              ? moment(row.date).format("DD/MM/YY - hh:mm A")
                              : "-"}
                          </div>
                        </div>
                        <div className="cl-cell-notes">
                          {row.description ? row.description : "-"}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                }
                return null;
              })}
            {this.state.tabActive === 2 &&
              this.props.reminders.length === 0 && (
                <div className="spinner-container filters-social__title">
                  Currently no reminders to show
                </div>
              )}
          </div>
          <div className="plusBtnCalLV" onClick={this.addNote}>
            <div className="non-rotatable">+</div>
          </div>
        </div>
        <Popup
          open={this.state.modalVisible}
          onClose={this.closeCreationOfReminder}
          className="popup-createReminder"
        >
          <div className="">
            <div className="contact-form createReminderPopup">
              <div className="closePendingPopupCross" onClick={this.closeModal}>
                <img src={crossicon} alt="Pupup Cross" />
              </div>
              <h3 className="title-form">
                {this.state.isCreatePopup
                  ? i18n.t("calendarList.newReminder")
                  : "Reminder"}
              </h3>
              <form
                className="new-reminder__form"
                action="#"
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <div className="input-container newTrialWrapp">
                  <label htmlFor="reminderType" className="label-modal">
                    {i18n.t("calendarList.reminderType")}
                  </label>
                  <Select
                    name="reminderType"
                    options={reminderTypes}
                    value={this.state.reminderType}
                    onChange={this.handleChangeReminderType}
                  />
                  {this.state.reminderTypeError && (
                    <p className="form-text-error">
                      {this.state.reminderTypeError}
                    </p>
                  )}
                </div>
                <div className="input-container">
                  <label htmlFor="reminderDate" className="label-modal">
                    {i18n.t("calendarList.dateReminder")}
                  </label>
                  <DatePicker
                    name="reminderDate"
                    className="date-picker-calendar"
                    selected={this.state.reminderDate}
                    onChange={this.handleChangeCalendar}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="LLL"
                    timeCaption="time"
                  />
                </div>
                {this.state.reminderType &&
                  this.state.reminderType.value !== "Others" && (
                    <div className="input-container newTrialWrapp ntw__ti-inputs forReminderToolTip">
                      <label htmlFor="patientId" className="label-modal">
                        {i18n.t("calendarList.patientId")}
                      </label>
                      <input
                        type="text"
                        name="patientId"
                        className="get-freetext-name"
                        onChange={this.findPatientId}
                        defaultValue={this.state.patientIdTooltip.immunity_id}
                      />
                      {Array.isArray(this.state.patientIdTooltip) &&
                        this.state.patientIdTooltip.length > 0 && (
                          <PatientIdTooltip
                            patientIds={this.state.patientIdTooltip}
                            Mpicked={this.patientPicked}
                            setPatientId
                          />
                        )}
                    </div>
                  )}

                {this.state.reminderType &&
                  this.state.reminderType.value === "Physician Followup" && (
                    <div className="input-container newTrialWrapp ntw__ti-inputs forReminderToolTip">
                      <label htmlFor="physicianName" className="label-modal">
                        {i18n.t("calendarList.physicianName")}
                      </label>
                      <input
                        type="text"
                        name="physicianName"
                        className="get-freetext-name"
                        onChange={this.findPhysicianName}
                        defaultValue={this.state.physicianTooltip.name}
                      />
                      {Array.isArray(this.state.physicianTooltip) &&
                        this.state.physicianTooltip.length > 0 && (
                          <PhysiciansTooltip
                            physiciansNames={this.state.physicianTooltip}
                            Mpicked={this.physicianPicked}
                            isReminder
                          />
                        )}
                    </div>
                  )}

                <div className="input-container">
                  <label htmlFor="description" className="label-modal">
                    {i18n.t("calendarList.description")}
                  </label>
                  <textarea
                    className="textarea-modal"
                    name="description"
                    placeholder={i18n.t("calendarList.addDescription")}
                    onChange={this.setDescription}
                    defaultValue={this.state.description}
                  />
                </div>
                <div className="hr-container">
                  <hr className="form-separator" />
                </div>
                <div className="input-container newTrialWrapp ntw__ti-inputs forReminderToolTip">
                  <label htmlFor="responsible" className="label-modal">
                    {i18n.t("calendarList.responsible")}
                  </label>
                  <input
                    type="text"
                    name="responsible"
                    className="get-freetext-name"
                    onChange={this.findResponsibleName}
                    defaultValue={this.state.responsibleTooltip.name}
                  />
                  {Array.isArray(this.state.responsibleTooltip) &&
                    this.state.responsibleTooltip.length > 0 && (
                      <PhysiciansTooltip
                        physiciansNames={this.state.responsibleTooltip}
                        Mpicked={this.responsiblePicked}
                        isReminder
                      />
                    )}
                </div>
              </form>
              <div className="button-container">
                <div
                  className="cancel-button"
                  onClick={
                    this.state.isCreatePopup
                      ? this.closeModal
                      : this.deleteReminder
                  }
                >
                  {this.state.isCreatePopup
                    ? i18n.t("general.cancel")
                    : "Delete"}
                </div>
                <div
                  onClick={
                    this.state.isCreatePopup
                      ? this.addContactNote
                      : this.editReminder
                  }
                  className="mdForHeaderAndArrow__btn"
                >
                  {this.state.isCreatePopup
                    ? i18n.t("calendarList.createReminder")
                    : "Edit reminder"}
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <Popup
          open={this.state.creationOfReminder}
          onClose={this.closeCreationOfReminder}
          className="popup-newtrial popup-reminderInfo"
        >
          <div className="add-note-contact-container import-container__header">
            <div
              className="closePendingPopupCross"
              onClick={this.closeCreationOfReminder}
            >
              <img src={crossicon} alt="Cross" />
            </div>

            {this.state.textOfCreationOfReminder === "spinner" && <Spinner />}

            {this.state.textOfCreationOfReminder !== "spinner" && (
              <h1>{this.state.textOfCreationOfReminder}</h1>
            )}
          </div>
        </Popup>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    modalGeneric: state.modalGeneric,
    userToken: state.userToken,
    responsibles: state.responsibles,
    reminders: state.reminders
  };
}

export default withRouter(connect(mapStateToProps)(CalendarList));
