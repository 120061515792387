import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function setMedicationsEPCListReducer(
  state = initialState.medicationsEPCList,
  action
) {
  switch (action.type) {
    case types.SET_MEDICATIONS_EPC_LIST:
      return action.medicationsEPCList;
    default:
      return state;
  }
}
